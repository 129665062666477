import gsap from 'gsap';

export default function ReviewViewAnimation(setStart) {
  gsap.to('#reviewScore', {
    scrollTrigger: {
      trigger: '#reviewScore',
      start: 'top bottom',
      // markers: true,
      onEnter: () => setStart(true),
      onEnterBack: () => setStart(true),
      onLeave: () => setStart(false),
      onLeaveBack: () => setStart(false),
    },
  });
}
