import React from 'react';
import * as API from '../../../api/api';
import * as Storage from '../../../common/Storage';
import { DOGIT_V3_EVENT_TYPE } from '../../../common/Constants';

export default function NudgeView({ dgUserNo }) {
  React.useEffect(() => {
    API.createV3EventLog({
      dgUserNo,
      uid: Storage.getV3Uid(),
      tempUid: Storage.getV3TempUid(),
      pageFullUrl: window.location.pathname + window.location.search,
      pageUrl: (window.location.pathname + window.location.search).split('?')[0],
      eventType: DOGIT_V3_EVENT_TYPE.VISITING,
    })
      .then(() => {
        window.location.replace('/list');
      })
      .catch(API.errorHandler);
  }, []);

  return <></>;
}
