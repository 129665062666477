import { css } from '@emotion/css/macro';
import Color from '../../../../common/Color';
import { DOGIT_V3_PROGRAM_TYPE } from '../../../../common/Constants';
import * as _ from 'lodash';
import StyleConstants from '../../../../common/StyleConstants';
import { Fragment } from 'react/cjs/react.production.min';

export default function RecommendView({ type, onClick, isAllInOne = false, onClickOpenDetailModal = undefined }) {
  const program = [
    {
      title: '전문 훈련사의 방문 교육',
      color: '#7462f4',
      type: DOGIT_V3_PROGRAM_TYPE.VST_TRAINING,
    },
    {
      title: '순둥이가 되는 사회화 교육',
      // desc: '친절하고 온순한 강아지 키우기',
      color: '#ffb423',
      type: DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION,
    },
    {
      title: '분리불안 예방 교육',
      // desc: '독립적인 강아지로 자라기',
      color: '#5256d7',
      type: DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY,
    },
    {
      title: '매너견을 위한 배변 교육',
      // desc: '패드 정조준을 위해서!',
      color: '#4bd4c5',
      type: DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING,
    },
    {
      title: '즐거운 산책 매너 교육',
      // desc: '산책으로 스트레스 해소하기',
      color: '#f48395',
      type: DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING,
    },
    {
      title: '고요한 일상을 위한 짖음 교육',
      // desc: '',
      color: '#801370',
      type: DOGIT_V3_PROGRAM_TYPE.BARK,
    },
    {
      title: '올인원 교육',
      // desc: '퍼피 때 필요한 교육 TOP4',
      color: '#48bcf6',
      type: DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE,
    },
  ];
  const style = css`
    max-width: ${StyleConstants.MAX_WIDTH}px;
    width: 100%;
    margin: 40px 0;
    h1 {
      font-size: 18px;
      letter-spacing: -0.67px;
      line-height: 1.44;
      color: #42607f;
      margin: 0 0 20px 27px;
      b {
        font-weight: bold;
      }
    }
  `;

  const list = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: calc(${StyleConstants.MAX_WIDTH}px - 54px);
    height: 90px;
    border-radius: 10px;
    border: solid 1px #cacede;
    margin: 10px 0;
    div {
      display: flex;
      flex-direction: column;
      margin-left: 22px;
      h2 {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.52px;
        color: #42607f;
        line-height: 1.38;
      }
      h3 {
        font-size: 14px;
        letter-spacing: -0.46px;
        color: #42607f;
        line-height: 1.57;
      }
      button {
        width: 75px;
        height: 28px;
        border: 10px;
        border-radius: 10px;
        margin-top: 7px;
        color: ${Color.WHITE};
        font-weight: bold;
        font-size: 12px;
        letter-spacing: -0.39px;
      }
    }
    img {
      width: 55px;
      height: 55px;
      margin-right: 16px;
    }
  `;

  const listStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  return (
    <article className={style} id='program-list'>
      {type === DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE ? (
        <h1>
          <b>올인원이 부담된다면,</b>
          <br />
          이런 교육은 어떠세요? 💁🏻‍♀️
        </h1>
      ) : (
        <h1>
          <b>도깃이 추천</b>하는
          <br />
          프로그램 살펴보기!
        </h1>
      )}
      <div className={listStyle}>
        {program.map((el, idx) =>
          el.type === DOGIT_V3_PROGRAM_TYPE.VST_TRAINING ||
          el.type === DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING ||
          el.type === DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING ||
          el.type === DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION ||
          el.type === DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE ? (
            <Fragment key={idx} />
          ) : (
            <article
              id={`program-detail-${el.type}`}
              key={idx}
              className={list}
              style={{ display: type !== el.type || !type ? 'flex' : 'none' }}
              onClick={_.debounce(() => {
                onClick(el.type);
              }, 300)}
            >
              <div>
                <h3>{el.desc}</h3>
                <h2>{el.title}</h2>
                <button style={{ backgroundColor: el.color, width: '75px' }}>자세히 보기</button>
              </div>
              <img
                src={
                  el.type === DOGIT_V3_PROGRAM_TYPE.VST_TRAINING
                    ? '//static.dogmate.co.kr/dogit/landing-v3/program-detail/dogit-v3-jerry-choi.png'
                    : `//static.dogmate.co.kr/dogit/landing-v3/program-detail/dogit-v3-dog-profile${el.type}.png`
                }
              />
            </article>
          )
        )}
      </div>
    </article>
  );
}
