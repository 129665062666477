import * as Util from '../../common/Util';
import * as React from 'react';
import PrescriptionLandingView from './view/PrescriptionLandingView';
import { UserInfoContext } from '../../components/PageWrapper';
import { DOGIT_V3_EVENT_TYPE } from '../../common/Constants';

export default function PrescriptionLandingContainer() {
  const context = React.useContext(UserInfoContext);

  React.useEffect(() => {
    Util.createEventLog(context.dgUserNo, context.hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  });

  const onClickGotoTypeform = () => {
    Util.createEventLog(
      context.dgUserNo,
      context.hpNo,
      DOGIT_V3_EVENT_TYPE.CTA_FLOATING,
      '/prescription-typeform?id=pWBjfIJa'
    );
  };

  return <PrescriptionLandingView onClickGotoTypeform={onClickGotoTypeform} />;
}
