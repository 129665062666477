import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import { DOGIT_V3_PROGRAM_TYPE } from '../../../common/Constants';
import StyleConstants from '../../../common/StyleConstants';

const style = css`
  width: 100%;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
`;
const imgWrp = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
  }
`;
const btnStyle = css`
  left: 0;
  margin: 0 auto;
  width: 100%;
  background-color: #f48c95;
  color: ${Color.WHITE};
  font-size: 22px;
  font-weight: bold;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 50px;
  margin-top: -1px;
`;
const bannerWrp = css`
  margin: 0 auto;
  width: 80%;
  img {
    width: 100%;
  }
`;
const btnImgWrp = css`
  width: 80%;
  img {
    width: 100%;
  }
`;

export default function PrescriptionView({
  data,
  prescriptionInfo,
  prescriptionNo,
  dgUserNo,
  onClickGotoDetail,
  onClickGotoMission,
  firstMissionCode,
  onClickGotoMy,
  end,
}) {
  return (
    <article className={style}>
      <div className={imgWrp}>
        <img src={prescriptionInfo.get(prescriptionNo).img} />
      </div>
      <>
        {prescriptionNo < 100 || prescriptionNo === 114 ? (
          <>
            {prescriptionInfo.get(prescriptionNo).isSelf && (
              <div className={bannerWrp}>
                <img
                  src={`//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit-v3.2-prescription-${
                    prescriptionInfo.get(prescriptionNo).programType
                  }-v3.png`}
                />
                <button
                  id='self_btn'
                  onClick={() => onClickGotoDetail(prescriptionInfo.get(prescriptionNo).programType)}
                  className={btnStyle}
                >
                  {' '}
                  {'신청하기 >'}
                </button>
              </div>
            )}
            {prescriptionNo === 114 ? (
              <div className={btnImgWrp} onClick={onClickGotoMy}>
                <img src='//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v4_prescription_btn.png' />
              </div>
            ) : (
              <div className={bannerWrp} style={{ marginTop: '14px' }}>
                <img src={`//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit-v3.2-prescription-vst-v2.png`} />
                <button
                  id='vst_btn'
                  onClick={() => onClickGotoDetail(DOGIT_V3_PROGRAM_TYPE.VST_TRAINING)}
                  className={btnStyle}
                >
                  {'신청하기 >'}
                </button>
              </div>
            )}
          </>
        ) : (
          !end && (
            <div className={btnImgWrp} onClick={onClickGotoMission}>
              <img src='//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v4_prescription_btn.png' />
            </div>
          )
        )}
      </>
    </article>
  );
}
