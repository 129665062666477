import PaymentCompleteView from './view/PaymentCompleteView';
import * as React from 'react';
import * as Sentry from '@sentry/react';
import LoadingView from '../../components/LoadingView';
import * as API from '../../api/api';
import { DOGIT_PAYMENT_STATUS, DOGIT_V3_EVENT_TYPE } from '../../common/Constants';
import * as Util from '../../common/Util';
import { UserInfoContext } from '../../components/PageWrapper';

export default function PaymentCompleteContainer({ dgPmtNo }) {
  const { dgUserNo, hpNo } = React.useContext(UserInfoContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [programName, setProgramName] = React.useState('');
  const [price, setPrice] = React.useState(0);

  React.useEffect(() => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  }, []);

  React.useEffect(() => {
    API.getPmtInfo(dgPmtNo)
      .then((res) => {
        setPrice(res.data.price);
        setProgramName(res.data.programName);
        setIsLoading(false);
      })
      .catch(API.errorHandler);
  }, []);

  const onClick = () => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.SUBMIT, '/my');
  };

  return (
    <>
      {isLoading ? <LoadingView /> : <PaymentCompleteView programName={programName} price={price} onClick={onClick} />}
    </>
  );
}
