import React from 'react';
import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import IconClose from '../../../icons/IconClose';

export default function TermsView({ onClick }) {
  const wrapper = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${Color.WHITE};
    z-index: 65535;
  `;

  const topWrapper = css`
    height: 49px;
    border-bottom: solid 1px #cacede;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 21px 12px;
    button {
      background-color: rgba(0, 0, 0, 0);
    }
  `;

  const title = css`
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.72px;
    color: #494a4a;
  `;

  const bodyWrapper = css`
    background-color: ${Color.WHITE};
    padding: 15px 21px 150px;
    overflow-y: scroll;
  `;
  return (
    <div className={wrapper}>
      <div className={topWrapper}>
        <div className={title}>도깃 이용약관</div>
        <button onClick={onClick}>
          <IconClose strokeColor={Color.MEDIUM_GRAY} size='14' />
        </button>
      </div>
      <div className={bodyWrapper}>
        <>
          <H1>제1조 (목적)</H1>
          <P>
            이 약관은 (주)도그메이트(이하 "회사"라 한다)가 제공하는 반려동물 문제 행동 개선 프로그램 및 기타
            제반서비스(이하 "서비스"라 한다) 를 이용함에 있어 회사와 이용자 사이의 권리ㆍ의무 및 책임사항을 규정함을
            목적으로 합니다.
          </P>
        </>
        <>
          <H1>제2조 (정의)</H1>
          <OlDecimal>
            <Li>
              "회사"란 재화 또는 용역(이하 "재화등"이라 함)을 이용자에게 제공하기 위하여 컴퓨터등 정보통신설비를
              이용하여 재화등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의
              의미로도 사용합니다.
            </Li>
            <Li>"이용자"란 "사이트"에 접속하여 이 약관에 따라 "회사"가 제공하는 "서비스"를 받는 회원을 말합니다.</Li>
            <Li>
              "도깃 사이트"(이하 "사이트"라 한다)란 "회사"에 회원등록한 이용자가 다양한 정보와 서비스를 제공받을 수
              있도록 "회사"가 제작, 운영하는 인터넷 웹사이트를 의미합니다.
            </Li>
          </OlDecimal>
        </>
        <>
          <H1>제3조 (약관 등의 명시와 설명 및 개정)</H1>
          <OlDecimal>
            <Li>
              "회사"는 회사의 상호, 대표자 성명, 주소, 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업
              신고번호 및 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 온라인 서비스 초기화면에 게시합니다.
            </Li>
            <Li>
              "회사"는 전자상거래등에서의 소비자보호에관한법률, 약관의규제에관한법률, 전자거래기본법, 전자서명법,
              정보통신망이용촉진등에관한법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수
              있습니다.
            </Li>
            <Li>
              "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 "사이트"의 초기화면에 그
              적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 이 경우 "회사"는 개정 전 내용과 개정 후 내용을
              명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
            </Li>
            <Li>
              "회사"가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미
              체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관
              조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 "회사"에 송신하여 "회사"의 동의를
              받은 경우에는 개정약관 조항이 적용됩니다.
            </Li>
            <Li>
              "이용자"는 개정약관을 공지 또는 통지 받은 후 30일 내에 개정약관에 대해 명시적으로 거부의사를 표시하지
              아니한 경우 개정약관에 동의한 것으로 봅니다. 또한 이용자 본인이 개정약관의 적용에 동의하지 않는 경우 회원
              탈퇴 또는 약관 철회 요청을 할 수 있습니다.
            </Li>
            <Li>
              회사는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 ‘개별약관’)을 정하여 이를 홈페이지 등을 통해 미리
              공지할 수 있으며. "이용자"는 항상 본 약관 및 개별 약관의 내용에 변경이 있는지 여부를 주시하여야 하며,
              변경사항의 공지가 있을 시에는 이를 확인하여야 합니다.
            </Li>
            <Li>
              이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의 소비자보호에관한법률,
              약관의규제등에관한법률, 공정거래위원회가 정하는 전자상거래등에서의소비자보호지침 및 관계법령 또는 상관례에
              따릅니다.
            </Li>
          </OlDecimal>
        </>
        <>
          <H1>제4조 (서비스의 제공 및 변경)</H1>
          <OlDecimal>
            <Li>"회사"는 다음과 같은 서비스를 제공합니다.</Li>
            <OlLowerAlpha>
              <Li>서비스에 대한 정보 제공 및 구매계약의 체결</Li>
              <Li>구매 고객에 한해 온라인 컨텐츠 제공</Li>
            </OlLowerAlpha>
            <Li>
              "회사"는 재화 또는 용역의 품질 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화
              또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여
              현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
            </Li>
            <Li>
              "회사"가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의
              사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
            </Li>
            <Li>
              전항의 경우 "회사"는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, "회사"가 고의 또는 과실이 없음을
              입증하는 경우에는 그러하지 아니합니다.
            </Li>
          </OlDecimal>
        </>
        <>
          <H1>제5조 (서비스의 중단)</H1>
          <OlDecimal>
            <Li>
              "회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의
              제공을 일시적으로 중단할 수 있습니다.
            </Li>
            <Li>
              "회사"는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에
              대하여 배상합니다. 단, "회사"가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
            </Li>
            <Li>
              사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 "회사"는
              제8조에 정한 방법으로 이용자에게 통지합니다.{' '}
            </Li>
          </OlDecimal>
        </>
        <>
          <H1>제6조 (회원가입)</H1>
          <OlDecimal>
            <Li>
              이용자는 "서비스"의 구매를 위해 "회사"가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는
              의사표시를 함으로써 자동적으로 회원가입이 됩니다.
            </Li>
            <Li>
              "회사"는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로
              등록합니다.
            </Li>
            <OlLowerAlpha>
              <Li>
                가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조 제3항에
                의한 회원자격 상실 후 3년이 경과한 자로서 "회사"의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
              </Li>
              <Li>등록 내용에 허위, 기재누락, 오기가 있는 경우</Li>
              <Li>기타 회원으로 등록하는 것이 "회사"의 기술상 현저히 지장이 있다고 판단되는 경우</Li>
            </OlLowerAlpha>
            <Li>회원가입계약의 성립시기는 "회사"의 승낙이 회원에게 도달한 시점으로 합니다.</Li>
          </OlDecimal>
        </>
        <>
          <H1>제7조 (회원 탈퇴 및 자격 상실 등)</H1>
          <OlDecimal>
            <Li>
              회원자격은 도깃 서비스 신청 후 탈퇴하는 시점까지 유지됩니다. 회원이 회원 탈퇴를 원하는 경우 "회사"에 다음
              방법으로 요청해야 하며, "회사"는 이에 대해 업무일 기준 5일 이내 처리하여야 합니다.
            </Li>
            <OlLowerAlpha>
              <Li>
                회원의 이름, 연락처에 대한 정보를 "회사"에서 지정한 메일 주소로 발송한다. (메일 주소 :
                help@dogmate.co.kr )
              </Li>
            </OlLowerAlpha>
            <Li>회원이 다음 각호의 사유에 해당하는 경우, "회사"는 회원자격을 제한 및 정지시킬 수 있습니다.</Li>
            <OlLowerAlpha>
              <Li>가입 신청시에 허위 내용을 등록한 경우</Li>
              <Li>
                "회사"을 이용하여 구입한 재화등의 대금, 기타 "회사"가용에 관련하여 회원이 부담하는 채무를 기일에
                지급하지 않는 경우
              </Li>
              <Li>다른 사람의 "회사" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</Li>
              <Li>"회사"을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</Li>
            </OlLowerAlpha>
            <Li>
              "회사"가 회원 자격을 제한‧정지 시킨후, 동일한 행위가 2회이상 반복되거나 30일이내에 그 사유가 시정되지
              아니하는 경우 "회사"는 회원자격을 상실시킬 수 있습니다.
            </Li>
            <Li>
              "회사"가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록
              말소전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
            </Li>
          </OlDecimal>
        </>
        <>
          <H1>제8조 (회원에 대한 통지)</H1>
          <OlDecimal>
            <Li>
              "회사"가 회원에 대한 통지를 하는 경우, 회원이 "회사"와 미리 약정하여 지정한 연락처 또는 전자우편 주소로 할
              수 있습니다.
            </Li>
            <Li>
              "회사"는 불특정다수 회원에 대한 통지의 경우 "회사" 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다.
              다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
            </Li>
          </OlDecimal>
        </>
        <>
          <H1>제9조 (개인정보보호)</H1>
          <OlDecimal>
            <Li>
              "회사"는 이용자의 정보수집 시 구매계약 이행에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로
              하며 그 외 사항은 선택사항으로 합니다.
            </Li>
            <Bold>필수 입력 사항</Bold>
            <OlLowerCircle>
              <Li>이용자의 식별을 위한 내용: 이름, 전화번호</Li>
              <Li>계약이행의 연락 및 안내 등의 고지를 위한 내용: 전화번호 및 이메일 주소</Li>
              <Li>
                "회사"가 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
              </Li>
            </OlLowerCircle>
            <OlDecimal>
              <Li>
                제공된 개인정보는 당해 이용자의 동의없이 목적외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든
                책임은 "회사"가 집니다. 다만, 다음의 경우에는 예외로 합니다.
              </Li>
              <OlLowerAlpha>
                <Li>도용방지를 위하여 본인확인에 필요한 경우</Li>
                <Li>법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우</Li>
              </OlLowerAlpha>
              <Li>
                "회사"가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명
                및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자,
                제공목적 및 제공할 정보의 내용) 등 정보통신망이용촉진등에관한법률 제22조제2항이 규정한 사항을 미리
                명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
              </Li>
              <Li>
                이용자는 언제든지 "회사"가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며
                "회사"는 이에 대해 지체없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는
                "회사"는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
              </Li>
              <Li>
                "회사"는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한
                이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
              </Li>
              <Li>
                "회사" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는
                당해 개인정보를 지체없이 파기합니다.
              </Li>
            </OlDecimal>
          </OlDecimal>
        </>
        <>
          <H1>제10조 (회사의 의무)</H1>
          <OlDecimal>
            <Li>
              "회사"는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라
              지속적이고, 안정적으로 재화ㆍ용역을 제공하는데 최선을 다하여야 합니다.
            </Li>
            <Li>
              "회사"는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한
              보안 시스템을 갖추어야 합니다.
            </Li>
            <Li>
              "회사"가 상품이나 용역에 대하여 「표시ㆍ광고의공정화에관한법률」 제3조 소정의 부당한 표시ㆍ광고행위를
              함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
            </Li>
            <Li>"회사"는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</Li>
          </OlDecimal>
        </>
        <>
          <H1>제11조 (이용자의 의무)</H1>
          <P>이용자는 다음 행위를 하여서는 안됩니다.</P>
          <OlDecimal>
            <OlDecimal>
              <Li>신청 또는 변경시 허위 내용의 등록</Li>
              <Li>타인의 정보 도용</Li>
              <Li>"회사"에 게시된 정보의 변경</Li>
              <Li>"회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</Li>
              <Li>"회사" 기타 제3자의 저작권 등 지적재산권에 대한 침해</Li>
              <Li>"회사" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</Li>
            </OlDecimal>
          </OlDecimal>
        </>
        <>
          <H1>제12조 (저작권의 귀속 및 이용제한)</H1>
          <OlDecimal>
            <Li>"회사"가 작성한 저작물에 대한 저작권 기타 지적재산권은 "회사"에 귀속합니다.</Li>
            <Li>
              이용자는 "회사"를 이용함으로써 얻은 정보 중 "회사"에게 지적재산권이 귀속된 정보를 "회사"의 사전 승낙없이
              복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는
              안됩니다.
            </Li>
            <Li>"회사"는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.</Li>
          </OlDecimal>
        </>
        <>
          <H1>제13조 (면책조항)</H1>
          <OlDecimal>
            <Li>
              회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한
              책임이 면제됩니다.
            </Li>
            <Li>회사는 회원의 귀책사유로 인하여 발생한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.</Li>
            <Li>
              회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지
              않습니다.
            </Li>
            <Li>
              회사는 이용자 상호간 또는 이용자와 제3자 간에 콘텐츠를 매개로 하여 발생한 분쟁 등에 대하여 책임을 지지
              않습니다.
            </Li>
          </OlDecimal>
        </>
        <>
          <H1>제14조 (분쟁해결)</H1>
          <OlDecimal>
            <Li>
              "회사"는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여
              피해보상처리기구를 설치ㆍ운영합니다.
            </Li>
            <Li>
              "회사"는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가
              곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
            </Li>
            <Li>
              "회사"와 이용자간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회
              또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
            </Li>
          </OlDecimal>
        </>
        <>
          <H1>제15조 (재판권 및 준거법)</H1>
          <OlDecimal>
            <Li>
              "회사"와 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는
              경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지
              않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
            </Li>
            <Li>"회사"와 이용자간에 제기된 전자상거래 소송에는 국내법을 적용합니다.</Li>
          </OlDecimal>
        </>
        <br />
        <Bold>부칙</Bold>
        <P>이 약관은 2021년 5월 17일부터 시행합니다.</P>
      </div>
    </div>
  );
}

export function P({ children }) {
  const p = css`
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.52px;
    color: ${Color.LIGHT_DARK_GRAY};
  `;

  return <p className={p}>{children}</p>;
}

export function H1({ children }) {
  const h1 = css`
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: -0.67px;
    margin: 13px 0;
    color: #404141;
  `;

  return <div className={h1}>{children}</div>;
}

export function OlDecimal({ children }) {
  const ol = css`
    list-style-type: decimal;
    margin-left: 14px;
  `;

  return <ol className={ol}>{children}</ol>;
}

export function OlLowerAlpha({ children }) {
  const ol = css`
    list-style-type: lower-alpha;
    margin-left: 14px;
  `;

  return <ol className={ol}>{children}</ol>;
}

export function OlLowerCircle({ children }) {
  const circle = css`
    list-style-type: circle;
    margin-left: 28px;
  `;

  return <ol className={circle}>{children}</ol>;
}

export function Li({ children }) {
  const li = css`
    font-size: 14px;
    line-height: 1.64;
    letter-spacing: -0.46px;
    color: ${Color.LIGHT_DARK_GRAY};
  `;

  return <li className={li}>{children}</li>;
}

export function Bold({ children }) {
  const bold = css`
    font-size: 14px;
    font-weight: bold;
    line-height: 1.64;
    letter-spacing: -0.46px;
    color: ${Color.LIGHT_DARK_GRAY};
  `;
  return <p className={bold}>{children}</p>;
}
