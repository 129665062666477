import * as React from 'react';
import * as Storage from '../../common/Storage';
import * as API from '../../api/api';
import * as Sentry from '@sentry/react';
import * as Util from '../../common/Util';
import axios from 'axios';
import LoginView from './view/LoginView';

const Kakao = window.Kakao;
const SERVICE_TYPE = {
  RAYON: 1,
  DOGIT: 2,
};

export default function LoginContainerV2({ code, state, next }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [kakaoId, setKakaoId] = React.useState('');

  React.useEffect(() => {
    if (code) {
      const grant_type = 'authorization_code';
      const client_id = process.env.REACT_APP_KAKAO_REST_API_KEY;

      axios({
        method: 'POST',
        url: `https://kauth.kakao.com/oauth/token?grant_type=${grant_type}&client_id=${client_id}&code=${code}&redirect_uri=${process.env.REACT_APP_URL}/login`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      }).then((res) => {
        const accessToken = res.data.access_token;
        const refreshToken = res.data.refresh_token;

        Kakao.Auth.setAccessToken(accessToken);

        setTimeout(() => {
          Kakao.API.request({
            url: '/v2/user/me',
            success: (res) => {
              const kakaoAccount = res['kakao_account'];
              const params = {
                refreshToken,
                kakaoId: res.id,
                phoneNumber: kakaoAccount['phone_number'] || '',
                email: kakaoAccount['email'] || '',
                ageRange: kakaoAccount['age_range'] || '',
                profileImg: (kakaoAccount.profile && kakaoAccount.profile['profile_image_url']) || '',
                serviceType: SERVICE_TYPE.DOGIT,
              };

              Storage.setPhoneNumber(kakaoAccount['phone_number']);

              API.requestKakaoToken(params)
                .then((res) => {
                  setKakaoId(res.data.kakaoId);
                })
                .then(() => {
                  return API.requestCsrfToken({
                    kakaoId: res.id,
                    phoneNumber: kakaoAccount['phone_number'] || '',
                    email: kakaoAccount['email'] || '',
                    isRecvMkt: Storage.getIsRecvMkt(),
                  });
                })
                .then((res) => {
                  if (res.data.isNewUser) {
                    if (process.env.REACT_APP_ENV === 'production') {
                      if (!window.wcs_add) window.wcs_add = {};
                      window.wcs_add['wa'] = 's_568cb1d0f9ec';

                      if (window.wcs) {
                        const _nasa = {};
                        _nasa['cnv'] = window.wcs.cnv('2', '1');
                        window.wcs.inflow();
                        window.wcs_do(_nasa);
                      }
                      try {
                      } catch (error) {
                        Sentry.captureMessage(error, 'error');
                      }
                    }

                    // Util.sendGaEvent('[도깃_로그인]카카오_가입');
                    Util.sendKakaoPixelCompleteRegistrationEvent('카카오 가입');
                  }
                  Storage.setToken(res.data.csrf);
                  const next = state.replace(/next=/, '');
                  setTimeout(() => window.location.replace(next), 1000);
                });
            },
            fail: (error) => {
              alert('카카오 로그인에 실패하였습니다.');
              setIsLoading(false);
            },
          });
        }, 500);
      });
    }
  }, [code]);

  function loginWithKakao() {
    if (!code) {
      Kakao.Auth.authorize({
        redirectUri: `${process.env.REACT_APP_URL}/login`,
        state: `next=${next}`,
        scope: 'phone_number profile_image account_email gender age_range',
      });
    }
  }

  return <LoginView kakaoLogin={loginWithKakao} onClickBack={() => window.history.back()} />;
}
