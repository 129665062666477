import * as _ from 'lodash';
import { css } from '@emotion/css/macro';
import StyleConstants from '../../../common/StyleConstants';
import {
  DOGIT_CHALLENGE_LOG_STATUS,
  DOGIT_CHALLENGE_STATUS,
  DOGIT_V3_PROGRAM_CODE,
  DOGIT_V3_PROGRAM_TYPE,
} from '../../../common/Constants';
import IconPrev from '../../../icons/IconPrev';
import IconClose from '../../../icons/IconClose';
import moment from 'moment';
import * as API from '../../../api/api';
import axios from 'axios';
import { useState } from 'react';
import LoadingView from '../../mission/subview/LoadingView';
import IconCamera from '../../../icons/IconCamera';
import IconMediaPlay from '../../../icons/IconMediaPlay';

const style = css`
  width: 90vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
`;
const descWrp = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin-top: 57px;
`;
const desc = css`
h1 {
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.67px;
  color: #196497;
  margin-bottom: 4px;
}
p {
  font-size 12px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #42607f;
}
`;
const divider = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100vw;
  height: 5px;
  background-color: #ecf0f1;
`;
const prescriptionBtnStyle = css`
  width: 95%;
  height: 30px;
  border-radius: 15px;
  border: solid 1px #6742ce;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  p {
    color: #6742ce;
  }
`;
const articleWrp = css`
  margin-left: 30px;
  h2 {
    margin: 25px 0 10px;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.67px;
    color: #42607f;
    font-weight: bold;
  }
`;
const articleStyle = css``;
const challengeWrp = css`
  width: 95%;
  margin-top: 30px;
  h2 {
    font-size: 18px;
    font-weight: bold;
    color: #42607f;
    margin-bottom: 4px;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.52px;
    color: #757575;
  }
  h3 {
    margin-top: 20px;
    color: #4755d0;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.67px;
    font-weight: bold;
  }
  #progress {
    width: 100%;
    height: 13px;
    -webkit-appearance: none;
  }
  #progress::-webkit-progress-bar {
    border-radius: 6.5px;
    background-color: #e7ecf8;
  }
  #progress::-webkit-progress-value {
    border-radius: 6.5px;
    background-color: #f48c95;
  }
`;
const cntWrp = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 14px;
    letter-spacing: -0.52px;
    font-weight: bold;
    color: #f48c95;
    border-radius: 5px;
    border: solid 1px #f58c95;
    margin-top: 7px;
    padding: 5px;
  }
  p {
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.52px;
    color: #757575;
  }
`;
const certImgWrp = css`
  width: 100%;
  margin: 0 auto;
`;
const certImgRow = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
`;
const certImgStyle = css`
  width: 10vw;
  height: 10vw;
  border-radius: 5px;
  background-color: #e7ecf8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.29vw;
  position: relative;
  div {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }
  p {
    size: 14px;
    color: #c4c7d3;
  }
  img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const certWrp = css`
  width: 95%;
  margin-bottom: 35px;
  h2 {
    font-size: 18px;
    font-weight: bold;
    color: #42607f;
    margin-bottom: 7px;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.52px;
    color: #4b4b4b;
  }
  h3 {
    margin-top: 20px;
    color: #4b4b4b;
    font-weight: bold;
    font-size: 15px;
  }
  ul {
    margin-top: 12px;
    margin-left: 8px;
    li {
      font-size: 13px;
      list-style-type: '・ ';
      letter-spacing: -0.52px;
      line-height: 24px;
      color: #4b4b4b;
    }
  }
`;
const refundWrp = css`
  ${certWrp}
  ul {
    margin: 8px 0 0 8px;
    li {
      list-style: '• ';
      list-style-position: outside;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.52px;
      color: #4b4b4b;
      b {
        font-weight: bold;
      }
    }
  }
`;
const floatingBtnWrp = css`
  position: fixed;
  width: 100vw;
  bottom: 40px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 655;
`;
const floatingBtn = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93%;
  height: 50px;
  background-color: #f48c95;
  border-radius: 26px;
  p {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: -0.43px;
    color: white;
  }
`;
const iconWrp = css`
  width: 8px;
  height: 25px;
  position: absolute;
  top: 14px;
  left: 14px;
`;
const certCautionWrp = css`
  background-color: #ffe3e3;
  width: 95%;
  padding: 8px 13px;
  border-radius: 8px;
  margin-bottom: 29px;
  h1 {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.45px;
    color: #d0021b;
    font-weight: bold;
    margin-bottom: 3px;
  }
  p {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.45px;
    line-height: 18px;
    color: #4b4b4b;
  }
`;
const complete14Style = css`
  font-size: 12px;
  line-height: 2.67;
  letter-spacing: -0.45px;
  margin: 18px 0 0 0;
  color: #42607f;
  b {
    font-weight: bold;
  }
  #target {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 32px;
    width: 100%;
    margin: 0 auto 10px;
    border: 1px solid #42607f;
    border-radius: 8px;
  }
  #target:after {
    content: '';
    position: absolute;
    border-top: 10px solid #fff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    bottom: -8px;
    left: 8px;
  }
  #target:before {
    content: '';
    position: absolute;
    border-top: 10px solid #42607f;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    bottom: -10px;
    left: 8px;
  }
`;
const challengeMediaWrp = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const videoUrlStyle = css`
  border: dashed #c7cdda 1px;
  width: calc(20vw + 9px);
  height: calc(30vw + 18px);
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  b {
    font-weight: bold;
  }
`;
const uploadVideoBtnStyle = css`
  background-color: #e7ecf8;
  width: 15vw;
  border-radius: 21px;
  height: 22px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const hasVideoUrlStyle = css`
  border: solid rgba(0, 0, 0, 0) 1px;
  width: calc(20vw + 9px);
  height: calc(30vw + 18px);
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  position: relative;
  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
    z-index: 10;
    border-radius: 5px;
  }
  div {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    font-size: 10px;
    line-height: 22px;
    letter-spacing: -0.37px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 100;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export default function ChallengeInfoView({
  challengeInfo,
  articleList,
  logImgMap,
  onClickOpenImgView,
  onClickGotoPrescription,
  onClickGotoMission,
  isOpenImgView,
  onClickGotoMy,
  setVideoSrc,
  setIsOpenVideoView,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const onChangeVideo = (event) => {
    const file = event.target.files[0];
    const newFileName = String(moment().format('YYYYMMDDHHmmss'));

    setIsLoading(true);

    API.createVideoURL({
      dgUserProgramNo: challengeInfo.dgUserProgramNo,
      fileName: newFileName,
      isAfter: event.target.id === 'camera-after',
    })
      .then((res) => {
        const signedUrl = res.data;

        return axios({
          method: 'PUT',
          url: signedUrl,
          data: file,
        });
      })
      .then(() => {
        API.updateVideoURL({
          dgUserProgramNo: challengeInfo.dgUserProgramNo,
          videoUrl: newFileName,
          isAfter: event.target.id === 'camera-after',
        }).then(() => {
          window.location.reload();
        });
      });
  };
  return (
    <article className={style}>
      {isLoading && <LoadingView alpha={0.8} children={<div>영상을 업로드하고 있습니다</div>} />}
      {/*
        // * 상단
      */}
      <div className={iconWrp} onClick={onClickGotoMy}>
        <IconPrev width='8px' height='25px' strokeColor='#595d5d' />
      </div>
      <section className={descWrp}>
        <div className={desc}>
          <h1>{challengeInfo.programName}</h1>
          <p style={{ fontSize: '12px' }}>프로그램 이용기간</p>
          <p style={{ fontSize: '12px' }}>{`${challengeInfo.startYmd} ~ ${challengeInfo.endYmd}`}</p>
        </div>
        <div className={imgWrp('115px')}>
          <img
            src={`//static.dogmate.co.kr/dogit/v4/challenge-info/dogit-v4-challenge-info-title-${
              challengeInfo.programCode === DOGIT_V3_PROGRAM_CODE.SEPARATION_CHALLENGE
                ? DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY
                : DOGIT_V3_PROGRAM_TYPE.BARK
            }.png`}
          />
        </div>
      </section>
      <div className={prescriptionBtnStyle} onClick={() => onClickGotoPrescription(challengeInfo)}>
        <p>진단 확인하기</p>
      </div>
      <div className={divider} />
      {/*
        // * 아티클
      */}
      {/* <section className={articleWrp}>
        <h2>📚 피가 되고 살이 되는 아티클</h2>
        {!_.isEmpty(articleList) &&
          articleList.map((article, idx) => <div key={`article${idx}`} className={articleStyle}></div>)}
      </section> */}
      {/*
        // * 달성율
      */}
      <section className={challengeWrp}>
        <h2>챌린지 달성율</h2>
        <p>미션은 최대 30회까지 참여가 가능합니다.</p>
        <p>포기하지말고 꾸준히 참여해보세요!</p>
        <h3>{`${challengeInfo.cnt}일 연속 뿌시는 중🔥`}</h3>
        <progress id='progress' max={30} value={challengeInfo.totalCnt} />
        <div className={cntWrp}>
          <div>{`누적 미션 참여 ${challengeInfo.totalCnt}회`}</div>
          <p>30회</p>
        </div>
        {challengeInfo.chlngSt !== DOGIT_CHALLENGE_STATUS.DEFAULT && (
          <div className={complete14Style}>
            <div id='target'>
              🎉&nbsp;<b>연속 14일 달성!</b>&nbsp;남은 미션도 꾸준히 참여해보세요!
            </div>
          </div>
        )}
        <div className={challengeMediaWrp} style={{ marginTop: '22px' }}>
          <div>
            {challengeInfo.beforeVideoUrl ? (
              <div className={hasVideoUrlStyle} style={{ marginRight: '9px' }}>
                <video
                  src={challengeInfo.beforeVideoUrl + '#t=0.5'}
                  preload='metadata'
                  onClick={() => {
                    setVideoSrc(challengeInfo.beforeVideoUrl + '#t=0.5');
                    setIsOpenVideoView(true);
                  }}
                />
                <div>
                  개선 전 영상&nbsp;&nbsp;
                  <IconMediaPlay size={11} strokeColor='#fff' />
                </div>
              </div>
            ) : (
              <div className={videoUrlStyle} style={{ marginRight: '9px' }}>
                <input
                  id='camera-before'
                  type='file'
                  name='camera'
                  accept='video/*'
                  onChange={onChangeVideo}
                  style={{ display: 'none' }}
                />
                <label htmlFor='camera-before'>
                  <p>
                    <b>
                      문제행동
                      <br />
                      영상
                    </b>
                    을<br />
                    올려주세요
                  </p>
                  <div className={uploadVideoBtnStyle}>
                    <IconCamera size={11} strokeColor={'#42607f'} />
                  </div>
                </label>
              </div>
            )}
          </div>
          <div className={certImgWrp}>
            <div className={certImgRow}>
              {Array.from({ length: 5 }).map((el, idx) => {
                const newIdx = idx;
                const log = logImgMap ? logImgMap.get(newIdx) : null;

                return (
                  <div
                    key={newIdx}
                    className={certImgStyle}
                    style={{
                      marginLeft: idx === 0 && 0,
                      marginRight: idx === 4 && 0,
                    }}
                  >
                    {log && log.imgUrl ? (
                      <>
                        {log.challengeLogSt === DOGIT_CHALLENGE_LOG_STATUS.FAIL && (
                          <div
                            onClick={() =>
                              onClickOpenImgView({
                                imgUrl: log.imgUrl,
                                programName: challengeInfo.programName,
                                currentMissionCode: log.currentMissionCode,
                                challengeLogSt: log.challengeLogSt,
                                regDate: log.regDate,
                              })
                            }
                          >
                            <IconClose strokeColor={'#ffffff'} size={16} />
                          </div>
                        )}
                        <img
                          src={log.thumbImgUrl}
                          onClick={() =>
                            onClickOpenImgView({
                              imgUrl: log.imgUrl,
                              challengeLogSt: log.challengeLogSt,
                              programName: challengeInfo.programName,
                              currentMissionCode: log.currentMissionCode,
                              regDate: log.regDate,
                            })
                          }
                        />
                      </>
                    ) : (
                      <p>{newIdx + 1}</p>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={certImgRow}>
              {Array.from({ length: 5 }).map((el, idx) => {
                const newIdx = idx + 5;
                const log = logImgMap ? logImgMap.get(newIdx) : null;
                return (
                  <div
                    key={newIdx}
                    className={certImgStyle}
                    style={{
                      marginLeft: idx === 0 && 0,
                      marginRight: idx === 4 && 0,
                    }}
                  >
                    {log && log.imgUrl ? (
                      <>
                        {log.challengeLogSt === DOGIT_CHALLENGE_LOG_STATUS.FAIL && (
                          <div
                            onClick={() =>
                              onClickOpenImgView({
                                imgUrl: log.imgUrl,
                                programName: challengeInfo.programName,
                                currentMissionCode: log.currentMissionCode,
                                challengeLogSt: log.challengeLogSt,
                                regDate: log.regDate,
                              })
                            }
                          >
                            <IconClose strokeColor={'#ffffff'} size={16} />
                          </div>
                        )}
                        <img
                          src={log.thumbImgUrl}
                          onClick={() =>
                            onClickOpenImgView({
                              imgUrl: log.imgUrl,
                              programName: challengeInfo.programName,
                              currentMissionCode: log.currentMissionCode,
                              challengeLogSt: log.challengeLogSt,
                              regDate: log.regDate,
                            })
                          }
                        />
                      </>
                    ) : (
                      <p>{newIdx + 1}</p>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={certImgRow}>
              {Array.from({ length: 5 }).map((el, idx) => {
                const newIdx = idx + 10;
                const log = logImgMap ? logImgMap.get(newIdx) : null;

                return (
                  <div
                    key={newIdx}
                    className={certImgStyle}
                    style={{
                      marginLeft: idx === 0 && 0,
                      marginRight: idx === 4 && 0,
                    }}
                  >
                    {log?.imgUrl ? (
                      <>
                        {log.challengeLogSt === DOGIT_CHALLENGE_LOG_STATUS.FAIL && (
                          <div
                            onClick={() =>
                              onClickOpenImgView({
                                imgUrl: log.imgUrl,
                                programName: challengeInfo.programName,
                                currentMissionCode: log.currentMissionCode,
                                challengeLogSt: log.challengeLogSt,
                                regDate: log.regDate,
                              })
                            }
                          >
                            <IconClose strokeColor={'#ffffff'} size={16} />
                          </div>
                        )}
                        <img
                          src={log.thumbImgUrl}
                          onClick={() =>
                            onClickOpenImgView({
                              imgUrl: log.imgUrl,
                              programName: challengeInfo.programName,
                              currentMissionCode: log.currentMissionCode,
                              challengeLogSt: log.challengeLogSt,
                              regDate: log.regDate,
                            })
                          }
                        />
                      </>
                    ) : (
                      <p>{newIdx + 1}</p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={challengeMediaWrp} style={{ marginBottom: '22px' }}>
          <div className={certImgWrp}>
            <div className={certImgRow}>
              {Array.from({ length: 5 }).map((el, idx) => {
                const newIdx = idx + 15;
                const log = logImgMap ? logImgMap.get(newIdx) : null;
                return (
                  <div
                    key={newIdx}
                    className={certImgStyle}
                    style={{
                      marginLeft: idx === 0 && 0,
                      marginRight: idx === 4 && 0,
                    }}
                  >
                    {log && log.imgUrl ? (
                      <>
                        {log.challengeLogSt === DOGIT_CHALLENGE_LOG_STATUS.FAIL && (
                          <div
                            onClick={() =>
                              onClickOpenImgView({
                                imgUrl: log.imgUrl,
                                programName: challengeInfo.programName,
                                currentMissionCode: log.currentMissionCode,
                                challengeLogSt: log.challengeLogSt,
                                regDate: log.regDate,
                              })
                            }
                          >
                            <IconClose strokeColor={'#ffffff'} size={16} />
                          </div>
                        )}
                        <img
                          src={log.thumbImgUrl}
                          onClick={() =>
                            onClickOpenImgView({
                              imgUrl: log.imgUrl,
                              programName: challengeInfo.programName,
                              currentMissionCode: log.currentMissionCode,
                              challengeLogSt: log.challengeLogSt,
                              regDate: log.regDate,
                            })
                          }
                        />
                      </>
                    ) : (
                      <p>{newIdx + 1}</p>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={certImgRow}>
              {Array.from({ length: 5 }).map((el, idx) => {
                const newIdx = idx + 20;
                const log = logImgMap ? logImgMap.get(newIdx) : null;
                return (
                  <div
                    key={newIdx}
                    className={certImgStyle}
                    style={{
                      marginLeft: idx === 0 && 0,
                      marginRight: idx === 4 && 0,
                    }}
                  >
                    {log?.imgUrl ? (
                      <>
                        {log.challengeLogSt === DOGIT_CHALLENGE_LOG_STATUS.FAIL && (
                          <div
                            onClick={() =>
                              onClickOpenImgView({
                                imgUrl: log.imgUrl,
                                programName: challengeInfo.programName,
                                currentMissionCode: log.currentMissionCode,
                                challengeLogSt: log.challengeLogSt,
                                regDate: log.regDate,
                              })
                            }
                          >
                            <IconClose strokeColor={'#ffffff'} size={16} />
                          </div>
                        )}
                        <img
                          src={log.thumbImgUrl}
                          onClick={() =>
                            onClickOpenImgView({
                              imgUrl: log.imgUrl,
                              programName: challengeInfo.programName,
                              currentMissionCode: log.currentMissionCode,
                              challengeLogSt: log.challengeLogSt,
                              regDate: log.regDate,
                            })
                          }
                        />
                      </>
                    ) : (
                      <p>{newIdx + 1}</p>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={certImgRow}>
              {Array.from({ length: 5 }).map((el, idx) => {
                const newIdx = idx + 25;
                const log = logImgMap ? logImgMap.get(newIdx) : null;
                return (
                  <div
                    key={newIdx}
                    className={certImgStyle}
                    style={{
                      marginLeft: idx === 0 && 0,
                      marginRight: idx === 4 && 0,
                    }}
                  >
                    {log?.imgUrl ? (
                      <>
                        {log.challengeLogSt === DOGIT_CHALLENGE_LOG_STATUS.FAIL && (
                          <div
                            onClick={() =>
                              onClickOpenImgView({
                                imgUrl: log.imgUrl,
                                programName: challengeInfo.programName,
                                currentMissionCode: log.currentMissionCode,
                                challengeLogSt: log.challengeLogSt,
                                regDate: log.regDate,
                              })
                            }
                          >
                            <IconClose strokeColor={'#ffffff'} size={16} />
                          </div>
                        )}
                        <img
                          src={log.thumbImgUrl}
                          onClick={() =>
                            onClickOpenImgView({
                              imgUrl: log.imgUrl,
                              programName: challengeInfo.programName,
                              currentMissionCode: log.currentMissionCode,
                              challengeLogSt: log.challengeLogSt,
                              regDate: log.regDate,
                            })
                          }
                        />
                      </>
                    ) : (
                      <p>{newIdx + 1}</p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            {challengeInfo.afterVideoUrl ? (
              <div className={hasVideoUrlStyle} style={{ marginLest: '9px' }}>
                <video
                  src={challengeInfo.afterVideoUrl + '#t=0.5'}
                  preload='metadata'
                  onClick={() => {
                    setVideoSrc(challengeInfo.afterVideoUrl + '#t=0.5');
                    setIsOpenVideoView(true);
                  }}
                />
                <div>
                  개선 후 영상&nbsp;&nbsp;
                  <IconMediaPlay size={11} strokeColor='#fff' />
                </div>
              </div>
            ) : (
              <div className={videoUrlStyle} style={{ marginLest: '9px' }}>
                <input
                  id='camera-after'
                  type='file'
                  name='camera'
                  accept='video/*'
                  onChange={onChangeVideo}
                  style={{ display: 'none' }}
                />
                <label htmlFor='camera-after'>
                  <p>
                    <b>
                      문제행동
                      <br />
                      개선 영상
                    </b>
                    을<br />
                    올려주세요
                  </p>
                  <div className={uploadVideoBtnStyle}>
                    <IconCamera size={11} strokeColor={'#42607f'} />
                  </div>
                </label>
              </div>
            )}
          </div>
        </div>
        <div className={certCautionWrp}>
          <h1>🚫 꼭 확인해주세요!</h1>
          <p>
            미션과 무관한 사진인증의 경우 14일 연속 참여했더라도
            <br />
            환급대상에서 자동으로 제외됩니다.
          </p>
        </div>
      </section>
      {/*
        // * 인증 방법
      */}
      <section className={certWrp}>
        <h2>인증 방법 안내</h2>
        <p>☝🏻 미션창에서 '챌린지 완료' 버튼 누르기</p>
        <p>✌🏻 강아지 촬영 후 사진 인증하기</p>
        <p>🤟🏻 인증하기를 누르고 '불주먹' 페이지를 확인하기!</p>
        <div className={imgWrp('100%', 'margin-top: 13px')}>
          <img src='//static.dogmate.co.kr/dogit/v4/challenge-info/dogit-v4-challenge-info-v2.png' />
        </div>
        <h3>🚫&nbsp;&nbsp;아래 사진은 인증 무효됩니다.</h3>
        <ul>
          <li>실내가 아닌 실외에서의 촬영 (ex, 산책 중, 차안, 외부..)</li>
          <li>강아지를 직접 촬영하지 않은 경우 (핸드폰 사진 촬영..)</li>
          <li>사진첩 갤러리를 통해 사진을 업로드한 경우</li>
          <li>강아지의 얼굴만 크게 촬영 된 경우</li>
        </ul>
      </section>

      {challengeInfo.chlngSt !== DOGIT_CHALLENGE_STATUS.DEFAULT && (
        <section className={refundWrp}>
          <h2>환급 안내</h2>
          <ul>
            <li>
              환급을 위해 이용일 종료 후&nbsp;
              <b>
                <u>환급신청서를 작성</u>
              </b>
              해 주셔야 합니다.
            </li>
            <li>환급 신청서 미 작성 시 환급은 되지 않습니다.</li>
            <li>
              환급은 이용일 기간 종료 후 <b>영업일 7일 이내로 결제하신 카드 취소</b>로 진행됩니다.
            </li>
          </ul>
        </section>
      )}

      {!isOpenImgView && challengeInfo.endYmd >= moment().format('YYYY-MM-DD') && (
        <div className={floatingBtnWrp}>
          <div
            className={floatingBtn}
            onClick={() =>
              onClickGotoMission(challengeInfo.dgUserProgramNo, challengeInfo.programCode, challengeInfo.prescriptionNo)
            }
          >
            <p>{`${
              (!_.isEmpty(challengeInfo) &&
                (challengeInfo.nextMissionCode
                  ? challengeInfo.nextMissionCode.split('-')[1]
                  : challengeInfo.currentMissionCode.split('-')[1])) ||
              1
            }회차 미션 참여${
              !_.isEmpty(
                challengeInfo.logs.filter((log) => {
                  if (challengeInfo.nextMissionCode) {
                    return log.currentMissionCode === challengeInfo.nextMissionCode;
                  } else {
                    return log.currentMissionCode === challengeInfo.currentMissionCode;
                  }
                })
              )
                ? ' 완료'
                : ''
            }`}</p>
          </div>
        </div>
      )}
    </article>
  );
}

const imgWrp = (width, imgCss) => {
  return css`
    width: ${width};
    img {
      width: 100%;
      ${imgCss}
    }
  `;
};
