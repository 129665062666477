import { useRef, useEffect } from 'react';
import { createWidget } from '@typeform/embed';
import { css } from '@emotion/css/macro';
import '@typeform/embed/build/css/widget.css';

export default function TypeformView({
  formId,
  callback,
  hpNo,
  userName,
  petName,
  type,
  prevUrl,
  dgPetNo,
  log,
  dgUserProgramNo,
  isV4,
  code,
}) {
  const container = useRef();
  const hidden = {};

  if (hpNo) {
    hidden.hp_no = hpNo;
  }

  if (dgPetNo) {
    hidden.dg_pet_no = dgPetNo;
  }

  if (userName) {
    hidden.user_name = userName;
  }

  if (petName) {
    hidden.pet_name = petName;
  }

  if (type) {
    hidden.type = type;
  }

  if (prevUrl) {
    hidden.prev_url = prevUrl;
  }

  if (log) {
    hidden.log = log;
  }

  if (dgUserProgramNo) {
    hidden.up = dgUserProgramNo;
  }

  if (code) {
    hidden.code = code;
  }

  useEffect(() => {
    document.querySelector('#root').style.overflowY = hidden;
    createWidget(formId, {
      hidden,
      container: container.current,
      onQuestionChanged: () => {
        if (document.querySelector('.tf-v1-widget-close') !== null) {
          document.querySelector('.tf-v1-widget-close').style.display = 'none';
        }
      },
      onSubmit: callback,
    });
  }, []);

  const typeformWrapper = css`
    height: 90vh;
    width: 100%;
    z-index: 8;
    body,
    html {
      position: fixed;
      width: 100%;
      overflow: hidden;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translateZ(0);
    }
  `;

  const typeform = css`
    height: 90%;
    width: 100%;
  `;

  return (
    <div style={{ backgroundColor: isV4 && 'rgb(77, 89, 207)', width: '100vw', height: '100vh', overflowY: 'hidden' }}>
      <div className={typeformWrapper}>
        <div className={typeform} ref={container} />
      </div>
    </div>
  );
}
