import React from 'react';
import HelmetCommon from '../../components/HelmetCommon';
import PageWrapper from '../../components/PageWrapper';
import ProgramDetailContainer from './ProgramDetailContainer';
import * as Util from '../../common/Util';
import { DOGIT_V3_PROGRAM_BTN_ACTION_TYPE } from '../../common/Constants';

export default function ProgramDetailPage() {
  const query = Util.useQuery();
  const type = parseInt(query.get('type'));

  let [ogTitle, ogDesc] = ['', ''];

  if (type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SOCIALIZATION) {
    ogTitle = '강아지 사회화 훈련 쉽고 재미있게 배우기 | 도깃';
    ogDesc = '강아지 사회화 훈련, 도깃 함께라면 전문가 도움 없이 보호자가 스스로 할 수 있어요!';
  } else if (type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SEPARATION_ANXIETY) {
    ogTitle = '강아지 분리불안 예방 훈련 쉽고 재미있게 배우기 | 도깃';
    ogDesc =
      '강아지 분리불안 충분히 예방할 수 있어요. 초보 보호자도 쉽게 따라하는 강아지 훈련 도깃 으로 시작해보세요!';
  } else if (type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.TOILET_TRAINING) {
    ogTitle = '강아지 배변 훈련 쉽고 재미있게 배우기 | 도깃';
    ogDesc =
      '강아지 배변 훈련 때문에 아직도 골치 아픈 일이 있나요? 도깃 배변 훈련 프로그램이면 전문가의 도움없이 보호자 혼자 해결할 수 있어요.';
  } else if (type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.WALKING_TRAINING) {
    ogTitle = '강아지 산책 훈련 쉽고 재미있게 배우기 | 도깃';
    ogDesc =
      '안전하고 건강한 강아지 산책 훈련, 초보 보호자도 쉽게 따라할 수 있어요. 도깃 으로 시작해봐요.';
  } else {
    ogTitle = '새끼 강아지 에게 필요한 모든 훈련 교육들 | 도깃';
    ogDesc =
      '강아지 처음 키우시나요? 새끼 강아지 에게 필요한 모든 퍼피 교육 도깃 에서 확인하세요.  강아지 훈련, 전문가의 도움없이 보호자 스스로 충분히 할 수 있어요!';
  }

  return type ? (
    <>
      <HelmetCommon
        title={ogTitle}
        description={ogDesc}
        keywords='강아지, 퍼피 교육, 훈련, 강아지 훈련, 새끼 강아지, 아기 강아지, 퍼피 클래스, 트레이닝, 교육, 반려견, 애견, 퍼피 트레이닝, 사회화 훈련, 분리불안, 분리불안 훈련, 배변, 배변훈련, 훈련사'
        url={process.env.REACT_APP_URL}
        ogImageUrl='https://static.dogmate.co.kr/dogit/og_image/dogit_og_v3.jpeg'
      />
      <PageWrapper next='/v3-program-detail'>
        <ProgramDetailContainer type={type} />
      </PageWrapper>
    </>
  ) : (
    <></>
  );
}
