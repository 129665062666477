import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import * as _ from 'lodash';
import {
  DOGIT_V3_APPLICATION_TYPE,
  DOGIT_V3_PROGRAM_BTN_ACTION_TYPE,
  DOGIT_V3_PROGRAM_TYPE,
} from '../../../common/Constants';
import StyleConstants from '../../../common/StyleConstants';

const style = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  h1 {
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -0.9px;
    color: #42607f;
    text-align: center;
    b {
      font-weight: bold;
    }
  }
`;
const ticketTop = css`
  width: 273px;
  height: 155px;
  border-radius: 15px 15px 0 0;
  background: linear-gradient(130deg, #4855d1 45%, #50e3c2 123%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  p {
    font-size: 16px;
    line-height: 1.88;
    letter-spacing: -0.6px;
    color: #50e3c2;
  }
  h2 {
    font-size: 48px;
    font-weight: bold;
    line-height: 0.63;
    letter-spacing: -1.8;
    color: ${Color.WHITE};
    margin-top: 4px;
  }
`;
const ticketBottom = css`
  width: 273px;
  height: 130px;
  background-color: ${Color.WHITE};
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  h4 {
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: -0.6px;
    color: #42607f;
    /* margin-left: 29px; */
  }
  h2 {
    color: #4855d1;
    font-size: 30px;
    line-height: 0.73;
    letter-spacing: -1.12px;
    font-weight: bold;
  }
`;
const flexRow = css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 7px 0 0 29px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    border-radius: 5px;
    background: linear-gradient(116deg, #fad961 13%, #f76b1c 143%);
    color: #000;
    height: 22px;
    border: 0;
    padding: 1px;
    margin-left: 4px;
    font-weight: bold;
  }
  h3 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.82px;
    color: #4855d1;
  }
  s {
    font-size: 16px;
    letter-spacing: -0.6px;
    color: #4855d1;
    margin-right: 6px;
  }
`;
const desc = css`
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: -0.45px;
  text-align: center;
  color: #747878;
  margin-top: 20px;
`;
const submitBtn = css`
  background-color: #f48c95;
  width: 224px;
  height: 50px;
  border-radius: 24px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.43px;
  color: ${Color.WHITE};
`;
const imgWrp = css`
  width: 33%;
  z-index: 2;
  margin-top: 20px;
  img {
    width: 100%;
    margin-bottom: -60px;
  }
`;
const titleWrp = css`
  width: 80%;
  margin: 0 auto 0;
  img {
    width: 100%;
  }
`;
const descWrp = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const priceStyle = css`
  margin: 8px 0 0 24px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #4755d0;
`;
const refundStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 0 24px;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -1.05px;
  color: #f48c95;
  img {
    width: 100px;
    height: 20px;
  }
`;

const program = new Map([
  [
    DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION,
    {
      name: '퍼피 사회화 교육 1개월',
      month: '1개월',
      rate: '31%',
      beforePrice: '23,330',
      afterPrice: '16,000',
      perMon: '16,000',
    },
  ],
  [
    DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY,
    {
      name: '퍼피 분리불안 예방 교육 1개월',
      month: '1개월',
      rate: '67%',
      beforePrice: '29,000',
      afterPrice: '15,000',
      perMon: '15,000',
    },
  ],
  [
    DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING,
    {
      name: '퍼피 배변 교육 1개월',
      month: '1개월',
      rate: '30%',
      beforePrice: '20,000',
      afterPrice: '14,000',
    },
  ],
  [
    DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING,
    {
      name: '퍼피 산책 매너 교육 2개월',
      month: '2개월',
      rate: '30%',
      beforePrice: '30,000',
      afterPrice: '21,000',
      perMon: '11,500',
    },
  ],
  [
    DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE,
    {
      name: '올인원 퍼피 트레이닝 3개월',
      month: '3개월',
      rate: '47%',
      beforePrice: '85,000',
      afterPrice: '45,000',
      perMon: '15,000',
    },
  ],
  [
    DOGIT_V3_PROGRAM_TYPE.BARK,
    {
      name: '실내 짖음 교육 1개월',
      month: '1개월',
      rate: '67%',
      beforePrice: '29,000',
      afterPrice: '15,000',
      petMon: '15,000',
    },
  ],
]);

export default function CompleteView({ dgV3ResponseNo, type, onClickGotoLanding }) {
  let [applicationType, programType] = [null, null];
  if (String(type).length === 1) {
    [applicationType, programType] = [null, type];
  } else {
    [applicationType, programType] = [parseInt(String(type)[0]), parseInt(String(type)[1])];
  }

  return (
    <>
      {dgV3ResponseNo && type && applicationType === DOGIT_V3_APPLICATION_TYPE.CUSTOMER_SERVICE ? (
        <CustomerServiceCompleteView type={programType} onClickGotoLanding={onClickGotoLanding} />
      ) : applicationType === DOGIT_V3_APPLICATION_TYPE.FREE ? (
        <>{/* 체험판 시작*/}</>
      ) : (
        <WaitingView
          dgV3ResponseNo={dgV3ResponseNo}
          onClickGotoLanding={onClickGotoLanding}
          programType={programType}
          program={program}
        />
      )}
    </>
  );
}

function WaitingView({ dgV3ResponseNo, onClickGotoLanding, programType, program }) {
  const { name, month, rate, beforePrice, afterPrice } = program.get(programType);
  return (
    <section className={style}>
      <header>
        <img src='' />
        <h1>
          <b>
            선착순이 마감되어
            <br />
            신청에 실패했어요 😩
          </b>
        </h1>
      </header>
      <div className={imgWrp}>
        <img src='//static.dogmate.co.kr/dogit/landing-v3/program-application/v3.2/dogit-v3.2-application-complete-img.png' />
      </div>
      <article className={ticketTop}>
        <p>대기번호</p>
        <h2>{dgV3ResponseNo}번</h2>
      </article>
      <article className={ticketBottom}>
        <div className={titleWrp}>
          <img
            src={`//static.dogmate.co.kr/dogit/landing-v3/program-application/v3.2/dogit-v3.2-application-title-${programType}.png`}
          />
        </div>
        <div className={descWrp}>
          {/* <div className={flexRow}>
            <h3>{month}</h3>
            <div>{rate}</div>
          </div> */}
          <div className={priceStyle}>
            {/* <s>{`정가 ${beforePrice}원`}</s> */}
            {`${beforePrice}원`}
          </div>
          <div className={refundStyle}>
            0원
            <img src='//static.dogmate.co.kr/dogit/landing-v3/program-detail/v3.2/dogit-v3.2-refund.png' />
          </div>
        </div>
      </article>
      <div className={desc}>
        대기번호 순서에 따라
        <br />
        다음 프로그램 오픈 때 다시 안내드리겠습니다.
      </div>
      <button className={submitBtn} onClick={_.debounce(onClickGotoLanding, 300)}>
        홈으로
      </button>
    </section>
  );
}

function CustomerServiceCompleteView({ type, onClickGotoLanding }) {
  const programWrapper = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 87.5%;
    height: 220px;
    border: solid 1px #979797;
    border-radius: 8px;
    background: linear-gradient(113deg, #4755d0 -2%, #6431bc 112%);
    left: 0;
    margin: 120px auto 10px;
    h4 {
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.6px;
      margin: 20px 20px 8px;
      color: ${Color.WHITE};
      b {
        font-weight: bold;
      }
    }
    h3 {
      font-size: 22px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -0.82px;
      margin: 12px 20px 8px;
      color: ${Color.WHITE};
    }
  `;
  const imgWrapper = css`
    height: 30px;
    width: 100%;
    margin: 4px 0 8px 20px;
    img {
      height: 100%;
    }
  `;
  const priceStyle = css`
    margin: 12px 0 0 20px;
    font-size: 30px;
    font-weight: bold;
    line-height: 0.73;
    letter-spacing: -1.12px;
    color: ${Color.WHITE};
    s {
      font-weight: normal;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.6px;
      color: #e1e1e1;
      margin-left: 4px;
    }
  `;
  const pricePerMonthStyle = css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 4px 0 0 20px;
    span {
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.6px;
      color: ${Color.WHITE};
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      border-radius: 5px;
      background: linear-gradient(116deg, #fad961 13%, #f76b1c 143%);
      color: #000;
      height: 22px;
      border: 0;
      padding: 1px;
      margin-left: 4px;
      font-weight: bold;
    }
  `;
  const completeImgWrapper = css`
    display: block;
    left: 0;
    margin: -143px auto 15px;
    width: 180px;
    height: 124px;
    img {
      width: 100%;
      height: 100%;
    }
  `;
  const descWrapper = css`
    line-height: 1.38;
    text-align: center;
    letter-spacing: -0.6px;
    color: #747878;
    b {
      font-weight: bold;
    }
  `;
  const program = [
    {
      month: '3개월 무제한 이용',
      rate: '40%',
      beforePrice: '70,000',
      afterPrice: '49,000',
      perMon: '16,333',
    },
    {
      month: '2개월 무제한 이용',
      rate: '30%',
      beforePrice: '50,000',
      afterPrice: '35,000',
      perMon: '17,500',
    },
    { month: '1개월 무제한 이용', rate: '30%', beforePrice: '20,000', afterPrice: '14,000' },
    {
      month: '2개월 무제한 이용',
      rate: '30%',
      beforePrice: '30,000',
      afterPrice: '21,000',
      perMon: '11,500',
    },
    {
      month: '6개월 무제한 이용',
      rate: '47%',
      beforePrice: '170,000',
      afterPrice: '90,000',
      perMon: '15,000',
    },
  ];
  return (
    <section className={style}>
      <header>
        <h1>
          <b>
            상담신청이
            <br />
            완료
          </b>
          되었습니다 🙋🏻‍♀️
        </h1>
      </header>
      <div className={programWrapper}>
        <div className={completeImgWrapper}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/program-application/dogit-v3-cs-complete.png' />
        </div>
        {type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.ALL_IN_ONE ? <h4>사회화, 분리불안 예방, 산책 매너</h4> : <></>}
        <div className={imgWrapper}>
          <img src={`//static.dogmate.co.kr/dogit/landing-v3/program-detail/v3.1/dogit-v3-title-${type}.png`} />
        </div>
        <h3>{program[type - 1].month}</h3>
        <h2 className={priceStyle}>
          {`${program[type - 1].afterPrice}원`}
          <s>{`정가 ${program[type - 1].beforePrice}원`}</s>
        </h2>
        {program[type - 1].perMon && (
          <div className={pricePerMonthStyle}>
            <span>{`월 ${program[type - 1].perMon}원`}</span>
            <div>{program[type - 1].rate}</div>
          </div>
        )}
      </div>
      <div className={descWrapper}>
        <b>최대한 빠르게 상담을 위해 연락드리겠습니다.</b>
      </div>
      <button className={submitBtn} onClick={_.debounce(onClickGotoLanding, 300)}>
        홈으로
      </button>
    </section>
  );
}
