import React from 'react';
import { css } from '@emotion/css/macro';

export default function IconKakao({ width, height, strokeColor, backgroundColor }) {
  const styleStroke = css`
    fill: ${strokeColor}
  `
  const styleBackground = css`
    fill: ${backgroundColor}
  `

  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <defs>
          <path id="e95wsgpuna" d="M0 0.025L29.927 0.025 29.927 27.125 0 27.125z"/>
      </defs>
      <g fill="none" fillRule="evenodd">
          <g>
              <g>
                  <g transform="translate(-59 -168) translate(59 168)">
                      <mask id="hgr8nhj6hb" fill="#fff">
                          <use xlinkHref="#e95wsgpuna"/>
                      </mask>
                      <path className={styleBackground} d="M14.964.025C6.699.025 0 5.245 0 11.685c0 4.192 2.839 7.865 7.099 9.92-.313 1.152-1.133 4.173-1.298 4.82-.203.802.298.79.626.575.258-.17 4.1-2.744 5.76-3.856.899.132 1.827.202 2.777.202 8.264 0 14.963-5.222 14.963-11.66 0-6.44-6.7-11.66-14.963-11.66" mask="url(#hgr8nhj6hb)"/>
                  </g>
                  <path className={styleStroke} d="M9.238 9H4.762C4.34 9 4 9.313 4 9.699c0 .386.34.698.762.698h1.455v4.921c0 .18.08.355.219.483.136.126.328.199.523.199h.163c.195 0 .385-.073.522-.199.14-.128.22-.302.22-.483v-4.92h1.374c.421 0 .762-.313.762-.7C10 9.314 9.66 9 9.238 9M19.375 14.741h-1.882V9.753c0-.416-.334-.753-.746-.753-.413 0-.747.337-.747.753v5.376c0 .042.005.081.012.12-.008.04-.012.08-.012.12 0 .349.279.631.625.631h2.75c.346 0 .625-.282.625-.63 0-.347-.279-.629-.625-.629M25.843 14.93l-2.465-2.757 2.227-1.897c.301-.256.301-.673 0-.93-.301-.256-.79-.256-1.093 0l-2.757 2.348V9.748c0-.413-.393-.748-.877-.748-.485 0-.878.335-.878.748v5.504c0 .414.393.748.878.748.484 0 .877-.334.877-.748v-1.698l.501-.426 2.332 2.605c.26.295.752.355 1.098.133.347-.222.417-.641.157-.936M11.065 13.321l.92-2.648.03.003.92 2.645h-1.87zm3.897 1.787l-2-5.52-.008-.007c-.128-.336-.497-.581-.937-.581-.489 0-.893.3-.973.695-.014.03-.028.059-.038.09l-1.968 5.323c-.122.35.06.732.405.854.346.123.726-.061.847-.41l.34-.98h2.74l.34.98c.121.349.5.533.846.41.346-.122.528-.504.406-.854z" transform="translate(-59 -168) translate(59 168)"/>
              </g>
          </g>
      </g>
    </svg>
  );
}
