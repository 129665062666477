import * as _ from 'lodash';
import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import StyleConstants from '../../../common/StyleConstants';

const style = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  h1 {
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -0.9px;
    color: #42607f;
    text-align: center;
    b {
      font-weight: bold;
    }
  }
`;
const ticketTop = css`
  width: 273px;
  height: 155px;
  border-radius: 15px 15px 0 0;
  background: linear-gradient(130deg, #4855d1 45%, #50e3c2 123%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  p {
    font-size: 16px;
    line-height: 1.88;
    letter-spacing: -0.6px;
    color: #50e3c2;
  }
  h2 {
    font-size: 48px;
    font-weight: bold;
    line-height: 0.63;
    letter-spacing: -1.8;
    color: ${Color.WHITE};
    margin-top: 4px;
  }
`;
const ticketBottom = css`
  width: 273px;
  height: 130px;
  background-color: ${Color.WHITE};
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  h4 {
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: -0.6px;
    color: #42607f;
    /* margin-left: 29px; */
  }
  h2 {
    color: #4855d1;
    font-size: 30px;
    line-height: 0.73;
    letter-spacing: -1.12px;
    font-weight: bold;
  }
`;
const flexRow = css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 7px 0 0 29px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    border-radius: 5px;
    background: linear-gradient(116deg, #fad961 13%, #f76b1c 143%);
    color: #000;
    height: 22px;
    border: 0;
    padding: 1px;
    margin-left: 4px;
    font-weight: bold;
  }
  h3 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.82px;
    color: #4855d1;
  }
  s {
    font-size: 16px;
    letter-spacing: -0.6px;
    color: #4855d1;
    margin-right: 6px;
  }
`;
const desc = css`
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: -0.45px;
  text-align: center;
  color: #747878;
  margin-top: 20px;
`;
const submitBtn = css`
  background-color: #f48c95;
  width: 224px;
  height: 50px;
  border-radius: 24px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.43px;
  color: ${Color.WHITE};
`;
const imgWrp = css`
  width: 33%;
  z-index: 2;
  margin-top: 20px;
  img {
    width: 100%;
    margin-bottom: -60px;
  }
`;
const titleWrp = css`
  width: 80%;
  margin: 0 auto 0;
  img {
    width: 100%;
  }
`;
const descWrp = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const priceStyle = css`
  margin: 8px 0 0 24px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #4755d0;
`;
const refundStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 0 24px;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -1.05px;
  color: #f48c95;
  img {
    width: 100px;
    height: 20px;
  }
`;

export default function ChallengeWaitView({ responseNo, programType, onClickGotoHome }) {
  return (
    <section className={style}>
      <header>
        <img src='' />
        <h1>
          <b>
            선착순이 마감되어
            <br />
            신청에 실패했어요 😩
          </b>
        </h1>
      </header>
      <div className={imgWrp}>
        <img src='//static.dogmate.co.kr/dogit/landing-v3/program-application/v3.2/dogit-v3.2-application-complete-img.png' />
      </div>
      <article className={ticketTop}>
        <p>대기번호</p>
        <h2>{responseNo}번</h2>
      </article>
      <article className={ticketBottom}>
        <div className={titleWrp}>
          <img
            src={`//static.dogmate.co.kr/dogit/landing-v3/program-application/v3.2/dogit-v3.2-application-title-${programType}.png`}
          />
        </div>
        <div className={descWrp}>
          {/* <div className={flexRow}>
        <h3>{month}</h3>
        <div>{rate}</div>
      </div> */}
          <div className={priceStyle}>
            {/* <s>{`정가 ${beforePrice}원`}</s> */}
            {`29, 000원`}
          </div>
          <div className={refundStyle}>
            0원
            <img src='//static.dogmate.co.kr/dogit/landing-v3/program-detail/v3.2/dogit-v3.2-refund.png' />
          </div>
        </div>
      </article>
      <div className={desc}>
        대기번호 순서에 따라
        <br />
        다음 프로그램 오픈 때 다시 안내드리겠습니다.
      </div>
      <button className={submitBtn} onClick={_.debounce(onClickGotoHome, 300)}>
        홈으로
      </button>
    </section>
  );
}
