import { css } from '@emotion/css/macro';
import Color from '../../../../common/Color';
import StyleConstants from '../../../../common/StyleConstants';
import IconClose from '../../../../icons/IconClose';

const style = css`
  width: 100vw;
  height: 100vh;
`;
const topStyle = css`
  position: fixed;
  border-radius: 30px 30px 0 0;
  top: 48px;
  left: 0;
  z-index: 5;
  background-color: ${Color.LIGHT_WHITE};
  border-bottom: 1px solid ${Color.WHITE};
  width: 100%;
  height: 48px;
  button {
    position: absolute;
    top: 16px;
    right: 30px;
    background-color: none;
  }
`;
const imgWrapper = css`
  position: absolute;
  top: 96px;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
`;
const imgStyle = css`
  width: 100%;
  padding-bottom: 90px;
`;

export default function DetailModalView({ type, onClickClose }) {
  return (
    <div className={style}>
      <div className={topStyle}>
        <button id='detail-modal-close-btn' style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} onClick={onClickClose}>
          <IconClose size='20' strokeColor={'#b1b4c1'} />
        </button>
      </div>
      <div className={imgWrapper}>
        <img className={imgStyle} src={`//static.dogmate.co.kr/dogit/landing-v3/program-detail/dogit-v3-detail-modal-${type}.jpg`} />
      </div>
    </div>
  );
}
