import { useEffect, useState } from 'react';
import { useSpring, animated, config } from 'react-spring';

export default function SpringNumber({ score, isStart, decimal, thousandSeparator, immediate = true, unit = '' }) {
  const [option, setOption] = useState({ number: 0 });

  useEffect(() => {
    if (isStart) {
      setOption({
        reset: false,
        reverse: false,
        number: score,
        delay: 0,
        config: config.default,
        duration: 0,
      });
    } else {
      setOption({ number: 0, immediate: immediate });
    }
  }, [isStart]);

  const { number } = useSpring(option);

  return (
    <animated.div>
      {number.to((n) => {
        if (thousandSeparator) {
          return Number(n.toFixed(decimal)).toLocaleString() + unit;
        } else {
          return n.toFixed(decimal) + unit;
        }
      })}
    </animated.div>
  );
}
