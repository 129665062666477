import React from 'react';
import { css } from '@emotion/css/macro';
import { useSpring, animated } from 'react-spring';
import StyleConstants from '../common/StyleConstants';
import Color from '../common/Color';

export default function LoadingView({ alpha }) {
  const style = css`
    width: 100%;
    height: 100%;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, ${alpha === undefined ? 0.3 : alpha});
  `
  const styleCenter = css`
    position: absolute;
    width: 100%;
    max-width: ${StyleConstants.MAX_WIDTH}px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 20;
    width: 114px;
    height: 95px;
    img {
      width: 100%;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      font-size: 14px;
      color: ${Color.DOGIT_BLUE};
    }
  `

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    }
  }, []);

  return (
    <div className={style}>
      <div className={styleCenter}>
        <img src='//static.dogmate.co.kr/dogit/illust/illust-blue-dog-114x95.png' />
        <div>
          로딩중...
        </div>
      </div>
    </div>
  );
}
