import { css } from '@emotion/css/macro';
import { DOGIT_REFUND_READY_STEP } from '../../common/Constants';
import StyleConstants from '../../common/StyleConstants';
import TypeformView from '../typeform/view/TypeformView';

const style = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100vw;
  height: 100vh;
  margin-top: 69px;
  h1 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    letter-spacing: -0.67px;
    text-align: center;
    color: #42607f;
    margin-top: 9px;
  }
  h2 {
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.52px;
    text-align: center;
    color: #747878;
    margin-bottom: 39px;
  }
`;
const titleImgWrp = css`
  width: 59%;
  img {
    width: 100%;
    height: 100%;
  }
`;
const imgWrp = css`
  margin: 33px 0;
  width: 65%;
  img {
    width: 100%;
    height: 100%;
  }
`;
const btnStyle = css`
  background-color: #f48c95;
  width: 70%;
  height: 50px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.43px;
  border-radius: 26px;
`;

export default function RefundReadyView({ currentStep, up, onClickGotoHome }) {
  return (
    <>
      {currentStep === DOGIT_REFUND_READY_STEP.TYPEFORM ? (
        <>
          <TypeformView
            dgUserProgramNo={up}
            formId={process.env.REACT_APP_ENV !== 'production' ? 'UUrhKHcI' : 'I5kfwJa4'}
            isV4={true}
          />
        </>
      ) : currentStep === DOGIT_REFUND_READY_STEP.COMPLETE ? (
        <article className={style}>
          <div className={titleImgWrp}>
            <img src='//static.dogmate.co.kr/dogit/landing-v3/my/dogit-v4-refund-ready-title.png' />
          </div>
          <div className={imgWrp}>
            <img src='//static.dogmate.co.kr/dogit/landing-v3/my/dogit-v4-refund-ready.png' />
          </div>
          <h2>
            환급은 영업일 7일 이내로
            <br />
            완료될 예정입니다
          </h2>
          <button className={btnStyle} onClick={onClickGotoHome}>
            홈으로 가기
          </button>
        </article>
      ) : (
        <></>
      )}
    </>
  );
}
