import React from 'react';
import moment from 'moment';

export default function RemainTime(props) {
  const [time, setTime] = React.useState(props.noHour ? '00:00' : '00:00:00');
  const [isRunHook, setIsRunHook] = React.useState(false);
  let intervalId = null;

  React.useEffect(() => {
    intervalId = setInterval(() => {
      renderTime(props.quitDate, props.noHour, props.hookAfter5seconds, props.hookAfterExpire);
    }, 1000);
    renderTime(props.quitDate, props.noHour, props.hookAfter5seconds, props.hookAfterExpire)
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  const renderTime = (quitDate, noHour, hookAfter5seconds, hookAfterExpire) => {
    const x = moment(quitDate);
    const y = moment();
    const duration = moment.duration(x.diff(y));

    if (duration.as('seconds') <= 0) {
      // setTime(props.noHour ? '00:00' : '00:00:00');
      if (props.textFormat) {
        setTime(
          props.textFormat
            .replace('{DAY}', '0')
            .replace('{HOUR}', '00')
            .replace('{MINUTE}', '00')
            .replace('{SECOND}', '00')
        )
      }

      if (intervalId) clearInterval(intervalId);
      if (hookAfterExpire) {
        hookAfterExpire();
      }
      return noHour ? '00:00' : '00:00:00';
    }

    const dayAndHour = duration.get('days') * 24 + duration.get('hours');
    const days = duration.get('days') > 0 ? `${duration.get('days')}` : '';
    const hours = duration.get('hours').toString().length > 1 ? duration.get('hours') : `0${duration.get('hours')}`;
    const minutes = duration.get('minutes').toString().length > 1 ? duration.get('minutes') : `0${duration.get('minutes')}`;
    const seconds = duration.get('seconds').toString().length > 1 ? duration.get('seconds') : `0${duration.get('seconds')}`;

    if (!isRunHook && hookAfter5seconds && `${minutes}:${seconds}` < '02:52') {
      hookAfter5seconds();
      setIsRunHook(true);
    }

    if (props.textFormat) {
      setTime(
        props.textFormat
          .replace('{DAY}', days)
          .replace('{HOUR}', hours)
          .replace('{MINUTE}', minutes)
          .replace('{SECOND}', seconds)
      )
    }
    // setTime(noHour ? `${minutes}:${seconds}` : `${days}${hours}:${minutes}:${seconds}`);

    // return noHour ? `${minutes}:${seconds}` : `${days}${hours}:${minutes}:${seconds}`;
  };

  return time;
}