import { css } from '@emotion/css/macro';
import StyleConstants from '../common/StyleConstants';

const style = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  padding: 20px 20px;
  background-color: #e7ecf8;
`;
const iconWrapper = css`
  display: flex;
  flex-direction: row;
  a {
    margin-right: 8px;
    div {
      width: 25px;
      img {
        width: 100%;
      }
    }
  }
`;
const descWrapper = css`
  margin: 17px 0;
`;
const footerDescStyle = css`
  font-size: 12px;
  line-height: 1.83;
  letter-spacing: -0.45px;
  color: #5256d7;
`;

export default function FooterView() {
  return (
    <div className={style}>
      <div className={iconWrapper}>
        <a href='https://www.facebook.com/dogit.kr'>
          <div>
            <img src='//static.dogmate.co.kr/dogit/icon/facebook-icon.png' />
          </div>
        </a>
        <a href='https://www.youtube.com/channel/UCOF0bT0O0O1IAtV6haJ1MDg'>
          <div>
            <img src='//static.dogmate.co.kr/dogit/icon/youtube-icon.png' />
          </div>
        </a>
        <a href='https://post.naver.com/my.naver?memberNo=16183154'>
          <div>
            <img src='//static.dogmate.co.kr/dogit/icon/naver-post-icon.png' />
          </div>
        </a>
      </div>
      <div className={descWrapper}>
        <div className={footerDescStyle}>© 2021 Dogmate. All Rights Reserved.</div>
        <div className={footerDescStyle}>상호 : 주식회사 도그메이트</div>
        <div className={footerDescStyle}>대표자명 : 이하영</div>
        <div className={footerDescStyle}>개인정보책임관리자 : 이하영 </div>
        <div className={footerDescStyle}>사업자등록번호 : 157-88-00212</div>
        <div className={footerDescStyle}>통신판매업신고번호 : 제 2018-서울서초-2349 호</div>
        <div className={footerDescStyle}>서울 마포구 양화로11길 68, 2층</div>
      </div>
    </div>
  );
}
