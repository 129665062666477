import CSTypeformCompleteView from './CSTypeformCompleteView';
import * as Storage from '../../common/Storage';
import * as API from '../../api/api';
import * as Util from '../../common/Util';
import { DOGIT_V3_EVENT_TYPE } from '../../common/Constants';
import * as React from 'react';
import { UserInfoContext } from '../../components/PageWrapper';

export default function CSTypeformCompleteContainer() {
  const { dgUserNo, hpNo } = React.useContext(UserInfoContext);

  const onClickGotoIdx = () => {
    // Util.sendGaEvent('[도깃_V3_사전_질문지_작성_완료_페이지]홈으로_버튼');
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.CTA, '/');
  };
  return <CSTypeformCompleteView onClickGotoIdx={onClickGotoIdx} />;
}
