import { css } from '@emotion/css';
import PrivacyView from '../../../doc/view/PrivacyView';

export default function PrivacyModalView({ onClickClose }) {
  const deem = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    overflow-x: hidden;
    z-index: 999;
  `;

  return (
    <section>
      <div className={deem}>
        <PrivacyView onClickClose={onClickClose} />
      </div>
    </section>
  );
}
