import * as React from 'react';
import * as Util from '../../common/Util';
import * as API from '../../api/api';
import * as _ from 'lodash';
import {
  DOGIT_CHALLENGE_STATUS,
  DOGIT_V3_EVENT_TYPE,
  DOGIT_V3_MISSION_STEP_TYPE,
  DOGIT_V3_PROGRAM_CODE,
  DOGIT_V3_PROGRAM_TYPE,
} from '../../common/Constants';
import { UserInfoContext } from '../../components/PageWrapper';
import MyView from './MyView';
import ChannelButton from '../../components/ChannelButton';
import ChannelService from '../../common/ChannelService';
import MenuView from '../../components/MenuView';
import RefundModalView from './subview/RefundModalView';
import EventModalView from './subview/EventModalView';
import GuideModalView from './subview/GuideModalView';
import TermsView from '../doc/view/TermsView';
import CertModalView from './subview/CertModalView';
import { SettingView } from '../../components/SettingView';
import moment from 'moment';
import { RefundRegisterModalView } from './subview/RefundRegisterModalView';

const prescriptionFormId = new Map([
  [DOGIT_V3_PROGRAM_CODE.SEPARATION_CHALLENGE, process.env.REACT_APP_ENV === 'production' ? 'Lm8HHHyx' : 'kAzl47Dl'],
  [DOGIT_V3_PROGRAM_CODE.BARK_CHALLENGE, process.env.REACT_APP_ENV === 'production' ? 'ZXgejUs7' : 'kAzl47Dl'],
]);

export default function MyContainer() {
  const {
    dgUserNo,
    userName,
    hpNo,
    dgUserProgramList,
    profileImg,
    petList,
    prescriptionNo,
    userStatus,
    notificationTime,
    isRecvNotification,
  } = React.useContext(UserInfoContext);

  const [chlngSt, setChlngSt] = React.useState([]);
  const [isOpenTooltip, setIsOpenTooltip] = React.useState(false);
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  const [isOpenEventModal, setIsOpenEventModal] = React.useState(false);
  const [isOpenGuideModal, setIsOpenGuideModal] = React.useState(false);
  const [isOpenTermsModal, setIsOpenTermsModal] = React.useState(false);
  const [isOpenCertModal, setIsOpenCertModal] = React.useState(false);
  const [isOpenRefundModal, setIsOpenRefundModal] = React.useState(false);
  const [certStartYmd, setCertStartYmd] = React.useState('');
  const [certEndYmd, setCertEndYmd] = React.useState('');
  const [certProgramName, setCertProgramName] = React.useState('');
  const [isOpenSetting, setIsOpenSetting] = React.useState(false);
  const [notiTime, setNotiTime] = React.useState(notificationTime);
  const [isRecvNoti, setIsRecvNoti] = React.useState(isRecvNotification);
  const [reviewUp, setReviewUp] = React.useState(null);

  React.useEffect(() => {
    ChannelService.boot({
      pluginKey: '2809822e-6edb-481d-91a9-76a55c95a057',
      customLauncherSelector: '#dogit-channel-io-button',
      hideChannelButtonOnBoot: true,
    });

    return () => {
      ChannelService.shutdown();
    };
  }, []);

  React.useEffect(() => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  }, []);

  React.useEffect(() => {
    API.getChlngSt(dgUserNo).then((res) => {
      setChlngSt(res.data);
    });
  }, []);

  React.useEffect(() => {
    const list = chlngSt.filter((el) => !el.isCompleteOnboarding);
    if (!_.isEmpty(list)) {
      window.location.href = `/onboarding?no=${list[0].dgUserProgramNo}`;
    }
  }, [chlngSt]);

  const onClickOpenTooltip = () => {
    if (!isOpenTooltip) {
      setIsOpenTooltip(true);
    }
  };
  const onClickCloseTooltip = () => {
    if (isOpenTooltip) {
      setIsOpenTooltip(false);
    }
  };

  const onClickGotoPrescription = (up) => {
    if (!up.prescriptionNo) {
      return alert('미션을 참여하여 진단을 받아보세요');
    }

    API.getMissionCode(up.dgUserProgramNo).then((res) => {
      let missionCode = res.data;

      Util.createEventLog(
        dgUserNo,
        hpNo,
        DOGIT_V3_EVENT_TYPE.PRESCRIPTION_BTN,
        `/prescription?no=${prescriptionNo}&mission-code=${missionCode}&up=${up.dgUserProgramNo}`
      );
    });
  };

  const onClickGotoMission = (dgUserProgramNo, missionCode, programCode, prescriptionNo) => {
    if (prescriptionNo === 114) {
      return alert(
        '전문가 상담 후 프로그램 이용이 가능합니다.\n1522-9666으로 연락드릴 예정이오니 꼭 받아주세요! 😉\n(상담은 평일 11시~18시 사이에 연락드립니다.)'
      );
    } else if (!prescriptionNo) {
      Util.createEventLog(
        dgUserNo,
        hpNo,
        programCode === 'SEPARATION_CHALLENGE'
          ? DOGIT_V3_EVENT_TYPE.MISSION_OPEN_SEPARATION_CHALLENGE
          : DOGIT_V3_EVENT_TYPE.MISSION_OPEN_BARK_CHALLENGE,
        `/prescription-typeform?up=${dgUserProgramNo}&id=${prescriptionFormId.get(programCode)}`
      ).catch(API.errorHandler);
    } else {
      API.getMissionCode(dgUserProgramNo).then((res) => {
        let missionCode = res.data;
        API.createChallengeLog(dgUserProgramNo, missionCode)
          .then((res) => {
            Util.createEventLog(
              dgUserNo,
              hpNo,
              programCode === 'SEPARATION_CHALLENGE'
                ? DOGIT_V3_EVENT_TYPE.MISSION_OPEN_SEPARATION_CHALLENGE
                : DOGIT_V3_EVENT_TYPE.MISSION_OPEN_BARK_CHALLENGE,
              `/mission?step=${DOGIT_V3_MISSION_STEP_TYPE.IN_PROGRESS}&up=${dgUserProgramNo}&log=${res.data}&code=${missionCode}`
            );
          })
          .catch(API.errorHandler);
      });
    }
  };

  const onClickCloseMenu = () => {
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    setIsOpenMenu(false);
  };

  const onClickOpenMenu = () => {
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.MENU_BTN);
    setIsOpenMenu(true);
  };

  const onClickGotoHome = () => {
    // Util.sendGaEvent(`[도깃_v4]마이_메뉴_홈버튼`);
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.INDEX_BTN, '/');
  };

  const onClickOpenRefundModal = (dgUserProgramNo) => {
    setIsOpenRefundModal(true);
    setReviewUp(dgUserProgramNo);
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.REFUND_BTN);
  };

  const onClickCloseRefundModal = () => {
    setIsOpenRefundModal(false);
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.REFUND_BTN);
  };

  const onClickOpenEventModal = () => {
    // Util.sendGaEvent(`[도깃_v4]마이_이벤트_베너`);
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.MODAL);
    setIsOpenEventModal(true);
  };

  const onClickOpenGuideModal = () => {
    // Util.sendGaEvent(`[도깃_v4]마이_이용가이드`);
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.MODAL);
    setIsOpenGuideModal(true);
  };

  const onClickCloseEventModal = () => {
    setIsOpenEventModal(false);
    document.querySelector('body').style.overflowY = 'scroll';
  };

  const onClickCloseGuideModal = () => {
    setIsOpenGuideModal(false);
    document.querySelector('body').style.overflowY = 'scroll';
  };

  const onClickOpenTermsModal = () => {
    // Util.sendGaEvent(`[도깃_v4]마이_이용약관`);
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.MODAL);
    setIsOpenTermsModal(true);
  };

  const onClickCloseTermsModal = () => {
    setIsOpenTermsModal(false);
  };

  const onClickOpenCertModal = (event, programName, startYmd, endYmd) => {
    event.stopPropagation();
    setIsOpenCertModal(true);
    setCertProgramName(programName);
    setCertStartYmd(startYmd);
    setCertEndYmd(endYmd);
  };

  const onClickCloseCertModal = () => {
    setIsOpenCertModal(false);
  };

  const onClickGotoDetail = (programType) => {
    Util.createEventLog(
      dgUserNo,
      hpNo,
      programType === DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY
        ? DOGIT_V3_EVENT_TYPE.SEPARATION_ANXIETY_DETAIL_BTN
        : DOGIT_V3_EVENT_TYPE.BARK_DETAIL_BTN,
      `/v3-program-detail?type=${programType}`
    );
  };

  const onClickGotoChallengeInfo = (up) => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.CHALLENGE_INFO_BTN, `/challenge-info?up=${up}`);
  };

  const chlngCmpltList = chlngSt.filter((el) => el.chlngSt === DOGIT_CHALLENGE_STATUS.COMPLETE);

  const updateAlarmTime = (event) => {
    const time = event.target.value;
    API.updateUserNotificationTime(time).then(() => {
      setNotiTime(time);
    });
  };
  const updateIsRecvNotification = (event) => {
    const isRecvNotification = event.target.checked;
    API.updateIsRecvNotification(isRecvNotification).then(() => {
      setIsRecvNoti(isRecvNotification);
    });
  };
  const onClickOpenSetting = () => {
    setIsOpenSetting(true);
  };
  const onClickCloseSetting = () => {
    setIsOpenSetting(false);
  };

  return (
    <>
      {_.isEmpty(chlngSt.filter((el) => !el.isCompleteOnboarding)) && (
        <>
          {/* {!_.isEmpty(chlngCmpltList) && (
            <onClickGotoRefundTypeformModalView chlngCmpltList={chlngCmpltList} onClickGotoRefundTypeform={onClickGoToRefundTypeform} />
          )} */}
          {isOpenEventModal && <EventModalView onClick={onClickCloseEventModal} />}
          {isOpenGuideModal && (
            <GuideModalView onClick={onClickCloseGuideModal} onClickOpenTermsModal={onClickOpenTermsModal} />
          )}
          {isOpenTermsModal && <TermsView onClick={onClickCloseTermsModal} />}
          {isOpenCertModal && certStartYmd && certEndYmd && certProgramName && (
            <CertModalView
              onClick={onClickCloseCertModal}
              profileImg={profileImg}
              userName={userName}
              startYmd={certStartYmd}
              endYmd={certEndYmd}
              programName={certProgramName}
            />
          )}
          {isOpenRefundModal && (
            <RefundRegisterModalView
              dgUserNo={dgUserNo}
              hpNo={hpNo}
              userName={userName}
              dgUserProgramNo={reviewUp}
              onClickCloseRefundModal={onClickCloseRefundModal}
            />
          )}
          {!isOpenEventModal && !isOpenGuideModal && !isOpenRefundModal && (
            <>
              <MenuView
                userStatus={userStatus}
                isOpenMenu={isOpenMenu}
                onClickOpenMenu={onClickOpenMenu}
                onClickCloseMenu={onClickCloseMenu}
                onClickGotoHome={onClickGotoHome}
                onClickOpenSetting={
                  chlngSt.filter((el) => el.endYmd >= moment().format('YYYY-MM-DD')).length > 0
                    ? onClickOpenSetting
                    : undefined
                }
              />
              {isOpenSetting ? (
                <SettingView
                  onClickCloseSetting={onClickCloseSetting}
                  isRecvNoti={isRecvNoti}
                  notiTime={notiTime}
                  updateIsRecvNotification={updateIsRecvNotification}
                  updateAlarmTime={updateAlarmTime}
                />
              ) : (
                <MyView
                  chlngSt={chlngSt}
                  profileImg={profileImg}
                  userName={userName}
                  petName={petList[0].petName}
                  dgUserProgramList={dgUserProgramList}
                  isOpenTooltip={isOpenTooltip}
                  onClickOpenTooltip={onClickOpenTooltip}
                  onClickCloseTooltip={onClickCloseTooltip}
                  onClickGotoPrescription={onClickGotoPrescription}
                  onClickGotoMission={onClickGotoMission}
                  onClickOpenEventModal={onClickOpenEventModal}
                  onClickOpenGuideModal={onClickOpenGuideModal}
                  onClickOpenCertModal={onClickOpenCertModal}
                  onClickGotoDetail={onClickGotoDetail}
                  onClickGotoChallengeInfo={onClickGotoChallengeInfo}
                  onClickOpenRefundModal={onClickOpenRefundModal}
                  setReviewUp={setReviewUp}
                />
              )}
            </>
          )}
          {_.isEmpty(chlngCmpltList) && !isOpenMenu && !isOpenEventModal && <ChannelButton bottom='40px' />}
        </>
      )}
    </>
  );
}
