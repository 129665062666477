import { css } from '@emotion/css/macro';
import StyleConstants from '../../common/StyleConstants';
import * as _ from 'lodash';
import * as React from 'react';
import { DOGIT_CHALLENGE_STATUS, DOGIT_V3_PROGRAM_CODE, DOGIT_V3_PROGRAM_TYPE } from '../../common/Constants';
import moment from 'moment';

const program = new Map([
  [
    'SEPARATION_CHALLENGE',
    {
      bg: 'linear-gradient(136deg, #4fc793 19%, #2fa0b0 89%)',
      dateColor: '#cafff3',
      titleColor: '#f8e71c',
      barColor: '#6c4edc',
    },
  ],
  [
    'BARK_CHALLENGE',
    {
      bg: 'linear-gradient(108deg, #c284e7 19%, #a186ed 89%)',
      dateColor: '#adbcf0',
      titleColor: '#50e3c2',
      barColor: '#f48c95',
    },
  ],
]);

const tooltipWrp = css`
  position: absolute;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100vw;
`;
const tooltip = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 90%;
  display: block;
  z-index: 100;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #dbdbdb;
  border-radius: 8px;
  div {
    margin: 20px 19px 14px;
    font-size: 14px;
    letter-spacing: -0.52px;
    line-height: 1.43;
    h3 {
      color: #747878;
      font-weight: normal;
      word-break: keep-all;
      b {
        color: #42607f;
        font-weight: bold;
      }
    }
  }
`;
const style = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px 0 110px;
`;
const profileWrp = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.75px;
    color: #42607f;
    margin-top: 22px;
  }
  h2 {
    color: #42607f;
    font-size: 14px;
    margin-top: 15px;
    b {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  #tooltip:after {
    border-top: 0px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #fff;
    content: '';
    position: absolute;
    top: -9px;
    left: 38%;
    z-index: 101;
  }
  #tooltip:before {
    border-top: 0px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #dbdbdb;
    content: '';
    position: absolute;
    top: -10px;
    left: 38%;
    z-index: 101;
  }
`;
const imgWrp = css`
  width: 58px;
  margin-right: 24px;
  img {
    width: 100%;
  }
`;

const defaultProfileImgWrp = css`
  width: 70px;
  height: 70px;
  img {
    width: 100%;
    height: 100%;
  }
`;
const kakaoProfileImgWrp = css`
  height: 70px;
  width: 70px;
  background-size: cover;
  border-radius: 36px;
`;
const prescriptionBtnStyle = css`
  color: #f48c95;
  font-size: 14px;
  letter-spacing: -0.52px;
  width: 130px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0);
  border: solid 1px #f48c95;
  border-radius: 23px;
  margin: 24px 0 0;
`;
const divider = css`
  width: 100%;
  height: 5px;
  background-color: #ecf0f1;
  margin: 30px 0;
`;
const upStyle = css`
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  padding: 18px 0;
  border: 1px solid #dbdbdb;
  h1 {
    font-size: 22px;
    font-weight: bold;
    margin: 25px 0 9px 0;
  }
  h2 {
    color: #f8e71c;
    font-size: 14px;
    letter-spacing: -0.52px;
    font-weight: bold;
    margin-left: 24px;
  }
`;
const upEndStyle = css`
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 12px 0px;
  background-color: #f3f3f3;
  margin-bottom: 11px;
`;
const upInfoStyle = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
const upInfoEndStyle = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
const upInfoDescWrp = css`
  display: flex;
  flex-direction: column;
  h3 {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.52px;
    margin-bottom: 8px;
    margin-left: 24px;
  }
  h4 {
    padding-left: 17px;
    font-size: 12px;
    letter-spacing: -0.45px;
  }
`;
const upInfoDescEndWrp = css`
  display: flex;
  flex-direction: column;
  h3 {
    padding-left: 17px;
    color: #747878;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.52px;
    margin-bottom: 8px;
  }
  s {
    padding-left: 17px;
    font-size: 12px;
    letter-spacing: -0.45px;
    color: #747878;
  }
`;
const progressBarStyle = css`
  width: 80%;
  height: 12px;
  border-radius: 6.5px;
  background-color: #e5edf4;
  margin-left: -18px;
`;
const progressBarDescStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -15px;
  margin-right: -25px;
  img {
    width: 50px;
    height: 18px;
  }
  p {
    font-size: 10px;
    line-height: 1.8;
    letter-spacing: -0.37px;
    text-align: center;
    color: #e5edf4;
  }
`;
const descWrp = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
`;
const inner = css`
  height: 12px;
  border-radius: 6.5px;
`;
const missionBtnStyle = css`
  width: 85%;
  height: 40px;
  margin-top: 3px;
  margin-bottom: 19px;
  background-color: #f48c95;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.6px;
  z-index: 101;
`;

const refundBtnStyle = css`
  width: 85%;
  height: 40px;
  margin-top: -8px;
  margin-bottom: 19px;
  background-color: #f48c95;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.6px;
  z-index: 101;
`;
const banner = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  padding: 9px 0 15px;
  background-color: #f3f3f3;
  border-radius: 8px;
  border: solid 1px #efeaea;
`;
const bannerDescWrp = css`
  margin-left: 19px;
  h1 {
    font-size: 18px;
    letter-spacing: -0.67px;
    line-height: 32px;
    color: #42607f;
    font-weight: bold;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 67px;
    height: 25px;
    background-color: #4755d0;
    border-radius: 5px;
    p {
      color: #fff;
      font-size: 14px;
      letter-spacing: -0.52px;
    }
  }
`;
const bannerImgWrp = css`
  margin-right: 19px;
  width: 70px;
  height: 68px;
  z-index: 101;
  img {
    width: 100%;
    height: 100%;
  }
`;
const prescriptionBtnWrp = css`
  width: 75vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
const separationPrescriptionBtnStyle = css`
  color: #5d84cf;
  font-size: 14px;
  letter-spacing: -0.52px;
  width: 130px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0);
  border: solid 1px #5d84cf;
  border-radius: 23px;
  margin: 24px 0 0;
`;
const barkPrescriptionBtnStyle = css`
  color: #6742ce;
  font-size: 14px;
  letter-spacing: -0.52px;
  width: 130px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0);
  border: solid 1px #6742ce;
  border-radius: 23px;
  margin: 24px 0 0;
`;
const refundReadyBadge = css`
  margin-right: 15px;
  border-radius: 28px;
  border: solid 1px #f8e71c;
  padding: 6px 14px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.52px;
  color: #f8e71c;
`;
const refundReadyEndBadge = css`
  margin-right: 15px;
  border-radius: 28px;
  border: solid 1px #d8d8d8;
  padding: 6px 14px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.52px;
  background-color: #d8d8d8;
  color: #747878;
`;
const eventBannerStyle = css`
  width: 100vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 26px 0;
  background-color: #f7f7f7;
`;
const eventBannerDescWrp = css`
  display: flex;
  flex-direction: column;
  margin-left: 26px;
  h2 {
    font-size: 12px;
    letter-spacing: -0.39px;
    margin-top: 0px;
    color: #5a7698;
  }
  h1 {
    font-size: 14px;
    letter-spacing: -0.46px;
    margin-top: 4px;
    color: #5a7698;
  }
`;
const eventBannerImgWrp = css`
  height: 42px;
  margin-right: 28px;
  img {
    height: 100%;
  }
`;
const certBtnStyle = css`
  width: 88%;
  height: 39px;
  background-color: #fff;
  color: #42607f;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.52px;
  margin-top: 13px;
  border-radius: 20px;
  border: solid 1px #e7e6e6;
`;
const addStyle = css`
  width: 85%;
  height: 52px;
  border-radius: 18px;
  border: dashed 1px #cbcbcb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: -0.45px;
  color: #9a9a9a;
  margin-bottom: 18px;
`;
const addImgWrp = css`
  width: 20px;
  height: 18.6px;
  margin-right: 3px;
  img {
    width: 100%;
  }
`;

export default function MyView({
  chlngSt,
  profileImg,
  userName,
  petName,
  isOpenTooltip,
  onClickOpenTooltip,
  onClickCloseTooltip,
  onClickGotoPrescription,
  onClickGotoMission,
  onClickOpenEventModal,
  onClickOpenGuideModal,
  onClickOpenCertModal,
  onClickGotoDetail,
  onClickGotoChallengeInfo,
  onClickOpenRefundModal,
}) {
  return (
    <>
      <article className={style} onClick={onClickCloseTooltip}>
        <div className={profileWrp}>
          {profileImg ? (
            <div className={kakaoProfileImgWrp} style={{ backgroundImage: `url(${profileImg})` }} />
          ) : (
            <div className={defaultProfileImgWrp}>
              <img src='//static.dogmate.co.kr/dogit/landing-v3/my/dogit-v3.2-my-default-profile.png' />
            </div>
          )}
          <h1>{userName && userName + '님'}</h1>
          <h2>
            👊🏻 <b onClick={onClickOpenTooltip}>전액 환급</b>에 도전하시나요?
          </h2>
          <div
            className={tooltipWrp}
            style={{
              top: document.querySelector('#guide') && document.querySelector('#guide').getBoundingClientRect().y,
            }}
          >
            {isOpenTooltip && (
              <div className={tooltip} id='tooltip'>
                <div>
                  <h3>
                    <b>14일 연속 미션에 참여</b>하세요! 미션 참여를 인정 받기 위해선 <b>반드시 '제출' 버튼</b>을
                    눌러야해요. 제출 후에 나오는 '불주먹'을 꼭 확인하세요!
                  </h3>
                </div>
              </div>
            )}
          </div>
          {/* <div className={prescriptionBtnWrp}>
            {chlngSt
              .filter((el) => el.endYmd >= moment().format('YYYY-MM-DD HH:mm:ss'))
              .map((up, idx) => {
                return (
                  <div key={`prescription-id-${idx}`} id={`prescription-id-${idx}`}>
                    <button
                      id='guide'
                      className={
                        up.programCode === DOGIT_V3_PROGRAM_CODE.SEPARATION_CHALLENGE
                          ? separationPrescriptionBtnStyle
                          : barkPrescriptionBtnStyle
                      }
                      onClick={() => onClickGotoPrescription(up)}
                    >
                      {up.programCode === DOGIT_V3_PROGRAM_CODE.SEPARATION_CHALLENGE
                        ? '분리불안 진단 보기'
                        : '짖음 진단 보기'}
                    </button>
                  </div>
                );
              })}
          </div> */}
          <div className={eventBannerStyle} onClick={onClickOpenEventModal}>
            <div className={eventBannerDescWrp}>
              <h2>🎁 챌린지 후기 이벤트!</h2>
              <h1>리뷰 참여자 전원 1OO% 선물 증정!</h1>
            </div>
            <div className={eventBannerImgWrp}>
              <img src='//static.dogmate.co.kr/dogit/v4/my/dogit-v4-my-banner.png' />
              <img src='//static.dogmate.co.kr/dogit/v4/my/dogit-v4-my-banner.png' />
            </div>
          </div>
        </div>
        {chlngSt
          .filter((up) => up.endYmd >= moment().format('YYYY-MM-DD'))
          .map((up, idx) => {
            const bg = program.get(up.programCode).bg;
            const dateColor = program.get(up.programCode).dateColor;
            const titleColor = program.get(up.programCode).titleColor;
            const barColor = program.get(up.programCode).barColor;

            if (!bg || !dateColor || !titleColor || !barColor || up.startYmd > moment().format('YYYY-MM-DD')) return;

            if (up.endYmd >= moment().format('YYYY-MM-DD')) {
              return (
                <React.Fragment key={`program_${idx}`}>
                  <div
                    className={upStyle}
                    style={{ background: bg }}
                    onClick={() => onClickGotoChallengeInfo(up.dgUserProgramNo)}
                  >
                    <div className={upInfoStyle}>
                      <div className={upInfoDescWrp}>
                        <h3>{up.programName} ‣</h3>
                        <h2>🔥 누적 참여 {up.totalCnt}일 째</h2>
                      </div>
                      {/* {up.chlngSt === DOGIT_CHALLENGE_STATUS.REFUND_READY && (
                      <div className={refundReadyBadge}>환급 예정</div>
                    )} */}
                    </div>
                    <div className={imgWrp}>
                      <img
                        src={`//static.dogmate.co.kr/dogit/v4/my/dogit-v4-my-program-${
                          up.programCode === DOGIT_V3_PROGRAM_CODE.SEPARATION_CHALLENGE
                            ? DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY
                            : DOGIT_V3_PROGRAM_TYPE.BARK
                        }.png`}
                      />
                    </div>
                  </div>
                  <button
                    id={`mission-btn-${up.programCode}`}
                    onClick={() => {
                      onClickGotoMission(
                        up.dgUserProgramNo,
                        up.nextMissionCode ? up.nextMissionCode : up.currentMissionCode,
                        up.programCode,
                        up.prescriptionNo
                      );
                    }}
                    className={missionBtnStyle}
                  >
                    {!up.isSubmitTodayMission
                      ? `${
                          up.nextCode ? up.nextCode.split('-')[1] : up.currentMissionCode.split('-')[1] || 1
                        }회차 미션 참여`
                      : `${
                          up.nextCode ? up.nextCode.split('-')[1] : up.currentMissionCode.split('-')[1] || 1
                        }회차 미션 인증 완료`}
                  </button>
                </React.Fragment>
              );
            }
          })}
        {_(chlngSt)
          .filter((el) => {
            return el.programCode === 'SEPARATION_CHALLENGE' && el.endYmd >= moment().format('YYYY-MM-DD');
          })
          .value().length === 0 && (
          <div className={addStyle} onClick={() => onClickGotoDetail(DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY)}>
            <div className={addImgWrp}>
              <img src='//static.dogmate.co.kr/dogit/v4/my/dogit-v4-my-add.png' />
            </div>
            분리불안 해결 챌린지 추가하기
          </div>
        )}
        {_(chlngSt)
          .filter((el) => {
            return el.programCode === 'BARK_CHALLENGE' && el.endYmd >= moment().format('YYYY-MM-DD');
          })
          .value().length === 0 && (
          <div className={addStyle} onClick={() => onClickGotoDetail(DOGIT_V3_PROGRAM_TYPE.BARK)}>
            <div className={addImgWrp}>
              <img src='//static.dogmate.co.kr/dogit/v4/my/dogit-v4-my-add.png' />
            </div>
            짖음 해결 챌린지 추가하기
          </div>
        )}
        {chlngSt
          .filter((el) => el.endYmd < moment().format('YYYY-MM-DD'))
          .map((up, idx) => (
            <React.Fragment key={`program_${idx}`}>
              <div onClick={() => onClickGotoChallengeInfo(up.dgUserProgramNo)} className={upEndStyle}>
                <div className={upInfoEndStyle}>
                  <div className={upInfoDescEndWrp}>
                    <h3 style={{ color: '#42607f' }}>{up.programName} ‣</h3>
                    {/* <s>
                        {up.startYmd.replace(/\-/g, '.')} ~ {up.endYmd.replace(/\-/g, '.')}
                      </s> */}
                  </div>
                  {
                    <div className={refundReadyEndBadge}>
                      {up.chlngSt === DOGIT_CHALLENGE_STATUS.REFUND_READY
                        ? '환급 예정'
                        : up.chlngSt === DOGIT_CHALLENGE_STATUS.REFUND_COMPLETE
                        ? '환급 완료'
                        : '챌린지 종료'}
                    </div>
                  }
                </div>
                <button
                  className={certBtnStyle}
                  onClick={(event) => onClickOpenCertModal(event, up.programName, up.startYmd, up.endYmd)}
                >
                  수료증보기 🎖
                </button>
              </div>
              {up.chlngSt === DOGIT_CHALLENGE_STATUS.COMPLETE && (
                <button className={refundBtnStyle} onClick={() => onClickOpenRefundModal(up.dgUserProgramNo)}>
                  환급 신청서 작성하기
                </button>
              )}
            </React.Fragment>
          ))}
        <div className={banner} onClick={onClickOpenGuideModal}>
          <div className={bannerDescWrp}>
            <h1>도깃 이용 가이드</h1>
            <div>
              <p>보러가기</p>
            </div>
          </div>
          <div className={bannerImgWrp}>
            <img src='//static.dogmate.co.kr/dogit/landing-v3/my/dogit-v3.2-guide.png' />
          </div>
        </div>
      </article>
    </>
  );
}
