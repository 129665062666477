import { css } from '@emotion/css/macro';
import Color from '../common/Color';
import StyleConstants from '../common/StyleConstants';
import IconClose from '../icons/IconClose';
import HpNoCert from './HpNoCert';
import _ from 'lodash';

const deem = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
`;
const closeWrapper = css`
  /* position: absolute; */
  /* right: 20px; */
  /* top: 36px; */
`;
const style = css`
  position: fixed;
  bottom: 0;
  width: 100vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  height: 560px;
  background: ${Color.WHITE};
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
`;
const titleWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 36px 20px 23px;
  h1 {
    font-size: 22px;
    line-height: 1.36;
    letter-spacing: -0.82px;
    color: #406081;
    b {
      font-weight: bold;
    }
  }
`;
const programStyle = css`
  padding: 18px;
  margin: 0 20px;
  border-radius: 10px;
  border: solid 2px #4855d1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;
const priceWrapper = css`
  p {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: -0.52px;
    color: #406081;
    s {
      font-size: 12px;
      line-height: 1.83;
      font-weight: normal;
    }
    span {
      font-size: 12px;
      border-radius: 5px;
      background: linear-gradient(116deg, #fad961 13%, #f76b1c 143%);
      color: #000;
      border: 0;
      padding: 1px 3px;
      margin-left: 4px;
    }
  }
`;
const imgWrapper = css`
  width: 53px;
  img {
    width: 100%;
  }
`;
const formWrapper = css`
  display: flex;
  flex-direction: column;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  margin: 10px 20px 0;
  label {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: -0.56px;
    color: #383939;
    margin: 10px 0;
  }
  h2 {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: -0.56px;
    color: #383939;
    margin: 10px 0;
  }
  input {
    font-size: 14px;
    border: none;
  }
  input::placeholder {
    font-size: 14px;
    letter-spacing: -0.52px;
  }
`;
const borderBottomWrapper = css`
  border-bottom: solid 1px #cacede;
  width: 90vw;
  padding-bottom: 7px;
  margin-bottom: 4px;
`;
const privacyWrapper = css`
  margin: 19px 45px;
  p {
    font-size: 13px;
    line-height: 1.62;
    letter-spacing: -0.45px;
    color: #747878;
    a {
      color: #747878;
    }
  }
`;
const btnWrapper = css`
  width: 100vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  display: flex;
  justify-content: center;
`;
const btnStyle = css`
  background-color: #f48c95;
  width: 90%;
  height: 50px;
  border-radius: 24px;
  left: 0;
  margin: 0 auto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.43px;
  color: ${Color.WHITE};
`;

export default function CustomerServiceModalView({ type, onClickCloseCSModal, onChangeInputValue, hpNo, setHpNo, setIsHpNoCert, onClickSubmitCS }) {
  return (
    <section>
      <div className={deem}>
        <article className={style}>
          <div className={titleWrapper}>
            <h1>
              전문가 상담
              <b> 구매하기</b>
            </h1>
            <div id='modal-close-btn' className={closeWrapper} onClick={onClickCloseCSModal}>
              <IconClose size={20} strokeColor={'#b1b4c1'} />
            </div>
          </div>
          <div className={programStyle}>
            <div className={priceWrapper}>
              <p>도깃 훈련사 전화 상담 (30분 내외)</p>
              <p>
                <s>정가 39,000원</s> 25,000원
                <span>64%</span>
              </p>
            </div>
            <div className={imgWrapper}>
              <img src='//static.dogmate.co.kr/dogit/landing-v3/program-detail/v3.2/dogit-v3-cs-jerry.png' />
            </div>
          </div>
          <div className={formWrapper}>
            <label htmlFor='userName'>보호자 이름</label>
            <div className={borderBottomWrapper}>
              <input type='text' id='userName' name='userName' required onChange={onChangeInputValue} placeholder='이름을 입력해주세요!' style={{ width: '280px' }} />
            </div>
            <h2>휴대폰 번호</h2>
            <HpNoCert
              hpNo={hpNo}
              runWhenCertifyDone={(hpNo) => {
                setHpNo(hpNo);
                setIsHpNoCert(true);
              }}
            />
          </div>
          <div className={privacyWrapper}>
            <p>
              본 구매하기 신청서를 제출하면 아래의 개인정보 수집 및 이용동의에 동의함으로 간주합니다.{' '}
              <a href='https://www.notion.so/dogmate/d3946d323a484499951fa670e181d9bc '>내용보기</a>
            </p>
          </div>
          <div className={btnWrapper}>
            <button className={btnStyle} onClick={onClickSubmitCS}>
              결제 신청하기
            </button>
          </div>
        </article>
      </div>
    </section>
  );
}
