import * as React from 'react';
import { css } from '@emotion/css/macro';
import IconClose from '../../../../icons/IconClose';
import BadgeView from './BadgeView';
import { isAndroid } from '../../../../common/Util';
import StyleConstants from '../../../../common/StyleConstants';

const mediaStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 65535;
  img {
    margin-top: 50px;
    width: 100%;
  }
  video {
    margin-top: 50px;
  }
`;
const topStyle = css`
  position: fixed;
  top: 12px;
  right: 12px;
  button {
    background-color: rgba(0, 0, 0, 0);
  }
  z-index: 65535;
`;
const openMoreStyle = css`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  padding: 127px 16px 0;
  p {
    margin: 0 30px;
    word-break: keep-all;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.52px;
    color: #fff;
  }
`;
const closeMoreStyle = css`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  padding: 30px 16px 80px;
  p {
    margin: 0 30px;
    word-break: keep-all;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.52px;
    color: #fff;
    span {
      margin-left: 4px;
      border-bottom: 1px solid #a8a8a8;
      color: #a8a8a8;
      font-weight: bold;
    }
  }
`;
const starRateAndNameWrp = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 30px;
  h2 {
    font-size: 12px;
    letter-spacing: -0.45px;
    color: #fff;
  }
`;
const badgeWrp = css`
  display: flex;
  flex-direction: row;
  margin: 24px 0;
`;

export default function VideoView({ reviewInfo, onClickCloseVideoView }) {
  const [isOpenMore, setIsOpenMore] = React.useState(false);

  return (
    <article className={mediaStyle}>
      <div className={topStyle}>
        <button
          onClick={() => {
            setIsOpenMore(false);
            onClickCloseVideoView();
          }}
        >
          <IconClose strokeColor='#fff' size={14} />
        </button>
      </div>
      <video
        width='100%'
        controls={true}
        type={isAndroid() ? '' : 'video/mp4'}
        src={reviewInfo.src}
        controlsList='nodownload'
        playsInline
      />
      {isOpenMore ? (
        <div className={openMoreStyle}>
          <div className={starRateAndNameWrp}>
            <h2>{`${reviewInfo.userName}님 (${reviewInfo.petKind} • ${reviewInfo.age} 세)`}</h2>
          </div>
          <p>{reviewInfo.review}</p>
          <div className={badgeWrp} style={{ margin: '30px 0 10px 30px' }}>
            {reviewInfo.badge.map((badge, idx) => (
              <BadgeView key={`badge-${idx}`} desc={badge} />
            ))}
          </div>
          <div className={badgeWrp} style={{ margin: '0 0 0 30px' }}>
            <BadgeView desc={`❣️ ${reviewInfo.missionCompleteCnt}회 참여`} />
          </div>
        </div>
      ) : (
        <div className={closeMoreStyle}>
          <div className={starRateAndNameWrp}>
            <h2>{`${reviewInfo.userName}님 (${reviewInfo.petKind} • ${reviewInfo.age} 세)`}</h2>
          </div>
          <p>
            {reviewInfo.review.slice(0, 55)}&nbsp;…&nbsp;<span onClick={() => setIsOpenMore(true)}>더보기</span>
          </p>
        </div>
      )}
    </article>
  );
}
