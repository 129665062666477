import { DOGIT_V3_MISSION_STEP_TYPE } from '../../common/Constants';
import TypeformView from '../typeform/view/TypeformView';
import MissionCompleteView from './subview/MissionCompleteView';
import * as _ from 'lodash';
import AuthenticationView from './subview/AuthenticationView';

export default function MissionView({
  id,
  hpNo,
  dgPetNo,
  result,
  step,
  dgChallengeLogNo,
  dgUserProgramNo,
  code,
  programName,
  logRegDate,
  img,
  onChangeImg,
  setIsLoading,
  onClickGotoCompleteView,
  onClickGotoChallengeInfo,
}) {
  return (
    <>
      {
        <>
          {step === DOGIT_V3_MISSION_STEP_TYPE.IN_PROGRESS ? (
            id && (
              <TypeformView
                dgUserProgramNo={dgUserProgramNo}
                formId={id}
                hpNo={hpNo}
                dgPetNo={dgPetNo}
                log={dgChallengeLogNo}
                isV4={true}
                code={code}
              />
            )
          ) : step === DOGIT_V3_MISSION_STEP_TYPE.AUTHENTICATION ? (
            <AuthenticationView
              img={img}
              onChangeImg={onChangeImg}
              setIsLoading={setIsLoading}
              onClickGotoCompleteView={onClickGotoCompleteView}
            />
          ) : step === DOGIT_V3_MISSION_STEP_TYPE.COMPLETE ? (
            <MissionCompleteView
              dgUserProgramNo={dgUserProgramNo}
              result={result}
              onClickGotoChallengeInfo={onClickGotoChallengeInfo}
              code={code}
              programName={programName}
              logRegDate={logRegDate}
            />
          ) : (
            <></>
          )}
        </>
      }
    </>
  );
}
