import React from 'react';
import { css } from '@emotion/css/macro';

export default function IconPrev({ width, height, strokeColor }) {
  const styleStroke = css`
    fill-rule: evenodd;
    stroke-width: 0.8;
    stroke: ${strokeColor};
    fill: ${strokeColor};
  `

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <path className={styleStroke} d="M10.328 19.136a.672.672 0 00.459-1.164l-8.424-7.861a.055.055 0 01-.019-.043c0-.025.014-.038.019-.043l8.423-7.862a.672.672 0 10-.917-.983L1.446 9.042A1.407 1.407 0 001 10.068c0 .387.163.762.446 1.026l8.423 7.862c.13.12.294.18.459.18" />
    </svg>
  );
}


