import React from 'react';
import Helmet from 'react-helmet';

const HelmetCommon = ({ title, description, keywords, url, ogImageUrl }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='og:type' content='website' />
      <meta name='og:title' content={title} />
      <meta name='og:description' content={description} />
      <meta name='og:image' content={ogImageUrl} />
      <meta name='og:url' content={url} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={ogImageUrl} />
      <meta property='og:url' content={url} />
      <meta property='al:web:url' content={url} />
      {/* <meta property='al:ios:url' content='dogmate://' />
      <meta property='al:ios:app_store_id' content='1433852915' />
      <meta property='al:ios:app_name' content='도그메이트 - 펫시터 예약 서비스' />
      <meta property='al:android:url' content='dogmate://' />
      <meta property='al:android:app_name' content='도그메이트 - 펫시터 반려동물 강아지 돌봄 앱' />
      <meta property='al:android:package' content='cokr.dogmate.aos.prod' /> */}
    </Helmet>
  )
}

export default HelmetCommon;
