import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import StyleConstants from '../../../common/StyleConstants';
import * as _ from 'lodash';
import * as React from 'react';
import { DOGIT_V3_PROGRAM_TYPE } from '../../../common/Constants';

const style = css`
  width: 100vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  margin-bottom: 80px;
`;
const title = css`
  width: 100%;
  height: 227px;
  background: linear-gradient(to bottom, #4855d1 66%, #5949d2, #5949d2);
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
const titleDesc = css`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.9;
  color: #f8e71c;
  margin-left: 21px;
`;
const titleImg = css`
  width: 66%;
  margin: 10px 0 37px 21px;
  img {
    width: 100%;
  }
`;
const listWrapper = css`
  padding-top: 10px;
`;
const programDesc = css`
  width: 84%;
  border-radius: 12px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.18);
  border: solid 0.5px #e8e8e8;
  background-color: ${Color.WHITE};
  left: 0;
  margin: 20px auto;
  padding: 20px 0;
  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 20px 10px;
    img {
      margin-right: 6px;
    }
    h2 {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.52px;
      color: #42607f;
    }
  }
  h3 {
    margin: 5px 0 0 20px;
    font-size: 14px;
    letter-spacing: -0.52px;
    color: #757575;
  }
  h2 {
    margin: 17px;
    color: #42607f;
    font-weight: bold;
    font-size: 14px;
  }
  ul {
    margin: 0 0 20px 20px;
    li {
      color: #484848;
      font-size: 14px;
      letter-spacing: -0.52px;
      margin-top: 5px;
      b {
        font-weight: bold;
      }
    }
    li::before {
      content: '•';
      margin-right: 4px;
    }
  }
  button {
    display: block;
    width: 90%;
    height: 40px;
    border-radius: 15px;
    left: 0;
    margin: 0 auto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.6px;
    color: ${Color.WHITE};
    img {
      position: absolute;
      margin-left: 50px;
      margin-top: -15px;
      width: 50px;
      height: 47px;
    }
  }
`;

export default function ProgramListView({ onClick }) {
  const programList = [
    {
      type: DOGIT_V3_PROGRAM_TYPE.CHALLENGE,
      period: '',
      desc: ['의지를 돈으로 사세요! 챌린지에 참여하고', '문제행동 개선하고 전액 환급까지!'],
      curriculum: [],
      btnBg: 'linear-gradient(137deg, #fa66a1 64%, #9062eb 113%)',
    },
    {
      type: DOGIT_V3_PROGRAM_TYPE.VST_TRAINING,
      period: '',
      desc: ['우리 강아지에게 꼭 맞는 교육을 훈련사의', '문제행동 '],
      curriculum: [],
      btnBg: 'linear-gradient(137deg, #7361f3 64%, #9062eb 113%)',
    },
    {
      type: DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION,
      period: '3개월 과정',
      desc: ['퍼피 때부터 낯선 자극이나 환경에서', '생길 수 있는 두려움을 예방해줘요'],
      curriculum: [
        <li>
          보호자의 <b>터치에 익숙</b>해지기
        </li>,
        <li>
          <b>다양한 소리</b>에 놀라지 않는 연습하기
        </li>,
        <li>
          <b>처음보는 강아지</b>와 친해지기 등
        </li>,
      ],
      btnBg: 'linear-gradient(137deg, #7361f3 64%, #9062eb 113%)',
    },
    {
      type: DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY,
      period: '1개월 과정',
      desc: ['혼자 있어도 불안해 하지 않을 수 있도록', '독립심을 키워줘요'],
      curriculum: [
        <li>
          <b>자립심</b> 생기는 생활 습관 만들기
        </li>,
        <li>
          집 안에서 <b>보호자와 분리하는 연습</b>하기
        </li>,
        <li>
          보호자의 <b>외출 과정에 익숙</b>해지기
        </li>,
      ],
      btnBg: 'linear-gradient(137deg, #49bcf6 64%, #7b45fc 113%, #c0dc88 113%)',
    },
    {
      type: DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING,
      period: '1개월 과정',
      desc: ['실수 없이 완벽하게', '배변을 가리는 법을 알게 돼요'],
      curriculum: [
        <li>
          화장실로 적합한 <b>위치 정하기</b>
        </li>,
        <li>
          <b>화장실의 개념</b>을 이해시키기
        </li>,
        <li>
          배변 타이밍 포착하고 <b>배변 패턴화</b>하기
        </li>,
      ],
      btnBg: 'linear-gradient(137deg, #9b58cb 64%, #3023ae 113%, #c0dc88 113%)',
    },
    {
      type: DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING,
      period: '2개월 과정',
      desc: ['보호자의 리드에 따라 안전하고', '재밌게 산책하는 방법을 배울 수 있어요'],
      curriculum: [
        <li>
          산책 시 꼭 지켜야 할 <b>약속 만들기</b>
        </li>,
        <li>
          산책을 하다가도 <b>보호자에게 집중</b>하기
        </li>,
      ],
      btnBg: 'linear-gradient(106deg, #3bd2b1 63%, #686eda 108%, #c0dc88 109%)',
    },
    {
      type: DOGIT_V3_PROGRAM_TYPE.BARK,
      period: '1개월 과정',
      desc: ['초인종 소리나 외부인의 방문을', '긍정적인 경험으로 변화시켜줘요'],
      curriculum: [
        <li>
          <b>초인종 소리</b>에 익숙해지기
        </li>,
        <li>
          <b>현관문 소리</b>에 익숙해지기
        </li>,
        <li>
          <b>외부인 방문</b>에 익숙해지기
        </li>,
      ],
      btnBg: 'linear-gradient(137deg, #ad2198 65%, #420557 113%, #c0dc88 113%)',
    },
  ];
  return (
    <section className={style}>
      <article className={title}>
        <p className={titleDesc}>꼭 필요한 퍼피 교육,</p>
        <div className={titleImg}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/program-list/dogit-v3-list-title.png' />
        </div>
      </article>
      <article className={listWrapper}>
        {programList.map((el, idx) =>
          el.type === DOGIT_V3_PROGRAM_TYPE.VST_TRAINING ||
          el.type === DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING ||
          el.type === DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING ||
          el.type === DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION ||
          el.type === DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE ? (
            <React.Fragment key={idx} />
          ) : (
            <article className={programDesc} key={idx}>
              <header>
                <img
                  style={{
                    height:
                      el.type === DOGIT_V3_PROGRAM_TYPE.VST_TRAINING || el.type === DOGIT_V3_PROGRAM_TYPE.CHALLENGE
                        ? '60px'
                        : '24px',
                  }}
                  src={
                    el.type === DOGIT_V3_PROGRAM_TYPE.CHALLENGE
                      ? `//static.dogmate.co.kr/dogit/v4/list/dogit-v4-list-title-8-14.png`
                      : `//static.dogmate.co.kr/dogit/landing-v3/program-detail/dogit-v3-curriculum-title${el.type}.png`
                  }
                />
                <h2 style={{ margin: 0 }}>{el.period}</h2>
              </header>
              {el.desc.map((innerEl, idx) => (
                <h3 style={{ fontWeight: 'normal' }} key={idx}>
                  {innerEl}
                </h3>
              ))}
              <h2>📍 대표 커리큘럼</h2>
              <ul>
                {el.curriculum.map((cur, idx) => (
                  <React.Fragment key={idx}>{cur}</React.Fragment>
                ))}
              </ul>
              <button
                id={`program-detail-${el.type}`}
                style={{
                  background: el.btnBg,
                }}
                onClick={_.debounce(() => onClick(el.type), 300)}
              >
                자세히 보기 {el.type === DOGIT_V3_PROGRAM_TYPE.CHALLENGE && '👊🏻'}
                {el.type === DOGIT_V3_PROGRAM_TYPE.VST_TRAINING && (
                  <img src='//static.dogmate.co.kr/dogit/landing-v3/program-detail/dogit-v3-jerry-choi.png' />
                )}
              </button>
            </article>
          )
        )}
        {/* <AllInOneProgramDesc onClick={onClick} /> */}
      </article>
    </section>
  );
}
