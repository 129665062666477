import { css } from '@emotion/css/macro';
import StyleConstants from '../../../common/StyleConstants';

const style = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 87.5%;
  height: 100%;
  margin: 32px auto;
  h1 {
    font-size: 22px;
    line-height: 29px;
    letter-spacing: -0.82px;
    color: #42607f;
    font-weight: bold;
    margin-bottom: 18px;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.45px;
    color: #747878;
    text-align: center;
    b {
      color: #4c5bcd;
      font-weight: bold;
    }
  }
`;
const imgWrp = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 71vw;
  img {
    max-width: 100%;
    max-height: 71vw;
  }
  margin-bottom: 10px;
`;
const submitBtn = css`
  width: 70%;
  height: 50px;
  border-radius: 42px;
  background-color: #f48c95;
  color: #fff;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.43px;
  font-weight: bold;
  margin: 24px auto 0;
  display: block;
`;
const fileStyle = css`
  width: 100%;
  input {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
  label {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding: 13px 0;
    border-radius: 25px;
    background-color: #e6e8f4;
    font-size: 16px;
    letter-spacing: -0.6px;
    font-weight: bold;
    color: #4c5bcd;
    margin-bottom: 26px;
  }
`;
const certCautionWrp = css`
  background-color: #ffe3e3;
  width: 95%;
  padding: 8px 13px;
  border-radius: 8px;
  margin-bottom: 29px;
  h1 {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.45px;
    color: #d0021b;
    font-weight: bold;
    margin-bottom: 3px;
    text-align: left;
  }
  p {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.45px;
    line-height: 18px;
    color: #4b4b4b;
    text-align: left;
  }
`;

export default function AuthenticationView({ img, onChangeImg, setIsLoading, onClickGotoCompleteView }) {
  return (
    <article className={style}>
      <h1>
        미션 참여 인증을 위해
        <br />
        사진을 촬영해주세요&nbsp;📸
      </h1>
      <div
        className={imgWrp}
        style={{
          border: img && 'solid 1px rgb(224, 224, 224)',
          borderRadius: img && '20px',
        }}
      >
        {img ? (
          <img
            id='auth-img'
            onLoad={() => {
              setIsLoading(false);
              URL.revokeObjectURL(img);
            }}
            src={img}
            alt='인증 사진'
          />
        ) : (
          <img src={'//static.dogmate.co.kr/dogit/landing-v3/mission/dogit-v4-mission-auth.png'} />
        )}
      </div>
      <div className={fileStyle}>
        <input type='file' id='camera' name='camera' capture='camera' accept='image/*' onChange={onChangeImg} />
        <label htmlFor='camera'>{img ? '다시 촬영하기' : '촬영하기'}</label>
      </div>
      <div className={certCautionWrp}>
        <h1>🚫 꼭 확인해주세요!</h1>
        <p>
          미션과 무관한 사진인증의 경우 14일 연속 참여했더라도
          <br />
          환급대상에서 자동으로 제외됩니다.
        </p>
      </div>
      <p>
        📸&nbsp;&nbsp;<b>미션에 참여한 강아지를 촬영해주셔야 인증됩니다!</b>
      </p>
      {/* <p>🚫&nbsp;&nbsp;미션 참여와 무관한 사진은 미인증 처리될 수 있어요!</p> */}
      <p>🙋🏻‍♀️&nbsp;&nbsp;사진은 귀여움을 널리 알리는데 사용될 수 있어요!</p>
      {img && (
        <button className={submitBtn} onClick={onClickGotoCompleteView}>
          챌린지 인증하기
        </button>
      )}
    </article>
  );
}
