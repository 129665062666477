import { css } from '@emotion/css/macro';
import Color from '../../common/Color';

const style = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
const titleImgWrapper = css`
  width: 80%;
  margin-bottom: 10px;
  img {
    width: 100%;
  }
`;
const imgWrapper = css`
  width: 50%;
  margin-bottom: 50px;
  img {
    width: 100%;
  }
`;
const titleDesc = css`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: -0.9px;
  text-align: center;
  color: #42607f;
  margin-bottom: 35px;
`;
const desc = css`
  font-size: 16px;
  text-align: center;
  color: #747878;
  margin-bottom: 78px;
  line-height: 1.38;
  letter-spacing: -0.6px;
  b {
    font-weight: bold;
    color: #4853cf;
  }
`;
const btnStyle = css`
  padding: 11px 88px;
  height: 50px;
  border-radius: 24px;
  background-color: #f48c95;
  color: ${Color.WHITE};
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: -0.43px;
  font-weight: bold;
  img {
    position: absolute;
    width: 50px;
    margin-top: -50px;
  }
`;

export default function CSTypeformCompleteView({ onClickGotoIdx }) {
  return (
    <article className={style}>
      <div className={titleImgWrapper}>
        <img src='//static.dogmate.co.kr/dogit/landing-v3/program-detail/v3.2/dogit-v3.2-cs-tf-complete-title.png' />
      </div>
      <h1 className={titleDesc}>
        도깃 훈련 전문가 상담이
        <br />
        진행될 예정입니다
      </h1>
      <div className={imgWrapper}>
        <img src='//static.dogmate.co.kr/dogit/landing-v3/program-detail/v3.2/dogit-v3.2-cs-tf-complete-img.png' />
      </div>
      <h2 className={desc}>
        <b>
          {' '}
          영업일 2일 내에 상담 일정을 확정하여
          <br />
          문자로 안내
        </b>
        드리겠습니다.&nbsp;🙏
      </h2>
      <button onClick={onClickGotoIdx} className={btnStyle}>
        <img src='//static.dogmate.co.kr/dogit/landing-v3/program-detail/dogit-v3-jerry-choi.png' />
        홈으로
      </button>
    </article>
  );
}
