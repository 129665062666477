import * as Util from '../../common/Util';
import PaymentCompleteContainer from './PaymentCompleteContainer';
import HelmetCommon from '../../components/HelmetCommon';

export default function PaymentCompletePage() {
  const query = Util.useQuery();
  const dgPmtNo = parseInt(query.get('dgPmtNo'));

  return (
    <>
      <HelmetCommon
        title='보호자가 직접 하는 강아지 훈련 챌린지 | 도깃'
        description='하루에 한번! 도깃이 주는 훈련 미션만 따라하면 강아지의 문제 행동이 개선될거예요.'
        keywords='강아지 훈련,강아지 분리불안,강아지 분리불안 훈련,강아지 짖음,짖음,짖음 훈련,훈련,훈련소,훈련사,방문 훈련,강아지 훈련사,강아지 문제 행동,애견 훈련,애견 훈련사,반려견 훈련,반려견 훈련사,강아지 입질,도깃,펫,펫시터,도그메이트'
        url={process.env.REACT_APP_URL}
        ogImageUrl='https://static.dogmate.co.kr/dogit/og_image/dogit_og_v1.jpg'
      />
      <PaymentCompleteContainer dgPmtNo={dgPmtNo} />
    </>
  );
}
