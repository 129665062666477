import React from 'react';

export default function IconMediaPlay({ size, strokeColor }) {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox={`0 0 16 16`} version='1.1'>
      <title>Group 5</title>
      <g id='Page-1' stroke={strokeColor} strokeWidth='1' fillRule='evenodd'>
        <g id='후기(보통,만족-시)' transform='translate(-36.000000, -1470.000000)' fill={strokeColor}>
          <g id='Group-5' transform='translate(36.000000, 1470.000000)'>
            <path
              d='M8,0.957264957 C4.11664957,0.957264957 0.957264957,4.11664957 0.957264957,8 C0.957264957,11.8833504 4.11664957,15.042735 8,15.042735 C11.8833504,15.042735 15.042735,11.8833504 15.042735,8 C15.042735,4.11664957 11.8833504,0.957264957 8,0.957264957 M8,16 C3.58878632,16 0,12.4112137 0,8 C0,3.58878632 3.58878632,0 8,0 C12.4112137,0 16,3.58878632 16,8 C16,12.4112137 12.4112137,16 8,16'
              id='Fill-1'
            ></path>
            <path
              d='M6.76172308,11.1702154 C6.23276581,11.465053 5.58305641,11.0766769 5.5922188,10.4711385 L5.62982564,7.98812991 L5.66756923,5.50498462 C5.67673162,4.89958291 6.33792821,4.5310359 6.85772308,4.84173675 L8.98927863,6.11585641 L11.1208342,7.38997607 C11.6406291,7.70067692 11.6291419,8.4576 11.1001846,8.75243761 L8.93088547,9.9613265 L6.76172308,11.1702154 Z'
              id='Fill-3'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
