export default {
  DOGIT_PINK: '#F48C95',
  DOGIT_MEDIUM_PINK: '#ff8392',
  DOGIT_DARK_PINK: '#e65c6c',
  DOGIT_BLUE: '#4C5BCD',
  DOGIT_DARK_BLUE: '#333f9E',
  DOGIT_MEDIUM_BLUE: '#4855d1',
  DOGIT_YELLOW: '#F9D815',
  BLACK: '#000',
  WHITE: '#fff',
  WHITE_80: 'rgba(255, 255, 255, 0.8)',
  BLACK_11: 'rgba(0, 0, 0, 0.11)',
  DARK_GRAY: '#383939',
  MEDIUM_DARK_GRAY: '#484848',
  LIGHT_DARK_GRAY: '#787878',
  MEDIUM_GRAY: '#B1B4C1',
  GRAY: '#A7ABAB',
  LIGHT_GRAY: '#C6CACA',
  LIGHT_LIGHT_GRAY: '#F1F1F1',
  F6_GRAY: '#F6F6F6',
  LIGHT_WHITE: '#f6f7fb',
  E0_GRAY: '#E0E0E0',
  ED_GRAY: '#EDEDED',
  EE_GRAY: '#EEEEEE',
  C4_GRAY: '#C4C4C4',
  GRAY_60: '#606060',
  BLUE_GRAY: '#CACEDE',
  LIGHT_BLUE_GRAY: '#e2e5f4',
  GRAY: '#e6e8f4',
  DARK_PURPLE: '#8b349d',
  PURPLE: '#651896',
  MEDIUM_PURPLE: '#B953C8',
  LIGHT_PURPLE: '#C8A9C7',

  LAVENDER: '#B8C0E0',
  KAKAO_BROWN: '#391B1B',
  KAKAO_YELLOW: '#FAE300',

  DOGIT_DARK_RED: '#f53b44',
  DOGIT_LIGHT_VIOLET: '#976DFB',
  DOGIT_LIGHT_BLUE: '#6b92fc'
};
