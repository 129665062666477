import * as React from 'react';
import * as Util from '../../common/Util';
import * as API from '../../api/api';
import { DOGIT_REFUND_READY_STEP, DOGIT_V3_EVENT_TYPE } from '../../common/Constants';
import { UserInfoContext } from '../../components/PageWrapper';
import RefundReadyView from './RefundReadyView';

export default function RefundReadyContainer({ step, up }) {
  const [currentStep, setCurrentStep] = React.useState(step);
  const { dgUserNo, hpNo } = React.useContext(UserInfoContext);

  React.useEffect(() => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  });

  React.useEffect(() => {
    if (currentStep === DOGIT_REFUND_READY_STEP.COMPLETE) {
      API.updateRefundReady({ dgUserProgramNo: up }).catch((err) => {
        API.errorHandler(err);
        window.location.href = '/my';
      });
    }
  });

  const onClickGotoHome = () => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.MY_BTN, '/my');
  };

  return <RefundReadyView currentStep={currentStep} up={up} onClickGotoHome={onClickGotoHome} />;
}
