import { css } from '@emotion/css/macro';
import StyleConstants from '../../../common/StyleConstants';
import IconClose from '../../../icons/IconClose';

const wrp = css`
  position: fixed;
  bottom: top;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  z-index: 65535;
`;
const topBarStyle = css`
  position: fixed;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100vw;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #535454;
  font-size: 16px;
  h1 {
    color: #535454;
    font-weight: bold;
    margin-left: 15px;
    letter-spacing: -0.6px;
    margin-bottom: 4px;
  }
  button {
    background-color: #fff;
    margin-right: 15px;
    margin-bottom: 4px;
  }
`;
const imgWrp = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  padding-top: 45px;
  margin-bottom: 90px;
  img {
    width: 100%;
  }
`;

export default function EventModalView({ onClick }) {
  document.querySelector('body').style.overflow = 'hidden';
  return (
    <div className={wrp}>
      <div className={topBarStyle}>
        <h1>리뷰 이벤트</h1>
        <button onClick={onClick}>
          <IconClose size='16' strokeColor='#b1b4c1' />
        </button>
      </div>
      <div className={imgWrp}>
        <img src='//static.dogmate.co.kr/dogit/v4/my/dogit-v4-event-info.jpg' />
      </div>
    </div>
  );
}
