import { css } from '@emotion/css/macro';
import { isAndroid } from '../../../common/Util';
import IconClose from '../../../icons/IconClose';

const style = css`
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 65534;

  video {
    margin-top: 60px;
    z-index: 65535;
  }
`;
const topBarStyle = css`
  height: 32px;
  position: fixed;
  top: 12px;
  width: 100vw;
  div {
    position: absolute;
    right: 12px;
  }
`;

export function VideoView({ src, setIsOpenVideoView, setVideoSrc }) {
  return (
    <article className={style}>
      <div className={topBarStyle}>
        <div
          onClick={() => {
            setIsOpenVideoView(false);
            setVideoSrc('');
          }}
        >
          <IconClose size={14} strokeColor='#fff' />
        </div>
      </div>
      <video
        width='100%'
        controls='true'
        type={isAndroid() ? '' : 'video/mp4'}
        src={src}
        controlsList='nodownload'
        playsInline
      ></video>
    </article>
  );
}
