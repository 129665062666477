import React from 'react';
import * as API from '../../api/api';
import * as Util from '../../common/Util';
import * as Storage from '../../common/Storage';
import {
  DOGIT_USER_STATUS,
  DOGIT_V3_CURRENT_STEP_TYPE,
  DOGIT_V3_EVENT_TYPE,
  DOGIT_V3_PROGRAM_BTN_ACTION_TYPE,
  DOGIT_V3_PROGRAM_CODE,
  DOGIT_V3_PROGRAM_TYPE,
} from '../../common/Constants';
import ApplicationView from './view/ApplicationView';
import CompleteView from './view/CompleteView';
import PrivacyModalView from './view/subview/PrivacyModalView';
import { UserInfoContext } from '../../components/PageWrapper';

export default function ProgramApplicationContainer({ type }) {
  const context = React.useContext(UserInfoContext);
  const { userStatus, dgUserNo, userName, petName, petKind, petBirthYm, isRecvMkt } = context;
  const [hpNo, setHpNo] = React.useState(context.hpNo ? context.hpNo : '');
  const [currentStep, setCurrentStep] = React.useState(DOGIT_V3_CURRENT_STEP_TYPE.APPLICATION);
  const [dgV3ResponseNo, setDgV3ResponseNo] = React.useState(null);
  const [dgV3ResponseInfo, setDgV3ResponseInfo] = React.useState({});
  const [isHpNoCert, setIsHpNoCert] = React.useState(
    userStatus === DOGIT_USER_STATUS.IN_PROGRESS || userStatus === DOGIT_USER_STATUS.END ? true : false
  );
  const [privacy, setPrivacy] = React.useState(
    userStatus === DOGIT_USER_STATUS.IN_PROGRESS || userStatus === DOGIT_USER_STATUS.END ? true : false
  );
  const [mkt, setMkt] = React.useState(false);
  const [isOpenPrivacy, setIsOpenPrivacy] = React.useState(false);
  const [programType, setProgramType] = React.useState(DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.NONE);

  React.useEffect(() => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  }, [currentStep]);

  const onClickChangeStep = (step) => {
    setCurrentStep(step);
  };

  // * 유저 정보가 이미 존재하는 유저는 따로 입력할 필요없이 바로 처리
  React.useEffect(() => {
    if (dgUserNo && userName && petName && petKind && petBirthYm && isRecvMkt !== undefined && hpNo) {
      dgV3ResponseInfo.dgUserNo = dgUserNo;
      dgV3ResponseInfo.userName = userName;
      dgV3ResponseInfo.petName = petName;
      dgV3ResponseInfo.petKind = petKind;
      dgV3ResponseInfo.petBirthYm = petBirthYm;
      dgV3ResponseInfo.isRecvMkt = isRecvMkt;
      dgV3ResponseInfo.hpNo = hpNo;
      setIsHpNoCert(true);
      setPrivacy(true);
      if (isHpNoCert && privacy) {
        onClickSubmit();
      }
    }
  }, []);

  const onClickSubmit = () => {
    const { userName, petBirthYear, petBirthMonth, petKind, petName } = dgV3ResponseInfo;
    let { petBirthYm } = dgV3ResponseInfo;
    const programType = type;
    const uid = Storage.getV3Uid();
    const tempUid = Storage.getV3TempUid();

    if (!userName) {
      return alert('이름을 입력해주세요!');
    }
    if (!hpNo) {
      return alert('전화번호를 입력해주세요!');
    }
    if (!petName) {
      return alert('반려견 이름을 입력해주세요!');
    }
    if (!petKind) {
      return alert('견종을 입력해주세요!');
    }
    if (!isHpNoCert) {
      return alert('휴대전화 번호 인증을 해주세요!');
    }
    if (!privacy) {
      return alert('개인 정보 취급 방침에 동의해주세요!');
    }
    if (petKind.length >= 50) {
      return alert('견종은 50자를 넘을 수 없습니다.');
    }
    if (userName.length >= 50) {
      return alert('이름은 50자를 넘을 수 없습니다.');
    }
    if (!programType) {
      return alert('프로그램 선택이 잘못됐습니다.');
    }
    if (!uid) {
      return alert('uid 오류');
    }

    if (!petBirthYm) {
      if (!petBirthYear || petBirthYear === 'null') {
        return alert('반려견의 출생 년도를 입력해주세요!');
      }
      if (!petBirthMonth || petBirthMonth === 'null') {
        return alert('반려견의 출생 월을 입력해주세요!');
      }

      const year = petBirthYear.split('년')[0];
      const month = petBirthMonth.split('월')[0];

      petBirthYm = year + '-' + (month.length < 2 ? '0' + month : month);
    }

    const isRecvMkt = mkt;

    const [name, programCode] =
      type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SOCIALIZATION
        ? ['사회화', DOGIT_V3_PROGRAM_CODE.SOCIAL]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SEPARATION_ANXIETY
        ? ['분리불안', DOGIT_V3_PROGRAM_CODE.SEPARATION]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.TOILET_TRAINING
        ? ['배변', DOGIT_V3_PROGRAM_CODE.TOILET]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.WALKING_TRAINING
        ? ['산책', DOGIT_V3_PROGRAM_CODE.WALKING]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.ALL_IN_ONE
        ? ['올인원', DOGIT_V3_PROGRAM_CODE.ALL]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.BARK
        ? ['짖음', DOGIT_V3_PROGRAM_CODE.BARK]
        : type === DOGIT_V3_PROGRAM_CODE.VST_TRAINING
        ? ['방문훈련', DOGIT_V3_PROGRAM_CODE.VST_TRAINING]
        : type === DOGIT_V3_PROGRAM_TYPE.CHALLENGE
        ? ['챌린지', DOGIT_V3_PROGRAM_CODE.CHALLENGE]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SOCIALIZATION_TO_ALL
        ? ['사회화-올인원', DOGIT_V3_PROGRAM_CODE.ALL]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SEPARATION_TO_ALL
        ? ['분리불안-올인원', DOGIT_V3_PROGRAM_CODE.ALL]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.TOILET_TO_ALL
        ? ['배변-올인원', DOGIT_V3_PROGRAM_CODE.ALL]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.WALKING_TO_ALL
        ? ['산책-올인원', DOGIT_V3_PROGRAM_CODE.ALL]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SOCIALIZATION_TO_FREE
        ? ['사회화-체험판', DOGIT_V3_PROGRAM_CODE.SOCIAL_FREE]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SEPARATION_TO_FREE
        ? ['분리불안-체험판', DOGIT_V3_PROGRAM_CODE.SEPARATION_FREE]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.TOILET_TO_FREE
        ? ['배변-체험판', DOGIT_V3_PROGRAM_CODE.TOILET_FREE]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.WALKING_FREE
        ? ['산책-체험판', DOGIT_V3_PROGRAM_CODE.WALKING_FREE]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.BARK_TO_FREE
        ? ['짖음-체험판', DOGIT_V3_PROGRAM_CODE.BARK_FREE]
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.ALL_TO_FREE
        ? ['올인원-체험판', DOGIT_V3_PROGRAM_CODE.ALL_FREE]
        : ['', ''];

    if (programCode.endsWith('FREE')) {
      return createV3FreeResponse({
        userName,
        hpNo,
        programCode,
        petName,
        petKind,
        programType,
        uid,
        tempUid,
        petBirthYm,
        isRecvMkt,
        name,
      });
    } else {
      return createV3Response({
        userName,
        hpNo,
        programType,
        petName,
        petKind,
        uid,
        tempUid,
        petBirthYm,
        name,
        isRecvMkt,
      });
    }
  };

  const createV3Response = ({
    userName,
    hpNo,
    programType,
    petName,
    petKind,
    uid,
    tempUid,
    petBirthYm,
    isRecvMkt,
    name,
  }) => {
    if (programType >= 70 && String(programType)[0] === 7) {
      // Util.sendGaEvent(`[도깃_V3_상담_신청_페이지]${name}_신청완료_버튼`);
    } else {
      // Util.sendGaEvent(`[도깃_V3_본프로그램_신청_페이지]${name}_신청완료_버튼`);
    }

    API.createV3Response({
      userName,
      hpNo,
      programType,
      petName,
      petKind,
      uid,
      tempUid,
      petBirthYm,
      isRecvMkt,
    })
      .then((res) => {
        Util.createEventLog(res.dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.SUBMIT);
        setDgV3ResponseNo(res.data.dgV3ResponseNo);
        setProgramType(res.data.programType);
        setCurrentStep(DOGIT_V3_CURRENT_STEP_TYPE.COMPLETE);
      })
      .catch(API.errorHandler);
  };

  const createV3FreeResponse = ({
    userName,
    hpNo,
    programCode,
    petName,
    petKind,
    uid,
    tempUid,
    petBirthYm,
    isRecvMkt,
    name,
  }) => {
    // Util.sendGaEvent(`[도깃_V3_체험판_신청_페이지]${name}_신청완료_버튼`);

    API.updateV3User({
      userInfo: {
        userName,
        hpNo,
        isRecvMkt,
      },
      petInfo: {
        petName,
        petKind,
        petBirthYm,
      },
    })
      .then(() => {
        API.createFreeProgram({ programCode, uid: Storage.getV3Uid() })
          .then(() => {
            alert('🌱 체험판 신청이 완료되었습니다.\n지금부터 체험판이 시작됩니다.');
            window.location.href = '/list';
          })
          .catch(API.errorHandler);
      })
      .catch(API.errorHandler);
  };

  const onChangeInputValue = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setDgV3ResponseInfo({ ...dgV3ResponseInfo, [event.target.id]: value });
  };

  const onClickChangeMkt = () => {
    if (mkt) {
      setMkt(false);
    } else {
      setMkt(true);
    }
  };

  const onClickChangePrivacy = () => {
    if (privacy) {
      setPrivacy(false);
    } else {
      setPrivacy(true);
    }
  };

  const onClickChangeMktAndPrivacy = () => {
    if (!mkt || !privacy) {
      setPrivacy(true);
      setMkt(true);
    } else {
      setPrivacy(false);
      setMkt(false);
    }
  };

  const onClickOpenPrivacy = (event) => {
    event.stopPropagation();
    setIsOpenPrivacy(true);
  };
  const onClickGotoLanding = () => {
    // Util.sendGaEvent('[도깃_V3_신청_완료_페이지]홈으로_버튼');
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.CTA, '/');
  };

  return (
    <>
      {isOpenPrivacy && <PrivacyModalView onClickClose={() => setIsOpenPrivacy(false)} />}
      {currentStep === DOGIT_V3_CURRENT_STEP_TYPE.APPLICATION ? (
        type && !userStatus ? (
          <ApplicationView
            onClickChangeStep={onClickChangeStep}
            onClickSubmit={onClickSubmit}
            onChangeInputValue={onChangeInputValue}
            hpNo={hpNo}
            setHpNo={setHpNo}
            setIsHpNoCert={setIsHpNoCert}
            onClickChangeMkt={onClickChangeMkt}
            onClickChangePrivacy={onClickChangePrivacy}
            onClickChangeMktAndPrivacy={onClickChangeMktAndPrivacy}
            mkt={mkt}
            privacy={privacy}
            currentStep={currentStep}
            onClickOpenPrivacy={onClickOpenPrivacy}
            isOpenPrivacy={isOpenPrivacy}
            type={type}
          />
        ) : (
          <></>
        )
      ) : (
        <CompleteView
          onClickChangeStep={onClickChangeStep}
          dgV3ResponseNo={dgV3ResponseNo}
          type={type}
          programType={programType}
          onClickGotoLanding={onClickGotoLanding}
        />
      )}
    </>
  );
}
