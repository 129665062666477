import * as API from '../../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as Util from '../../common/Util';
import { DOGIT_V3_EVENT_TYPE, PAY_TYPE } from '../../common/Constants';
import { UserInfoContext } from '../../components/PageWrapper';
import PaymentViewV4 from './view/PaymentViewV4';
import LoadingView from '../../components/LoadingView';
import PaymentTypeSelectView from './view/PaymentTypeSelectView';

export default function PaymentContainerV4({ dgProgramNo, payType }) {
  const { dgUserNo, hpNo, userName, petList } = React.useContext(UserInfoContext);
  const [dgPmtNo, setDgPmtNo] = React.useState(0);
  const [cardInfo, setCardInfo] = React.useState({});
  const [focus, setFocus] = React.useState('');
  const [isComplete, setIsComplete] = React.useState(false);
  const [isLoading, serIsLoading] = React.useState(false);

  React.useEffect(() => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  }, []);

  React.useEffect(() => {
    API.createUserPaymentInfoV4({ dgProgramNo, dgPetNo: petList[0].dgPetNo })
      .then((res) => {
        setDgPmtNo(res.data.dgPmtNo);
      })
      .catch((err) => {
        API.errorHandler(err);
        if (_.get(err, 'response.data.error') || err.toString() === '이미 결제하셨습니다.') {
          window.location.href = '/my';
        } else {
          window.location.href = '/v3-program-detail/?type=8';
        }
      });
  }, []);

  React.useEffect(() => {
    if (cardInfo.cardNo1 && cardInfo.cardNo1.length === 4) {
      document.querySelector(`#cardNo2`).focus();
    }
  }, [cardInfo.cardNo1]);

  React.useEffect(() => {
    if (cardInfo.cardNo2 && cardInfo.cardNo2.length === 4) {
      document.querySelector(`#cardNo3`).focus();
    }
  }, [cardInfo.cardNo2]);

  React.useEffect(() => {
    if (cardInfo.cardNo3 && cardInfo.cardNo3.length === 4) {
      document.querySelector(`#cardNo4`).focus();
    }
  }, [cardInfo.cardNo3]);

  React.useEffect(() => {
    if (cardInfo.cardNo4 && cardInfo.cardNo4.length === 4) {
      document.querySelector(`#validMonth`).focus();
    }
  }, [cardInfo.cardNo4]);

  React.useEffect(() => {
    if (cardInfo.validMonth && cardInfo.validMonth.length === 2) {
      document.querySelector(`#validYear`).focus();
    }
  }, [cardInfo.validMonth]);

  React.useEffect(() => {
    if (cardInfo.validYear && cardInfo.validYear.length === 2) {
      document.querySelector(`#password`).focus();
    }
  }, [cardInfo.validYear]);

  React.useEffect(() => {
    if (cardInfo.password && cardInfo.password.length === 2) {
      setFocus('');
      document.querySelector(`#password`).blur();
    }
  }, [cardInfo.password]);

  React.useEffect(() => {
    if (
      cardInfo.birthYmd &&
      cardInfo.birthYmd.length >= 6 &&
      cardInfo.cardNo1 &&
      cardInfo.cardNo1.length === 4 &&
      cardInfo.cardNo2 &&
      cardInfo.cardNo2.length === 4 &&
      cardInfo.cardNo3 &&
      cardInfo.cardNo3.length === 4 &&
      cardInfo.cardNo4 &&
      cardInfo.cardNo4.length === 4 &&
      cardInfo.validMonth &&
      cardInfo.validMonth.length === 2 &&
      cardInfo.validYear &&
      cardInfo.validYear.length === 2 &&
      cardInfo.password &&
      cardInfo.password.length === 2
    ) {
      setIsComplete(true);
    }
  }, [cardInfo]);

  React.useEffect(() => {
    if (payType === PAY_TYPE.APP_CARD && dgPmtNo) {
      API.paypleAuthAppCard({ dgPmtNo }).then((res) => {
        const obj = {};
        obj.PCD_PAY_TYPE = 'card';
        obj.PCD_PAY_WORK = 'PAY';
        obj.PCD_CARD_VER = PAY_TYPE.APP_CARD;
        obj.PCD_PAYER_NO = dgUserNo;
        obj.PCD_PAYER_NAME = userName;
        obj.PCD_PAYER_HP = hpNo;
        obj.PCD_PAY_GOODS = res.data.programName;
        obj.PCD_PAY_TOTAL = res.data.pmtAmt;
        obj.PCD_AUTH_KEY = res.data.authKey;
        obj.PCD_PAY_URL = res.data.returnUrl;
        obj.PCD_RST_URL = `${process.env.REACT_APP_URL}/api/dogit/v4/payment/app-card/complete/${dgPmtNo}`;

        window.PaypleCpayAuthCheck(obj);
      });
    }
  }, [payType, dgPmtNo]);

  const onChange = (event) => {
    if (isNaN(Number(event.target.value))) {
      event.target.value = event.target.value.slice(0, -1);
      return alert('숫자 형식만 입력할 수 있습니다.');
    } else {
      setCardInfo({ ...cardInfo, [event.target.name]: event.target.value });
    }
  };
  const onClickClose = () => {
    if (window.confirm('결제를 취소하시겠습니까?')) {
      Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.CLOSE_BTN, '/v3-program-detail/?type=8');
    }
  };

  const onClickSubmit = () => {
    if (!dgPmtNo) {
      alert('결제 정보가 생성되지 않았습니다. 다시 시도해주세요');
      return window.location.reload();
    }

    const { birthYmd, cardNo1, cardNo2, cardNo3, cardNo4, validYear, validMonth, password } = cardInfo;
    const cardNo = cardNo1 + cardNo2 + cardNo3 + cardNo4;

    if (birthYmd.length < 6) {
      return alert('생년월일(사업자번호)을 다시 확인해주세요');
    }

    if (cardNo.length !== 16) {
      return alert('카드번호를 다시 확인해주세요');
    }

    if (password.length !== 2) {
      return alert('카드 비밀번호를 다시 확인해주세요');
    }

    if (validMonth.length !== 2 || validYear.length !== 2) {
      return alert('유효기간을 다시 확인해주세요');
    }

    serIsLoading(true);
    API.updateUserPaymentInfoV4({
      dgPmtNo,
      cardNo,
      birthYmd,
      password,
      validMonth,
      validYear,
    })
      .then(() => {
        Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.SUBMIT, `/pay-complete?dgPmtNo=${dgPmtNo}`);
      })
      .catch((err) => {
        API.errorHandler(err);
        serIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          {payType === PAY_TYPE.CARD && (
            <PaymentViewV4
              dgProgramNo={dgProgramNo}
              onClickSubmit={onClickSubmit}
              onChange={onChange}
              onClickClose={onClickClose}
              focus={focus}
              setFocus={setFocus}
              isComplete={isComplete}
            />
          )}
          {payType === PAY_TYPE.APP_CARD && <></>}
          {!payType && <PaymentTypeSelectView dgProgramNo={dgProgramNo} onClickClose={onClickClose} />}
        </>
      )}
    </>
  );
}
