import React from 'react';
import { css } from '@emotion/css/macro';
import { useSpring, animated } from 'react-spring';
import StyleConstants from '../common/StyleConstants';

export default function BottomUpPopup({ alpha, children, hideDeem, from, to, height }) {
  const style = css`
    width: 100%;
    height: ${height};
    z-index: ${hideDeem ? 2 : 10};
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, ${alpha === undefined ? 0.8 : alpha});
  `;
  const styleCenter = css`
    position: absolute;
    width: 100%;
    height: 100vh;
    max-width: ${StyleConstants.MAX_WIDTH}px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 20;
  `;

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const fadeInProps = useSpring({
    from: {
      position: 'absolute',
      width: '100%',
      bottom: from ? from : '-100%',
    },
    to: {
      bottom: to ? to : '0',
    },
    config: { duration: 300 },
  });

  return (
    <div className={style}>
      <animated.div style={fadeInProps}>
        <div className={styleCenter}>{children}</div>
      </animated.div>
    </div>
  );
}
