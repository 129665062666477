export const DOGIT_USER_CURRICULUM_STATUS = {
  IN_PROGRESS: 1,
  DONE: 2,
  LOCKED: 3,
};

export const DOGIT_USER_STATUS = {
  NONE: 0,
  IN_PROGRESS: 1,
  END: 2,
};

export const DOGIT_MISSION_TYPE = {
  GENERAL_MISSION: 1,
  NUMBER_MISSION: 2,
  ARTICLE: 3,
  QUIZ: 4,
  SURVEY: 5,
  CERTIFICATION_MISSION: 6,
  MULTIPLE_CHOICE: 7,
};

export const DOGIT_MISSION_CATEGORY = {
  GENERAL: 1,
  SUB: 2,
  MAIN: 3,
};

export const DOGIT_MISSION_FEEDBACK_STATUS = {
  GOOD: 3,
  SOSO: 2,
  BAD: 1,
};

export const DOGIT_PAYMENT_STATUS = {
  ERROR: 0,
  REQUEST: 1,
  COMPLETE: 11,
  PARTIAL_COMPLETE: 12,
  FAIL: 21,
  CANCEL: 101,
};

export const DOGIT_ONBOARDING_VIEW_NO = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
  FIFTH: 5,
  SIXTH: 6,
};

export const DOGIT_ONBOARDING_TYPE = {
  NONE: 0,
  A: 1,
  B: 2,
  C: 3,
  L: 4,
  I: 5,
};

export const DOGIT_CURRICULUM_CODE = {
  NONE: 'NONE',
  S1FREE: 'S1FREE',
  B4FREE: 'B4FREE',
  S1: 'S1',
  B4: 'B4',
};

export const PRODUCT_CODE = {
  NONE: 0,
  ONE_MONTH: 1,
  THREE_MONTHS: 3,
  FIVE_MONTHS: 5,
  ONE_WEEK: 17,
  NEW_ONE_MONTH: 101,
};

export const DOGIT_LANDING_TYPE = {
  NONE: 0,
  L: 1,
  I: 2,
};

export const DOGIT_FREE_MISSION_TYPE = {
  NONE: 0,
  EYE_CONTACT: 1,
  TOUCH: 2,
  V: 3,
  SIT_DOWN: 4,
  HOUSE: 5,
};

/**
 * 도깃 V3 프로그램 코드
 */
export const DOGIT_V3_PROGRAM_CODE = {
  SOCIAL: 'SOCIAL',
  SEPARATION: 'SEPARATION',
  TOILET: 'TOILET',
  WALKING: 'WALKING',
  BARK: 'BARK',
  VST_TRAINING: 'VST_TRAINING',
  CHALLENGE: 'CHALLENGE',
  ALL: 'ALL',
  SOCIAL_FREE: 'SOCIAL_FREE',
  SEPARATION_FREE: 'SEPARATION_FREE',
  TOILET_FREE: 'TOILET_FREE',
  WALKING_FREE: 'WALKING_FREE',
  BARK_FREE: 'BARK_FREE',
  ALL_FREE: 'ALL_FREE',
  SEPARATION_CHALLENGE: 'SEPARATION_CHALLENGE',
  BARK_CHALLENGE: 'BARK_CHALLENGE',
};

/**
 * 도깃 v3 프로그램 타입
 * 1: 사회화 교육
 * 2: 분리불안 예방 교육
 * 3: 배변훈련 마스터 교육
 * 4: 산책 교육
 * 5: 올인원
 * 6: 실내 짖음
 * 7: 방문 훈련
 * 8: 챌린지
 * 10: 전화 상담 프로그램
 */
export const DOGIT_V3_PROGRAM_TYPE = {
  SOCIALIZATION: 1,
  SEPARATION_ANXIETY: 2,
  TOILET_TRAINING: 3,
  WALKING_TRAINING: 4,
  ALL_IN_ONE: 5,
  BARK: 6,
  VST_TRAINING: 7,
  CHALLENGE: 8,
  CUSTOMER_SERVICE_PROGRAM: 10,
};
/**
 * 도깃 v3 버튼 액션 타입
 * 0: default
 * 1: 사회화 교육
 * 2: 분리불안 예방 교육
 * 3: 배변훈련 마스터 교육
 * 4: 산책 교육
 * 5: 올인원
 * 6: 실내짖음
 * 51: 사회화 -> 올인원
 * 52: 분리불안 -> 올인원
 * 53: 배변훈련 -> 올인원
 * 54: 산책 -> 올인원
 * 61: 사회회 -> 체험판
 * 62: 분리불안 -> 체험판
 * 63: 배변훈련 -> 체험판
 * 63: 산책 -> 체험판
 * 64: 올인원 -> 체험판
 * 70: 상담
 * 71: 사회화 -> 상담
 * 72: 분리불안 -> 상담
 * 73: 배변훈련 -> 상담
 * 73: 산책 -> 상담
 * 74: 올인원 -> 상담
 * 76: 짖음 -> 상담
 */
export const DOGIT_V3_PROGRAM_BTN_ACTION_TYPE = {
  NONE: 0,
  SOCIALIZATION: 1,
  SEPARATION_ANXIETY: 2,
  TOILET_TRAINING: 3,
  WALKING_TRAINING: 4,
  ALL_IN_ONE: 5,
  BARK: 6,
  VST_TRAINING: 7,
  CUSTOMER_SERVICE_PROGRAM: 10,
  SOCIALIZATION_TO_ALL: 51,
  SEPARATION_TO_ALL: 52,
  TOILET_TO_ALL: 53,
  WALKING_TO_ALL: 54,
  BARK_TO_ALL: 56,
  SOCIALIZATION_TO_FREE: 61,
  SEPARATION_TO_FREE: 62,
  TOILET_TO_FREE: 63,
  WALKING_TO_FREE: 64,
  ALL_TO_FREE: 65,
  BARK_TO_FREE: 66,
  CUSTOMER_SERVICE: 70,
  SOCIALIZATION_TO_CUSTOMER_SERVICE: 71,
  SEPARATION_TO_CUSTOMER_SERVICE: 72,
  TOILET_TO_CUSTOMER_SERVICE: 73,
  WALKING_TO_CUSTOMER_SERVICE: 74,
  ALL_TO_CUSTOMER_SERVICE: 75,
  BARK_TO_CUSTOMER_SERVICE: 76,
  CHANNEL_SOCIALIZATION: 81,
  CHANNEL_SEPARATION: 82,
  CHANNEL_TOILET: 83,
  CHANNEL_WALKING: 84,
  CHANNEL_ALL: 85,
  CHANNEL_BARK: 86,
  CHANNEL_CHALLENGE: 88,
};
/*
 .* 0: 방문
 .* 1: 메뉴 버튼
 .* 11: 메뉴 - 교육 프로그램 버튼
 .* 12: 메뉴 - 이용 방법 버튼
 .* 2: 상단 인덱스 버튼
 * 31: 사회화 교육 상세 버튼
 * 32: 분리불안 교육 상세 버튼
 * 33: 배변훈련 교육 상세 버튼
 * 34: 산책 교육 상세 버튼
 * 35: 올인원 상세 버튼
 .* 4: CTA버튼
 .* 41: CTA_floating
 .* 42: SUBMIT
 .* 43: NEXT
 * 60: 무제한으로 이용하고 싶다면?
 * 61: 올인원 - 사회화 옵션 버튼
 * 62: 분리불안 옵션 버튼
 * 63: 배변훈련 옵션 버튼
 * 64: 산책 옵션 버튼
 */
export const DOGIT_V3_EVENT_TYPE = {
  VISITING: 0,
  MENU_BTN: 1,
  MENU_EDU_LIST_BTN: 11,
  MENU_DESC_BTN: 12,
  INDEX_BTN: 2,
  MY_BTN: 21,
  CHALLENGE_INFO_BTN: 22,
  CLOSE_BTN: 3,
  SOCIALIZATION_DETAIL_BTN: 31,
  SEPARATION_ANXIETY_DETAIL_BTN: 32,
  TOILET_TRAINING_DETAIL_BTN: 33,
  WALKING_TRAINING_DETAIL_BTN: 34,
  ALL_IN_ONE_DETAIL_BYN: 35,
  BARK_DETAIL_BTN: 36,
  VST_TRAINING_BTN: 37,
  CHALLENGE_DETAIL_BTN: 38,
  CUSTOMER_SERVICE_DETAIL_BTN: 310,
  CTA: 4,
  CTA_FLOATING: 41,
  SUBMIT: 42,
  NEXT_BTN: 43,
  REFUND_BTN: 44,
  MODAL: 60,
  MODAL_SOCIALIZATION: 61,
  MODAL_SEPARATION_ANXIETY: 62,
  MODAL_TOILET_TRAINING: 63,
  MODAL_WALKING_TRAINING: 64,
  MODAL_BARK: 66,
  MISSION_OPEN_SOCIAL_FREE: 71,
  MISSION_OPEN_SEPARATION_FREE: 72,
  MISSION_OPEN_TOILET_FREE: 73,
  MISSION_OPEN_WALKING_FREE: 74,
  MISSION_OPEN_BARK_FREE: 76,
  MISSION_DIARY: 80,
  MISSION_COMPLETE: 81,
  PRESCRIPTION_BTN: 100,
  CHALLENGE_INFO_BTN: 101,
  MISSION_OPEN_SEPARATION_CHALLENGE: 172,
  MISSION_OPEN_BARK_CHALLENGE: 176,
};

/**
 * 1: 프로그램 선택
 * 2: 신청 단계
 * 3: 신청 완료
 */
export const DOGIT_V3_CURRENT_STEP_TYPE = {
  CHOOSE_PROGRAM: 0,
  APPLICATION: 1,
  COMPLETE: 2,
};

/**
 * 6: 체험판 신청
 * 7: 상담하기 신청
 */
export const DOGIT_V3_APPLICATION_TYPE = {
  FREE: 6,
  CUSTOMER_SERVICE: 7,
};

export const DOGIT_V3_ONBOARDING_TYPE = {
  STEP_ONE: 1,
  STEP_TWO: 2,
  STEP_THREE: 3,
  STEP_FOUR: 4,
};

export const DOGIT_V3_MISSION_STEP_TYPE = {
  ONBOARDING: 0,
  IN_PROGRESS: 1,
  AUTHENTICATION: 2,
  COMPLETE: 3,
};

export const DOGIT_CHALLENGE_LOG_STATUS = {
  DEFAULT: 0,
  COMPLETE: 11,
  FAIL: 101,
};

export const DOGIT_CHALLENGE_STATUS = {
  DEFAULT: 1,
  COMPLETE: 10,
  REFUND_READY: 11,
  REFUND_COMPLETE: 12,
};

export const DOGIT_REFUND_READY_STEP = {
  TYPEFORM: 1,
  COMPLETE: 2,
};

export const PAY_TYPE = {
  CARD: 1,
  APP_CARD: 2,
};
