import * as API from '../../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as Util from '../../common/Util';
import { DOGIT_PAYMENT_STATUS, DOGIT_V3_EVENT_TYPE } from '../../common/Constants';
import { UserInfoContext } from '../../components/PageWrapper';

const IMP = window.IMP;

export default function PaymentContainerV3({ dgProgramNo }) {
  const { dgUserNo, hpNo, userName, petList } = React.useContext(UserInfoContext);

  React.useEffect(() => {
    IMP.init('imp34695473');
  });

  React.useEffect(() => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  }, []);

  React.useEffect(() => {
    createPmtInfo();
  });

  const createPmtInfo = () => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.CTA);
    API.createUserPaymentInfoV3({ dgProgramNo, dgPetNo: petList[0].dgPetNo })
      .then((res) => {
        requestPay({
          dgProgramNo: res.data.dgProgramNo,
          dgPmtNo: res.data.dgPmtNo,
          dgPetNo: res.data.dgPetNo,
          hpNo: res.data.hpNo,
          dgProgramName: res.data.programName,
          pmtAmt: res.data.pmtAmt,
        });
      })
      .catch((err) => {
        API.errorHandler(err);
        if (_.get(err, 'response.data.error') || err.toString() === '이미 결제하셨습니다.') {
          window.location.href = '/my';
        }
      });
  };

  const requestPay = ({ dgPmtNo, dgPetNo, dgProgramName, pmtAmt, hpNo }) => {
    IMP.request_pay(
      {
        pg: 'nice',
        pay_method: 'card',
        merchant_uid:
          process.env.REACT_APP_ENV === 'development' ? 'dev_dgPmtNo_' + dgPmtNo : 'prod_dgPmtNo_' + dgPmtNo,
        name: dgProgramName,
        amount: pmtAmt,
        buyer_email: '',
        buyer_name: '',
        buyer_tel: hpNo,
        buyer_addr: '',
        buyer_postcode: '',
        m_redirect_url: `${process.env.REACT_APP_URL}/pay-complete?dgPmtNo=${dgPmtNo}`,
      },
      (rsp) => {
        if (rsp.success) {
          const params = {
            dgPmtNo,
            dgPetNo,
            impUid: rsp.imp_uid,
            merchantUid: rsp.merchant_uid,
            pmtSt: DOGIT_PAYMENT_STATUS.COMPLETE,
            errorMsg: rsp.error_msg,
          };
          API.updateUserPaymentInfo(params).catch(API.errorHandler);
        } else {
          const params = {
            dgPmtNo,
            dgPetNo,
            impUid: rsp.imp_uid,
            merchantUid: rsp.merchant_uid,
            pmtSt: DOGIT_PAYMENT_STATUS.FAIL,
            errorMsg: rsp.error_msg,
          };
          API.updateUserPaymentInfo(params).catch(API.errorHandler);
        }
      }
    );
  };

  return <></>;
}
