import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import { DOGIT_V3_PROGRAM_BTN_ACTION_TYPE } from '../../../common/Constants';
import StyleConstants from '../../../common/StyleConstants';

const style = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  margin: 60px 0;
`;
const imgWrp = css`
  width: 100%;
  img {
    width: 100%;
  }
`;
const btnStyle = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  background-color: #e47982;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  letter-spacing: -0.67px;
  color: ${Color.WHITE};
`;

export default function VstTrainingProgramDetailView({ onClickSubmitVstTringingProgram }) {
  return (
    <article className={style}>
      <div className={imgWrp}>
        <img src='//static.dogmate.co.kr/dogit/landing-v3/program-detail/v3.2/dogit-v3.2-detail-main-img-v4-7.png' />
      </div>
      <button onClick={onClickSubmitVstTringingProgram} className={btnStyle}>
        방문 훈련 신청하기
      </button>
    </article>
  );
}
