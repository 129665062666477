import React from 'react';
import _ from 'lodash';
import * as Util from '../common/Util';
import * as API from '../api/api';
import { DOGIT_USER_STATUS } from '../common/Constants';

export default function PageWrapper({ next, children }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [userData, setUserData] = React.useState({});

  React.useEffect(() => {
    API.getUserStatus()
      .then((response) => {
        setUserData({
          dgUserNo: response.data.dgUserNo,
          userName: response.data.userName,
          hpNo: response.data.hpNo,
          petList: response.data.petList,
          isRecvMkt: response.data.isRecvMkt,
          userStatus: response.data.userStatus,
          dgUserProgramList: response.data.dgUserProgramList,
          startYmd: response.data.startYmd,
          endYmd: response.data.endYmd,
          profileImg: response.data.profileImg,
          prescriptionNo: response.data.prescriptionNo,
          isRecvNotification: response.data.isRecvNotification,
          notificationTime: response.data.notificationTime,
        });

        const { userStatus } = response.data;

        if (window.location.pathname.indexOf('/privacy') === 0) {
          return setIsLoading(false);
        } else if (window.location.pathname.indexOf('/terms') === 0) {
          return setIsLoading(false);
        } else if (window.location.pathname.indexOf('/notion') === 0) {
          return setIsLoading(false);
        } else if (window.location.pathname.indexOf('/cs-tf-complete') === 0) {
          return setIsLoading(false);
        } else if (window.location.pathname.indexOf('/prescription-landing') === 0) {
          return setIsLoading(false);
        } else if (window.location.pathname.indexOf('/prescription-typeform') === 0) {
          return setIsLoading(false);
        } else if (window.location.pathname.indexOf('/prescription') === 0) {
          return setIsLoading(false);
        } else if (window.location.pathname.indexOf('/pay') === 0) {
          return setIsLoading(false);
        } else if (window.location.pathname.indexOf('/register') === 0) {
          return setIsLoading(false);
        }

        if (userStatus === DOGIT_USER_STATUS.NONE) {
          if (window.location.pathname === '') {
            setIsLoading(false);
          } else if (window.location.pathname === '/') {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/v3') === 0) {
            setIsLoading(false);
          } else {
            window.location.replace('/');
          }
        } else if (userStatus === DOGIT_USER_STATUS.IN_PROGRESS) {
          if (window.location.pathname.indexOf('/') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/v3') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/list') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/onboarding') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/mission') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/my') === 0) {
            setIsLoading(false);
          } else {
            window.location.replace('/');
          }
        } else if (userStatus === DOGIT_USER_STATUS.END) {
          if (window.location.pathname.indexOf('/') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/v3') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/list') === 0) {
            setIsLoading(false);
          } else {
            window.location.replace('/');
          }
        }
      })
      .catch((error) => {
        if (_.get(error, 'response.status') === 401) {
          if (window.location.pathname === '') {
            setIsLoading(false);
          } else if (window.location.pathname === '/') {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/login') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/notion') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/v3-program-detail') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/program-detail-ad') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/v3-program-list') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/v3-program-preview') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/v3-program-application') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/v3-cs-application') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/cs-tf-complete') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/prescription-landing') === 0) {
            setIsLoading(false);
          } else if (window.location.pathname.indexOf('/prescription-typeform') === 0) {
            return setIsLoading(false);
          } else if (window.location.pathname.indexOf('/prescription') === 0) {
            setIsLoading(false);
          } else {
            Util.resetAuthInfo();
            window.location.replace(`/login?next=${next}`);
          }
        } else {
          API.errorHandler(error);
        }
      });
  }, []);

  return (
    !isLoading && (
      // ? <LoadingView />
      <UserInfoContext.Provider
        value={{
          ...userData,
        }}
      >
        {children}
      </UserInfoContext.Provider>
    )
  );
}

export const UserInfoContext = React.createContext({});
