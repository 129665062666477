import { css } from '@emotion/css/macro';
import * as API from '../../../api/api';
import * as Util from '../../../common/Util';
import { useState } from 'react';
import StyleConstants from '../../../common/StyleConstants';
import IconClose from '../../../icons/IconClose';
import LoadingView from '../../mission/subview/LoadingView';
import axios from 'axios';
import moment from 'moment';
import IconPlay from '../../../icons/IconPlay';
import { DOGIT_V3_EVENT_TYPE } from '../../../common/Constants';
import IconMediaPlay from '../../../icons/IconMediaPlay';

const style = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  margin-bottom: 120px;
`;
const topBar = css`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h1 {
    margin: 4px 0 0 15px;
    font-weight: bold;
    size: 16px;
    line-height: 30px;
    letter-spacing: -0.6px;
    color: #535454;
  }
  button {
    margin-right: 15px;
    background-color: rgba(0, 0, 0, 0);
  }
  border-bottom: 1px solid #cacede;
`;
const titleStyle = css`
  margin: 25px 0 13px 23px;
  h1 {
    font-size: 18px;
    letter-spacing: -0.67px;
    color: #42607f;
    font-weight: bold;
    line-height: 1.5;
  }
`;
const starRateWrp = css`
  display: flex;
  justify-content: center;
`;
const starRateStyle = css`
  display: flex;
  flex-direction: row;
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      background-color: rgba(0, 0, 0, 0);
      width: 50px;
      height: 50px;
      margin: 8px 7px 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    div {
      font-size: 20px;
      text-align: center;
      p {
        margin-top: 5px;
        font-size: 10px;
        letter-spacing: -0.37px;
      }
    }
  }
`;
const divider = css`
  width: 90%;
  height: 1px;
  background-color: #eff2f2;
  margin: 25px auto;
`;

const satisfactionStyle = css`
  margin: 34px 0 0 25px;
  h2 {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.6px;
    color: #42607f;
  }
  ul {
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 0;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: -0.49px;
      color: #454747;
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }
`;
const satisfactionEtcDescStyle = css`
  min-height: 54px;
  border: solid 1px #cacede;
  border-radius: 10px;
  margin: 10px 0;
  overflow-y: scroll;
  padding: 6px 4px;
  resize: none;
  width: calc(85vw - 28px);
  padding: 6px 14px;
`;
const improvementStyle = css`
  margin: 34px 0 0 25px;
  h2 {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.6px;
    color: #42607f;
  }
  ul {
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 0;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: -0.49px;
      color: #454747;
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }
`;
const videoStyle = css`
  margin: 34px 0 0 25px;
  h2 {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.6px;
    color: #42607f;
  }
  div {
    margin-top: 12px;
    width: 165px;
    height: 42px;
    border-radius: 25px;
    background-color: #e6e8f4;
    input {
      position: absolute;
      width: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
      border: 0;
    }
    label {
      display: inline-block;
      text-align: center;
      width: 100%;
      padding: 13px 0;
      border-radius: 25px;
      background-color: #e6e8f4;
      font-size: 16px;
      letter-spacing: -0.6px;
      font-weight: bold;
      color: #4c5bcd;
      margin-bottom: 26px;
    }
  }
`;
const reviewStyle = css`
  margin: 34px 0 0 25px;
  h2 {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.6px;
    color: #42607f;
  }
  textarea {
    width: calc(85vw - 28px);
    min-height: 54px;
    border: solid 1px #cacede;
    border-radius: 10px;
    margin: 10px 0;
    overflow-y: scroll;
    padding: 6px 14px;
    resize: none;
    height: 142px;
    font-size: 14px;
    letter-spacing: -0.45px;
  }
`;
const purchasePathStyle = css`
  margin: 34px 0 25px 25px;
  h2 {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.6px;
    color: #42607f;
  }
  div {
    display: flex;
    flex-direction: column;
    ul {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      li {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.49px;
        color: #454747;
        img {
          width: 20px;
          height: 20px;
          margin: 0 6px;
        }
      }
    }
  }
`;
const isMktConsentStyle = css`
  margin: 25px 0 25px 25px;
  p {
    margin-left: -25px;
    font-size: 12px;
    text-align: center;
    letter-spacing: -0.45px;
    line-height: 1.3;
    color: #454747;
    margin-bottom: 6px;
    b {
      font-weight: bold;
    }
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 17px 0 0 -25px;
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: -0.49px;
      color: #454747;
      margin: 0 6px;
      img {
        width: 20px;
        height: 20px;
        margin: 0 6px;
      }
    }
  }
`;
const submitBtnStyle = css`
  margin-top: 34px;
  width: 87.5vw;
  height: 50px;
  border-radius: 15px;
  background-color: #f48c95;
  margin: 0 auto;
  position: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 16px;
    letter-spacing: -0.6px;
    font-weight: bold;
    color: #fff;
  }
`;
const videoUploadCompleteBoxStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 85vw;
  height: 34px;
  border-radius: 10px;
  border: solid 1px #cacede;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 15px;
  div {
    margin: 0 0 2px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    height: 10px;
    p {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.49px;
      color: #454747;
      margin-left: 4px;
    }
  }
  button {
    margin-right: 8px;
    background-color: rgba(0, 0, 0, 0);
  }
`;

export function RefundRegisterModalView({
  dgUserNo,
  hpNo,
  dgUserProgramNo,
  userName,
  programName,
  onClickCloseRefundModal,
}) {
  const [starRate, setStarRate] = useState(0);
  const [satisfaction, setSatisfaction] = useState(new Set());
  const [satisfactionEtcDesc, setSatisfactionEtcDesc] = useState('');
  const [improvement, setImprovement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [review, setReview] = useState('');
  const [purchasePath, setPurchasePath] = useState(null);
  const [isMktConsent, setIsMktConsent] = useState(null);
  const [fileName, setFileName] = useState('');

  const onClickChangeStarRate = (newStarRate) => {
    if (starRate >= 3 && newStarRate < 3) {
      setSatisfaction(new Set());
      setSatisfactionEtcDesc('');
    }

    setStarRate(newStarRate);
  };
  const onClickSubmit = () => {
    if (!getIsComplete()) {
      return alert('모든 정보를 입력해주세요');
    }

    API.submitReview({
      dgUserProgramNo,
      starRate,
      satisfactionEtcDesc,
      improvement,
      review,
      purchasePath,
      isMktConsent,
      afterVideoUrl: fileName,
      satisfaction: [...satisfaction],
    }).then(() => {
      Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.SUBMIT, '/my');
    });
  };

  const getIsComplete = () => {
    if (starRate <= 1 || starRate > 5) {
      return false;
    }

    if (starRate >= 3 && satisfaction.size === 0) {
      return false;
    }

    if (starRate >= 3 && satisfaction.has(5) && satisfactionEtcDesc.length === 0) {
      return false;
    }

    if (fileName === '') {
      return false;
    }

    if (review.length < 10) {
      return false;
    }

    if (purchasePath === null) {
      return false;
    }

    if (starRate >= 3 && isMktConsent === null) {
      return false;
    }

    return true;
  };

  const onChangeVideo = (event) => {
    const file = event.target.files[0];
    const newFileName = String(moment().format('YYYYMMDDHHmmss'));

    setIsLoading(true);

    API.createVideoURL({
      dgUserProgramNo,
      fileName: newFileName,
      isAfter: true,
    })
      .then((res) => {
        const signedUrl = res.data;

        return axios({
          method: 'PUT',
          url: signedUrl,
          data: file,
        });
      })
      .then(() => {
        setIsLoading(false);
        setFileName(newFileName);
      });
  };
  const onClickSetSatisfaction = (el) => {
    if (satisfaction.has(el)) {
      const newSet = new Set([...satisfaction]);
      newSet.delete(el);
      setSatisfaction(new Set([...newSet]));
    } else {
      setSatisfaction(new Set([...satisfaction, el]));
    }
  };

  const onClickSetImprovement = (el) => {
    setImprovement(el);
  };
  return (
    <article className={style}>
      {isLoading && <LoadingView alpha={0.9} children={<div>영상을 업로드하고 있습니다</div>} />}
      <div className={topBar}>
        <h1>환급 신청하기</h1>
        <button onClick={onClickCloseRefundModal}>
          <IconClose size={15} strokeColor='#b1b4c1' />
        </button>
      </div>
      <div className={titleStyle}>
        <h1>
          {userName}님 😄
          <br />
          {programName} 개선 프로그램은 어떠셨나요?
        </h1>
      </div>
      <div className={starRateWrp}>
        <div className={starRateStyle}>
          {Array.from({ length: 5 }).map((el, idx) => (
            <section key={'star-rate' + idx}>
              <button onClick={() => onClickChangeStarRate(idx + 1)}>
                {starRate > idx ? (
                  <div>
                    <img src='//static.dogmate.co.kr/dogit/v4/my/refund/star-pink.png' alt='on' />
                  </div>
                ) : (
                  <div>
                    <img src='//static.dogmate.co.kr/dogit/v4/my/refund/star-grey.png' alt='off' />
                  </div>
                )}
              </button>
              {idx === 0 ? (
                <div style={{ marginTop: '6px' }}>
                  🥶
                  <p
                    style={
                      starRate === 1 || starRate === 2 ? { color: '#454747', fontWeight: 'bold' } : { color: '#a7abab' }
                    }
                  >
                    별로에요
                  </p>
                </div>
              ) : idx === 2 ? (
                <div style={{ marginTop: '6px' }}>
                  😶
                  <p
                    style={
                      starRate === 3 || starRate === 4 ? { color: '#454747', fontWeight: 'bold' } : { color: '#a7abab' }
                    }
                  >
                    보통이에요
                  </p>
                </div>
              ) : idx === 4 ? (
                <div style={{ marginTop: '6px' }}>
                  🥰
                  <p style={starRate === 5 ? { color: '#454747', fontWeight: 'bold' } : { color: '#a7abab' }}>
                    최고에요!
                  </p>
                </div>
              ) : (
                <></>
              )}
            </section>
          ))}
        </div>
      </div>
      {starRate >= 3 && (
        <section className={satisfactionStyle}>
          <h2>도깃을 하며 만족스러웠던 부분은 뭔가요?</h2>
          <ul>
            <li onClick={() => onClickSetSatisfaction(1)}>
              <img
                src={`//static.dogmate.co.kr/dogit/v4/my/refund/${satisfaction.has(1) ? 'check' : 'check-no'}.png`}
              />
              매일 참여할 수 있는 미션
            </li>
            <li onClick={() => onClickSetSatisfaction(2)}>
              <img
                src={`//static.dogmate.co.kr/dogit/v4/my/refund/${satisfaction.has(2) ? 'check' : 'check-no'}.png`}
              />
              매일 알려주는 알람
            </li>
            <li onClick={() => onClickSetSatisfaction(3)}>
              <img
                src={`//static.dogmate.co.kr/dogit/v4/my/refund/${satisfaction.has(3) ? 'check' : 'check-no'}.png`}
              />
              전문가 상담
            </li>
            <li onClick={() => onClickSetSatisfaction(4)}>
              <img
                src={`//static.dogmate.co.kr/dogit/v4/my/refund/${satisfaction.has(4) ? 'check' : 'check-no'}.png`}
              />
              100% 환급 챌린지
            </li>
            <li onClick={() => onClickSetSatisfaction(5)}>
              <img
                src={`//static.dogmate.co.kr/dogit/v4/my/refund/${satisfaction.has(5) ? 'check' : 'check-no'}.png`}
              />
              기타
            </li>
          </ul>
          {satisfaction.has(5) && (
            <textarea
              className={satisfactionEtcDescStyle}
              value={satisfactionEtcDesc}
              onChange={(event) => setSatisfactionEtcDesc(event.target.value)}
            />
          )}
        </section>
      )}
      {starRate > 0 && (
        <section className={improvementStyle}>
          <h2>도깃 참여 후 문제행동이 개선되었나요?</h2>
          <ul>
            <li onClick={() => onClickSetImprovement(1)}>
              <img src={`//static.dogmate.co.kr/dogit/v4/my/refund/${improvement === 1 ? 'check' : 'check-no'}.png`} />
              🙋🏻‍♀️ 개선 되었어요.
            </li>
            <li onClick={() => onClickSetImprovement(2)}>
              <img src={`//static.dogmate.co.kr/dogit/v4/my/refund/${improvement === 2 ? 'check' : 'check-no'}.png`} />
              💁🏻‍♀️ 어느정도 개선 되었어요.
            </li>
            <li onClick={() => onClickSetImprovement(3)}>
              <img src={`//static.dogmate.co.kr/dogit/v4/my/refund/${improvement === 3 ? 'check' : 'check-no'}.png`} />
              🙅🏻‍♀️ 개선되지 않았어요.
            </li>
          </ul>
        </section>
      )}
      {starRate > 0 && (
        <section className={videoStyle}>
          <h2>도깃 참여 후 변화된 영상을 공유해주세요.</h2>
          {fileName && (
            <section className={videoUploadCompleteBoxStyle}>
              <div>
                <IconMediaPlay size={16} />
                <p>{fileName}.mp4</p>
              </div>
              <button onClick={() => setFileName('')}>
                <IconClose size={12} strokeColor='#b1b4c1' />
              </button>
            </section>
          )}
          <div>
            <input id='camera' type='file' name='camera' accept='video/*' onChange={onChangeVideo} />
            <label htmlFor='camera'>{fileName ? '영상 다시 올리기' : '영상올리기'}</label>
          </div>
        </section>
      )}

      {starRate > 0 &&
        (starRate < 3 ? (
          <section className={reviewStyle}>
            <h2>아쉬웠던 점을 도깃 팀에 알려주세요!</h2>
            <label htmlFor='feedback-input' />
            <textarea
              id='feedback-input'
              type='text'
              value={review}
              placeholder='도깃이 더 발전할 수 있도록 노력하겠습니다!'
              onChange={(event) => setReview(event.target.value)}
            />
          </section>
        ) : (
          <section className={reviewStyle}>
            <h2>도깃의 이용 경험을 알려주세요!</h2>
            <textarea
              placeholder='도깃이 더 발전할 수 있도록 자세한 후기를 남겨주세요.'
              value={review}
              onChange={(event) => setReview(event.target.value)}
            ></textarea>
          </section>
        ))}
      {starRate > 0 && (
        <section className={purchasePathStyle}>
          <h2>마지막으로 도깃은 어떤 경로로 알게되었나요?</h2>
          <div>
            <ul>
              <li onClick={() => setPurchasePath(1)}>
                <img
                  src={`//static.dogmate.co.kr/dogit/v4/my/refund/${purchasePath === 1 ? 'check' : 'check-no'}.png`}
                />
                인스타그램
              </li>
              <li onClick={() => setPurchasePath(2)}>
                <img
                  src={`//static.dogmate.co.kr/dogit/v4/my/refund/${purchasePath === 2 ? 'check' : 'check-no'}.png`}
                />
                페이스북
              </li>
              <li onClick={() => setPurchasePath(3)}>
                <img
                  src={`//static.dogmate.co.kr/dogit/v4/my/refund/${purchasePath === 3 ? 'check' : 'check-no'}.png`}
                />
                지인 추천
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li onClick={() => setPurchasePath(4)}>
                <img
                  src={`//static.dogmate.co.kr/dogit/v4/my/refund/${purchasePath === 4 ? 'check' : 'check-no'}.png`}
                />
                네이버 포스트
              </li>
              <li onClick={() => setPurchasePath(5)}>
                <img
                  src={`//static.dogmate.co.kr/dogit/v4/my/refund/${purchasePath === 5 ? 'check' : 'check-no'}.png`}
                />
                카페 후기
              </li>
              <li onClick={() => setPurchasePath(6)}>
                <img
                  src={`//static.dogmate.co.kr/dogit/v4/my/refund/${purchasePath === 6 ? 'check' : 'check-no'}.png`}
                />
                검색
              </li>
            </ul>
          </div>
        </section>
      )}
      {starRate >= 3 && (
        <>
          <div className={divider} />
          <section className={isMktConsentStyle}>
            <p>
              작성해주신 후기 및 도깃의 인증사진, 영상 등의
              <br />
              내용은 마케팅 용도로 활용될 수 있습니다.
            </p>
            <p>
              <b>(동의 시 추첨을 통해 스타벅스 기프티콘 지급)</b>
            </p>
            <ul>
              <li onClick={() => setIsMktConsent(true)}>
                <img
                  src={`//static.dogmate.co.kr/dogit/v4/my/refund/${isMktConsent === true ? 'check' : 'check-no'}.png`}
                />
                동의
              </li>
              <li onClick={() => setIsMktConsent(false)}>
                <img
                  src={`//static.dogmate.co.kr/dogit/v4/my/refund/${isMktConsent === false ? 'check' : 'check-no'}.png`}
                />{' '}
                미동의
              </li>
            </ul>
          </section>
        </>
      )}
      {starRate > 0 && (
        <div
          className={submitBtnStyle}
          style={{ backgroundColor: !getIsComplete() && '#DFE7F0' }}
          onClick={onClickSubmit}
        >
          <p>환급 신청 완료</p>
        </div>
      )}
    </article>
  );
}
