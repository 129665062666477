import * as React from 'react';
import * as Util from '../../common/Util';
import * as API from '../../api/api';
import { DOGIT_V3_EVENT_TYPE, DOGIT_V3_PROGRAM_TYPE } from '../../common/Constants';
import PrescriptionView from './view/PrescriptionView';
import { UserInfoContext } from '../../components/PageWrapper';
import _ from 'lodash';

export default function PrescriptionContainer({
  prescriptionNo,
  typeformHpNo,
  dgUserProgramNo,
  firstMissionCode,
  missionCode,
  end,
}) {
  const { dgUserNo, hpNo } = React.useContext(UserInfoContext);
  const [data, setData] = React.useState({});
  const [log, setLog] = React.useState(0);

  React.useEffect(() => {
    if (prescriptionNo > 100) {
      Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.VISITING);

      if (dgUserProgramNo && !missionCode) {
        API.createPrescription({ dgUserProgramNo, prescriptionNo, firstMissionCode })
          .then(() => {
            API.getChlngSt()
              .then((res) => {
                const challengeData = res.data;
                if (!_.isEmpty(challengeData)) {
                  setData(challengeData.filter((el) => el.dgUserProgramNo === dgUserProgramNo)[0]);
                }
              })
              .catch(API.errorHandler);
          })
          .catch(API.errorHandler);
      }
    } else {
      Util.createEventLog(dgUserNo, typeformHpNo, DOGIT_V3_EVENT_TYPE.VISITING);
      API.createPrescriptionNone({ prescriptionNo, hpNo: typeformHpNo }).catch(API.errorHandler);
    }
  }, []);

  React.useEffect(() => {
    if (log) {
      Util.createEventLog(
        dgUserNo,
        hpNo,
        DOGIT_V3_EVENT_TYPE.CTA,
        `/mission?step=1&code=${
          missionCode ? missionCode : data.nextMissionCode ? data.nextMissionCode : data.currentMissionCode
        }&log=${log}&up=${dgUserProgramNo}`
      );
    }
  }, [log]);

  const prescriptionInfo = new Map([
    [
      1,
      {
        isSelf: true,
        programType: DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_1.jpg',
      },
    ],
    [
      2,
      {
        isSelf: true,
        programType: DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_2.jpg',
      },
    ],
    [
      3,
      {
        isSelf: false,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_3.jpg',
      },
    ],
    [
      4,
      {
        isSelf: true,
        programType: DOGIT_V3_PROGRAM_TYPE.BARK,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_4.png',
      },
    ],
    [
      5,
      {
        isSelf: true,
        programType: DOGIT_V3_PROGRAM_TYPE.BARK,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_5.jpg',
      },
    ],
    [
      6,
      {
        isSelf: true,
        programType: DOGIT_V3_PROGRAM_TYPE.BARK,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_6.jpg',
      },
    ],
    [
      7,
      {
        isSelf: false,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_7.jpg',
      },
    ],
    [
      8,
      {
        isSelf: false,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_8.jpg',
      },
    ],
    [
      9,
      {
        isSelf: false,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_9.jpg',
      },
    ],
    [
      10,
      {
        isSelf: false,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_10.jpg',
      },
    ],
    [
      11,
      {
        isSelf: false,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_11.jpg',
      },
    ],
    [
      12,
      {
        isSelf: false,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_12.jpg',
      },
    ],
    [
      13,
      {
        isSelf: false,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_13.jpg',
      },
    ],
    [
      14,
      {
        isSelf: false,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_14.jpg',
      },
    ],
    [
      101,
      {
        isSelf: true,
        programType: DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v4_prescription_1.jpg',
      },
    ],
    [
      102,
      {
        isSelf: true,
        programType: DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v4_prescription_2.jpg',
      },
    ],
    [
      104,
      {
        isSelf: true,
        programType: DOGIT_V3_PROGRAM_TYPE.BARK,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v4_prescription_4.jpg',
      },
    ],
    [
      105,
      {
        isSelf: true,
        programType: DOGIT_V3_PROGRAM_TYPE.BARK,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v4_prescription_5.jpg',
      },
    ],
    [
      106,
      {
        isSelf: true,
        programType: DOGIT_V3_PROGRAM_TYPE.BARK,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v4_prescription_6.jpg',
      },
    ],
    [
      107,
      {
        isSelf: false,
        img: '//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_7.jpg',
      },
    ],
    [
      114,
      {
        isSelf: false,
        img: '//static.dogmate.co.kr/dogit/v4/prescription/prescription-etc.png',
      },
    ],
  ]);

  const onClickGotoDetail = (programType) => {
    Util.createEventLog(0, typeformHpNo, DOGIT_V3_EVENT_TYPE.CTA, `/v3-program-detail?type=${programType}`);
  };

  const onClickGotoMission = () => {
    const code = missionCode ? missionCode : data.nextMissionCode ? data.nextMissionCode : data.currentMissionCode;
    if (!log && data) {
      API.createChallengeLog(dgUserProgramNo, code)
        .then((res) => {
          setLog(res.data);
        })
        .catch(API.errorHandler);
    }
  };

  const onClickGotoMy = () => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.MY_BTN, '/my');
  };

  return (
    <>
      {prescriptionInfo.get(prescriptionNo) && (
        <PrescriptionView
          data={data}
          prescriptionInfo={prescriptionInfo}
          dgUserNo={dgUserNo}
          prescriptionNo={prescriptionNo}
          onClickGotoDetail={onClickGotoDetail}
          onClickGotoMission={onClickGotoMission}
          onClickGotoMy={onClickGotoMy}
          end={end}
        />
      )}
    </>
  );
}
