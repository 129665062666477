import { css } from '@emotion/css/macro';

const deem = css`
  background-color: #cdcdcd;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
`;
const style = css`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;
`;
const wrp = css`
  padding: 21px 0;
  width: 89%;
  border-radius: 16px;
  background-color: #fff;
  h1 {
    color: #404141;
    font-weight: bold;
    letter-spacing: -0.56px;
    margin: 0 23px 13px;
  }
  li {
    list-style-type: '・ ';
    font-size: 12px;
    color: #696a69;
    line-height: 1.67;
    letter-spacing: -0.45px;
    margin: 0 10px 0 23px;
    b {
      font-weight: bold;
    }
  }
  button {
    display: block;
    background-color: #f48c95;
    border-radius: 25px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.35);
    height: 50px;
    width: 70%;
    margin: 19px auto 0;
    color: #fff;
    font-weight: bold;
  }
`;

export default function ChallengeTermsView({ onClick }) {
  return (
    <>
      <div className={deem} />
      <div className={style}>
        <div className={wrp}>
          <h1>🌈&nbsp;&nbsp;이벤트 유의사항</h1>
          <li>챌린지 프로그램 결제 후 챌린지가 시작됩니다.</li>
          <li>환불은 고객센터를 통해 신청해주세요. 문의일 기준 영업일 3일 이내에 환불처리됩니다.</li>
          <li>환불 시 챌린지 환급은 적용되지 않습니다.</li>
          <li>
            <b>환불 규정 안내 (서비스 이용 시작일 = 결제일)</b>
          </li>
          <li>환불 시 서비스 이용 내역 복구는 불가합니다.</li>
          <li>30일 이용 기간 내 참여이력이 없는 경우 이용료 전액 환불</li>
          <li>이용 기간 중 1/3이 지난 경우(1~10일차), 납부한 이용료의 2/3에 해당되는 금액 환불</li>
          <li>1/2이 지난 경우(11~14일차), 납부한 이용료의 1/2에 해당되는 금액 환불</li>
          <li>1/2 기간 경과 후(15일차 이후), 환불 불가 </li>
          <li>환불은 서비스 이용기간 종료일 을 기준으로 영업일 7일 이내에 진행됩니다. </li>
          <button onClick={onClick}>확인</button>
        </div>
      </div>
    </>
  );
}
