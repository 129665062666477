import { css } from '@emotion/css/macro';
import { DOGIT_CHALLENGE_LOG_STATUS } from '../../../common/Constants';
import StyleConstants from '../../../common/StyleConstants';
import IconClose from '../../../icons/IconClose';

const deem = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
  overflow-y: scroll;
`;
const top = css`
  position: fixed;
  right: 15px;
  top: 15px;
`;

const wrp = css`
  width: 100%;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 70px 0 120px;
  h1 {
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.52px;
    color: white;
    text-align: left;
    margin: 11px 0 4px 20px;
  }
  h2 {
    font-size: 14px;
    line-height: 1.57px;
    letter-spacing: -0.52px;
    color: white;
    text-align: left;
    margin: 7px 0 11px 20px;
  }
  h3 {
    font-size: 10px;
    line-height: 22px;
    letter-spacing: -0.37px;
    color: #757575;
    margin: 0 0 0 20px;
  }
`;
const imgWrp = css`
  max-height: 75vh;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 11px;
  img {
    width: 100%;
  }
`;

export default function ImgView({ imgInfo, onClickCloseImgView, programName }) {
  document.querySelector('body').style.overflow = 'hidden';
  return (
    <>
      <div className={deem}>
        <div className={top} onClick={onClickCloseImgView}>
          <IconClose size={14} strokeColor='#b1b4c1' />
        </div>
        <div className={wrp}>
          <div className={imgWrp}>
            <img src={imgInfo.imgUrl} />
          </div>
          <h1>{`${programName} ${imgInfo.currentMissionCode && imgInfo.currentMissionCode.split('-')[1]}회차 인증`}</h1>
          {imgInfo.challengeLogSt === DOGIT_CHALLENGE_LOG_STATUS.FAIL && <h2>🚫 인증 무효 (미션과 무관한 사진)</h2>}
          <h3>{imgInfo.regDate}</h3>
        </div>
      </div>
    </>
  );
}
