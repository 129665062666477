import { css } from '@emotion/css/macro';
import IconClose from '../../../../icons/IconClose';

const style = css`
  display: flex;
  flex-direction: row;
  background-color: #4855d1;
  height: 40px;
  justify-content: center;
  align-items: center;
  h1 {
    color: #fff;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: -0.52px;
    b {
      font-weight: bold;
    }
  }
`;
const btnWrp = css`
  position: fixed;
  top: 12px;
  right: 12px;
`;
export default function BannerView({ onClick, onClickClose }) {
  return (
    <article className={style}>
      <h1 onClick={onClick}>
        지금 시작하면 <b>100% 환급 챌린지</b> 참여 가능!
      </h1>
      <div className={btnWrp} onClick={onClickClose}>
        <IconClose size={15} strokeColor={'#fff'} />
      </div>
    </article>
  );
}
