import { css } from '@emotion/css/macro';
import StyleConstants from '../../../common/StyleConstants';
import IconClose from '../../../icons/IconClose';

const style = css`
  position: fixed;
  bottom: top;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  z-index: 65534;
  background-color: #fff;
`;
const topBarStyle = css`
  position: fixed;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100vw;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #535454;
  font-size: 16px;
  h1 {
    color: #535454;
    font-weight: bold;
    margin-left: 15px;
    letter-spacing: -0.6px;
    margin-bottom: 4px;
  }
  button {
    background-color: #fff;
    margin-right: 15px;
    margin-bottom: 4px;
  }
`;
const imgWrp = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  left: 0;
  margin: 0 auto;
  img {
    width: 100%;
  }
`;
const top = css`
  background-color: #4856d2;
  padding: 25px 0;
  margin-top: 45px;
`;
const titleImgStyle = css`
  ${imgWrp};
  margin: 26px 0 0 15px;
`;
const section1 = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  ul {
    margin: 18px 20px 0 25px;
    li {
      font-size: 16px;
      line-height: 27.5px;
      letter-spacing: -0.52px;
      color: #6b6b6b;
      list-style: '∙ ';
      b {
        font-weight: bold;
      }
    }
    ol {
      margin: 8px 0 9px;
      div {
        width: 85vw;
        padding: 18px 0;
        background-color: #f3f3f3;
        ul {
          margin: 10px 4px 4px 25px;
          li {
            font-size: 12px;
            line-height: 23.5px;
            letter-spacing: -0.45px;
            color: #818181;
            list-style: '‣ ';
          }
          li#dot-list {
            list-style: '∙ ';
          }
        }
      }
    }
  }
`;
const flow = css`
  width: 90%;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  height: 31px;
  border-radius: 5px;
  background-color: #ffe1f1;
  margin: 12px auto 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  span {
    font-size: 16px;
    color: #5868e4;
    line-height: 26px;
    letter-spacing: -0.52px;
  }
`;
const subtitleStyle = css`
  color: #4489b4;
  font-size: 16px;
  letter-spacing: -0.6px;
  font-weight: bold;
  margin: 17px 22px 9px 15px;
  line-height: 27.5px;
`;
const section2 = css`
  p {
    margin: 9px 20px 0 15px;
    font-size: 16px;
    line-height: 27.5px;
    letter-spacing: -0.52px;
    color: #606060;
  }
`;
const divider = css`
  background-color: #d7ddde;
  height: 1px;
  width: 87.5%;
  margin: 26px auto 0;
`;
const termsBtnStyle = css`
  background-color: rgba(0, 0, 0, 0);
  margin: 40px 0 150px 15px;
  font-size: 20px;
  color: #939393;
  u {
    font-size: 14px;
  }
`;

export default function GuideModalView({ onClick, onClickOpenTermsModal }) {
  document.querySelector('body').style.overflow = 'hidden';
  return (
    <article className={style}>
      <div className={topBarStyle}>
        <h1>도깃 이용가이드</h1>
        <button onClick={onClick}>
          <IconClose size='16' strokeColor='#b1b4c1' />
        </button>
      </div>
      <div className={top}>
        <div className={imgWrp} style={{ width: '54%' }}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/guide/dogit-v4-guide-1.png' />
        </div>
      </div>
      <section className={section1}>
        <div className={titleImgStyle} style={{ width: '84%' }}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/guide/dogit-v4-guide-2.png' />
        </div>
        <ul>
          <li>
            30일 안에 <b>14일 연속 참여에 성공</b>하면 환급 성공!
          </li>
          <li>
            가장 중요한 점은! <b>미션 참여 완료</b>를 해야하는 것!
          </li>
        </ul>
        <div className={flow}>
          <span>
            💡&nbsp;미션참여&nbsp;
            <span style={{ color: '#898989' }}>‣</span>
            &nbsp;챌린지 완료 버튼&nbsp;
            <span style={{ color: '#898989' }}>‣</span>
            &nbsp;사진 인증&nbsp;
            <span style={{ color: '#898989' }}>‣</span>
            &nbsp;불주먹&nbsp;
          </span>
        </div>
        <div className={imgWrp} style={{ width: '90%' }}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/guide/dogit-v4-guide-3.png' />
        </div>
        <ul>
          <li>
            환급에 성공하면 <b>환급 신청</b> 하기! <u>기한 내 미작성 시 환급이 취소</u>될 수 있어요.
          </li>
          <li>
            이용기간이 종료되면 <b>7일 이내에 환급이 진행</b>돼요.
          </li>
        </ul>
      </section>
      <section className={section2}>
        <div className={titleImgStyle} style={{ width: '63%' }}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/guide/dogit-v4-guide-4.png' />
        </div>
        <div className={subtitleStyle}>🌈 챌린지 시작일을 늦출 수 있을까요?</div>
        <p>
          챌린지는 결제와 동시에 시작됩니다! 오늘 당장 미션을 하지 못한다고 너무 걱정마세요 :) 30일 기간 내에 편한
          날부터 시작해서 연속 14일만 채우면 되거든요. 다만 서비스 이용기간이 만료되는 30일 안에 14일을 채워야 하기
          때문에 결제일로부터 15일 내에는 반드시 시작해주셔야 30일 안에 14일 연속 참여 챌린지를 성공할 수 있어요!
        </p>
        <div className={divider} />
        <div className={subtitleStyle}>😵 미션은 매일 참여하는데, 강아지가 미션을 잘 못해요. 이래도 환급이 되나요?</div>
        <p>
          미션의 성과와 관계없이 참여 인증만 해주신다면 환급은 됩니다! 하지만 우리는 강아지의 문제행동을 고쳐야
          하니까요! 생각보다 미션 진행이 어렵다면 언제든 고객센터로 연락주세요. 반려견 문제행동 전문 상담가 가 도움을
          드릴게요!
        </p>
        <div className={divider} />
        <div className={subtitleStyle}>🙊 14일 연속참가에 실패했어요. 저는 이제 프로그램을 이용하지 못하나요? </div>
        <p>
          아뇨! 연속참가에 실패했더라도 이용기간 안에서는 프로그램을 무제한으로 자유롭게 이용할 수 있어요. 14일 연속참가
          챌린지에 실패했더라도 강아지 문제행동 고치는 훈련은 절대 포기하지 마세요!
        </p>
      </section>
      <section className={section1}>
        <div className={titleImgStyle} style={{ width: '50%' }}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/guide/dogit-v4-guide-5.png' />
        </div>
        <ul>
          <li>
            <b>도깃 환불 규정 안내(서비스 이용 시작일 = 결제일)</b>
          </li>
          <ol>
            <div>
              <ul>
                <li id='dot-list'>30일 이용 프로그램 환불 규정</li>
                <li>이용 기간 내 서비스 이용 시작 전 결제금 전액 환불</li>
                <li>서비스 이용 이력이 없더라도 이용 기간 이후 환불 불가</li>
                <li>이용 기간 중 1/3이 지난 경우 (1~10일차) 결제액 2/3에 해당되는 금액 환불</li>
                <li>1/2이 지난 경우(11~14일차) 납부한 이용료의 1/2에 해당 되는 금액 환불</li>
                <li>1/2 기간 경과 후(15일차 이후) 환불 불가</li>
                <li>이용기간 종료일을 기준으로 영업일 7일 이내에 환불됩니다.</li>
              </ul>
            </div>
          </ol>
          <li>
            환불 시 챌린지 <b>환급대상에서 제외</b>됩니다.
          </li>
          <li>
            <b>서비스 이용 양도는 불가</b>합니다.
          </li>
          <li>
            서비스 이용 시작일은 <b>최초 1회에 한해 최대 7일까지 연기</b>할 수 있습니다.
          </li>
        </ul>
      </section>
      <section className={section2}>
        <div className={titleImgStyle} style={{ width: '68%' }}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/guide/dogit-v4-guide-6.png' />
        </div>
        <div className={subtitleStyle}>🙋🏻‍♀️ 챌린지 성공했는데 환급은 언제되나요?</div>
        <p>
          환급 대상자에 해당되는 분들께는 카드 승인취소로 진행됩니다. 카드 취소는 이용기간이 종료되는 시점으 로부터 7일
          이내에 진행될 예정이며, 카드사 정책에 따라 최종 카드 결제 취소는 최대 7영업일이 소요될 수 있습니다
        </p>
        <div className={divider} />
        <div className={subtitleStyle}>✍🏼 환급 신청서는 어떻게 작성하나요?</div>
        <p>
          환급 대상자 자격이 되면 마이페이지에 '환급 신청하기' 버튼이 생성됩니다! 버튼이 보인다면 버튼을 통해 설문을
          먼저 작성해주세요. 설문을 작성해주신 분들을 대상으로 환급이 진행돼요.
        </p>
        <div className={divider} />
        <div className={subtitleStyle}>🙄 환급 신청 후 대상자에서 제외될 수 있나요?</div>
        <p>
          사진 인증에 따라 무관한 사진 (강아지가 없는 사진)을 찍어 인증한 경우, 환급 대상자여도 미션 미인증 처리되어,
          환급 대상자에서 제외됩니다! 꼭! 훈련 후 강아지와 함께 사진을 촬영해주세요!
        </p>
        <div className={divider} />
        <div className={subtitleStyle}>🚀 환급 이벤트는 언제까지 진행되나요? </div>
        <p>내부 사정으로 인해 이벤트 기간이 조기 종료 될 수 있으니 반드시 꼭 이번 전액 환급의 기회를 놓치지 마세요!</p>
      </section>
      <section className={section1}>
        <div className={titleImgStyle} style={{ width: '64%' }}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/guide/dogit-v4-guide-7.png' />
        </div>
        <ul>
          <li>
            <b>고객센터 FAQ 및 1:1 문의</b>를 통해 확인해주세요!{' '}
          </li>
        </ul>
      </section>
      <button className={termsBtnStyle} onClick={onClickOpenTermsModal}>
        ‣ <u> 도깃 이용 약관</u>
      </button>
    </article>
  );
}
