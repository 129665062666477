import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import { PAY_TYPE } from '../../../common/Constants';
import StyleConstants from '../../../common/StyleConstants';
import IconClose from '../../../icons/IconClose';

const style = css`
  display: flex;
  flex-direction: column;
  // align-items: center;
  height: 100vh;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
`;
const titleWrapper = css`
  margin-top: 30px;
  margin-left: 26px;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h1 {
    font-size: 22px;
    letter-spacing: -0.82px;
    line-height: 29px;
    color: #42607f;
    font-weight: bold;
  }
  h2 {
    font-size: 18px;
    letter-spacing: -0.67px;
    line-height: 29px;
    color: #42607f;
    font-weight: bold;
  }
`;
const imgWrapper = css`
  width: 100%;
  height: 12vh;
  margin-bottom: 8px;
  img {
    height: 100%;
  }
`;
const closeBtnStyle = css`
  position: fixed;
  top: 30px;
  right: 26px;
  z-index: 65535;
`;
const divider = css`
  width: 82%;
  height: 1px;
  background-color: #e6e6e6;
  margin: 21px 0 24px 26px;
`;
const btnWrapper = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const btnStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 87.5%;
  height: 80px;
  border: solid 1px #dbdbdb;
  border-radius: 20px;
  margin: 7px 0;
  div {
    margin-left: 21px;
    display: flex;
    flex-direction: column;
    h3 {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.6px;
      color: #42607f;
    }
    p {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.52px;
      color: #747878;
    }
  }
`;
export default function PaymentTypeSelectView({ dgProgramNo, onClickClose }) {
  return (
    <article className={style}>
      <div onClick={onClickClose} className={closeBtnStyle}>
        <IconClose size={14} strokeColor={Color.MEDIUM_GRAY} />
      </div>
      <div className={titleWrapper}>
        <div className={imgWrapper}>
          <img src='//static.dogmate.co.kr/dogit/v4/payment/type-select/dogit-v4-type-select.png' />
        </div>
        <h2>의지를 돈으로 사세요!</h2>
        <h1>챌린지 성공 시 전액 환급🤟🏻</h1>
      </div>
      <div className={divider} />
      <div className={btnWrapper}>
        <div
          className={btnStyle}
          onClick={() => (window.location.href = `/pay?no=${dgProgramNo}&pay=${PAY_TYPE.APP_CARD}`)}
        >
          <div>
            <h3>앱카드 결제</h3>
            <p>빠르고 편리한 앱카드 결제</p>
          </div>
          <img
            style={{
              width: '5vw',
              height: '9vw',
              marginRight: '28px',
              maxWidth: 'calc(500px * 0.06)',
              maxHeight: 'calc(500px * 0.1)',
            }}
            src='//static.dogmate.co.kr/dogit/v4/payment/type-select/icon-mobile.png'
          />
        </div>
        <div
          className={btnStyle}
          onClick={() => (window.location.href = `/pay?no=${dgProgramNo}&pay=${PAY_TYPE.CARD}`)}
        >
          <div>
            <h3>일반 결제</h3>
            <p>카드번호로 결제</p>
          </div>
          <img
            style={{
              width: '7.8vw',
              height: '6vw',
              marginRight: '24px',
              maxWidth: 'calc(640px * 0.079)',
              maxHeight: 'calc(640px * 0.06)',
            }}
            src='//static.dogmate.co.kr/dogit/v4/payment/type-select/icon-credit-card.png'
          />
        </div>
      </div>
    </article>
  );
}
