import React from 'react';
import HelmetCommon from '../../components/HelmetCommon';
import PageWrapper from '../../components/PageWrapper';
import ProgramPreviewContainer from './ProgramPreviewContainer';

export default function ProgramPreviewPage() {
  return (
    <>
      <HelmetCommon
        title='초보 보호자를 위한 강아지 교육 | 도깃'
        description='평생 함께 할 강아지, 퍼피 때 부터 올바른 교육이 필요하니까.'
        keywords='강아지, 퍼피 교육, 훈련, 강아지 훈련, 새끼 강아지, 아기 강아지, 퍼피 클래스, 트레이닝, 교육, 반려견, 애견, 퍼피 트레이닝, 사회화 훈련, 분리불안, 분리불안 훈련, 배변, 배변훈련, 훈련사'
        url={process.env.REACT_APP_URL}
        ogImageUrl='https://static.dogmate.co.kr/dogit/og_image/dogit_og_v3.jpeg'
      />
      <PageWrapper next='/v3-program-application'>
        <ProgramPreviewContainer />
      </PageWrapper>
    </>
  );
}
