import { css } from '@emotion/css/macro';
import StyleConstants from '../../../common/StyleConstants';
import IconClose from '../../../icons/IconClose';

const style = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 26px;
  h1 {
    font-size: 22px;
    line-height: 29px;
    letter-spacing: -0.82px;
    color: #42607f;
    font-weight: bold;
    margin-bottom: 8px;
  }
  p {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: #747878;
  }
`;
const divider = css`
  height: 1px;
  background-color: #e6e6e6;
  width: 82vw;
  margin: 17px 0 19px;
`;
const closeBtnStyle = css`
  position: fixed;
  top: 14px;
  right: 14px;
`;
const imgWrp = css`
  width: 45%;
  margin: 22px 0 5px 0;
  img {
    width: 100%;
  }
`;
const inputWrp = css`
  display: flex;
  flex-direction: column;
`;
const inputStyle = css`
  display: flex;
  flex-direction: column;
  label {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.52px;
    color: #747878;
    margin-bottom: 5px;
  }
  input {
    background-color: #eaeaea;
    width: calc(82vw - 30px);
    height: 50px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.67px;
    margin-bottom: 22px;
    padding: 0 15px;
  }
`;
const focusInputStyle = css`
  ${inputStyle}
  input {
    height: 47px;
    padding- left: 1px;
    border: solid 1.5px #f48c95;
    background-color: #fff;
    outline: none;
    padding: 0 13.5px;
  }
`;
const cardNoWrp = css`
  width: calc(82vw - 30px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
  height: 50px;
  border-radius: 8px;
  padding: 0 15px;
  input {
    width: 100%;
    height: 22px;
    font-size: 18px;
    text-align: center;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    margin: 0;
    padding: 0;
  }
  p {
    font-size: 18px;
    letter-spacing: -0.67px;
    margin: 0 8px;
  }
`;
const focusCardNoWrp = css`
  ${cardNoWrp};
  height: 47px;
  width: calc(82vw - 30px);
  padding: 0 13.5px;
  background-color: #fff;
  border: solid 1.5px #f48c95;
`;
const validYmWrp = css`
  width: calc(39vw - 40px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
  height: 50px;
  border-radius: 8px;
  margin-bottom: 22px;
  padding: 0 15px;
  input {
    width: 100%;
    height: 22px;
    font-size: 18px;
    text-align: center;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    margin: 0;
    padding: 0;
  }
  p {
    font-size: 18px;
    letter-spacing: -0.67px;
    margin: 0 8px;
  }
`;
const focusValidYmWrp = css`
  ${cardNoWrp};
  height: 47px;
  width: calc(39vw - 40px);
  padding: 0 13.5px;
  background-color: #fff;
  border: solid 1.5px #f48c95;
`;
const labelStyle = css`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.52px;
  color: #747878;
  margin-bottom: 5px;
`;
const btnStyle = css`
  width: 70vw;
  margin: 0 auto;
  height: 50px;
  background-color: #f48c95;
  border-radius: 24px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.43px;
`;
const flexRow = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 82vw;
`;
const captionStyle = css`
  width: 82vw;
  font-size: 10px;
  margin: 5px 0 22px 4px;
  margin-top: 5px;
  margin-bottom: 22px;
  color: #eb6877;
`;

export default function PaymentViewV4({ onClickSubmit, onChange, isComplete, onClickClose, focus, setFocus }) {
  return (
    <article className={style}>
      <div className={closeBtnStyle} onClick={onClickClose}>
        <IconClose strokeColor='#b1b4c1' size='16' />
      </div>
      <div className={imgWrp}>
        <img src='//static.dogmate.co.kr/dogit/landing-v3/pay/dogit-v4-pay-img.png' />
      </div>
      <h1>
        챌린지에 성공하면
        <br />
        100% 환급돼요!
      </h1>
      <p>챌린지 참여를 위해 결제를 완료해 주세요!</p>
      <div className={divider} />
      <div className={inputWrp}>
        <div className={focus === 'birthYmd' ? focusInputStyle : inputStyle}>
          <label
            style={{ color: focus === 'birthYmd' ? '#f48c95' : '#747878', fontWeight: focus === 'birthYmd' && 'bold' }}
            htmlFor='birthYmd'
          >
            생년월일(사업자번호)
          </label>
          <input
            name='birthYmd'
            placeholder='생년월일 6자리'
            onChange={onChange}
            type='text'
            maxLength='10'
            onFocus={() => setFocus('birthYmd')}
          />
        </div>
        <div>
          <label
            style={{ color: focus === 'cardNo' ? '#f48c95' : '#747878', fontWeight: focus === 'cardNo' && 'bold' }}
            className={labelStyle}
          >
            카드번호
          </label>
          <div className={focus === 'cardNo' ? focusCardNoWrp : cardNoWrp}>
            <label htmlFor='cardNo1'></label>
            <input
              id='cardNo1'
              name='cardNo1'
              onChange={onChange}
              type='text'
              maxLength='4'
              onFocus={() => setFocus('cardNo')}
            />
            <p>-</p>
            <label htmlFor='cardNo2' />
            <input
              id='cardNo2'
              name='cardNo2'
              onChange={onChange}
              type='text'
              maxLength='4'
              onFocus={() => setFocus('cardNo')}
            />
            <p>-</p>
            <label htmlFor='cardNo3'></label>
            <input
              id='cardNo3'
              name='cardNo3'
              onChange={onChange}
              type='text'
              maxLength='4'
              onFocus={() => setFocus('cardNo')}
            />
            <p>-</p>
            <label htmlFor='cardNo4'></label>
            <input
              id='cardNo4'
              name='cardNo4'
              onChange={onChange}
              type='text'
              maxLength='4'
              onFocus={() => setFocus('cardNo')}
            />
          </div>
          <h3 className={captionStyle}>입력하신 카드 정보는 최초 결제에만 사용되며 저장되지 않습니다!</h3>
        </div>
        <div className={flexRow}>
          <div className={focus === 'validYm' ? focusInputStyle : inputStyle}>
            <label
              style={{ color: focus === 'validYm' ? '#f48c95' : '#747878', fontWeight: focus === 'validYm' && 'bold' }}
            >
              유효기간
            </label>
            <div className={focus === 'validYm' ? focusValidYmWrp : validYmWrp}>
              <input
                id='validMonth'
                name='validMonth'
                onChange={onChange}
                type='text'
                placeholder='MM'
                maxLength='2'
                onFocus={() => setFocus('validYm')}
              />
              <p>/</p>
              <input
                id='validYear'
                name='validYear'
                onChange={onChange}
                type='text'
                placeholder='YY'
                maxLength='2'
                onFocus={() => setFocus('validYm')}
              />
            </div>
          </div>
          <div className={focus === 'password' ? focusInputStyle : inputStyle}>
            <label
              style={{
                color: focus === 'password' ? '#f48c95' : '#747878',
                fontWeight: focus === 'password' && 'bold',
              }}
              htmlFor='password'
            >
              카드 비밀번호
            </label>
            <input
              id='password'
              name='password'
              onChange={onChange}
              type='password'
              placeholder='앞 2자리 번호'
              maxLength={2}
              style={{ width: 'calc(39vw - 40px)' }}
              onFocus={() => setFocus('password')}
            />
          </div>
        </div>
      </div>
      <button
        className={btnStyle}
        onFocus={() => setFocus('')}
        style={{ backgroundColor: !isComplete && '#dfe7f0' }}
        onClick={() => (isComplete ? onClickSubmit() : alert('모든 정보를 입력해주세요'))}
      >
        완료
      </button>
    </article>
  );
}
