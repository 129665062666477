import _ from 'lodash';
import * as Sentry from '@sentry/react';
import * as API from '../api/api';
import * as Storage from './Storage';
import { removeToken, clearSession } from './Storage';
import { useLocation } from 'react-router-dom';

export function isDogmateApp() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('dogmate');
}

export function isDogmateAndroid() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('dogmate') && ua.includes('android');
}

export function isDogmateIos() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('dogmate ios');
}

export function isMobileWeb() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('mobile');
}

export function isAndroid() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('android');
}

export function isIos() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('iphone') || ua.includes('ipad');
}

export function sendGaEvent(eventAction) {
  const gaUserAgent = () => {
    if (isDogmateAndroid()) {
      return 'APP-Android';
    } else if (isDogmateIos()) {
      return 'APP-iOS';
    } else if (isAndroid()) {
      return 'WEB-M-Android';
    } else if (isIos()) {
      return 'WEB-M-iOS';
    } else if (!isMobileWeb() && !isDogmateApp()) {
      return 'WEB-PC';
    } else {
      return 'Unknown';
    }
  };

  try {
    window.gtag('event', eventAction, {
      hitType: 'event',
      eventCategory: gaUserAgent(),
      eventLabel: getLogin() ? '로그인' : '비로그인',
      uid: Storage.getV3Uid() || '',
    });
  } catch (error) {
    Sentry.captureMessage(error, 'error');
  }
}

export function sendFacebookPixelEvent(eventAction, value = undefined) {
  if (process.env.REACT_APP_ENV !== 'production') return;

  try {
    if (value !== undefined) {
      window.fbq('trackCustom', eventAction);
    } else {
      window.fbq('trackCustom', eventAction, {
        value,
        currency: 'KRW',
        content_type: 'product',
      });
    }
  } catch (error) {
    Sentry.captureMessage(error, 'error');
  }
}

export function sendKakaoPixelViewContentEvent(eventAction) {
  if (process.env.REACT_APP_ENV !== 'production') return;

  try {
    window.kakaoPixel('6065099648340571259').pageView();
    window.kakaoPixel('6065099648340571259').viewContent({
      tag: eventAction,
    });
  } catch (error) {
    Sentry.captureMessage(error, 'error');
  }
}

export function sendKakaoPixelCompleteRegistrationEvent(eventAction) {
  if (process.env.REACT_APP_ENV !== 'production') return;

  try {
    window.kakaoPixel('6065099648340571259').pageView();
    window.kakaoPixel('6065099648340571259').completeRegistration(eventAction);
  } catch (error) {
    Sentry.captureMessage(error, 'error');
  }
}

export function sendKakaoPixelPurchaseEvent(eventAction) {
  if (process.env.REACT_APP_ENV !== 'production') return;

  try {
    window.kakaoPixel('6065099648340571259').pageView();
    window.kakaoPixel('6065099648340571259').purchase(eventAction);
  } catch (error) {
    Sentry.captureMessage(error, 'error');
  }
}

export const getLogin = () => localStorage.getItem('is_login') || '';

export const resetAuthInfo = () => {
  clearSession();
  removeToken();
};

export const useQuery = () => new URLSearchParams(useLocation().search);

export function josa(word, generalJosa, hasJongSungJosa) {
  const lastChar = word.split('').pop();
  const lastCharIdx = lastChar.charCodeAt();

  if (lastCharIdx < 0xac00 || lastCharIdx > 0xd7a3) {
    return `${word}${generalJosa}/${hasJongSungJosa}`;
  }

  const hasJongSung = (lastCharIdx - 0xac00) % 28 > 0;

  return hasJongSung ? word + hasJongSungJosa : word + generalJosa;
}
export function removeTrailingSlash(target) {
  if (typeof target !== 'string' || _.isEmpty(target)) return target;
  if (target[target.length - 1] === '/') {
    return target.slice(0, -1);
  } else {
    return target;
  }
}

export function createEventLog(dgUserNo, hpNo, eventType, redirectUrl = undefined) {
  const uid = Storage.getV3Uid();
  const tempUid = Storage.getV3TempUid();
  let pageUrl = removeTrailingSlash(window.location.pathname);
  let pageFullUrl;
  if (pageUrl === '/prescription') {
    pageFullUrl = removeTrailingSlash(pageUrl + window.location.search.replace(/\+82/, '0').replace(/%20/g, ''));
  } else {
    pageFullUrl = removeTrailingSlash(pageUrl + window.location.search);
  }

  API.createV3EventLog({ dgUserNo, hpNo, uid, tempUid, pageFullUrl, pageUrl, eventType })
    .then((res) => {
      if (!uid) {
        Storage.setV3Uid(res.data.uid);
      }
      if (!tempUid || tempUid !== res.data.tempUid) {
        Storage.setV3TempUid(res.data.tempUid);
      }
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    })
    .catch(() => {});
}
