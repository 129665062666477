import React from 'react';
import { css } from '@emotion/css/macro';
import StyleConstants from '../../../common/StyleConstants';
import Color from '../../../common/Color';

export default function LoadingView({ alpha, children }) {
  const style = css`
    width: 100%;
    height: 100%;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, ${alpha === undefined ? 0.3 : alpha});
  `;
  const styleCenter = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: ${StyleConstants.MAX_WIDTH}px;
    z-index: 20;
    margin: 0 auto;
    img {
      width: 71vw;
      margin-top: -40%;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.43;
      letter-spacing: -0.52px;
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
      color: ${Color.WHITE};
    }
  `;

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className={style}>
      <div className={styleCenter}>
        <img src='//static.dogmate.co.kr/dogit/assign_loading/assign-loading.gif' />
        {children ? (
          children
        ) : (
          <div>
            미션 참여 인증을 위해
            <br />
            사진을 업로드하고 있습니다
          </div>
        )}
      </div>
    </div>
  );
}
