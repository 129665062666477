import * as React from 'react';
import * as API from '../../api/api';
import * as Util from '../../common/Util';
import OnboardingView from './OnboardingView';
import { DOGIT_V3_EVENT_TYPE, DOGIT_V3_ONBOARDING_TYPE } from '../../common/Constants';
import { UserInfoContext } from '../../components/PageWrapper';
import LoadingView from '../../components/LoadingView';

export default function OnboardingContainer({ code, dgUserProgramNo }) {
  const { dgUserNo, hpNo } = React.useContext(UserInfoContext);
  const [currentStep, setCurrentStep] = React.useState(DOGIT_V3_ONBOARDING_TYPE.STEP_ONE);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  }, [currentStep]);

  const onClickChangeCurrentStep = () => {
    const nextStep =
      currentStep === DOGIT_V3_ONBOARDING_TYPE.STEP_ONE
        ? DOGIT_V3_ONBOARDING_TYPE.STEP_TWO
        : currentStep === DOGIT_V3_ONBOARDING_TYPE.STEP_TWO
        ? DOGIT_V3_ONBOARDING_TYPE.STEP_THREE
        : currentStep === DOGIT_V3_ONBOARDING_TYPE.STEP_THREE
        ? DOGIT_V3_ONBOARDING_TYPE.STEP_FOUR
        : '';

    setCurrentStep(nextStep);
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.NEXT_BTN);
    window.scrollTo(0, 0);
  };

  const onClickSubmit = () => {
    API.updateIsCompleteOnboarding({ dgUserProgramNo })
      .then(() => {
        Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.CTA, `/my`);
      })
      .catch(API.errorHandler);
  };

  return (
    <>
      <>
        {isLoading && <LoadingView alpha={0.8} />}
        <div style={{ opacity: isLoading ? 0 : 1 }}>
          <OnboardingView
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            currentStep={currentStep}
            onClickChangeCurrentStep={onClickChangeCurrentStep}
            onClickSubmit={onClickSubmit}
          />
        </div>
      </>
    </>
  );
}
