import React from 'react';
import { css } from '@emotion/css/macro';

export default function IconCheck({size, strokeColor, borderColor, backgroundColor}) {
  const styleStroke = css`
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: ${strokeColor}
  `
  const styleBorder = css`
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: ${borderColor}
  `
  const styleBackground = css`
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: ${backgroundColor}
  `

  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size} ${size}`} version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${size/35})`}>
        <title>Group 6 Copy</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="도깃_오늘의미션_몇초,-확인" transform="translate(-201.000000, -406.000000)">
            <g id="Group-6-Copy" transform="translate(201.000000, 406.000000)">
              <path d="M34,17 C34,26.387883 26.387883,34 16.9976323,34 C7.6097493,34 0,26.387883 0,17 C0,7.61211699 7.6097493,0 16.9976323,0 C26.387883,0 34,7.61211699 34,17" id="Fill-1" fill={`${backgroundColor}`}></path>
              <path d="M15.6552418,22.1071825 C15.2427051,22.1071825 14.849813,21.9252825 14.5962191,21.6124825 L10.4815673,16.5498823 C10.0279556,15.9939822 10.1333221,15.1932822 10.7190885,14.7614822 C11.3084266,14.3313822 12.147787,14.4350822 12.597827,14.9892822 L15.5838069,18.6612823 C16.9821456,16.6654823 18.3304798,14.6101822 19.6020215,12.5327821 C19.975269,11.925882 20.7931989,11.718482 21.4343273,12.073782 C22.0736699,12.4273821 22.2897606,13.2076821 21.9182989,13.8162821 C20.3020837,16.4563822 18.5662151,19.0607824 16.7571256,21.5563825 C16.5142468,21.8912825 16.1195689,22.0952825 15.6927452,22.1071825 C15.6802441,22.1071825 15.6677429,22.1071825 15.6552418,22.1071825" id="Fill-4" fill={`${strokeColor}`}></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
