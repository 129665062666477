import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import StyleConstants from '../../../common/StyleConstants';

const style = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 60px;
`;
const successTitleWrp = css`
  margin: 8px 0;
  width: 25%;
  img {
    width: 100%;
  }
`;
const failTitleWrp = css`
  margin: 8px 0;
  width: 54%;
  img {
    width: 100%;
  }
`;
const successFistWrp = css`
  margin: 8px 0;
  width: 86%;
  margin: 0 auto;
  img {
    width: 100%;
  }
`;
const failFistWrp = css`
  margin: 8px 0;
  width: 56%;
  margin: 0 auto;
  img {
    width: 100%;
  }
`;
const title = css`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: -0.9px;
  text-align: center;
  color: #42507f;
  margin: 10px 0 30px;
`;
const btnStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  width: 224px;
  height: 50px;
  border-radius: 24px;
  background-color: #f48c95;
  margin: 20px 0 0;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.43px;
  color: ${Color.WHITE};
  margin: 27px auto 0;
`;
const descWrp = css`
  width: 80%;
  @media screen and (min-width: 414px) {
    width: 330px;
  }
  margin-top: 10px;
  border-top: 1px solid #dadada;
`;
const rowWrp = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;
  padding: 10px 4px;
  h2 {
    color: #747878;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: -0.45px;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: -0.52px;
    color: #406081;
  }
`;

export default function MissionCompleteView({
  result,
  dgUserProgramNo,
  programName,
  code,
  logRegDate,
  onClickGotoChallengeInfo,
}) {
  document.querySelector('body').style.overflowY = 'hidden';
  return (
    <div className={style}>
      <div className={failTitleWrp}>
        <img src={`//static.dogmate.co.kr/dogit/landing-v3/mission/dogit-v4-mission-fail-title.png`} />
      </div>
      <h1 className={title}>
        {result ? '고생하셨어요!' : '내일은 잘할 수 있어요!'}
        <br />
        불주먹을 받으세요!
      </h1>
      <div className={result ? successFistWrp : failFistWrp} id='background'>
        <img
          src={`//static.dogmate.co.kr/dogit/landing-v3/mission/dogit-v4-mission-${
            result ? 'sucess' : 'fail'
          }-fist.png`}
        />
      </div>
      <div className={descWrp}>
        <div className={rowWrp}>
          <h2>참여 챌린지</h2>
          <h3>{programName}</h3>
        </div>
        <div className={rowWrp}>
          <h2>참여 회차</h2>
          <h3>{code.split('-')[1]} 회차</h3>
        </div>
        <div className={rowWrp}>
          <h2>참여 일시</h2>
          <h3>{logRegDate}</h3>
        </div>
      </div>
      <div className={btnStyle} onClick={() => onClickGotoChallengeInfo(dgUserProgramNo)}>
        내 미션 현황 보러가기
      </div>
    </div>
  );
}
