import * as React from 'react';
import * as Util from '../../common/Util';
import * as API from '../../api/api';
import * as Storage from '../../common/Storage';
import ChannelService from '../../common/ChannelService';
import ChannelButton from '../../components/ChannelButton';
import {
  DOGIT_V3_EVENT_TYPE,
  DOGIT_V3_PROGRAM_BTN_ACTION_TYPE,
  DOGIT_V3_PROGRAM_CODE,
  DOGIT_V3_PROGRAM_TYPE,
} from '../../common/Constants';
import MenuView from '../../components/MenuView';
import ProgramListView from './view/ProgramListView';
import { UserInfoContext } from '../../components/PageWrapper';
import FooterView from '../../components/FooterView';

export default function ProgramListContainer() {
  const { userStatus, dgUserNo, hpNo } = React.useContext(UserInfoContext);
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  // * 채널톡
  React.useEffect(() => {
    ChannelService.boot({
      pluginKey: '2809822e-6edb-481d-91a9-76a55c95a057',
      customLauncherSelector: '#dogit-channel-io-button',
      hideChannelButtonOnBoot: true,
    });
    return () => {
      ChannelService.shutdown();
    };
  }, []);

  React.useEffect(() => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  }, []);

  const onClickCloseMenu = () => {
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    setIsOpenMenu(false);
  };

  const onClickOpenMenu = () => {
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';

    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.MENU_BTN);
    setIsOpenMenu(true);
  };
  const onClickGotoHome = () => {
    // Util.sendGaEvent(`[도깃_v3_교육_프로그램_페이지]홈버튼`);
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.INDEX_BTN, '/');
  };

  const onClickGotoDetail = (type) => {
    const [eventType, ga] =
      type === DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION
        ? [DOGIT_V3_EVENT_TYPE.SOCIALIZATION_DETAIL_BTN, '사회화_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY
        ? [DOGIT_V3_EVENT_TYPE.SEPARATION_ANXIETY_DETAIL_BTN, '분리불안_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING
        ? [DOGIT_V3_EVENT_TYPE.TOILET_TRAINING_DETAIL_BTN, '배변_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING
        ? [DOGIT_V3_EVENT_TYPE.WALKING_TRAINING_DETAIL_BTN, '산책_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.BARK
        ? [DOGIT_V3_EVENT_TYPE.BARK_DETAIL_BTN, '짖음_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE
        ? [DOGIT_V3_EVENT_TYPE.ALL_IN_ONE_DETAIL_BYN, '올인원_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.CUSTOMER_SERVICE_PROGRAM
        ? [DOGIT_V3_EVENT_TYPE.CUSTOMER_SERVICE_DETAIL_BTN, '전화상담_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.VST_TRAINING
        ? [DOGIT_V3_EVENT_TYPE.VST_TRAINING_BTN, '방문훈련_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.CHALLENGE
        ? [DOGIT_V3_EVENT_TYPE.CHALLENGE_DETAIL_BTN, '챌린지_상세_페이지_버튼']
        : ['', ''];

    // Util.sendGaEvent(`[도깃_V3_교육_프로그램_페이지]${ga}`);
    Util.createEventLog(dgUserNo, hpNo, eventType, `/v3-program-detail?type=${type}`);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <MenuView
        userStatus={userStatus}
        isOpenMenu={isOpenMenu}
        onClickOpenMenu={onClickOpenMenu}
        onClickCloseMenu={onClickCloseMenu}
        onClickGotoHome={onClickGotoHome}
      />
      <ProgramListView onClick={onClickGotoDetail} />
      <FooterView />
      {!isOpenMenu && <ChannelButton />}
    </div>
  );
}
