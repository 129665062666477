import * as React from 'react';
import { DOGIT_V3_EVENT_TYPE } from '../../common/Constants';
import * as Util from '../../common/Util';
import { UserInfoContext } from '../../components/PageWrapper';
import PrescriptionTypeformView from './PrescriptionTypeformView';

export default function PrescriptionTypeformContainer({ dgUserProgramNo, formId }) {
  const context = React.useContext(UserInfoContext);

  React.useEffect(() => {
    Util.createEventLog(
      context.dgUserNo ? context.dgUserNo : 0,
      context.hpNo ? context.hpNo : '',
      DOGIT_V3_EVENT_TYPE.VISITING
    );
  }, []);

  return <PrescriptionTypeformView dgUserProgramNo={dgUserProgramNo} formId={formId} />;
}
