import HpNoCert from '../../../components/HpNoCert';
import { css } from '@emotion/css/macro';
import moment from 'moment';
import * as React from 'react';
import * as _ from 'lodash';
import Color from '../../../common/Color';
import IconCheck from '../../../icons/IconCheck';
import StyleConstants from '../../../common/StyleConstants';

export default function DogitV3ApplicationView({
  type,
  onClickSubmit,
  onChangeInputValue,
  hpNo,
  setHpNo,
  setIsHpNoCert,
  onClickChangeMkt,
  onClickChangePrivacy,
  onClickChangeMktAndPrivacy,
  mkt,
  privacy,
  onClickOpenPrivacy,
}) {
  const style = css`
    max-width: ${StyleConstants.MAX_WIDTH}px;
    overflow-x: hidden;
    margin: 60px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;
  const title = css`
    width: 90vw;
    max-width: ${StyleConstants.MAX_WIDTH}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      font-size: 21px;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: -0.79px;
      color: #383939;
      margin: 0 0 7px 0;
    }
    p {
      font-size: 15px;
      letter-spacing: -0.52px;
      color: #747878;
    }
    img {
      width: 113px;
      height: 109px;
      object-fit: contain;
    }
  `;
  const userWrapper = css`
    display: flex;
    flex-direction: column;
    max-width: ${StyleConstants.MAX_WIDTH}px;
    margin: 10px 0 0 0px;
    label {
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.56px;
      color: #383939;
      margin: 10px 0;
    }
    h2 {
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.56px;
      color: #383939;
      margin: 10px 0;
    }
    input {
      font-size: 14px;
      border: none;
    }
    input::placeholder {
      font-size: 14px;
      letter-spacing: -0.52px;
    }
  `;
  const petWrapper = css`
    display: flex;
    max-width: ${StyleConstants.MAX_WIDTH}px;
    flex-direction: column;
    margin: 30px 0 0 0;
    label {
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.56px;
      color: #383939;
      margin: 10px 0;
    }
    h2 {
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.56px;
      color: #383939;
      margin: 10px 0;
    }
    input {
      font-size: 14px;
      border: none;
      width: 100%;
    }
    input::placeholder {
      font-size: 14px;
      letter-spacing: -0.52px;
    }
  `;
  const borderBottomWrapper = css`
    border-bottom: solid 1px #cacede;
    width: 90vw;
    padding-bottom: 7px;
    margin-bottom: 4px;
  `;
  const petBirthWrapper = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 20px 0;
    select {
      width: 120px;
      height: 36px;
      border-radius: 10px;
      border: solid 1px #cacede;
      background: ${Color.WHITE};
      text-align: center;
    }
  `;
  const checkWrapper = css`
    display: flex;
    max-width: ${StyleConstants.MAX_WIDTH}px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    height: 240px;
    border-radius: 23px 23px 0 0;
    box-shadow: 0 -1px 3px 0 rgba(198, 202, 202, 0.2);
    border: solid 1px #eff2f2;
  `;
  const divider = css`
    width: 80%;
    height: 1px;
    background-color: #eff2f2;
    margin: 20px 0;
  `;
  const checkBoxWrapper = css`
    max-width: ${StyleConstants.MAX_WIDTH}px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0 0 20px;
    font-size: 13px;
    letter-spacing: -0.45px;
    color: #747878;
    label {
      margin-left: 7px;
      min-width: 65%;
    }
    button {
      display: absolute;
      right: 19px;
      color: #c6caca;
      background-color: ${Color.WHITE};
      text-decoration: underline;
    }
  `;
  const iconWrapper = css`
    border-radius: 24px;
    border: 1px solid #dfe4e4;
    margin-left: 15px;
  `;
  const submitBtn = css`
    background-color: #f48c95;
    width: 90%;
    height: 50px;
    border-radius: 24px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.43px;
    color: ${Color.WHITE};
  `;
  React.useEffect(() => {
    const selectYear = document.getElementById('petBirthYear');
    const selectMonth = document.getElementById('petBirthMonth');
    const currentYear = parseInt(moment().format('YYYY'));

    if (selectYear.children.length < 2) {
      for (let i = 0; i < 25; i++) {
        const year = document.createElement('option');
        year.innerText = currentYear - i + '년';
        year.key = year.innerText;
        selectYear.appendChild(year);
      }
    }
    if (selectMonth.children.length < 2) {
      for (let i = 1; i <= 12; i++) {
        const month = document.createElement('option');
        month.innerText = i + '월';
        month.key = month.innerText;
        selectMonth.appendChild(month);
      }
    }
  }, []);
  return (
    <section className={style}>
      <header className={title}>
        <div>
          <h1>
            {type > 70
              ? '구매 전 상담을 위해'
              : type > 60
              ? '체험판 신청을 위해'
              : '챌린지 신청을 위해'}
            <br />
            정보를 입력해주세요.
          </h1>
          <p>딱 30초면 OK!</p>
        </div>
        <img src='//static.dogmate.co.kr/dogit/landing-v3/application/dogit-v3-application.png' />
      </header>
      <article className={userWrapper}>
        <label htmlFor='userName'>보호자 이름</label>
        <div className={borderBottomWrapper}>
          <input
            type='text'
            id='userName'
            required
            onChange={onChangeInputValue}
            placeholder='이름을 입력해주세요!'
            style={{ width: '280px' }}
          />
        </div>
        <h2>휴대폰 번호</h2>
        <HpNoCert
          hpNo={hpNo}
          runWhenCertifyDone={(hpNo) => {
            setHpNo(hpNo);
            setIsHpNoCert(true);
          }}
        />
      </article>
      <article className={petWrapper}>
        <label htmlFor='petName'>반려견 이름</label>
        <div className={borderBottomWrapper}>
          <input
            type='text'
            id='petName'
            required
            placeholder='신청하는 강아지의 이름을 알려주세요!'
            onChange={onChangeInputValue}
          />
        </div>
        <label htmlFor='petKing'>견종</label>
        <div className={borderBottomWrapper}>
          <input
            type='text'
            id='petKind'
            required
            placeholder='견종을 입력해주세요!'
            onChange={onChangeInputValue}
          />
        </div>
        <label>반려견 생일</label>
        <div className={petBirthWrapper}>
          <select id='petBirthYear' onChange={onChangeInputValue} defaultValue='출생 년도'>
            <option value='null'>출생 년도</option>
          </select>
          <select id='petBirthMonth' required onChange={onChangeInputValue} defaultValue='출생 월'>
            <option value='null'>출생 월</option>
          </select>
        </div>
      </article>
      <article className={checkWrapper}>
        <div className={checkBoxWrapper} onClick={onClickChangeMktAndPrivacy}>
          <div
            className={iconWrapper}
            style={{ borderColor: privacy && mkt ? '#f48c95' : '#dfe4e4' }}
          >
            <IconCheck
              size={20}
              strokeColor={privacy && mkt ? '#f48c95' : '#dfe4e4'}
              borderColor={privacy && mkt ? '#f48c95' : '#dfe4e4'}
            />
          </div>
          <label>아래 항목에 전체 동의합니다.</label>
        </div>
        <div className={divider}></div>
        <div className={checkBoxWrapper} onClick={onClickChangePrivacy}>
          <div className={iconWrapper} style={{ borderColor: privacy ? '#f48c95' : '#dfe4e4' }}>
            <IconCheck
              size={20}
              strokeColor={privacy ? '#f48c95' : '#dfe4e4'}
              borderColor={privacy && mkt ? '#f48c95' : '#dfe4e4'}
            />
          </div>
          <label htmlFor='privacy'>개인 정보 취급 방침에 동의합니다(필수)</label>
          <button onClick={onClickOpenPrivacy}>보기</button>
        </div>
        <div className={checkBoxWrapper} onClick={onClickChangeMkt}>
          <div className={iconWrapper} style={{ borderColor: mkt ? '#f48c95' : '#dfe4e4' }}>
            <IconCheck
              size={20}
              strokeColor={mkt ? '#f48c95' : '#dfe4e4'}
              borderColor={privacy && mkt ? '#f48c95' : '#dfe4e4'}
            />
          </div>
          <label htmlFor='isRecvMkt'>마케팅 정보 수신 동의합니다(선택)</label>
        </div>
        <button className={submitBtn} onClick={_.debounce(onClickSubmit, 1000)}>
          다음
        </button>
      </article>
    </section>
  );
}
