import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: "https://456fe3d22d2b4340b5fa6a1233e25dda@o201226.ingest.sentry.io/5646539",

  integrations: [new Integrations.BrowserTracing()],
});

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
} else {
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={"Sentry error. fallback."}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Sentry.ErrorBoundary>,
    document.getElementById('root')
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
