import React from 'react';
import HelmetCommon from '../../components/HelmetCommon';
import PageWrapper from '../../components/PageWrapper';
import * as Util from '../../common/Util';
import { DOGIT_V3_PROGRAM_BTN_ACTION_TYPE } from '../../common/Constants';
import ProgramDetailAdContainer from './ProgramDetailAdContainer';

export default function ProgramDetailAdPage() {
  const query = Util.useQuery();
  const type = parseInt(query.get('type'));
  const ver = parseInt(query.get('ver'));

  const ogTitle = '새끼 강아지 에게 필요한 모든 훈련 교육들 | 도깃';
  const ogDesc =
    '강아지 처음 키우시나요? 새끼 강아지 에게 필요한 모든 퍼피 교육 도깃 에서 확인하세요.  강아지 훈련, 전문가의 도움없이 보호자 스스로 충분히 할 수 있어요!';

  return type ? (
    <>
      <HelmetCommon
        title={ogTitle}
        description={ogDesc}
        keywords='강아지, 퍼피 교육, 훈련, 강아지 훈련, 새끼 강아지, 아기 강아지, 퍼피 클래스, 트레이닝, 교육, 반려견, 애견, 퍼피 트레이닝, 사회화 훈련, 분리불안, 분리불안 훈련, 배변, 배변훈련, 훈련사'
        url={process.env.REACT_APP_URL}
        ogImageUrl='https://static.dogmate.co.kr/dogit/og_image/dogit_og_v3.jpeg'
      />
      <PageWrapper>
        <ProgramDetailAdContainer type={type} ver={ver} />
      </PageWrapper>
    </>
  ) : (
    <></>
  );
}
