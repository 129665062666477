import { css } from '@emotion/css/macro';
import Color from '../common/Color';
import HamburgerBtn from './HamburgerBtn';
import * as _ from 'lodash';
import StyleConstants from '../common/StyleConstants';
import { DOGIT_USER_STATUS } from '../common/Constants';

export default function MenuView({
  userStatus,
  isOpenMenu,
  onClickOpenMenu,
  onClickCloseMenu,
  onClickGotoHome,
  isActiveRedDot = false,
  element,
  isOpenElement,
  onClickOpenSetting = undefined,
}) {
  const style = css`
    position: fixed;
    max-width: ${StyleConstants.MAX_WIDTH}px;
    width: 100%;
    top: 0;
    background-color: rgba(255, 255, 255, 0.95);
    height: 60px;
    top: 0;
    z-index: 999;
  `;
  const btnWrapper = css`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 50;
    height: 60px;
    img {
      width: 55px;
    }
  `;
  const menuWrapper = css`
    width: 100vw;
    max-width: ${StyleConstants.MAX_WIDTH}px;
    height: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.075s ease-in-out;
    z-index: 1000;
    ul {
      display: none;
    }
  `;
  const OpenedMenuWrapper = css`
    ${menuWrapper};
    padding-top: 24px;
    ul {
      display: block;
      li {
        color: ${Color.WHITE};
        margin-left: 24px;
        font-weight: bold;
        line-height: 1.7;
        font-size: 24px;
        a {
          font-weight: bold;
          line-height: 1.7;
          font-size: 24px;
          color: ${Color.WHITE};
          text-decoration: none;
        }
      }
      li::before {
        content: '∙';
        margin-right: 8px;
      }
    }
    height: 100vh;
    transition: all 0.075s ease-in-out;
  `;
  const menuBtnWapper = css`
    position: absolute;
    right: 17px;
  `;
  const settingBtn = css`
    position: absolute;
    left: 17px;
    width: 18px;
    height: 18px;
    img {
      width: 100%;
      height: 100%;
    }
  `;

  return (
    <article className={style} style={{ height: isOpenElement && '100px' }}>
      {isOpenElement && element}
      <div className={btnWrapper}>
        {window.location.pathname.startsWith('/my') && onClickOpenSetting && (
          <div className={settingBtn} onClick={onClickOpenSetting}>
            <img src='//static.dogmate.co.kr/dogit/v4/menu/setting-btn.png' />
          </div>
        )}
        <div onClick={_.debounce(onClickGotoHome, 300)}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/dogit-logo.png' />
        </div>
        <div className={menuBtnWapper}>
          <HamburgerBtn
            onClickOpenMenu={onClickOpenMenu}
            onClickCloseMenu={onClickCloseMenu}
            isActive={isOpenMenu}
            isActiveRedDot={isActiveRedDot}
          />
        </div>
      </div>
      <div className={isOpenMenu ? OpenedMenuWrapper : menuWrapper}>
        <ul>
          {userStatus === undefined ? (
            <li>
              <a href={`/login?next=/my`}>로그인</a>
            </li>
          ) : userStatus === DOGIT_USER_STATUS.IN_PROGRESS || userStatus === DOGIT_USER_STATUS.END ? (
            <li>
              <a href={`/my`}>챌린지</a>
            </li>
          ) : (
            <></>
          )}
          <li>
            <a href='/v3-program-list'>교육 프로그램</a>
          </li>
          <li>
            <a href='/v3-program-preview'>이용 방법</a>
          </li>
        </ul>
      </div>
    </article>
  );
}
