import React, { useState, useEffect } from 'react';
import './notion.css';
import 'prismjs/themes/prism-tomorrow.css';
import * as _ from 'lodash';
import { css } from '@emotion/css';
import { NotionRenderer } from 'react-notion';
import * as API from '../api/api';
import StyleConstants from '../common/StyleConstants';
import Color from '../common/Color';

export default function NotionView({
  url,
  isV3Onboarding,
  isMission,
  isSolution,
  isMissionComplete,
  setIsLoading = undefined,
  isTerms,
}) {
  const style = isTerms
    ? css`
        margin-top: 60px;
        position: relative;
        max-width: ${StyleConstants.MAX_WIDTH}px;
        width: 100vw;
        padding-bottom: 30px;
        background-color: ${Color.WHITE};
      `
    : isV3Onboarding
    ? css`
        position: relative;
        max-width: ${StyleConstants.MAX_WIDTH}px;
        width: 100vw;
        padding-bottom: 30px;
      `
    : isMission
    ? css`
        position: relative;
        max-width: 100vw;
        padding-bottom: 48px;
      `
    : isSolution
    ? css`
        position: relative;
        max-width: 100vw;
        padding-bottom: 84px;
        padding-top: 45px;
      `
    : isMissionComplete
    ? css`
        position: absolute;
        max-width: 100vw;
        top: 0;
        padding-bottom: 148px;
      `
    : css`
        position: absolute;
        max-width: 100vw;
        top: 48px;
        padding-bottom: 24px;
      `;
  const [blockMap, setBlockMap] = useState(null);

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(true);
    }

    const id =
      url &&
      url.replace(/https:\/\/www.notion.so\/dogmate\/|https:\/\/www.notion.so\/|https:\/\/dogmate.notion.site\//, '');

    API.getNotionBlock(id)
      .then((res) => {
        setBlockMap(res.data);

        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        API.errorHandler(err);
      });
  }, [url]);

  if (blockMap === null) return null;

  return (
    <div className={style}>
      <NotionRenderer
        mapPageUrl={(id) => `https://www.notion.so/${id}`}
        blockMap={blockMap}
        fullPage={true}
        hideHeader={true}
      />
    </div>
  );
}
