import React from 'react';
import moment from 'moment';
import { css } from '@emotion/css/macro';
import _ from 'lodash';
import * as Sentry from '@sentry/react';
import * as API from '../api/api';
import RemainTime from './RemainTime';
import Color from '../common/Color';
import StyleConstants from '../common/StyleConstants';

const hpNoWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: solid 1px #cacede;
  width: 90vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  input {
    width: 180px;
    border: 0;
    margin-top: 11px;
    margin-bottom: 11px;
  }
  button {
    width: 60px;
    height: 25px;
    border-radius: 4px;
    border: solid 1px #cacede;
    color: #757575;
    background-color: ${Color.WHITE};
    margin-bottom: 9px;
  }
`;
const certWrapper = css`
  display: flex;
  width: 90vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: solid 1px #cacede;
  input {
    width: 180px;
    border: 0;
    margin-top: 11px;
    margin-bottom: 11px;
  }
  button {
    width: 60px;
    height: 25px;
    border-radius: 4px;
    border: solid 1px #cacede;
    color: #757575;
    background-color: ${Color.WHITE};
    margin-bottom: 9px;
  }
  div {
    color: #757575;
    margin: 0 4px 11px 0;
    font-size: 14px;
  }
`;
const disabledWrapper = css`
  display: flex;
  width: 90vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: solid 1px #cacede;
  input {
    width: 180px;
    height: 20px;
    border: 0;
    margin-top: 11px;
    margin-bottom: 11px;
  }
  button {
    width: 60px;
    height: 25px;
    border-radius: 4px;
    border: solid 1px #cacede;
    color: #757575;
    background-color: ${Color.WHITE};
    margin-bottom: 9px;
  }
`;
const resendWrapper = css`
  margin: 8px 0 0 0;
  font-size: 14px;
  color: #757575;
`;

export default function HpNoCert({ hpNo: hpNoProps, runWhenCertifyDone }) {
  const [hpNo, setHpNo] = React.useState(hpNoProps);
  const [isConfirmEnable, setIsConfirmEnable] = React.useState(false);
  const [certNo, setCertNo] = React.useState('');
  const [showResendCert, setShowResendCert] = React.useState(false);
  const [showTimer, setShowTimer] = React.useState(false);
  const [isCertify, setIsCertify] = React.useState(false);
  const [isCertifyDone, setIsCertifyDone] = React.useState(false);
  const [couldSendCertify, setCouldSendCertify] = React.useState(true);
  const [isProgress, setIsProgress] = React.useState(false);

  const requestCertify = () => {
    if (!hpNo) {
      return alert('휴대전화 번호를 입력해주세요. (숫자만 입력해주세요)');
    }

    if (isCertify) {
      if (window.confirm('휴대전화 번호를 변경하시겠습니까? 다시 인증이 필요합니다.')) {
        setHpNo('');
        setIsCertify(false);
        setIsCertifyDone(false);
        setCertNo('');
        setShowResendCert(false);
        setShowTimer(false);
        setIsConfirmEnable(false);
      }
      return;
    }

    sendHpCertNo();
  };

  const sendHpCertNo = () => {
    if (isProgress) return;
    setIsProgress(true);

    API.sendHpCertNo(hpNo, 1)
      .then(() => {
        setIsProgress(false);
        setIsCertify(true);
        setShowTimer(true);
        setIsConfirmEnable(true);
        setCertNo('');
        setCouldSendCertify(false);

        setTimeout(() => {
          setCouldSendCertify(true);
        }, 5000);
      })
      .catch((error) => {
        setIsProgress(false);
        Sentry.captureMessage(error, 'error');
        alert('인증번호 발송에 문제가 있습니다.');
      });
  };

  const onClickCertify = _.debounce(requestCertify, 300);

  const onClickCertifyConfirm = () => {
    if (!hpNo) {
      return alert('휴대전화 번호를 입력해주세요. (숫자만 입력해주세요)');
    }

    if (!certNo) {
      return alert('인증번호를 입력해주세요');
    }

    API.verifyHpCertNo(hpNo, certNo, 1)
      .then(() => {
        alert('확인되었습니다.');
        setIsCertifyDone(true);
        // hook - put hpNo
        runWhenCertifyDone(hpNo);
        setShowTimer(false);
        setShowResendCert(false);
        setIsConfirmEnable(false);
      })
      .catch((error) => {
        Sentry.captureMessage(error, 'error');
        alert('인증번호가 잘못되었습니다.');
      });
  };

  const onChangeValue = (event) => {
    if (event.target.name === 'certNo' && event.target.value.length > 4) {
      return;
    }

    if (event.target.name === 'certNo') {
      setCertNo(event.target.value);
    }

    if (event.target.name === 'hpNo') {
      setHpNo(event.target.value);
    }
  };

  const onClickResendCertify = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!couldSendCertify) {
      return alert('5초 후에 다시 시도해주세요.');
    }

    setShowTimer(false);
    setIsConfirmEnable(false);

    sendHpCertNo();
  };

  return (
    <section>
      <article className={hpNoWrapper}>
        <input
          type='number'
          pattern='[0-9]*'
          name='hpNo'
          placeholder='휴대폰 번호를 입력해주세요!'
          value={hpNo}
          onChange={onChangeValue}
          readOnly={isCertifyDone}
          style={{ color: isCertifyDone ? '#757575' : '#383939' }}
        />
        <button id='hp-cert-btn' onClick={onClickCertify}>
          {isProgress ? '-' : isCertify ? '변경하기' : '인증번호'}
        </button>
      </article>
      <article className={isCertify ? certWrapper : disabledWrapper}>
        <input
          type='number'
          pattern='[0-9]*'
          name='certNo'
          placeholder='인증번호를 입력해주세요!'
          value={certNo}
          onChange={onChangeValue}
          readOnly={isCertifyDone}
          style={{ color: isCertifyDone ? '#757575' : '#383939' }}
        />
        <div>
          {showTimer && (
            <RemainTime
              quitDate={moment().add(3, 'minutes').toDate()}
              textFormat={'{MINUTE}:{SECOND}'}
              hookAfter5seconds={() => {
                setShowResendCert(true);
              }}
              hookAfterExpire={() => {
                setIsConfirmEnable(false);
              }}
            />
          )}
        </div>
        <button
          style={{
            color: isCertify ? '#fff' : '#757575',
            backgroundColor: isCertifyDone ? '#cacede' : isCertify ? Color.DOGIT_BLUE : '#fff',
          }}
          disabled={!isConfirmEnable}
          onClick={onClickCertifyConfirm}
        >
          {isCertifyDone ? '인증완료' : '인증'}
        </button>
      </article>
      {showResendCert && (
        <span className={resendWrapper}>
          인증번호가 도착하지 않으셨나요?{' '}
          <a onClick={onClickResendCertify}>
            <u>인증번호 재발송</u>
          </a>
        </span>
      )}
    </section>
  );
}
