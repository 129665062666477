import * as React from 'react';
import * as Util from '../../common/Util';
import * as API from '../../api/api';
import * as Storage from '../../common/Storage';
import { DOGIT_V3_CURRENT_STEP_TYPE, DOGIT_V3_EVENT_TYPE } from '../../common/Constants';
import { UserInfoContext } from '../../components/PageWrapper';
import RegisterView from './RegisterView';
import PrivacyModalView from '../program-application/view/subview/PrivacyModalView';
import ChallengeTermsView from './subview/ChallengeTermsView';
import ChallengeWaitView from './subview/ChallengeWaitView';

export default function RegisterContainer({ next, type }) {
  const { dgUserNo, hpNo } = React.useContext(UserInfoContext);
  const [userInfo, setUserInfo] = React.useState({});
  const [isOpenPrivacyTerm, setIsOpenPrivacyTerm] = React.useState(false);
  const [isOpenChallengeEventTerm, setIsOpenChallengeEventTerm] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(DOGIT_V3_CURRENT_STEP_TYPE.APPLICATION);
  const [responseNo, setResponseNo] = React.useState(238);

  React.useEffect(() => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  }, []);

  const onClickSubmit = () => {
    const { userName, petBirthYear, petBirthMonth, petKind, petName, privacy, isRecvMkt, challengeEvent } = userInfo;
    const uid = Storage.getV3Uid();
    const tempUid = Storage.getV3TempUid();

    if (!userName) {
      return alert('이름을 입력해주세요!');
    }
    if (userName.length >= 50) {
      return alert('이름은 50자를 넘을 수 없습니다.');
    }
    if (!petName) {
      return alert('반려견 이름을 입력해주세요!');
    }
    if (petName.length >= 50) {
      return alert('반려견 이름은 50자를 넘을 수 없습니다.');
    }
    if (!petKind) {
      return alert('견종을 입력해주세요!');
    }
    if (petKind.length >= 50) {
      return alert('견종은 50자를 넘을 수 없습니다.');
    }
    if (!petBirthYear) {
      return alert('반려견의 출생 년도를 입력해주세요!');
    }
    if (!petBirthMonth) {
      return alert('반려견의 출생 월을 입력해주세요!');
    }
    if (!privacy) {
      return alert('개인 정보 취급 방침에 동의해주세요!');
    }
    if (!challengeEvent) {
      return alert('이벤트 유의사항에 동의해주세요!');
    }

    const year = petBirthYear.split('년')[0];
    const month = petBirthMonth.split('월')[0];

    const petBirthYm = `${year}-${month.length < 2 ? '0' + month : month}`;

    API.updateV3User({
      userInfo: {
        userName,
        hpNo,
        isRecvMkt,
      },
      petInfo: {
        petName,
        petKind,
        petBirthYm,
      },
    }).catch(API.errorHandler);

    // Util.sendGaEvent(`[도깃_v4_챌린지 신청]신청완료버튼`);
    // Util.sendFacebookPixelEvent(`[도깃_v4_챌린지 신청]신청완료버튼`);

    if (next) {
      Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.SUBMIT, next);
    } else {
      Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.SUBMIT);

      API.createV3Response({
        userName,
        hpNo,
        petName,
        petKind,
        petBirthYm,
        isRecvMkt,
        uid,
        tempUid,
        programType: type,
      })
        .then((res) => {
          setResponseNo(res.data.dgV3ResponseNo);
          setCurrentStep(DOGIT_V3_CURRENT_STEP_TYPE.COMPLETE);
        })
        .catch(API.errorHandler);
    }
  };

  const onChange = (event) => {
    // eslint-disable-next-line no-restricted-globals
    const value = event.target.value;

    // eslint-disable-next-line no-restricted-globals
    setUserInfo({ ...userInfo, [event.target.id]: value });
  };

  const onClickChangeValue = (target, value) => {
    if (target === 'all') {
      setUserInfo({ ...userInfo, isRecvMkt: value, privacy: value, challengeEvent: value });
    }
    if (target === 'isRecvMkt') {
      setUserInfo({ ...userInfo, isRecvMkt: value });
    }
    if (target === 'privacy') {
      setUserInfo({ ...userInfo, privacy: value });
    }
    if (target === 'challengeEvent') {
      setUserInfo({ ...userInfo, challengeEvent: value });
    }
  };

  const onClickOpenTerm = (target) => {
    if (target === 'privacy') {
      setIsOpenPrivacyTerm(true);
    }

    if (target === 'challengeEvent') {
      setIsOpenChallengeEventTerm(true);
    }
  };

  const onClickGotoHome = () => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.CTA, '/');
  };

  return (
    <>
      {isOpenPrivacyTerm && <PrivacyModalView onClickClose={() => setIsOpenPrivacyTerm(false)} />}
      {isOpenChallengeEventTerm && <ChallengeTermsView onClick={() => setIsOpenChallengeEventTerm(false)} />}
      {currentStep === DOGIT_V3_CURRENT_STEP_TYPE.APPLICATION ? (
        <RegisterView
          onClickSubmit={onClickSubmit}
          onChange={onChange}
          onClickChangeValue={onClickChangeValue}
          onClickOpenTerm={onClickOpenTerm}
          isRecvMkt={userInfo.isRecvMkt}
          privacy={userInfo.privacy}
          challengeEvent={userInfo.challengeEvent}
        />
      ) : (
        responseNo && <ChallengeWaitView responseNo={responseNo} programType={type} onClickGotoHome={onClickGotoHome} />
      )}
    </>
  );
}
