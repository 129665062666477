import React from 'react';
import { css } from '@emotion/css/macro';
import StyleConstants from '../../../common/StyleConstants';
import Color from '../../../common/Color';

const style = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -60px;
`;
const titleWrp = css`
  h1 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: -0.9px;
    text-align: center;
    color: #42607f;
  }
`;
const imgWrp = css`
  width: 40%;
  margin: 25px auto 35px;
  img {
    width: 100%;
  }
`;
const titleImgWrp = css`
  width: 35%;
  margin: 10px auto;
  img {
    width: 100%;
  }
`;
const divider = css`
  width: 86%;
  height: 1px;
  background-color: #dadada;
`;
const descWrp = css`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0;
  h2 {
    font-size: 12px;
    letter-spacing: -0.45px;
    color: #747878;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #406081;
  }
`;
const btnWrp = css`
  margin-top: 80px;
  background-color: #f48c95;
  padding: 15px 75px;
  border-radius: 36px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.43px;
  color: ${Color.WHITE};
`;

export default function PaymentCompleteView({ programName, price, onClick }) {
  return (
    <article className={style}>
      <div className={titleWrp}>
        <div className={titleImgWrp}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/pay/dogit-v3.2-pay-complete-title.png' />
        </div>
        <h1>
          오늘부터 문제행동
          <br />
          같이 뿌셔봐요👊🏻
        </h1>
      </div>
      <div className={imgWrp}>
        <img src='//static.dogmate.co.kr/dogit/landing-v3/pay/dogit-v3.2-pay-complete-img.png' />
      </div>
      <div className={divider} />
      <div className={descWrp}>
        <h2>신청 챌린지</h2>
        <h3>{programName}</h3>
      </div>
      <div className={divider} />
      <div className={descWrp}>
        <h2>결제 금액</h2>
        <h3>{`${price.toLocaleString()}원`}</h3>
      </div>
      <div className={divider} />
      <button className={btnWrp} onClick={onClick}>
        미션 참여하기
      </button>
    </article>
  );
}
