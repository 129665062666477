import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import { DOGIT_V3_PROGRAM_TYPE } from '../../../common/Constants';
import StyleConstants from '../../../common/StyleConstants';
import * as _ from 'lodash';
import { Fragment } from 'react';

const style = css`
  width: 100vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
`;
const title = css`
  width: 100%;
  height: 227px;
  background: linear-gradient(to bottom, #4855d1 66%, #5949d2, #5949d2);
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
const titleDesc = css`
  font-size: 32px;
  margin-left: 21px;
`;
const titleImg = css`
  width: 56%;
  margin: 10px 0 37px 21px;
  img {
    width: 100%;
  }
`;
const stepStyle = css`
  padding: 48px 27px 40px;
`;
const stepWrapper = css``;
const stepTitle = css`
  font-size: 22px;
  letter-spacing: -0.82;
  line-height: 1.36;
  color: #42607f;
  b {
    font-weight: bold;
  }
`;
const stepDesc = css`
  font-size: 16px;
  color: #757575;
  margin-top: 9px;
  line-height: 1.5;
  word-wrap: keep-word;
`;
const stepImg = css`
  left: 0;
  margin: 40px auto 90px;
  width: 80%;
  img {
    border-radius: 16px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
    width: 100%;
  }
`;

const programListDesc = css`
  margin: 0 27px 80px;
  h1 {
    font-size: 18px;
    letter-spacing: -0.67px;
    line-height: 1.44;
    color: #42607f;
    margin: 0 0 20px 0;
    b {
      font-weight: bold;
    }
  }
`;

const programListStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  border-radius: 10px;
  border: solid 1px #cacede;
  left: 0;
  margin: 10px auto;
  div {
    display: flex;
    flex-direction: column;
    margin-left: 22px;
    h2 {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.52px;
      color: #42607f;
      line-height: 1.38;
    }
    h3 {
      font-size: 14px;
      letter-spacing: -0.46px;
      color: #42607f;
      line-height: 1.57;
    }
    button {
      width: 75px;
      height: 28px;
      border: 10px;
      border-radius: 10px;
      margin-top: 7px;
      color: ${Color.WHITE};
      font-weight: bold;
      font-size: 12px;
      letter-spacing: -0.39px;
    }
  }
  img {
    width: 55px;
    height: 55px;
    margin-right: 16px;
  }
`;

export default function ProgramPreviewView({ onClick }) {
  const stepList = [
    {
      step: 'STEP1.',
      title: '알림이 오면 훈련을 시작해요',
      desc: '내가 정해놓은 시간에 훈련 알림이 도착해요',
      img: '//static.dogmate.co.kr/dogit/landing-v3/dogit-v3-step1.gif',
    },
    {
      step: 'STEP2.',
      title: '스텝별로 훈련을 따라해요',
      desc: '순서대로 차근차근 알려주니 훈련을 따라하기 쉬워요',
      img: '//static.dogmate.co.kr/dogit/landing-v3/dogit-v3-step2.gif',
    },
    {
      step: 'STEP3.',
      title: '강아지 반응을 입력하고 맞춤 커리큘럼을 받아요',
      desc: '강아지가 보인 반응에 따라 우리 강아지만의 맞춤 학습법이 만들어져요',
      img: '//static.dogmate.co.kr/dogit/landing-v3/dogit-v3-step3.gif',
    },
    {
      step: 'STEP4.',
      title: '잘 안되는 건 바로 상담해요',
      desc: '나만의 전담 매니저와 실시간으로 대화를 나눌 수 있어요',
      img: '//static.dogmate.co.kr/dogit/landing-v3/dogit-v3-step4.gif',
    },
  ];

  const program = [
    {
      title: '전문 훈련사의 방문 교육',
      color: '#7462f4',
      type: DOGIT_V3_PROGRAM_TYPE.VST_TRAINING,
    },
    {
      title: '순둥이가 되는 사회화 교육',
      // desc: '친절하고 온순한 강아지 키우기',
      color: '#ffb423',
      type: DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION,
    },
    {
      title: '분리불안 예방 교육',
      // desc: '독립적인 강아지로 자라기',
      color: '#5256d7',
      type: DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY,
    },
    {
      title: '매너견을 위한 배변 교육',
      // desc: '패드 정조준을 위해서!',
      color: '#4bd4c5',
      type: DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING,
    },
    {
      title: '즐거운 산책 매너 교육',
      // desc: '산책으로 스트레스 해소하기',
      color: '#f48395',
      type: DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING,
    },
    {
      title: '고요한 일상을 위한 짖음 교육',
      // desc: '',
      color: '#801370',
      type: DOGIT_V3_PROGRAM_TYPE.BARK,
    },
    {
      title: '올인원 교육',
      // desc: '퍼피 때 필요한 교육 TOP4',
      color: '#48bcf6',
      type: DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE,
    },
  ];

  return (
    <section className={style}>
      <article className={title}>
        <p className={titleDesc}>👀</p>
        <div className={titleImg}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/program-preview/dogit-v3-preview-title.png' />
        </div>
      </article>
      <article className={stepStyle}>
        {stepList.map((el, idx) => (
          <div key={idx} className={stepWrapper}>
            <h1 className={stepTitle}>{el.step}</h1>
            <h1 className={stepTitle}>
              <b>{el.title}</b>
            </h1>
            <p className={stepDesc}>{el.desc}</p>
            <div className={stepImg}>
              <img src={el.img} />
            </div>
          </div>
        ))}
      </article>
      <article className={programListDesc}>
        <h1>
          초보 보호자를 위한
          <br />
          <b>도깃의 다른 프로그램을 확인</b>해보세요.
        </h1>
        {program.map((el, idx) =>
          el.type === DOGIT_V3_PROGRAM_TYPE.VST_TRAINING ||
          el.type === DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING ||
          el.type === DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING ||
          el.type === DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION ||
          el.type === DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE ? (
            <Fragment key={idx} />
          ) : (
            <article
              id={`program-detail-${el.type}`}
              key={idx}
              className={programListStyle}
              style={{ display: 'flex' }}
              onClick={_.debounce(() => onClick(el.type), 300)}
            >
              <div>
                <h3>{el.desc}</h3>
                <h2>{el.title}</h2>
                <button style={{ backgroundColor: el.color, width: '75px' }}>자세히 보기</button>
              </div>
              <img
                src={
                  el.type === DOGIT_V3_PROGRAM_TYPE.VST_TRAINING
                    ? '//static.dogmate.co.kr/dogit/landing-v3/program-detail/dogit-v3-jerry-choi.png'
                    : `//static.dogmate.co.kr/dogit/landing-v3/program-detail/dogit-v3-dog-profile${el.type}.png`
                }
              />
            </article>
          )
        )}
      </article>
    </section>
  );
}
