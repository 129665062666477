import { css } from '@emotion/css/macro';
import StyleConstants from '../common/StyleConstants';
import IconClose from '../icons/IconClose';

const style = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  background-color: #fff;
  z-index: 65535;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const topStyle = css`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #cacede;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.6px;
    font-weight: bold;
    margin-left: 15px;
    color: #535454;
  }
  div {
    margin-right: 15px;
  }
`;
const descStyle = css`
  h1 {
    font-size: 12px;
    line-height: 32px;
    letter-spacing: -0.45px;
    font-weight: bold;
    color: #42607f;
    margin: 20px 0 20px 15px;
  }
  div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    p {
      font-size: 16px;
      line-height: 32px;
      letter-spacing: -0.5px;
      margin-left: 15px;
      color: #747878;
    }
    input {
      margin-right: 15px;
    }
  }
`;
const toggleStyle = css`
  user-select: none;
  #slider-bg {
    display: inline-block;
    width: 45px;
    height: 26px;
    margin: 0 18px 0 0;
    border: solid 1px #dbdbdb;
    border-radius: 15px;
    box-sizing: border-box;
    background: #dbdbdb;
    position: relative;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }
  #slider-ball {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: #fff;
    position: absolute;
    top: 2px;
    left: 1px;
    transition: 0.2s ease-in-out;
  }
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox']:checked + #slider-bg {
    border: solid 1px #4755d0;
    background-color: #4755d0;
    #slider-ball {
      left: calc(100% - 20px);
      background: #ffffff;
    }
  }
`;
const timeStyle = css`
  user-select: none;
  input[type='time'] {
    font-size: 16px;
    line-height: 32px;
    letter-spacing: -0.52px;
    color: #4755d0;
    margin-right: 14px;
    border: none;
    font-family: Spoqa Han Sans;
    background-color: transparent;
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
  }
  input[type='time']:focus {
    outline: none;
  }
`;
export function SettingView({ onClickCloseSetting, isRecvNoti, notiTime, updateIsRecvNotification, updateAlarmTime }) {
  const [h, m] = notiTime.split(':');
  const hour = h > 12 ? `오후 ${h - 12}` : `오전 ${h}`;
  return (
    <article className={style}>
      <div className={topStyle}>
        <h1>설정</h1>
        <div onClick={onClickCloseSetting}>
          <IconClose size={15} strokeColor='#b1b4c1' />
        </div>
      </div>
      <div className={descStyle}>
        <h1>알람 설정</h1>
        <div>
          <p>미션 알람 받기</p>
          <span>
            <label className={toggleStyle} htmlFor='is-recv-noti'>
              <input
                id='is-recv-noti'
                type='checkbox'
                checked={isRecvNoti}
                onChange={(event) => updateIsRecvNotification(event)}
              />
              <span id='slider-bg'>
                <span id='slider-ball'></span>
              </span>
            </label>
          </span>
        </div>
        {isRecvNoti && (
          <div>
            <p>미션 알람 시간 설정</p>
            <span>
              <label className={timeStyle} htmlFor='time-input'>
                <input id='time-input' type='time' value={notiTime} onChange={(event) => updateAlarmTime(event)} />
              </label>
            </span>
          </div>
        )}
      </div>
    </article>
  );
}
