import React from 'react';
import * as Util from '../../common/Util';
import * as API from '../../api/api';
import {
  DOGIT_USER_STATUS,
  DOGIT_V3_EVENT_TYPE,
  DOGIT_V3_PROGRAM_BTN_ACTION_TYPE,
  DOGIT_V3_PROGRAM_TYPE,
} from '../../common/Constants';
import MenuView from '../../components/MenuView';
import PurchaseModalView from '../../components/PurchaseModalView';
import StyleConstants from '../../common/StyleConstants';
import BottomUpPopup from '../../components/BottomUpPopup';
import ChannelService from '../../common/ChannelService';
import ChannelButton from '../../components/ChannelButton';
import { UserInfoContext } from '../../components/PageWrapper';
import moment from 'moment';
import ProgramDetailAdView from './view/ProgramDetailAdView';
import BannerView from './view/sub-view/BannerView';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ReviewViewAnimation from '../program-detail/animation/ReviewViewAnimation';

const paypleUrl = 'https://link.payple.kr/NDg3OjE2NDMyNjEzODM';

export default function ProgramDetailAdContainer({ type, ver }) {
  const context = React.useContext(UserInfoContext);
  const { userStatus, dgUserNo } = context;
  const [view, setView] = React.useState(1);
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [isOpenDetailModal, setIsOpenDetailModal] = React.useState(false);
  const [detailType, setDetailType] = React.useState(null);
  const [selected, setSelected] = React.useState(
    type === DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY || type === DOGIT_V3_PROGRAM_TYPE.BARK ? type : 0
  );
  const [isSentSwipeMsg, setIsSentSwipeMsg] = React.useState(false);
  const [reviewNo, setReviewNo] = React.useState(2);
  const [isStartTrainerAnimation, setIsStartTrainerDataAnimation] = React.useState(false);
  const [programDescFoldViewNo, setProgramDescFoldViewNo] = React.useState(undefined);
  const [isFirstOpenProgramDescFold, setIsFirstOpenProgramDescFold] = React.useState(true);
  const [isOpenCSModal, setIsOpenCSModal] = React.useState(false);
  const [userName, setUserName] = React.useState('');
  const [hpNo, setHpNo] = React.useState('');
  const [isHpNoCert, setIsHpNoCert] = React.useState(false);
  const [isCta, setIsCta] = React.useState(false);
  const [isOpenTopBanner, setIsOpenTopBanner] = React.useState(true);

  const [reviewList, setReviewList] = React.useState([]);
  const [totalParticipatedCnt, setTotalParticipatedCnt] = React.useState(0);
  const [avgImprovementRate, setAvgImprovementRate] = React.useState(0);
  const [reviewCnt, setReviewCnt] = React.useState(0);
  const [isStartReviewScoreAnimation, setIsStartReviewScoreAnimation] = React.useState(false);

  React.useEffect(() => {
    API.getReviewInfo().then((res) => {
      setReviewList(res.data.reviewList);
      setAvgImprovementRate(res.data.avgImprovementRate);
      setTotalParticipatedCnt(res.data.totalParticipatedCnt);
      setReviewCnt(res.data.reviewCnt);
    });

    window.onload = () => {
      gsap.registerPlugin(ScrollTrigger);
      ReviewViewAnimation(setIsStartReviewScoreAnimation);
    };
  }, []);

  window.addEventListener('scroll', () => {
    if (!isCta) {
      if (window.scrollY > 10) {
        setIsCta(true);
      }
    } else {
      if (window.scrollY <= 10) {
        setIsCta(false);
      }
    }
  });

  React.useEffect(() => {
    if (
      type === DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING ||
      type === DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING ||
      type === DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION ||
      type === DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE ||
      type === DOGIT_V3_PROGRAM_TYPE.CUSTOMER_SERVICE_PROGRAM
    ) {
      window.location.href = '/v3-program-list';
    }
  }, []);

  React.useEffect(() => {
    Util.createEventLog(dgUserNo, context.hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  }, []);

  React.useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = isOpenModal || isOpenCSModal ? 'hidden' : 'scroll';
  }, [isOpenModal]);

  React.useEffect(() => {
    // if (
    // type !== DOGIT_V3_PROGRAM_TYPE.CUSTOMER_SERVICE_PROGRAM &&
    // type !== DOGIT_V3_PROGRAM_TYPE.VST_TRAINING
    // ) {
    // window.onload = () => {
    // gsap.registerPlugin(ScrollTrigger);
    // AITrainerViewAnimation(setIsStartTrainerDataAnimation);
    // ReviewViewAnimation(setIsStartReviewScoreAnimation);
    // HalfTextBoxViewAnimation();
    // ReviewBadgeAnimation();
    // };
    // }

    ChannelService.boot({
      pluginKey: '2809822e-6edb-481d-91a9-76a55c95a057',
      customLauncherSelector: '#dogit-channel-io-button',
      hideChannelButtonOnBoot: true,
    });

    return () => {
      ChannelService.shutdown();
    };
  }, []);

  React.useEffect(() => {
    if (!isFirstOpenProgramDescFold) {
      document
        .getElementById(`programDescFold${programDescFoldViewNo}`)
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [programDescFoldViewNo]);

  const onSlideChange = (view) => {
    if (!isSentSwipeMsg) {
      window.dataLayer.push({
        event: 'Swipe',
      });
      setIsSentSwipeMsg(true);
    }
    setView(view);
  };

  const onClickChangeSelected = (type) => {
    setSelected(type);
  };

  const onClickCloseMenu = () => {
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    setIsOpenMenu(false);
  };

  const onClickOpenMenu = () => {
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';

    if (isOpenModal) {
      setIsOpenModal(false);
    }
    if (isOpenDetailModal) {
      setIsOpenDetailModal(false);
    }

    Util.createEventLog(dgUserNo, context.hpNo, DOGIT_V3_EVENT_TYPE.MENU_BTN);
    setIsOpenMenu(true);
  };

  const onClickOpenModal = (eventType) => {
    const name =
      type === DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION
        ? '사회화'
        : type === DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY
        ? '분리불안'
        : type === DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING
        ? '배변'
        : type === DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING
        ? '산책'
        : type === DOGIT_V3_PROGRAM_TYPE.BARK
        ? '짖음'
        : type === DOGIT_V3_PROGRAM_TYPE.VST_TRAINING
        ? '방문훈련'
        : type === DOGIT_V3_PROGRAM_TYPE.CHALLENGE
        ? '챌린지'
        : '올인원';

    // Util.sendGaEvent(
    //   `[도깃_v4_AD_상세_${ver}]${name}${
    //     eventType === DOGIT_V3_EVENT_TYPE.CTA ? '_신청하기_버튼' : '_신청하기_플로팅_버튼'
    //   }`
    // );
    Util.createEventLog(dgUserNo, context.hpNo, eventType);
    setIsOpenModal(true);
  };
  const onClickCloseModal = () => {
    setIsOpenModal(false);
  };

  const onClickGotoDetail = (type) => {
    const [eventType, ga] =
      type === DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION
        ? [DOGIT_V3_EVENT_TYPE.SOCIALIZATION_DETAIL_BTN, '사회화_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY
        ? [DOGIT_V3_EVENT_TYPE.SEPARATION_ANXIETY_DETAIL_BTN, '분리불안_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING
        ? [DOGIT_V3_EVENT_TYPE.TOILET_TRAINING_DETAIL_BTN, '배변_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING
        ? [DOGIT_V3_EVENT_TYPE.WALKING_TRAINING_DETAIL_BTN, '산책_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.BARK
        ? [DOGIT_V3_EVENT_TYPE.BARK_DETAIL_BTN, '짖음_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE
        ? [DOGIT_V3_EVENT_TYPE.ALL_IN_ONE_DETAIL_BYN, '올인원_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.CUSTOMER_SERVICE_PROGRAM
        ? [DOGIT_V3_EVENT_TYPE.CUSTOMER_SERVICE_DETAIL_BTN, '전화상담_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.VST_TRAINING
        ? [DOGIT_V3_EVENT_TYPE.VST_TRAINING_BTN, '방문훈련_상세_페이지_버튼']
        : type === DOGIT_V3_PROGRAM_TYPE.CHALLENGE
        ? [DOGIT_V3_EVENT_TYPE.CHALLENGE_DETAIL_BTN, '챌린지_상세_페이지_버튼']
        : ['', ''];

    // Util.sendGaEvent(`[도깃_v4_AD_상세_${ver}]${ga}`);
    Util.createEventLog(dgUserNo, context.hpNo, eventType, `/v3-program-detail?type=${type}`);
  };

  const onClickSubmit = () => {
    if (!selected) return alert('프로그램을 선택해주세요!');

    const [name, programNo] =
      selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SOCIALIZATION
        ? ['사회화', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SEPARATION_ANXIETY
        ? ['분리불안', 7]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.TOILET_TRAINING
        ? ['배변', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.WALKING_TRAINING
        ? ['산책', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.BARK
        ? ['짖음', 8]
        : selected === DOGIT_V3_PROGRAM_TYPE.VST_TRAINING
        ? ['방문훈련', 0]
        : selected === DOGIT_V3_PROGRAM_TYPE.CHALLENGE
        ? ['챌린지', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.ALL_IN_ONE
        ? ['올인원', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SOCIALIZATION_TO_ALL
        ? ['사회화-올인원', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SEPARATION_TO_ALL
        ? ['분리불안-올인원', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.TOILET_TO_ALL
        ? ['배변-올인원', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.WALKING_TO_ALL
        ? ['산책-올인원', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SOCIALIZATION_TO_FREE
        ? ['사회화-체험판', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SEPARATION_TO_FREE
        ? ['분리불안-체험판', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.TOILET_TO_FREE
        ? ['배변-체험판', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.WALKING_TO_FREE
        ? ['산책-체험판', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.BARK_TO_FREE
        ? ['짖음-체험판', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.ALL_TO_FREE
        ? ['올인원-체험판', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SOCIALIZATION_TO_CUSTOMER_SERVICE
        ? ['사회화-상담하기', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SEPARATION_TO_CUSTOMER_SERVICE
        ? ['분리불안-상담하기', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.TOILET_TO_CUSTOMER_SERVICE
        ? ['배변-상담하기', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.WALKING_TO_CUSTOMER_SERVICE
        ? ['산책-상담하기', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.BARK_TO_CUSTOMER_SERVICE
        ? ['짖음-상담하기', 0]
        : selected === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.ALL_TO_CUSTOMER_SERVICE
        ? ['올인원-상담하기', 0]
        : '';

    if (selected >= 60) {
      if (userStatus === DOGIT_USER_STATUS.IN_PROGRESS || userStatus === DOGIT_USER_STATUS.END) {
        window.location.href = '/list';
      }
    }

    // Util.sendGaEvent(`[도깃_v4_AD_상세_${ver}]${name}_신청_버튼`);
    // Util.sendFacebookPixelEvent(`[도깃_v4_AD_상세_${ver}]${name}_신청하기 버튼`);

    // const url = dgUserNo ? `/register?type=${selected}` : `/login?next=/register?type=${selected}`;
    const url = dgUserNo ? `/register?next=/pay?no=${programNo}` : `/login?next=/register?next=/pay?no=${programNo}`;
    Util.createEventLog(dgUserNo, context.hpNo, DOGIT_V3_EVENT_TYPE.SUBMIT, url);
  };

  const onClickGotoHome = () => {
    const path =
      window.location.pathname.indexOf('/') === 0
        ? '랜딩'
        : window.location.pathname.indexOf('/v3-program-detail') === 0
        ? '상세'
        : '';
    // Util.sendGaEvent(`[도깃_v4_AD_상세_${ver}]${path}_홈버튼`);
    Util.createEventLog(dgUserNo, context.hpNo, DOGIT_V3_EVENT_TYPE.INDEX_BTN, '/');
  };

  const onClickOpenDetailModal = (type) => {
    const [eventType, name] =
      type === DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION
        ? [DOGIT_V3_EVENT_TYPE.MODAL_SOCIALIZATION, '사회화']
        : type === DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY
        ? [DOGIT_V3_EVENT_TYPE.MODAL_SEPARATION_ANXIETY, '분리불안']
        : type === DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING
        ? [DOGIT_V3_EVENT_TYPE.MODAL_TOILET_TRAINING, '배변']
        : type === DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING
        ? [DOGIT_V3_EVENT_TYPE.MODAL_WALKING_TRAINING, '산책']
        : type === DOGIT_V3_PROGRAM_TYPE.BARK
        ? [DOGIT_V3_EVENT_TYPE.MODAL_BARK, '짖음']
        : type === DOGIT_V3_PROGRAM_TYPE.CHALLENGE
        ? [DOGIT_V3_EVENT_TYPE.CHALLENGE, '챌린지']
        : ['', ''];

    // Util.sendGaEvent(`[도깃_V3_올인원_상세_페이지]${name}_상세_모달_버튼`);
    Util.createEventLog(dgUserNo, context.hpNo, eventType);
    setDetailType(type);
    setIsOpenDetailModal(true);
  };

  const onClickCloseDetailModal = () => {
    setIsOpenDetailModal(false, () => {});
  };

  const onClickCloseCSModal = () => {
    setIsOpenCSModal(false);
  };

  const onClickChangeFoldView = (viewNo) => {
    // Util.sendGaEvent(`[도깃_v4_AD_상세_${ver}] 폴드버튼`);
    if (isFirstOpenProgramDescFold) {
      setIsFirstOpenProgramDescFold(false);
    }
    setProgramDescFoldViewNo(viewNo);
  };

  const onClickOpenCSModal = () => {
    Util.createEventLog(dgUserNo, context.hpNo, DOGIT_V3_EVENT_TYPE.CTA);
    setIsOpenCSModal(true);
  };

  const onClickMoreReview = (reviewNo) => {
    // Util.sendGaEvent(`[도깃_v4_AD_상세_${ver}]리뷰 더보기`);
    setReviewNo(reviewNo);
  };

  const onClickChannel = () => {
    const evenType =
      type === DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION
        ? DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.CHANNEL_SOCIALIZATION
        : type === DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY
        ? DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.CHANNEL_SEPARATION
        : type === DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING
        ? DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.CHANNEL_TOILET
        : type === DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING
        ? DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.CHANNEL_WALKING
        : type === DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE
        ? DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.CHANNEL_ALL
        : type === DOGIT_V3_PROGRAM_TYPE.BARK
        ? DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.CHANNEL_BARK
        : type === DOGIT_V3_PROGRAM_TYPE.CHALLENGE
        ? DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.CHANNEL_CHALLENGE
        : '';

    Util.createEventLog(dgUserNo, context.hpNo, evenType);
  };

  const onChangeInputValue = (event) => {
    if (event.target.name === 'userName') {
      setUserName(event.target.value);
    }
  };

  const onClickSubmitCS = (type = DOGIT_V3_PROGRAM_TYPE.CUSTOMER_SERVICE_PROGRAM) => {
    if (!userName) return alert('보호자 이름을 입력해주세요');
    if (!hpNo) return alert('휴대폰 번호를 입력해주세요');
    if (!isHpNoCert) return alert('휴대폰 인증을 해주세요');

    onClickPay(hpNo);
  };

  const onClickSubmitCSProgram = () => {
    setIsOpenModal(false);
    setIsOpenCSModal(true);
    // Util.sendGaEvent(`[도깃_v4_AD_상세_${ver}]전화상담_신청_버튼`);
    // Util.sendFacebookPixelEvent(`[도깃_v4_AD_상세_${ver}]전화상담_신청하기 버튼`);
    Util.createEventLog(dgUserNo, context.hpNo, DOGIT_V3_EVENT_TYPE.SUBMIT);
  };

  const onClickPay = () => {
    if (isOpenModal) setIsOpenModal(false);
    if (isOpenCSModal) setIsOpenCSModal(false);

    // window.location.href = `${paypleUrl}?OID=${hpNo}-${moment().format('YYYYMMDDHHmmss')}`;
    window.open(`${paypleUrl}?OID=${hpNo}-${moment().format('YYYYMMDDHHmmss')}`, '_blank');
  };

  const onClickSubmitVstTringingProgram = () => {
    // Util.sendGaEvent('`[도깃_v4_AD_상세_${ver}]방문훈련_신청_버튼');
    // Util.sendFacebookPixelEvent('[도깃_v4_AD_상세_${ver}]방문훈련_신청_버튼');

    Util.createEventLog(
      dgUserNo,
      context.hpNo,
      DOGIT_V3_EVENT_TYPE.SUBMIT,
      'https://tcf7et7ub8c.typeform.com/to/TmcPG2Wn'
    );
  };

  const onClickGotoEvent = () => {
    // Util.sendGaEvent(`[도깃_v4_AD_상세_${ver}]상단 배너 클릭`);
    // Util.sendFacebookPixelEvent(`[도깃_v4_AD_상세_${ver}]상단 배너 클릭`);

    window.location.href = '#landing-6';
  };

  return (
    <>
      {type === DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING ||
      type === DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING ||
      type === DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION ||
      type === DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE ||
      type === DOGIT_V3_PROGRAM_TYPE.CUSTOMER_SERVICE_PROGRAM ? (
        <></>
      ) : (
        <div style={{ maxWidth: `${StyleConstants.MAX_WIDTH}px`, width: '100%' }}>
          {isOpenModal && (
            <BottomUpPopup height={'100%'}>
              <PurchaseModalView
                type={type}
                selected={selected}
                onClickChangeSelected={onClickChangeSelected}
                onClickSubmit={onClickSubmit}
                onClickCloseModal={onClickCloseModal}
                onClickSubmitCSProgram={onClickSubmitCSProgram}
              />
            </BottomUpPopup>
          )}
          <article style={{ display: 'block' }}>
            <MenuView
              userStatus={userStatus}
              onClickOpenMenu={onClickOpenMenu}
              isOpenMenu={isOpenMenu}
              onClickCloseMenu={onClickCloseMenu}
              onClickGotoHome={onClickGotoHome}
              element={<BannerView onClickClose={() => setIsOpenTopBanner(false)} onClick={onClickGotoEvent} />}
              isOpenElement={isOpenTopBanner}
            />
            <ProgramDetailAdView
              type={type}
              view={view}
              ver={ver}
              onSlideChange={onSlideChange}
              onClickGotoDetail={onClickGotoDetail}
              isOpenModal={isOpenModal}
              onClickOpenModal={onClickOpenModal}
              isStartTrainerAnimation={isStartTrainerAnimation}
              isStartReviewScoreAnimation={isStartReviewScoreAnimation}
              programDescFoldViewNo={programDescFoldViewNo}
              onClickChangeFoldView={onClickChangeFoldView}
              reviewNo={reviewNo}
              setReviewNo={setReviewNo}
              onClickMoreReview={onClickMoreReview}
              onClickOpenDetailModal={onClickOpenDetailModal}
              isCta={isCta}
              isOpenMenu={isOpenMenu}
              isOpenTopBanner={isOpenTopBanner}
              onClickGotoEvent={onClickGotoEvent}
              reviewList={reviewList}
              totalParticipatedCnt={totalParticipatedCnt}
              avgImprovementRate={avgImprovementRate}
              reviewCnt={reviewCnt}
            />
          </article>
          {!isOpenMenu && !isOpenModal && !isOpenCSModal && <ChannelButton bottom='110px' onClick={onClickChannel} />}
        </div>
      )}
    </>
  );
}
