import * as Util from '../../common/Util';
import HelmetCommon from '../../components/HelmetCommon';
import PageWrapper from '../../components/PageWrapper';
import PrescriptionResultContainer from './PrescriptionContainer';

export default function PrescriptionPage() {
  const query = Util.useQuery();
  const prescriptionNo = parseInt(query.get('no')) || parseInt(query.get('prescription_no'));
  const typeformHpNo = query.get('hp_no') ? query.get('hp_no').replace(/82/, '0').replace(/-| /g, '') : '';
  const dgUserProgramNo = parseInt(query.get('up'));
  const firstMissionCode = query.get('code');
  const missionCode = query.get('mission-code');
  const end = parseInt(query.get('end'));

  return (
    <>
      <HelmetCommon
        title='초보 보호자를 위한 강아지 교육 | 도깃'
        description='평생 함께 할 강아지, 퍼피 때 부터 올바른 교육이 필요하니까. 도깃에서 강아지 훈련 시작하세요.'
        keywords='강아지, 퍼피 교육, 훈련, 강아지 훈련, 새끼 강아지, 아기 강아지, 퍼피 클래스, 트레이닝, 교육, 반려견, 애견, 퍼피 트레이닝, 사회화 훈련, 분리불안, 분리불안 훈련, 배변, 배변훈련, 훈련사, 사회화, 산책'
        url={process.env.REACT_APP_URL}
        ogImageUrl='https://static.dogmate.co.kr/dogit/og_image/dogit_og_v3.jpeg'
      />
      <PageWrapper
        next={`/prescription?no=${prescriptionNo}&typeformHpNo=${typeformHpNo}&dgUserProgramNo=${dgUserProgramNo}&firstMissionCode=${firstMissionCode}&missionCode=${missionCode}`}
      >
        <PrescriptionResultContainer
          prescriptionNo={prescriptionNo}
          typeformHpNo={typeformHpNo}
          dgUserProgramNo={dgUserProgramNo}
          firstMissionCode={firstMissionCode}
          missionCode={missionCode}
          end={end}
        />
      </PageWrapper>
    </>
  );
}
