import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import StyleConstants from '../../../common/StyleConstants';

const style = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
`;
const imgWrp = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  height: 100vh;
  img {
    width: 100%;
  }
`;
const btnWrp = css`
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 40px;
  width: 100%;
  left: 0;
  margin: 0 auto;
`;
const btnStyle = css`
  width: 90%;
  height: 60px;
  background-color: #f48c95;
  border-radius: 24px;
  color: ${Color.WHITE};
  font-size: 26px;
  font-weight: bold;
  letter-spacing: -0.97px;
`;

export default function PrescriptionLandingView({ onClickGotoTypeform }) {
  document.querySelector('#root').style.backgroundColor = '#4855d1';
  return (
    <article className={style}>
      <div className={imgWrp}>
        <img src='//static.dogmate.co.kr/dogit/landing-v3/prescription/dogit_v3.2_prescription_landing_v2.jpg' />
      </div>
      <div className={btnWrp}>
        <button className={btnStyle} onClick={onClickGotoTypeform}>
          진단받기
        </button>
      </div>
    </article>
  );
}
