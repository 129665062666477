import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import StyleConstants from '../../../common/StyleConstants';
import FooterView from '../../../components/FooterView';
import RecommendView from './sub-view/RecommendView';
import _ from 'lodash';

const style = css`
  display: flex;
  flex-direction: column;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
`;
const imgWrapper = css`
  width: 100%;
  img {
    width: 100%;
  }
`;
const priceViewStyle = css`
  margin: 25px 0 60px 0;
`;
const titleStyle = css`
  font-size: 22px;
  color: #406081;
  margin: 0 0 22px 25px;
  line-height: 1.36;
  letter-spacing: -0.82px;
  b {
    font-weight: bold;
  }
`;
const boxStyle = css`
  display: flex;
  flex-direction: column;
  width: 87.5%;
  /* height: 220px; */
  border: solid 1px #979797;
  border-radius: 8px;
  background: linear-gradient(114deg, #7461f4 0%, #8f61eb 112%);
  left: 0;
  margin: 0 auto;
  p {
    font-size: 16px;
    line-height: 1.88;
    letter-spacing: -0.6px;
    color: ${Color.WHITE};
    b {
      font-weight: bold;
    }
  }
  h4 {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.6px;
    margin: 20px 20px 8px;
    color: ${Color.WHITE};
    b {
      font-weight: bold;
    }
  }
  h3 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.82px;
    margin: 12px 20px 8px;
    color: ${Color.WHITE};
  }
`;
const descStyle = css`
  margin: 15px 0 0 20px;
`;
const priceDescWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px;
  p {
    color: #f8e71c;
    font-size: 12px;
    line-height: 2.5;
    letter-spacing: -0.45px;
  }
`;
const priceWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const priceStyle = css`
  font-size: 30px;
  font-weight: bold;
  line-height: 0.73;
  letter-spacing: -1.12px;
  color: #f8e71c;
  s {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.6px;
    color: #e1e1e1;
    margin-left: 4px;
    color: ${Color.WHITE};
  }
`;
const rateStyle = css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    border-radius: 5px;
    background: linear-gradient(116deg, #fad961 13%, #f76b1c 143%);
    color: #000;
    height: 22px;
    border: 0;
    padding: 1px;
    margin-left: 4px;

    font-weight: bold;
  }
`;
const btnWrapper = css`
  display: flex;
  flex-direction: column;
`;
const btnStyle = css`
  display: block;
  height: 60px;
  background-color: ${Color.DOGIT_MEDIUM_BLUE};
  border-radius: 8px;
  left: 0;
  margin: 10px 40px 35px;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: -0.97px;
  color: ${Color.WHITE};
`;

export default function CustomerServiceProgramDetailView({ type, isOpenCSModal, onClickOpenCSModal, onClickGotoDetail }) {
  return (
    <>
      <section className={style}>
        <div className={imgWrapper} style={{ backgroundColor: '#e7ecf8' }}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/program-detail/v3.2/dogit-v3.2-detail-main-img-v5-10-1.png' />
        </div>
        <div className={btnWrapper} style={{ backgroundColor: '#e7ecf8' }}>
          <button className={btnStyle} onClick={onClickOpenCSModal}>
            진단 티켓 구매하기
          </button>
        </div>
        <div className={imgWrapper}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/program-detail/v3.2/dogit-v3.2-detail-main-img-v4-10-2.png' />
        </div>
        <div className={imgWrapper}>
          <img src='//static.dogmate.co.kr/dogit/landing-v3/program-detail/v3.2/dogit-v3.2-detail-main-img-v5-10-3.png' />
        </div>
        <div className={btnWrapper}>
          <button className={btnStyle} onClick={onClickOpenCSModal}>
            진단 티켓 구매하기
          </button>
        </div>
        <RecommendView type={type} onClick={onClickGotoDetail} />
        <FooterView />
      </section>
      {!isOpenCSModal && <CtaButtonView onClickOpenCSModal={onClickOpenCSModal} />}
    </>
  );
}

function PriceView({ onClickOpenCSModal }) {
  return (
    <article className={priceViewStyle}>
      <h1 className={titleStyle}>
        <b>
          도깃 훈련사 전화 상담
          <br />
          오픈혜택가
        </b>
        를 놓치지 마세요
      </h1>
      <div className={boxStyle}>
        <div className={descStyle}>
          <p>
            💁🏻‍♀️ &nbsp;<b>1:1 음성 통화</b> (30분 내외)
          </p>
          <p>
            ✍🏼 &nbsp;문제행동의 <b>정확한 원인 분석</b>
          </p>
          <p>
            🌼 &nbsp;해결책 및 <b>구체적인 훈련법 제안</b>
          </p>
          <p>
            🔖 &nbsp;<b>리포트</b> 제공
          </p>
        </div>
        <div className={priceDescWrapper}>
          <p>상담 오픈 이벤트 가격</p>
          <div className={priceWrapper}>
            <h2 className={priceStyle}>
              {`9,800원`}
              <s>30,000원</s>
            </h2>
            <div className={rateStyle}>
              <div>67%</div>
            </div>
          </div>
        </div>
      </div>
      <button className={btnStyle} onClick={onClickOpenCSModal}>
        전문가 상담 신청하기
      </button>
    </article>
  );
}

const ctaFloatingBtnStyle = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  z-index: 6;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-top: solid 1px #cacede;
`;
const ctaBtn = css`
  width: 100%;
  height: 70px;
  background-color: #e47982;
  color: ${Color.WHITE};
  font-weight: bold;
  font-size: 24px;
  letter-spacing: -0.9px;
  box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.5);
`;

function CtaButtonView({ onClickOpenCSModal }) {
  return (
    <div className={ctaFloatingBtnStyle}>
      <button className={ctaBtn} onClick={_.debounce(() => onClickOpenCSModal(), 300)}>
        전문가 상담 신청하기
      </button>
    </div>
  );
}
