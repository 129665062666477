import React from 'react';
import { css } from '@emotion/css/macro';

export default function IconClose({ size, strokeColor }) {
  const styleStroke = css`
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: ${strokeColor};
  `;

  return (
    <svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <g transform={`scale(${size / 15})`}>
        <g transform='translate(-284.000000, -206.000000)'>
          <g transform='translate(284.000000, 206.000000)'>
            <g>
              <path
                id='path-1_1_'
                className={styleStroke}
                d='M9.09,7.5l5.58-5.58c0.44-0.44,0.44-1.15,0-1.59c-0.44-0.44-1.15-0.44-1.59,0L7.5,5.91
                L1.92,0.33c-0.44-0.44-1.15-0.44-1.59,0c-0.44,0.44-0.44,1.15,0,1.59L5.91,7.5l-5.58,5.58c-0.44,0.44-0.44,1.15,0,1.59
                C0.55,14.89,0.84,15,1.13,15c0.29,0,0.58-0.11,0.8-0.33L7.5,9.09l5.58,5.58c0.22,0.22,0.51,0.33,0.8,0.33
                c0.29,0,0.58-0.11,0.8-0.33c0.44-0.44,0.44-1.15,0-1.59L9.09,7.5z'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
