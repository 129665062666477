import CSApplicationView from './CSApplicationView';
import { DOGIT_V3_EVENT_TYPE, DOGIT_V3_PROGRAM_BTN_ACTION_TYPE } from '../../common/Constants';
import * as Util from '../../common/Util';
import * as API from '../../api/api';
import * as Storage from '../../common/Storage';
import * as React from 'react';
import * as Sentry from '@sentry/react';
import { UserInfoContext } from '../../components/PageWrapper';

export default function CSApplicationContainer({ type, step, prevUrl }) {
  const { dgUserNo, hpNo } = React.useContext(UserInfoContext);

  const onClickGotoLanding = () => {
    // Util.sendGaEvent('[도깃_V3_상담_신청_완료_페이지]홈으로_버튼');
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.CTA, '/');
  };

  const requestTypeForm = () => {
    const program =
      type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SOCIALIZATION_TO_CUSTOMER_SERVICE
        ? '_사회화'
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.SEPARATION_TO_CUSTOMER_SERVICE
        ? '_분리불안'
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.TOILET_TO_CUSTOMER_SERVICE
        ? '_배변'
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.WALKING_TO_CUSTOMER_SERVICE
        ? '_산책'
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.ALL_TO_CUSTOMER_SERVICE
        ? '_올인원'
        : type === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.BARK_TO_CUSTOMER_SERVICE
        ? '_짖음'
        : '';
    // Util.sendGaEvent(`[도깃_v3]타입폼_${program}_상담_제출`);

    if (process.env.REACT_APP_ENV === 'production') {
      // * 광고 대행사 트래킹 스크립트
      try {
        if (!window.wcs_add) window.wcs_add = {};
        window.wcs_add['wa'] = 's_568cb1d0f9ec';

        if (window.wcs) {
          const _nasa = {};
          _nasa['cnv'] = window.wcs.cnv('4', '1');
          window.wcs.inflow();
          window.wcs_do(_nasa);
        }
      } catch (error) {
        Sentry.captureMessage(error, 'error');
      }
    }
    const uid = Storage.getV3Uid();
    const tempUid = Storage.getV3TempUid();

    API.createV3CSResponse({
      hpNo,
      uid,
      tempUid,
      programType: type,
    })
      .then(() => {
        window.location.href = `/v3-cs-application?type=${type}&step=2&prevUrl=${
          window.location.pathname + window.location.search
        }`;
      })
      .catch(API.errorHandler);
  };

  return (
    <CSApplicationView
      hpNo={hpNo}
      currentStep={step}
      type={type}
      prevUrl={prevUrl}
      onClickGotoLanding={onClickGotoLanding}
      requestTypeForm={requestTypeForm}
    />
  );
}
