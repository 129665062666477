import { css } from '@emotion/css/macro';
import Color from '../../common/Color';
import { DOGIT_V3_ONBOARDING_TYPE } from '../../common/Constants';
import StyleConstants from '../../common/StyleConstants';
import NotionView from '../../components/NotionView';
import OnboardingSubView from './subview/OnbordingSubView';

const style = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  height: 100vh;
`;
const bottomStyle = css`
  width: 100%;
  position: fixed;
  bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const stepMarkerStyle = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 37px;
  div {
    width: 12px;
    height: 12px;
    border: solid 1px #f48c95;
    border-radius: 24px;
    margin-left: 12px;
  }
`;
const buttonStyle = css`
  width: 100%;
  button {
    display: block;
    margin: 0 auto;
    width: 80%;
    height: 50px;
    background-color: #f48c95;
    border-radius: 24px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.43px;
    color: ${Color.WHITE};
  }
`;

export default function OnboardingView({ currentStep, onClickChangeCurrentStep, onClickSubmit }) {
  return (
    <div className={style}>
      <OnboardingSubView currentStep={currentStep} />
      <div className={bottomStyle}>
        {currentStep !== DOGIT_V3_ONBOARDING_TYPE.STEP_FOUR ? (
          <>
            <div className={stepMarkerStyle}>
              <div style={{ backgroundColor: currentStep === DOGIT_V3_ONBOARDING_TYPE.STEP_ONE ? '#f48c95' : '' }} />
              <div style={{ backgroundColor: currentStep === DOGIT_V3_ONBOARDING_TYPE.STEP_TWO ? '#f48c95' : '' }} />
              <div style={{ backgroundColor: currentStep === DOGIT_V3_ONBOARDING_TYPE.STEP_THREE ? '#f48c95' : '' }} />
              <div style={{ backgroundColor: currentStep === DOGIT_V3_ONBOARDING_TYPE.STEP_FOUR ? '#f48c95' : '' }} />
            </div>
            <div className={buttonStyle}>
              <button onClick={onClickChangeCurrentStep} style={{ width: '115px' }}>
                다음
              </button>
            </div>
          </>
        ) : (
          <div className={buttonStyle}>
            <button onClick={onClickSubmit}>자 이제 미션을 시작해 볼까요?</button>
          </div>
        )}
      </div>
    </div>
  );
}
