import React from 'react';
import { css } from '@emotion/css/macro';
import Color from '../common/Color';

export default function HamburgerBtn({ onClickOpenMenu, onClickCloseMenu, isActive, isActiveRedDot }) {
  const hamburger = css`
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    opacity: 0.7;
  `;
  const hamburgerBox = css`
    width: 25px;
    height: 25px;
    display: inline-block;
    position: relative;
  `;
  const hamburgerInner = css`
    display: block;
    top: 50%;
    margin-top: -2px;
    &,
    &::before,
    &::after {
      width: 25px;
      height: 2px;
      background-color: ${Color.DOGIT_MEDIUM_BLUE};
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }
    &::before,
    &::after {
      content: '';
      display: block;
    }
    &::before {
      top: -7px;
    }
    &::after {
      bottom: -7px;
    }
    & {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }
    &::after {
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  `;

  const hamburgerSqueeze = css`
    ${hamburger}
    & {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }
    &::after {
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  `;

  const hamburgerSqueezeIsActive = css`
    ${hamburger}
    & {
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &::before {
      top: 0;
      opacity: 1;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }
    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  `;

  const hamburgerInnerIsActive = css`
    ${hamburgerInner}
    & {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }
    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  `;
  const redDot = css`
    position: absolute;
    z-index: 5;
    width: 8px;
    height: 8px;
    border-radius: 24px;
    background-color: ${Color.DOGIT_DARK_RED};
    /* margin-top: -4px;
    margin-left: -8px;
    margin-top: 0px;
    margin-left: -10px; */
    margin-top: 0px;
    margin-left: -4px;
  `;

  return (
    <>
      {!isActive && isActiveRedDot && <div id='Blink' className={redDot} />}
      <button
        className={isActive ? hamburgerSqueezeIsActive : hamburgerSqueeze}
        type='button'
        onClick={isActive ? onClickCloseMenu : onClickOpenMenu}
      >
        <span className={hamburgerBox}>
          <span className={isActive ? hamburgerInnerIsActive : hamburgerInner}></span>
        </span>
      </button>
    </>
  );
}
