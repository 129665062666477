import _ from 'lodash';

export const setToken = (data) => localStorage.setItem('token', data);
export const getToken = () => localStorage.getItem('token') || '';
export const removeToken = () => localStorage.removeItem('token');

export const clearSession = () => {
  sessionStorage.clear();
};

export const setIsRecvMkt = (data) => localStorage.setItem('isRecvMkt', data);
export const getIsRecvMkt = () => localStorage.getItem('isRecvMkt') === 'true';

export const setPhoneNumber = (data) => sessionStorage.setItem('phone', data);
export const getPhoneNumber = (data) => sessionStorage.getItem('phone') || '';

export const setMainMissionCompleteDate = (data) => localStorage.setItem('mainMissionCompleteDate', data);
export const getMainMissionCompleteDate = () => localStorage.getItem('mainMissionCompleteDate') || '';

export const setV3Uid = (uid) => {
  return localStorage.setItem('v3Uid', uid);
};
export const getV3Uid = () => localStorage.getItem('v3Uid') || '';

export const setV3TempUid = (tempUid) => {
  return localStorage.setItem('v3TempUid', tempUid);
};
export const getV3TempUid = () => localStorage.getItem('v3TempUid') || '';
