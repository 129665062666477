import 'swiper/swiper-bundle.min.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import LoginPage from './pages/login/LoginPage';
import LandingPage from './pages/landing/LandingPage';
import ProgramApplicationPage from './pages/program-application/ProgramApplicationPage';
import ProgramDetailPage from './pages/program-detail/ProgramDetailPage';
import ProgramListPage from './pages/program-list/ProgramListPage';
import ProgramPreviewPage from './pages/program-preview/ProgramPreviewPage';
import MissionPage from './pages/mission/MissionPage';
import OnboardingPage from './pages/onboarding/OnboardingPage';
import CSApplicationPage from './pages/cs-application/CSApplicationPage';
import NudgePage from './pages/nudge/NudgePage';
import CSTypeformCompletePage from './pages/cs-typeform-complete/CSTypeformCompletePage';
import PrescriptionLandingPage from './pages/prescription-landing/PrescriptionLandingPage';
import PrescriptionTypeformPage from './pages/prescription-typeform/PrescriptionTypeformPage';
import PaymentPage from './pages/payment/PaymentPage';
import PaymentCompletePage from './pages/payment/PaymentCompletePage';
import RegisterPage from './pages/register/RegisterPage';
import MyPage from './pages/my/MyPage';
import PrescriptionPage from './pages/prescription/PrescriptionPage';
import NotionPage from './pages/notion/NotionPage';
import RefundReadyPage from './pages/refund-ready/RefundReadyPage';
import ChallengeInfoPage from './pages/challenge-info/ChallengeInfoPage';
import ProgramDetailAdPage from './pages/program-detail-ad/ProgramDetailAdPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' exact>
          <LandingPage />
        </Route>
        <Route path='/notion' exact>
          <NotionPage />
        </Route>
        <Route path='/login' exact>
          <LoginPage />
        </Route>
        <Route path='/onboarding' exact>
          <OnboardingPage />
        </Route>
        <Route path='/nudge' exact>
          <NudgePage />
        </Route>
        <Route path='/mission' exact>
          <MissionPage />
        </Route>
        <Route path='/v3-program-detail' exact>
          <ProgramDetailPage />
        </Route>
        <Route path='/program-detail-ad' exact>
          <ProgramDetailAdPage />
        </Route>
        <Route path='/v3-program-list' exact>
          <ProgramListPage />
        </Route>
        <Route path='/v3-program-preview' exact>
          <ProgramPreviewPage />
        </Route>
        <Route path='/v3-program-application' exact>
          <ProgramApplicationPage />
        </Route>
        <Route path='/v3-cs-application' exact>
          <CSApplicationPage />
        </Route>
        <Route path='/cs-tf-complete' exact>
          <CSTypeformCompletePage />
        </Route>
        <Route path='/prescription-landing' exact>
          <PrescriptionLandingPage />
        </Route>
        <Route path='/prescription-typeform' exact>
          <PrescriptionTypeformPage />
        </Route>
        <Route path='/prescription' exact>
          <PrescriptionPage />
        </Route>
        <Route path='/pay' exact>
          <PaymentPage />
        </Route>
        <Route path='/pay-complete' exact>
          <PaymentCompletePage />
        </Route>
        <Route path='/register' exact>
          <RegisterPage />
        </Route>
        <Route path='/refund-ready' exact>
          <RefundReadyPage />
        </Route>
        <Route path='/challenge-info' exact>
          <ChallengeInfoPage />
        </Route>
        <Route path='/my' exact>
          <MyPage />
        </Route>
        <Route path='*'>
          <Redirect
            to={{
              pathname: '/404',
            }}
          />
        </Route>
      </Switch>
    </Router>
  );
}

export default Sentry.withProfiler(App);
