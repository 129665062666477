import axios from 'axios';
import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { getToken } from '../common/Storage';

export const errorHandler = (error) => {
  Sentry.captureMessage(error, 'error');
  const errMsg = _.get(error, 'response.data.error') || error.toString();
  alert(errMsg);
};

export const sendHpCertNo = (hpNo, simpleLoginType) =>
  axios.put('/api/simple-login/hp-cert-no', { hpNo, simpleLoginType }, {});

export const verifyHpCertNo = (hpNo, certNo, simpleLoginType) =>
  axios.put('/api/simple-login/hp-cert-no/verify', { hpNo, certNo, simpleLoginType }, {});

export const createDogitTypeformResponse = (formId, responseId, hpNo, startYmd, landingType) =>
  axios.post('/api/dogit/typeform', { formId, responseId, hpNo, startYmd, landingType }, {});

export const getCurriculumList = () =>
  axios.get('/api/dogit/curriculum', {
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const getCurriculumStampList = (from, to) =>
  axios.get('/api/dogit/curriculum/stamp-list', {
    params: {
      from,
      to,
    },
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const requestKakaoToken = ({ kakaoId, phoneNumber, email, ageRange, profileImg, refreshToken, serviceType }) =>
  axios({
    method: 'POST',
    url: '/api/dogit/user/kakao',
    data: {
      kakaoId,
      phoneNumber,
      email,
      ageRange,
      profileImg,
      refreshToken,
      serviceType,
    },
  });

export const requestCsrfToken = ({ kakaoId, phoneNumber, email, isRecvMkt }) =>
  axios({
    method: 'POST',
    url: '/api/dogit/user',
    data: {
      isRecvMkt,
      email,
      kakaoId: '' + kakaoId,
      hpNo: phoneNumber,
    },
  });

export const getUserStatus = () =>
  axios.get('/api/dogit/user/status', {
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const updateIsCompleteSecondOnboarding = ({ isComplete }) =>
  axios({
    method: 'PUT',
    url: '/api/dogit/user/is-complete-second-onboarding',
    data: {
      isComplete,
    },
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const getMissionDetail = (verDtlNo) =>
  axios({
    method: 'GET',
    url: `/api/dogit/mission/detail?verDtlNo=${verDtlNo}`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const updateUserNotificationTime = (notificationTime) =>
  axios.put(
    '/api/dogit/user/notification-time',
    { notificationTime },
    {
      headers: {
        'X-CSRF-TOKEN': getToken(),
      },
    }
  );

export const updateIsRecvNotification = (isRecvNotification) =>
  axios({
    method: 'PUT',
    url: `/api/dogit/user/notification/${isRecvNotification}`,
    headers: { 'X-CSRF-TOKEN': getToken() },
  });

export const getMissionList = () =>
  axios.get('/api/dogit/mission', {
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const createUserCurriculumLog = (verDtlNo, currentCurriculumDay) =>
  axios({
    method: 'POST',
    url: '/api/dogit/user-curriculum/log',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: {
      verDtlNo,
      currentCurriculumDay,
    },
  });

export const updateUserCurriculumLog = (dgUserCurriculumLogNo, missionResult) => {
  return axios({
    method: 'PUT',
    url: `/api/dogit/user-curriculum/log/${dgUserCurriculumLogNo}`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: missionResult,
  });
};

export const getNotionBlock = (id) =>
  axios({
    method: 'GET',
    url: '/api/notion',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    params: { id },
    timeout: 5000,
  });

export const createMissionFeedback = (missionFeedbackStatus) =>
  axios.post(
    '/api/dogit/mission/feedback',
    { missionFeedbackStatus },
    {
      headers: {
        'X-CSRF-TOKEN': getToken(),
      },
    }
  );

export const createMissionListOpenLog = () =>
  axios.post(
    '/api/dogit/mission-list/open',
    {},
    {
      headers: {
        'X-CSRF-TOKEN': getToken(),
      },
    }
  );

export const createPageOpenLog = (pageUrl) =>
  axios.post(
    '/api/dogit/page-open',
    { pageUrl },
    {
      headers: {
        'X-CSRF-TOKEN': getToken(),
      },
    }
  );

export const updateTestMissionYmdAndIsGoToNextLevel = (dgUserCurriculumNo, isGoToNextLevel) =>
  axios({
    method: 'PUT',
    url: '/api/dogit/mission/test',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: {
      dgUserCurriculumNo,
      isGoToNextLevel,
    },
  });

// ! deprecated
export const createUserPaymentInfo = (params) =>
  axios({
    method: 'POST',
    url: '/api/dogit/payment',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const updateUserPaymentInfo = (params) => {
  return axios({
    method: 'PUT',
    url: '/api/dogit/v3/payment',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });
};

export const getPmtHistory = () =>
  axios({
    method: 'GET',
    url: '/api/dogit/payment/history',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const cancelPay = (params) =>
  axios({
    url: '/api/dogit/payment/cancel',
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const getCurriculumCodeNameAndOnboardingType = () =>
  axios({
    url: '/api/dogit/assign',
    method: 'GET',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const getTypeformInfo = () =>
  axios({
    url: '/api/dogit/tf',
    method: 'GET',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const getTypeformResponse = () =>
  axios({
    url: '/api/dogit/typeform/response',
    method: 'GET',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const createFreeAssign = (params) =>
  axios({
    url: '/api/dogit/assign-free',
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const getMainMissionScore = () =>
  axios({
    url: '/api/dogit/score',
    method: 'GET',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const getFirstMainMissionUrl = () =>
  axios({
    url: '/api/dogit/mission/detail/first-main-mission-url',
    method: 'GET',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const updateCurriculumMainMissionSeq = (dtlNo) =>
  axios({
    url: `/api/dogit/current-main-mission-seq/${dtlNo}`,
    method: 'PUT',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const getCurrentMainMissionSeq = () =>
  axios({
    url: '/api/dogit/seq-info',
    method: 'GET',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const createV3EventLog = (params) =>
  axios({
    url: '/api/dogit/v3/event-log',
    method: 'POST',
    data: params,
  });

export const createV3Response = (params) =>
  axios({
    url: '/api/dogit/v3',
    method: 'POST',
    data: params,
  });

export const createV3ResponseInEnd = (params) =>
  axios({
    url: '/api/dogit/v3/end',
    method: 'POST',
    data: params,
  });

export const createV3CSResponse = (params) =>
  axios({
    url: '/api/dogit/v3/cs',
    method: 'POST',
    data: params,
  });

export const updateV3User = (params) =>
  axios({
    url: '/api/dogit/user/update',
    method: 'PUT',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const createFreeProgram = (params) => {
  return axios({
    url: '/api/dogit/user-program',
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });
};

export const updateIsCompleteOnboarding = (params) =>
  axios({
    method: 'PUT',
    url: '/api/dogit/user/is-complete-onboarding',
    data: params,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const submitUserProgram = (params) =>
  axios({
    method: 'POST',
    url: '/api/dogit/user-program/submit',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const submitCustomerServiceProgram = (params) =>
  axios({
    method: 'POST',
    url: '/api/dogit/v3/cs-program',
    data: params,
  });

export const getPaypleAuth = () =>
  axios({
    method: 'GET',
    url: '/api/dogit/v3/payple-auth',
  });

// export const sendPrescriptionSms = (params) =>
//   axios({
//     method: 'POST',
//     url: '/api/dogit/v3/prescription/msg',
//   });

export const createUserPaymentInfoV3 = (params) =>
  axios({
    method: 'POST',
    url: '/api/dogit/v3/payment',
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const getChlngSt = () =>
  axios({
    method: 'GET',
    url: `/api/dogit/mission/challenge`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const getTypeformRes = (formId, responseId) =>
  axios({
    method: 'GET',
    url: `/api/dogit/mission/typeform-res?formId${formId}&responseId=${responseId}`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const createChallengeLog = (dgUserProgramNo, currentMissionCode) =>
  axios({
    method: 'POST',
    url: `/api/dogit/mission/challenge`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: {
      dgUserProgramNo,
      currentMissionCode,
    },
  });

export const updateChallengeLog = (params) =>
  axios({
    method: 'PUT',
    url: `/api/dogit/mission/challenge`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const createPrescription = (params) =>
  axios({
    method: 'POST',
    url: `/api/dogit/user-program/prescription`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const createPrescriptionNone = (params) =>
  axios({
    method: 'POST',
    url: `/api/dogit/v3/prescription-none`,
    data: params,
  });

export const getChallengeLogRegDate = (dgChallengeLogNo) =>
  axios({
    method: 'GET',
    url: `/api/dogit/mission/challenge/log/reg-date?dgChallengeLogNo=${dgChallengeLogNo}`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const updateRefundReady = (params) =>
  axios({
    method: 'PUT',
    url: `/api/dogit/user-program/refund-ready`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const getChallengeFormId = (code) =>
  axios({
    method: 'GET',
    url: `/api/dogit/mission/challenge/form-id?code=${code}`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const createUserPaymentInfoV4 = (params) =>
  axios({
    method: 'POST',
    url: `/api/dogit/v4/payment`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const updateUserPaymentInfoV4 = (params) =>
  axios({
    method: 'PUT',
    url: `/api/dogit/v4/payment`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const getPmtInfo = (dgPmtNo) =>
  axios({
    method: 'GET',
    url: `/api/dogit/v4/payment?dgPmtNo=${dgPmtNo}`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const createAuthImgURL = (params) =>
  axios({
    method: 'POST',
    url: `/api/dogit/mission/auth`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const uploadAWSAuthImgURL = (url, data) =>
  axios({
    url,
    data,
    method: 'PUT',
  });

export const uploadAuthImgURL = (params) =>
  axios({
    method: 'PUT',
    url: `/api/dogit/mission/auth`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const getAuthImgURL = (params) =>
  axios({
    method: 'GET',
    url: `/api/dogit/mission/auth?dgChallengeLogNo=${params.dgChallengeLogNo}${
      params.fileName ? `&fileName=${params.fileName}` : ''
    }`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const getChallengeInfo = (up) =>
  axios({
    method: 'GET',
    url: `/api/dogit/mission/challenge/${up}`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const getArticleReadCnt = () =>
  axios({
    method: 'GET',
    url: `/api/dogit/mission/article`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const paypleAuthAppCard = (params) =>
  axios({
    method: 'POST',
    url: `/api/dogit/v4/payment/app-card`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const updateUserPaymentInfoV4AppCard = (params) =>
  axios({
    method: 'PUT',
    url: `/api/dogit/v4/payment/app-card`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const createVideoURL = (params) =>
  axios({
    method: 'POST',
    url: `/api/dogit/review/video-url`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const updateVideoURL = (params) =>
  axios({
    method: 'PUT',
    url: `/api/dogit/review/video-url`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const submitReview = (params) =>
  axios({
    method: 'POST',
    url: `/api/dogit/review`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
    data: params,
  });

export const getReviewInfo = () =>
  axios({
    method: 'GET',
    url: `/api/dogit/review-main`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const getReviewList = ({ offset, limit }) =>
  axios({
    method: 'GET',
    url: `/api/dogit/review-main/list?offset=${offset}&limit=${limit}`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });

export const getMissionCode = (dgUserProgramNo) =>
  axios({
    method: 'GET',
    url: `/api/dogit/mission/code?dgUserProgramNo=${dgUserProgramNo}`,
    headers: {
      'X-CSRF-TOKEN': getToken(),
    },
  });
