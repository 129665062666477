import * as React from 'react';
import * as Util from '../../common/Util';
import * as API from '../../api/api';
import * as _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ko';
import { DOGIT_V3_EVENT_TYPE, DOGIT_V3_MISSION_STEP_TYPE, DOGIT_V3_PROGRAM_CODE } from '../../common/Constants';
import { UserInfoContext } from '../../components/PageWrapper';
import ChallengeInfoView from './view/ChallengeInfoView';
import ImgView from './view/ImgView';
import LoadingView from '../../components/LoadingView';
import { VideoView } from './view/VideoView';

const prescriptionFormId = new Map([
  [DOGIT_V3_PROGRAM_CODE.SEPARATION_CHALLENGE, process.env.REACT_APP_ENV === 'production' ? 'Lm8HHHyx' : 'kAzl47Dl'],
  [DOGIT_V3_PROGRAM_CODE.BARK_CHALLENGE, process.env.REACT_APP_ENV === 'production' ? 'ZXgejUs7' : 'kAzl47Dl'],
]);

export default function ChallengeInfoContainer({ dgUserProgramNo }) {
  const { dgUserNo, userName, hpNo, dgUserProgramList, profileImg, petList, prescriptionNo, userStatus } =
    React.useContext(UserInfoContext);

  const [challengeInfo, setChallengeInfo] = React.useState({});
  const [articleCnt, setArticleCnt] = React.useState(0);
  const [logImgMap, setLogImgMap] = React.useState(null);
  const [isOpenImgView, setIsOpenImgView] = React.useState(false);
  const [imgInfo, setImgInfo] = React.useState({});
  const [articleNo, setArticleNo] = React.useState(null);
  const [isOpenArticleView, setIsOpenArticleView] = React.useState(false);
  const [isOpenVideoView, setIsOpenVideoView] = React.useState(false);
  const [videoSrc, setVideoSrc] = React.useState('');

  React.useEffect(() => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  }, []);

  React.useEffect(() => {
    API.getChallengeInfo(dgUserProgramNo)
      .then((res) => {
        setChallengeInfo(res.data);
        const map = new Map();

        for (const log of res.data.logs) {
          const regYmd = moment(log.regDate).format('YYYY-MM-DD');
          map.set(moment.duration(moment(regYmd).diff(res.data.startYmd)).asDays(), {
            imgUrl: log.imgUrl,
            thumbImgUrl: log.thumbImgUrl,
            challengeLogSt: log.challengeLogSt,
            currentMissionCode: log.currentMissionCode,
            regDate: moment(regYmd).locale('ko').format('YYYY년 MM월 DD일 dd'),
          });
        }

        setLogImgMap(map);
      })
      .catch(API.errorHandler);
  }, []);

  // React.useEffect(() => {
  //   API.getArticleReadCnt()
  //     .then((res) => {
  //       setArticleCnt(res.data);
  //     })
  //     .catch(API.errorHandler);
  // });

  const onClickOpenImgView = (log) => {
    setImgInfo(log);
    setIsOpenImgView(true);
  };

  const onClickCloseImgView = () => {
    setImgInfo({});
    setIsOpenImgView(false);
    document.querySelector('body').style.overflow = 'scroll';
  };

  const onClickOpenArticleView = (articleNo) => {
    setArticleNo(articleNo);
    setIsOpenArticleView(true);
  };

  const onclickGotoPrescription = (up) => {
    if (!up.prescriptionNo) {
      Util.createEventLog(
        dgUserNo,
        hpNo,
        up.programCode === 'SEPARATION_CHALLENGE'
          ? DOGIT_V3_EVENT_TYPE.MISSION_OPEN_SEPARATION_CHALLENGE
          : DOGIT_V3_EVENT_TYPE.MISSION_OPEN_BARK_CHALLENGE,
        `/prescription-typeform?up=${dgUserProgramNo}&id=${prescriptionFormId.get(up.programCode)}`
      ).catch(API.errorHandler);
    } else {
      API.getMissionCode(dgUserProgramNo).then((res) => {
        let missionCode = res.data;
        let url = `/prescription?no=${prescriptionNo}&mission-code=${missionCode}&up=${up.dgUserProgramNo}`;

        if (up.endYmd < moment().format('YYYY-MM-DD')) {
          url += `&end=1`;
        }

        Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.PRESCRIPTION_BTN, url);
      });
    }
  };

  const onClickGotoMission = (dgUserProgramNo, programCode, prescriptionNo) => {
    if (prescriptionNo === 114) {
      return alert(
        '고객님은 프로그램 이용 전에 심층 상담이 필요합니다.\n곧 저희 담당자가 연락드리도록 하겠습니다.\n주말인 경우 평일에 연락드리오니 참고 부탁드립니다.\n기타 궁금한 사항은 채팅 상담으로 문의주시길 바랍니다.'
      );
    } else if (!prescriptionNo) {
      Util.createEventLog(
        dgUserNo,
        hpNo,
        programCode === 'SEPARATION_CHALLENGE'
          ? DOGIT_V3_EVENT_TYPE.MISSION_OPEN_SEPARATION_CHALLENGE
          : DOGIT_V3_EVENT_TYPE.MISSION_OPEN_BARK_CHALLENGE,
        `/prescription-typeform?up=${dgUserProgramNo}&id=${prescriptionFormId.get(programCode)}`
      ).catch(API.errorHandler);
    } else {
      API.getMissionCode(dgUserProgramNo).then((res) => {
        let missionCode = res.data;
        API.createChallengeLog(dgUserProgramNo, missionCode)
          .then((res) => {
            Util.createEventLog(
              dgUserNo,
              hpNo,
              programCode === 'SEPARATION_CHALLENGE'
                ? DOGIT_V3_EVENT_TYPE.MISSION_OPEN_SEPARATION_CHALLENGE
                : DOGIT_V3_EVENT_TYPE.MISSION_OPEN_BARK_CHALLENGE,
              `/mission?step=${DOGIT_V3_MISSION_STEP_TYPE.IN_PROGRESS}&up=${dgUserProgramNo}&log=${res.data}&code=${missionCode}`
            );
          })
          .catch(API.errorHandler);
      });
    }
  };

  const onClickGotoMy = () => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.MY_BTN, '/my');
  };

  return (
    <>
      {!_.isEmpty(imgInfo) && isOpenImgView && (
        <ImgView imgInfo={imgInfo} onClickCloseImgView={onClickCloseImgView} programName={challengeInfo.programName} />
      )}
      {isOpenVideoView && videoSrc && (
        <VideoView src={videoSrc} setIsOpenVideoView={setIsOpenVideoView} setVideoSrc={setVideoSrc} />
      )}
      {!_.isEmpty(challengeInfo) ? (
        !isOpenImgView &&
        !isOpenVideoView && (
          <ChallengeInfoView
            challengeInfo={challengeInfo}
            logImgMap={logImgMap}
            onClickOpenImgView={onClickOpenImgView}
            onClickGotoPrescription={onclickGotoPrescription}
            onClickGotoMission={onClickGotoMission}
            isOpenImgView={isOpenImgView}
            onClickGotoMy={onClickGotoMy}
            setVideoSrc={setVideoSrc}
            setIsOpenVideoView={setIsOpenVideoView}
          />
        )
      ) : (
        <LoadingView alpha={0.8} />
      )}
    </>
  );
}
