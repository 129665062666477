import { css } from '@emotion/css/macro';

const badgeStyle = css`
  padding: 6px 10px;
  border-radius: 14px;
  background-color: #ededed;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.52px;
  color: #456381;
  margin: 0 2px;
`;

export default function BadgeView({ desc }) {
  return <div className={badgeStyle}>{desc}</div>;
}
