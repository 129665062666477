import React from 'react';
import { css } from '@emotion/css/macro';
import StyleConstants from '../common/StyleConstants';

export default function ChannelButton({ bottom, onClick = undefined }) {
  const style = css`
    max-width: ${StyleConstants.MAX_WIDTH}px;
    width: 100%;
    position: fixed;
    bottom: ${bottom || '80px'};
    right: 20px;
    z-index: 10000;
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
    width: 50px;
    img {
      width: 100%;
    }
  `;

  return (
    <button id='dogit-channel-io-button' className={style} onClick={onClick}>
      <img src='//static.dogmate.co.kr/dogit/logo/channelio.png' />
    </button>
  );
}
