import React from 'react';
import { css } from '@emotion/css/macro';
import IconMediaPlay from '../../../../icons/IconMediaPlay';
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/react';
import SpringNumber from '../../../../components/SpringNumber';
import BadgeView from './BadgeView';

const reviewWrpStyle = css`
  margin: 50px 0 0 25px;
  h2 {
    font-size: 26px;
    line-height: 40px;
    letter-spacing: -1.79px;
    color: #42607f;
    font-weight: bold;
    margin-bottom: 24px;
  }
`;
const cntAndScoreStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 14px;
      letter-spacing: -0.52px;
      color: #939191;
      text-align: center;
    }
    h3 {
      margin-top: 12px;
      font-size: 24px;
      color: #42607f;
      font-weight: bold;
      text-align: center;
    }
  }
`;
const reviewStyle = css`
  h1 {
    size: 16px;
    letter-spacing: -0.6px;
    color: #4b4b4b;
    font-weight: bold;
  }
`;
const starRateAndRegDateWrp = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  align-items: center;
  letter-spacing: -0.45px;
  color: #939191;
  // margin-top: 8px;
`;
const starRateWrp = css`
  display: flex;
  flex-direction: row;
  margin-right: 4px;
  img {
    width: 22px;
    height: 22px;
  }
`;
const divider = css`
  width: 94%;
  height: 1px;
  background-color: #ededed;
  margin: 24px 0;
`;
const reviewDescStyle = css`
  width: 90%;
  margin-top: 15px;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.56px;
  color: #939191;
`;
const badgeWrp = css`
  display: flex;
  flex-direction: row;
  margin: 24px 0;
`;
const reviewModalBtnStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 146px;
  height: 45px;
  font-size: 14px;
  letter-spacing: -0.33px;
  text-align: center;
  color: #4b4b4b;
  border-radius: 25px;
  border: solid 1px #ededed;
  margin: 45px auto;
`;
const reviewMediaWrp = css`
  margin: 24px 0;
  overflow-y: scroll;
  video {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    object-fit: cover;
  }
  img {
    width: 72px;
    height: 72px;
    border-radius: 8px;
  }
`;
const videoWrp = css`
  position: relative;
  width: 72px;
  height: 72px;
`;
const iconWrp = css`
  position: absolute;
  width: 18px;
  height: 18px;
  bottom: 4px;
  right: 4px;
`;
const nameImprovementBadgeWrp = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;
`;

export default function ReviewViewV2({
  onClickOpenImgView,
  onClickOpenReviewView,
  onClickOpenVideoView,
  reviewList,
  totalParticipatedCnt,
  avgImprovementRate,
  reviewCnt,
  isStartReviewScoreAnimation,
}) {
  const getUserName = (userName) => {
    if (userName.length === 2) {
      return `${userName[0]}*`;
    } else if (userName.length === 3) {
      return `${userName[0]}*${userName[1]}`;
    } else {
      return `${userName[0]}**${userName[userName.length - 1]}`;
    }
  };
  return (
    <React.Fragment>
      {reviewCnt >= (process.env.REACT_APP_ENV === 'production' ? 10 : 0) ? (
        <article id='reviewScore'>
          <div className={reviewWrpStyle}>
            <h2>💫 생생 후기</h2>
            <div className={cntAndScoreStyle}>
              <div style={{ borderRight: 'solid 1px #d2d2d2' }}>
                <p>누적 참여자</p>
                <h3>
                  <SpringNumber
                    score={totalParticipatedCnt}
                    thousandSeparator={true}
                    isStart={isStartReviewScoreAnimation}
                    unit='명'
                  />
                </h3>
              </div>
              <div>
                <p>문제행동 개선율</p>
                <h3>
                  <SpringNumber
                    score={Number(avgImprovementRate)}
                    decimal={0}
                    isStart={isStartReviewScoreAnimation}
                    unit='%'
                  />
                </h3>
              </div>
            </div>
            {reviewList.map((review, idx) => (
              <section key={`review-${idx}`} className={reviewStyle}>
                <div className={divider} />
                <div className={nameImprovementBadgeWrp}>
                  <h1>
                    {`${getUserName(review.dgUserProgramInfo.dgUserInfo.userName)} (${
                      review.dgUserProgramInfo.dgPetInfo.petKind
                    } • ${moment().diff(review.dgUserProgramInfo.dgPetInfo.petBirthYm, 'years')}세)`}
                  </h1>
                  <BadgeView
                    desc={
                      review.improvement === 1
                        ? '🥰 개선 되었어요'
                        : review.improvement === 2
                        ? '😉 어느정도 개선'
                        : '🙅🏻‍♀️ 개선되지 않았어요'
                    }
                  />
                </div>
                <div className={starRateAndRegDateWrp}>{moment(review.regDate).format('YYYY-MM-DD')}</div>
                <div className={reviewMediaWrp}>
                  <Swiper noSwiping={false} slidesPerView={4}>
                    {review.beforeVideoUrl && review.isShowBeforeVideo && (
                      <SwiperSlide width='72px' style={{ marginRight: '0px' }}>
                        <div
                          className={videoWrp}
                          onClick={() => onClickOpenVideoView(review.beforeVideoUrl + '#t=0.5', review)}
                        >
                          <video src={review.beforeVideoUrl + '#t=0.5'} preload='metadata' />
                          <div className={iconWrp}>
                            <IconMediaPlay size={18} strokeColor={'#fff'} />
                          </div>
                        </div>
                      </SwiperSlide>
                    )}
                    {review.afterVideoUrl && review.isShowAfterVideo && (
                      <SwiperSlide width='72px' style={{ marginRight: '0px' }}>
                        <div
                          className={videoWrp}
                          onClick={() => onClickOpenVideoView(review.afterVideoUrl + '#t=0.5', review)}
                        >
                          <video src={review.afterVideoUrl + '#t=0.5'} preload='metadata' />
                          <div className={iconWrp}>
                            <IconMediaPlay size={18} strokeColor={'#fff'} />
                          </div>
                        </div>
                      </SwiperSlide>
                    )}
                    {review.imgLogList.map((imgLog, idx) => (
                      <SwiperSlide width='72px' style={{ marginRight: '0px' }} key={`img-${idx}`}>
                        <img src={imgLog[0]} onClick={() => onClickOpenImgView(imgLog[1], review)} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <p onClick={() => onClickOpenImgView(review.imgLogList[0][1], review)} className={reviewDescStyle}>
                  {review.review.length > 150 ? (
                    <>
                      {review.review.slice(0, 150)}&nbsp;&nbsp;… <span>더보기</span>
                    </>
                  ) : (
                    review.review
                  )}
                </p>
                <div className={badgeWrp}>
                  <BadgeView
                    desc={
                      review.dgUserProgramInfo.dgProgramInfo.programName.includes('짖음')
                        ? '🙀 짖음 개선'
                        : '😿 분리불안 개선'
                    }
                  />
                  <BadgeView desc={`❣️ ${review.missionCompleteCnt}회 참여`} />
                </div>
              </section>
            ))}
          </div>
          <div className={reviewModalBtnStyle} onClick={onClickOpenReviewView}>
            <p>후기 전체 보기 +</p>
          </div>
        </article>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
}
