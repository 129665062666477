import * as React from 'react';
import * as API from '../../../../api/api';
import moment from 'moment';
import { css } from '@emotion/css/macro';
import BadgeView from './BadgeView';
import { Swiper, SwiperSlide } from 'swiper/react';
import IconMediaPlay from '../../../../icons/IconMediaPlay';
import IconClose from '../../../../icons/IconClose';
import ImgView from './ImgView';
import VideoView from './VideoView';

const reviewStyle = css`
  h1 {
    size: 16px;
    letter-spacing: -0.6px;
    color: #4b4b4b;
    font-weight: bold;
  }
`;
const starRateAndRegDateWrp = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  align-items: center;
  letter-spacing: -0.45px;
  color: #939191;
  // margin-top: 8px;
`;
const divider = css`
  width: 94%;
  height: 1px;
  background-color: #ededed;
  margin: 24px 0;
`;
const reviewDesc = css`
  width: 90%;
  margin-top: 15px;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.56px;
  color: #4b4b4b;
`;
const badgeWrp = css`
  display: flex;
  flex-direction: row;
  margin: 24px 0;
`;
const reviewMediaWrp = css`
  margin: 24px 0;
  overflow-y: scroll;
  video {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    object-fit: cover;
  }
  img {
    width: 72px;
    height: 72px;
    border-radius: 8px;
  }
`;
const videoWrp = css`
  position: relative;
  width: 72px;
  height: 72px;
`;
const iconWrp = css`
  position: absolute;
  width: 18px;
  height: 18px;
  bottom: 4px;
  right: 4px;
`;
const nameImprovementBadgeWrp = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;
`;
const reviewViewStyle = css`
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 65535;
  overflow-y: scroll;
`;
const reviewTopStyle = css`
  position: fixed;
  z-index: 700;
  background-color: #fff;
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: #cacede 1px solid;
  p {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.6px;
    color: #535454;
    font-weight: bold;
    margin: 6px 15px;
  }
  button {
    background-color: rgba(0, 0, 0, 0);
    margin: 6px 15px 0;
  }
`;
const reviewWrp = css`
  margin-top: 52px;
`;

const loadingStyle = css`
  display: block;
  position: relative;
  width: 3rem;
  height: 3rem;
  margin: 0 auto 50px;
  opacity: 1;
  transition: 0.2s ease-in-out;
  border: 0;
  background: none;
  border-radius: 0.3rem;
  padding: 0.5rem;
  box-shadow: none;
  div {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: 0.7rem;
    left: 0.8rem;
    border: 6px solid #ff8392;
    border-radius: 50%;
    -webkit-animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ff8392 transparent transparent transparent;
  }
  div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes ring {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
const loadingReadyStyle = css`
  display: block;
  position: relative;
  margin: 100px auto;
`;

export default function TotalReviewView({ onClickCloseReviewView }) {
  const [reviewList, setReviewList] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [isOpenVideoView, setIsOpenVideoView] = React.useState(false);
  const [isOpenImgView, setIsOpenImgView] = React.useState(false);
  const [reviewInfo, setReviewInfo] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [isEnd, setIsEnd] = React.useState(false);
  const limit = 10;

  React.useEffect(() => {
    API.getReviewList({ offset, limit }).then((res) => {
      setReviewList(res.data);
    });
  }, []);

  const onScrollEvent = () => {
    const element = document.querySelector('#dogit-review-modal');
    const viewHeight = window.innerHeight;
    const currentScrollHeight = element.scrollTop + viewHeight;
    const totalScrollHeight = element.scrollHeight;

    if (currentScrollHeight === totalScrollHeight && !isEnd) {
      setIsLoading(true);
      setTimeout(() => {
        API.getReviewList({ offset: offset + 10, limit }).then((res) => {
          if (res.data.length === 0) {
            setIsEnd(true);
          } else {
            setReviewList([...reviewList, ...res.data]);
            setOffset(offset + 10);
          }
          setIsLoading(false);
        });
      }, 500);
    }
  };

  const getUserName = (userName) => {
    if (userName.length === 2) {
      return `${userName[0]}*`;
    } else if (userName.length === 3) {
      return `${userName[0]}*${userName[1]}`;
    } else {
      return `${userName[0]}**${userName[userName.length - 1]}`;
    }
  };

  const onClickOpenVideoView = (src, review) => {
    setReviewInfo({
      src,
      review: review.review,
      starRate: review.starRate,
      userName: getUserName(review.dgUserProgramInfo.dgUserInfo.userName),
      petKind: review.dgUserProgramInfo.dgPetInfo.petKind,
      age: `${moment().diff(review.dgUserProgramInfo.dgPetInfo.petBirthYm, 'years')}세`,
      missionCompleteCnt: review.missionCompleteCnt,
      badge: [
        review.dgUserProgramInfo.dgProgramInfo.programName.includes('짖음') ? '🙀 짖음 개선' : '😿 분리불안 개선',
        review.improvement === 1 ? '🥰 개선 되었어요' : review.improvement === 2 ? '😉 어느정도 개선' : '',
      ],
    });
    setIsOpenVideoView(true);
  };

  const onClickOpenImgView = (src, review) => {
    setReviewInfo({
      src,
      review: review.review,
      starRate: review.starRate,
      userName: getUserName(review.dgUserProgramInfo.dgUserInfo.userName),
      petKind: review.dgUserProgramInfo.dgPetInfo.petKind,
      age: `${moment().diff(review.dgUserProgramInfo.dgPetInfo.petBirthYm, 'years')}세`,
      missionCompleteCnt: review.missionCompleteCnt,
      badge: [
        review.dgUserProgramInfo.dgProgramInfo.programName.includes('짖음') ? '🙀 짖음 개선' : '😿 분리불안 개선',
        review.improvement === 1
          ? '🥰 개선 되었어요'
          : review.improvement === 2
          ? '😉 어느정도 개선'
          : '🙅🏻‍♀️ 개선되지 않았어요',
      ],
    });
    setIsOpenImgView(true);
  };

  const onClickCloseVideoView = () => {
    setReviewInfo({});
    setIsOpenVideoView(false);
  };

  const onClickCloseImgView = () => {
    setReviewInfo({});
    setIsOpenImgView(false);
  };

  return (
    <article className={reviewViewStyle} onScroll={onScrollEvent} id='dogit-review-modal'>
      {isOpenImgView && <ImgView reviewInfo={reviewInfo} onClickCloseImgView={onClickCloseImgView} />}
      {isOpenVideoView && <VideoView reviewInfo={reviewInfo} onClickCloseVideoView={onClickCloseVideoView} />}
      <div className={reviewTopStyle}>
        <p>후기 전체보기</p>
        <button onClick={onClickCloseReviewView}>
          <IconClose strokeColor='#b1b4c1' size={16} />
        </button>
      </div>
      <div className={reviewWrp}>
        {reviewList &&
          reviewList.map((review, idx) => (
            <section key={`review-${idx}`} className={reviewStyle} style={{ margin: '20px 0 0 24px' }}>
              <div className={nameImprovementBadgeWrp}>
                <h1>
                  {`${getUserName(review.dgUserProgramInfo.dgUserInfo.userName)} (${
                    review.dgUserProgramInfo.dgPetInfo.petKind
                  } • ${moment().diff(review.dgUserProgramInfo.dgPetInfo.petBirthYm, 'years')}세)`}
                </h1>
                <BadgeView
                  desc={
                    review.improvement === 1
                      ? '🥰 개선 되었어요'
                      : review.improvement === 2
                      ? '😉 어느정도 개선'
                      : '🙅🏻‍♀️ 개선되지 않았어요'
                  }
                />
              </div>
              <div className={starRateAndRegDateWrp}>{moment(review.regDate).format('YYYY-MM-DD')}</div>
              <div className={reviewMediaWrp}>
                <Swiper noSwiping={false} slidesPerView={4}>
                  {review.beforeVideoUrl && review.isShowBeforeVideo && (
                    <SwiperSlide width='72px' style={{ marginRight: '0px' }}>
                      <div
                        className={videoWrp}
                        onClick={() => onClickOpenVideoView(review.beforeVideoUrl + '#t=0.5', review)}
                      >
                        <video style={{ marginLeft: '0' }} src={review.beforeVideoUrl + '#t=0.5'} />
                        <div className={iconWrp}>
                          <IconMediaPlay size={18} strokeColor={'#fff'} />
                        </div>
                      </div>
                    </SwiperSlide>
                  )}
                  {review.afterVideoUrl && review.isShowAfterVideo && (
                    <SwiperSlide width='72px' style={{ marginRight: '0px' }}>
                      <div
                        className={videoWrp}
                        onClick={() => onClickOpenVideoView(review.afterVideoUrl + '#t=0.5', review)}
                      >
                        <video src={review.afterVideoUrl + '#t=0.5'} />
                        <div className={iconWrp}>
                          <IconMediaPlay size={18} strokeColor={'#fff'} />
                        </div>
                      </div>
                    </SwiperSlide>
                  )}
                  {review.imgLogList.map((imgLog, idx) => (
                    <SwiperSlide width='72px' style={{ marginRight: '0px' }} key={`img-${idx}`}>
                      <img key={`img-${idx}`} src={imgLog[0]} onClick={() => onClickOpenImgView(imgLog[1], review)} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <p className={reviewDesc}>{review.review}</p>
              <div className={badgeWrp}>
                <BadgeView
                  desc={
                    review.dgUserProgramInfo.dgProgramInfo.programName.includes('짖음')
                      ? '🙀 짖음 개선'
                      : '😿 분리불안 개선'
                  }
                />
                <BadgeView desc={`❣️ ${review.missionCompleteCnt}회 참여`} />
              </div>
              <div className={divider} />
            </section>
          ))}
        <div className={isLoading ? loadingStyle : loadingReadyStyle}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </article>
  );
}
