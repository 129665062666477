import { css } from '@emotion/css/macro';
import { DOGIT_V3_ONBOARDING_TYPE } from '../../../common/Constants';

const style = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
`;
const imgWrp = css`
  width: 185px;
  height: 185px;
  margin-bottom: 47px;
  img {
    width: 100%;
  }
`;
const descWrp = css`
  width: 100%;
  h2 {
    margin-top: 15px;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: -0.67px;
    color: #7a7c8e;
  }
`;
const descStyle = css`
  width: 80vw;
  font-size: 22px;
  line-height: 1.45;
  letter-spacing: -0.82px;
  color: #1d253b;
  b {
    color: #4c5bcd;
    font-weight: bold;
  }
`;

export default function OnboardingSubView({ currentStep }) {
  return (
    <article className={style}>
      <div className={imgWrp} style={{ width: currentStep === DOGIT_V3_ONBOARDING_TYPE.STEP_FOUR && '210px' }}>
        <img src={`//static.dogmate.co.kr/dogit/landing-v3/onboarding/dogit-v4-onboarding-step-${currentStep}.png`} />
      </div>
      <div>
        {currentStep === DOGIT_V3_ONBOARDING_TYPE.STEP_ONE ? (
          <div className={descWrp}>
            <h1 className={descStyle}>
              챌린지 시작 전,
              <br />
              <b>
                훈련의 성공률을 높이는
                <br />
                생활 규칙
              </b>
              을 알려드릴게요!
              <br />
              같이 가볼까요?
            </h1>
          </div>
        ) : currentStep === DOGIT_V3_ONBOARDING_TYPE.STEP_TWO ? (
          <div className={descWrp}>
            <h1 className={descStyle}>
              보상의 강도를 높이기 위해
              <br />
              훈련 기간에는
              <b>
                간식 급여를
                <br />
                자제
              </b>
              해주세요!
              <br />
              <br />
              보호자들의 과한 스킨십도
              <br />
              강아지들에겐 보상으로 느껴져요.
              <br />
              <b>과한 스킨십도 자제</b>하기
            </h1>
          </div>
        ) : currentStep === DOGIT_V3_ONBOARDING_TYPE.STEP_THREE ? (
          <div className={descWrp}>
            <h1 className={descStyle}>
              <b>훈련 시간은</b>
              <br />
              <b>10분을 넘기지 않도록</b>
              하고
              <br />
              끝나면 충분한 휴식 시간을 주세요
              <br />
              <br />
              모든 학습이 그러하듯,
              <br />
              <b>반복할 수록 개선의 속도</b>는 빨라져요
            </h1>
          </div>
        ) : currentStep === DOGIT_V3_ONBOARDING_TYPE.STEP_FOUR ? (
          <div className={descWrp}>
            <h1 className={descStyle}>
              <b style={{ color: '#5770e8' }}>
                미션 참여를 확인을 위해
                <br />
                반드시 챌린지 완료를
              </b>
              눌러주세요.
              <br />
              <br />
              <b style={{ color: '#ce081d' }}>불주먹 화면을 확인</b>
              하시면
              <br />
              미션 참여가 완료돼요🙂
            </h1>
            <h2>
              ❌ 챌린지를 완료하지 않으면
              <br />
              미션참여로 인정되지 않습니다
            </h2>
          </div>
        ) : (
          <></>
        )}
      </div>
    </article>
  );
}
