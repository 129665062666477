import * as React from 'react';
import { UserInfoContext } from '../../components/PageWrapper';
import { DOGIT_V3_EVENT_TYPE, DOGIT_V3_MISSION_STEP_TYPE, DOGIT_V3_PROGRAM_CODE } from '../../common/Constants';
import * as Util from '../../common/Util';
import * as API from '../../api/api';
import * as _ from 'lodash';
import ChannelService from '../../common/ChannelService';
import MissionView from './MissionViewV2';
import * as moment from 'moment';
import { fileType } from '../../common/FileType';
import LoadingView from './subview/LoadingView';

const prescriptionFormId = new Map([
  [DOGIT_V3_PROGRAM_CODE.SEPARATION_CHALLENGE, process.env.REACT_APP_ENV === 'production' ? 'Lm8HHHyx' : 'kAzl47Dl'],
  [DOGIT_V3_PROGRAM_CODE.BARK_CHALLENGE, process.env.REACT_APP_ENV === 'production' ? 'ZXgejUs7' : 'kAzl47Dl'],
]);

export default function MissionContainer({
  step,
  code,
  result,
  dgPetNo,
  dgUserProgramNo,
  dgChallengeLogNo,
  nextMissionCode,
  prescriptionNo,
}) {
  const { dgUserNo, hpNo, petName, userName, userStatus, dgUserProgramList } = React.useContext(UserInfoContext);
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  const [logRegDate, setLogRegDate] = React.useState('');
  const [formId, setFormId] = React.useState('');
  const [img, setImg] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(step);
  let programCode = '';

  const up = dgUserProgramList.filter((el) => el.dgUserProgramNo === dgUserProgramNo);
  if (!_.isEmpty(up)) {
    programCode = up[0].dgProgramInfo.programCode;
  }

  React.useEffect(() => {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(
        navigator.userAgent
      );
    if (!isMobile) {
      alert('모바일로 진행해주세요!');
      window.location.href = 'my';
    }
  }, []);

  // * 미션 완료 불주먹 애니메이션 🔥
  React.useEffect(() => {
    if (navigator.userAgent.indexOf('Android') === -1) {
      if (result && logRegDate) {
        let particles = [];
        function pop() {
          for (let i = 0; i < 40; i++) {
            const p = document.createElement('particule');
            p.style.position = 'fixed';
            p.innerText = '🔥';
            p.style.fontSize = Math.random() * 100 + 'px';
            p.x = window.innerWidth * -0.5;
            p.y = window.innerHeight;
            p.vel = {
              x: (Math.random() - 0.5) * 5,
              y: Math.random() * -12 - 20,
            };
            p.mass = Math.random() * 0.2 + 0.8;
            particles.push(p);
            p.style.transform = `translate(${p.x}px, ${p.y}px)`;
            document.getElementById('background').appendChild(p);
          }
        }

        function render() {
          for (let i = particles.length - 1; i--; i > -1) {
            const p = particles[i];
            p.style.transform = `translate3d(${p.x}px, ${p.y}px, 1px)`;

            p.x += p.vel.x;
            p.y += p.vel.y;

            p.vel.y += 0.5 * p.mass;
            if (p.y > window.innerHeight * 2) {
              p.remove();
              particles.splice(i, 1);
            }
          }
          requestAnimationFrame(render);
        }
        pop();
        window.setTimeout(render, 700);
      }
    }
  });

  React.useEffect(() => {
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.VISITING);
  }, []);

  React.useEffect(() => {
    if (code) {
      API.getChallengeFormId(code)
        .then((res) => setFormId(res.data))
        .catch(API.errorHandler);
    }
  }, []);

  React.useEffect(() => {
    if (currentStep === DOGIT_V3_MISSION_STEP_TYPE.COMPLETE) {
      API.getChallengeLogRegDate(dgChallengeLogNo).then((res) => {
        setLogRegDate(moment(res.data).format('YYYY-MM-DD HH:mm:ss'));
      });
      Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.MISSION_COMPLETE);
      window.dataLayer.push({
        event: 'complete',
      });
    }
  });

  React.useEffect(() => {
    if (currentStep === DOGIT_V3_MISSION_STEP_TYPE.COMPLETE) {
      ChannelService.boot({
        pluginKey: '2809822e-6edb-481d-91a9-76a55c95a057',
        customLauncherSelector: '#dogit-channel-io-button',
        hideChannelButtonOnBoot: true,
      });
      return () => {
        ChannelService.shutdown();
      };
    }
  }, [currentStep]);

  React.useEffect(() => {
    if (result && nextMissionCode && currentStep === DOGIT_V3_MISSION_STEP_TYPE.COMPLETE)
      API.updateChallengeLog({
        dgChallengeLogNo,
        nextMissionCode,
        currentMissionCode: code,
      }).catch((err) => {
        API.errorHandler(err);

        if (_.get(err, 'response.data.error') || err.toString() === '접근 권한이 없습니다.') {
          window.location.href = '/my';
        }
      });
  }, [result, nextMissionCode, currentStep]);

  React.useEffect(() => {
    if (prescriptionNo) {
      API.createPrescription({ dgUserProgramNo, prescriptionNo }).catch(API.errorHandler);
      API.updateChallengeLog({ dgChallengeLogNo, currentMissionCode: 'onboarding', nextMissionCode: code }).catch(
        API.errorHandler
      );
    }
  }, [prescriptionNo]);

  React.useEffect(() => {
    if (currentStep === DOGIT_V3_MISSION_STEP_TYPE.AUTHENTICATION) {
      API.getAuthImgURL({
        dgChallengeLogNo,
      })
        .then((res) => {
          if (res.data) {
            setIsLoading(true);
            if (!!res.data[1]) {
              setImg(res.data[1]);
            } else {
              setIsLoading(false);
            }
          }
        })
        .catch(API.errorHandler);
    }
  }, [currentStep]);

  const getFileType = (data) => {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onloadend = (event) => {
          const { result } = event.target;
          const buf = result instanceof Uint8Array ? result : new Uint8Array(result);
          const type = fileType(buf);
          resolve(type);
        };
        fileReader.readAsArrayBuffer(data.slice(0, 100));
      } catch (err) {
        reject(err);
      }
    });
  };

  const onChangeImg = (event) => {
    const file = event.target.files[0];
    const exe = file.name.split('.').pop();
    const fileName = `${moment().format('HHmmss')}.${exe}`;
    let mimeType = '';

    setIsLoading(true);

    getFileType(file)
      .then((fileType) => {
        mimeType = fileType.mime;
      })
      .then(() =>
        API.createAuthImgURL({
          dgChallengeLogNo,
          mimeType,
          fileName,
        })
      )
      .then((res) => {
        const url = res.data;
        API.uploadAWSAuthImgURL(url, file)
          .then(() => {
            return API.uploadAuthImgURL({
              dgChallengeLogNo,
              fileName: fileName.toLowerCase(),
            });
          })
          .then(() => {
            const reader = new FileReader();
            reader.onload = (e) => {
              setImg(e.target.result);
            };

            reader.readAsDataURL(event.target.files[0]);
          })
          .catch(API.errorHandler);
      })
      .catch(API.errorHandler);
  };

  const onClickOpenMenu = () => {
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.MENU_BTN);
    setIsOpenMenu(true);
  };

  const onClickCloseMenu = () => {
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    setIsOpenMenu(false);
  };

  const onClickGotoMy = () => {
    // Util.sendGaEvent(`[도깃_v3]미션페이지_마이`);
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.MY_BTN, '/my');
  };

  const onClickGotoChallengeInfo = (up) => {
    // Util.sendGaEvent(`[도깃_v4]미션페이지_챌린지`);
    Util.createEventLog(dgUserNo, hpNo, DOGIT_V3_EVENT_TYPE.CHALLENGE_INFO_BTN, `/challenge-info?up=${up}`);
  };

  const onClickGotoCompleteView = () => {
    if (!img) {
      return alert('오늘 참여한 미션을 인증해주세요');
    }

    setCurrentStep(DOGIT_V3_MISSION_STEP_TYPE.COMPLETE);
  };

  return (
    <>
      {isLoading && <LoadingView alpha={0.9} />}
      <MissionView
        step={currentStep}
        result={result}
        id={currentStep === DOGIT_V3_MISSION_STEP_TYPE.ONBOARDING ? prescriptionFormId.get(programCode) : formId}
        dgPetNo={dgPetNo}
        userStatus={userStatus}
        userName={userName}
        petName={petName}
        hpNo={hpNo}
        isOpenMenu={isOpenMenu}
        onClickOpenMenu={onClickOpenMenu}
        onClickCloseMenu={onClickCloseMenu}
        onClickGotoMy={onClickGotoMy}
        dgChallengeLogNo={dgChallengeLogNo}
        dgUserProgramNo={dgUserProgramNo}
        code={code}
        programName={up[0].dgProgramInfo.programName}
        logRegDate={logRegDate}
        img={img}
        onChangeImg={onChangeImg}
        setIsLoading={setIsLoading}
        onClickGotoCompleteView={onClickGotoCompleteView}
        onClickGotoChallengeInfo={onClickGotoChallengeInfo}
      />
      {/* {missionStep === DOGIT_V3_MISSION_STEP_TYPE.COMPLETE && <ChannelButton />} */}
    </>
  );
}
