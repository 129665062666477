import { css } from '@emotion/css/macro';
import Color from '../../common/Color';
import * as _ from 'lodash';
import { DOGIT_V3_CURRENT_STEP_TYPE, DOGIT_V3_PROGRAM_BTN_ACTION_TYPE } from '../../common/Constants';
import StyleConstants from '../../common/StyleConstants';
import TypeformView from '../typeform/view/TypeformView';

const style = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  h1 {
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -0.9px;
    color: #42607f;
    text-align: center;
    b {
      font-weight: bold;
    }
  }
`;
const ticketTop = css`
  width: 273px;
  height: 155px;
  border-radius: 15px 15px 0 0;
  background: linear-gradient(130deg, #4855d1 45%, #50e3c2 123%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  p {
    font-size: 16px;
    line-height: 1.88;
    letter-spacing: -0.6px;
    color: #50e3c2;
  }
  h2 {
    font-size: 48px;
    font-weight: bold;
    line-height: 0.63;
    letter-spacing: -1.8;
    color: ${Color.WHITE};
    margin-top: 4px;
  }
`;
const ticketBottom = css`
  width: 273px;
  height: 155px;
  background-color: ${Color.WHITE};
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  h4 {
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: -0.6px;
    color: #42607f;
    margin-left: 29px;
  }
  h2 {
    color: #4855d1;
    font-size: 30px;
    line-height: 0.73;
    letter-spacing: -1.12px;
    font-weight: bold;
  }
`;
const flexRow = css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 7px 0 0 29px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    border-radius: 5px;
    background: linear-gradient(116deg, #fad961 13%, #f76b1c 143%);
    color: #000;
    height: 22px;
    border: 0;
    padding: 1px;
    margin-left: 4px;
    font-weight: bold;
  }
  h3 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.82px;
    color: #4855d1;
  }
  s {
    font-size: 16px;
    letter-spacing: -0.6px;
    color: #4855d1;
    margin-right: 6px;
  }
`;
const desc = css`
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: -0.45px;
  text-align: center;
  color: #747878;
  margin-top: 20px;
`;
const submitBtn = css`
  background-color: #f48c95;
  width: 224px;
  height: 50px;
  border-radius: 24px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.43px;
  color: ${Color.WHITE};
`;

export default function CSApplicationView({ hpNo, currentStep, type, onClickGotoLanding, requestTypeForm, prevUrl }) {
  return (
    <>
      {currentStep === DOGIT_V3_CURRENT_STEP_TYPE.APPLICATION ? (
        <TypeformView formId='n4PwGSoF' requestTypeForm={requestTypeForm} type={type} hpNo={hpNo} prevUrl={prevUrl} />
      ) : (
        <CustomerServiceCompleteView type={type} onClickGotoLanding={onClickGotoLanding} />
      )}
    </>
  );
}

function CustomerServiceCompleteView({ type, onClickGotoLanding }) {
  const programType = String(type).length > 1 ? parseInt(String(type)[1]) : type;

  const programWrapper = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 87.5%;
    height: 220px;
    border: solid 1px #979797;
    border-radius: 8px;
    background: linear-gradient(113deg, #4755d0 -2%, #6431bc 112%);
    left: 0;
    margin: 120px auto 10px;
    h4 {
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.6px;
      margin: 20px 20px 8px;
      color: ${Color.WHITE};
      b {
        font-weight: bold;
      }
    }
    h3 {
      font-size: 22px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -0.82px;
      margin: 12px 20px 8px;
      color: ${Color.WHITE};
    }
  `;
  const imgWrapper = css`
    height: 30px;
    width: 100%;
    margin: 4px 0 8px 20px;
    img {
      height: 100%;
    }
  `;
  const priceStyle = css`
    margin: 12px 0 0 20px;
    font-size: 30px;
    font-weight: bold;
    line-height: 0.73;
    letter-spacing: -1.12px;
    color: ${Color.WHITE};
    s {
      font-weight: normal;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.6px;
      color: #e1e1e1;
      margin-left: 4px;
    }
  `;
  const pricePerMonthStyle = css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 4px 0 0 20px;
    span {
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.6px;
      color: ${Color.WHITE};
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      border-radius: 5px;
      background: linear-gradient(116deg, #fad961 13%, #f76b1c 143%);
      color: #000;
      height: 22px;
      border: 0;
      padding: 1px;
      margin-left: 4px;
      font-weight: bold;
    }
  `;
  const completeImgWrapper = css`
    display: block;
    left: 0;
    margin: -143px auto 15px;
    width: 180px;
    height: 124px;
    img {
      width: 100%;
      height: 100%;
    }
  `;
  const descWrapper = css`
    line-height: 1.38;
    text-align: center;
    letter-spacing: -0.6px;
    color: #747878;
    b {
      font-weight: bold;
    }
  `;
  const program = [
    { month: '3개월 무제한 이용', rate: '40%', beforePrice: '70,000', afterPrice: '49,000', perMon: '16,333' },
    { month: '2개월 무제한 이용', rate: '30%', beforePrice: '50,000', afterPrice: '35,000', perMon: '17,500' },
    { month: '1개월 무제한 이용', rate: '30%', beforePrice: '20,000', afterPrice: '14,000' },
    { month: '2개월 무제한 이용', rate: '30%', beforePrice: '30,000', afterPrice: '21,000', perMon: '11,500' },
    { month: '6개월 무제한 이용', rate: '47%', beforePrice: '170,000', afterPrice: '90,000', perMon: '15,000' },
    { month: '2개월 무제한 이용', rate: '30%', beforePrice: '50,000', afterPrice: '35,000', perMon: '17,500' },
  ];
  return (
    <section className={style}>
      <header>
        <h1>
          <b>
            상담신청이
            <br />
            완료
          </b>
          되었습니다 🙋🏻‍♀️
        </h1>
      </header>
      {type !== DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.CUSTOMER_SERVICE ? (
        <div className={programWrapper}>
          <div className={completeImgWrapper}>
            <img src='//static.dogmate.co.kr/dogit/landing-v3/program-application/dogit-v3-cs-complete.png' />
          </div>
          {programType === DOGIT_V3_PROGRAM_BTN_ACTION_TYPE.ALL_IN_ONE ? <h4>사회화, 분리불안 예방, 산책 매너</h4> : <></>}
          <div className={imgWrapper}>
            <img src={`//static.dogmate.co.kr/dogit/landing-v3/program-detail/v3.1/dogit-v3-title-${programType}.png`} />
          </div>
          <h3>{program[programType - 1].month}</h3>
          <h2 className={priceStyle}>
            {`${program[programType - 1].afterPrice}원`}
            <s>{`정가 ${program[programType - 1].beforePrice}원`}</s>
          </h2>
          {program[programType - 1].perMon && (
            <div className={pricePerMonthStyle}>
              <span>{`월 ${program[programType - 1].perMon}원`}</span>
              <div>{program[programType - 1].rate}</div>
            </div>
          )}
        </div>
      ) : (
        <div style={{ margin: '0 0 15px' }} />
      )}
      <div className={descWrapper}>
        <b>최대한 빠르게 상담을 위해 연락드리겠습니다.</b>
      </div>
      <button className={submitBtn} onClick={_.debounce(onClickGotoLanding, 300)}>
        홈으로
      </button>
    </section>
  );
}
