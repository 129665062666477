import React from 'react';
import { css } from '@emotion/css/macro';
import StyleConstants from '../../../common/StyleConstants';
import * as _ from 'lodash';
import { DOGIT_V3_EVENT_TYPE } from '../../../common/Constants';
import moment from 'moment';
import ImgView from '../../program-detail/view/sub-view/ImgView';
import VideoView from '../../program-detail/view/sub-view/VideoView';
import TotalReviewView from '../../program-detail/view/sub-view/TotalReviewView';
import ReviewViewV2 from '../../program-detail/view/sub-view/ReviewViewV2';

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;
const style = css`
  ${flexColumn};
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin-bottom: 100px;
  margin-top: 60px;
`;
const imgStyle = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  height: 100%;
`;
const ctaBtnWrp = css`
  margin: -1px 0 -2px;
  width: 100%;
  img {
    width: 100%;
  }
`;
const btnWrp = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  position: fixed;
  bottom: 22px;
  width: 100%;
  z-index: 65535;
  img {
    width: 90%;
    margin: 0 5%;
  }
`;
const reviewWrp = css`
  padding-bottom: 60px;
  background-color: #7462f4;
`;
const reviewSlideStyle = css`
  background-color: #7462f4;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  overflow-y: hidden;
  margin-top: -420px;
  height: 550px;
  @media (min-width: 390px) {
    height: 540px;
    margin-top: -450px;
  }
  @media (min-width: 420px) {
    height: 590px;
    margin-top: -590px;
  }
  @media (min-width: 470px) {
    height: 610px;
    margin-top: -560px;
  }
  @media (min-width: 640px) {
    height: 550px;
    margin-top: -750px;
  }
`;
const slideStyle = css`
  display: block;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: calc(100% - 24px);
  margin-left: 24px;
  margin-top: 20px;
`;
const reviewStyle = css`
  background-color: #fff;
  padding: 23px 0 23px 13px;
  border-radius: 15px;
  width: 87%;
  display: flex;
  flex-direction: column;
  height: 110px;
  justify-content: space-between;
  p {
    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.56px;
    color: #646464;
    margin-right: 12px;
    word-break: keep-all;
  }
  div {
    font-size: 12px;
    line-height: 21px;
    letter-spacing: -0.45px;
    color: #4755d0;
    margin-top: 4px;
    text-align: left;
  }
`;
const imgGifWrp = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const gifStyle = css`
  width: 73%;
  border-radius: 15px;
  border: 1px solid #979797;
  margin: 13px 0 62px;
`;

export default function ProgramDetailAdView({
  ver,
  onClickOpenModal,
  isOpenModal,
  isCta,
  isOpenMenu,
  isOpenTopBanner,
  reviewList,
  totalParticipatedCnt,
  avgImprovementRate,
  reviewCnt,
  isStartReviewScoreAnimation,
}) {
  const [isOpenVideoView, setIsOpenVideoView] = React.useState(false);
  const [isOpenImgView, setIsOpenImgView] = React.useState(false);
  const [isOpenReviewView, setIsOpenReviewView] = React.useState(false);
  const [reviewInfo, setReviewInfo] = React.useState({});
  const [offsetY, setOffsetY] = React.useState(0);

  React.useEffect(() => {
    if (offsetY !== 0 && !isOpenImgView && !isOpenVideoView && !isOpenReviewView) {
      document.body.style.position = '';
      window.scrollTo(0, offsetY);
    }
  }, [isOpenImgView, isOpenVideoView, isOpenReviewView]);

  const getUserName = (userName) => {
    if (userName.length === 2) {
      return `${userName[0]}*`;
    } else if (userName.length === 3) {
      return `${userName[0]}*${userName[1]}`;
    } else {
      return `${userName[0]}**${userName[userName.length - 1]}`;
    }
  };

  const onClickOpenVideoView = (src, review) => {
    setOffsetY(window.scrollY);
    document.body.style.position = 'fixed';
    setReviewInfo({
      src,
      review: review.review,
      starRate: review.starRate,
      userName: getUserName(review.dgUserProgramInfo.dgUserInfo.userName),
      petKind: review.dgUserProgramInfo.dgPetInfo.petKind,
      age: `${moment().diff(review.dgUserProgramInfo.dgPetInfo.petBirthYm, 'years')}세`,
      missionCompleteCnt: review.missionCompleteCnt,
      badge: [
        review.dgUserProgramInfo.dgProgramInfo.programName.includes('짖음') ? '🙀 짖음 개선' : '😿 분리불안 개선',
        review.improvement === 1
          ? '🥰 개선 되었어요'
          : review.improvement === 2
          ? '😉 어느정도 개선'
          : '🙅🏻‍♀️ 개선되지 않았어요',
      ],
    });
    setIsOpenVideoView(true);
  };

  const onClickOpenImgView = (src, review) => {
    setOffsetY(window.scrollY);
    document.body.style.position = 'fixed';
    setReviewInfo({
      src,
      review: review.review,
      starRate: review.starRate,
      userName: getUserName(review.dgUserProgramInfo.dgUserInfo.userName),
      petKind: review.dgUserProgramInfo.dgPetInfo.petKind,
      age: `${moment().diff(review.dgUserProgramInfo.dgPetInfo.petBirthYm, 'years')}세`,
      missionCompleteCnt: review.missionCompleteCnt,
      badge: [
        review.dgUserProgramInfo.dgProgramInfo.programName.includes('짖음') ? '🙀 짖음 개선' : '😿 분리불안 개선',
        review.improvement === 1
          ? '🥰 개선 되었어요'
          : review.improvement === 2
          ? '😉 어느정도 개선'
          : '🙅🏻‍♀️ 개선되지 않았어요',
      ],
    });
    setIsOpenImgView(true);
  };

  const onClickOpenReviewView = () => {
    setOffsetY(window.scrollY);
    document.body.style.position = 'fixed';
    setIsOpenReviewView(true);
  };

  const onClickCloseVideoView = () => {
    setReviewInfo({});
    setIsOpenVideoView(false);
  };

  const onClickCloseImgView = () => {
    setReviewInfo({});
    setIsOpenImgView(false);
  };

  const onClickCloseReviewView = () => {
    setIsOpenReviewView(false);
  };

  return (
    <>
      {isOpenImgView && <ImgView reviewInfo={reviewInfo} onClickCloseImgView={onClickCloseImgView} />}
      {isOpenVideoView && <VideoView reviewInfo={reviewInfo} onClickCloseVideoView={onClickCloseVideoView} />}
      {isOpenReviewView && <TotalReviewView onClickCloseReviewView={onClickCloseReviewView} />}
      <section className={style} style={{ marginTop: isOpenTopBanner && '100px' }}>
        <img className={imgStyle} src={`//static.dogmate.co.kr/dogit/v4/detail-v2/landing-1.png`} />
        <img
          className={imgStyle}
          src={`//static.dogmate.co.kr/dogit/v4/detail-v2/landing-2${ver === 2 ? '-v2' : ''}.png`}
        />
        <div className={imgGifWrp}>
          <img className={imgStyle} src='//static.dogmate.co.kr/dogit/v4/detail-v2/landing-3.png' />
          <img className={gifStyle} src='//static.dogmate.co.kr/dogit/v4/detail-v2/gif.gif' />
        </div>
        <img className={imgStyle} src={`//static.dogmate.co.kr/dogit/v4/detail-v2/landing-4-v2.png`} />
        <ReviewViewV2
          onClickOpenImgView={onClickOpenImgView}
          onClickOpenReviewView={onClickOpenReviewView}
          onClickOpenVideoView={onClickOpenVideoView}
          reviewList={reviewList}
          totalParticipatedCnt={totalParticipatedCnt}
          avgImprovementRate={avgImprovementRate}
          reviewCnt={reviewCnt}
          isStartReviewScoreAnimation={isStartReviewScoreAnimation}
        />
        <div className={imgStyle}>
          <img className={imgStyle} src='//static.dogmate.co.kr/dogit/v4/detail-v2/landing-5-v2.png' />
        </div>
        <div id={'landing-6'} className={imgStyle}>
          <img style={{ width: '100%' }} src={`//static.dogmate.co.kr/dogit/v4/detail-v2/landing-6.png`} />
        </div>
        <img className={imgStyle} src={`//static.dogmate.co.kr/dogit/v4/detail-v2/landing-7.png`} />
        {/* <FooterView /> */}
      </section>
      {!isOpenModal && isCta && !isOpenMenu && !isOpenImgView && !isOpenVideoView && !isOpenReviewView && (
        <div className={btnWrp}>
          <img
            id='floating-btn'
            onClick={() => onClickOpenModal(DOGIT_V3_EVENT_TYPE.CTA_FLOATING)}
            src={`//static.dogmate.co.kr/dogit/v4/detail/dogit-v4-detail-cta.png`}
          />
        </div>
      )}
    </>
  );
}
