import { css } from '@emotion/css';
import Color from '../common/Color';
import * as _ from 'lodash';
import { DOGIT_V3_PROGRAM_TYPE } from '../common/Constants';
import IconClose from '../icons/IconClose';
import StyleConstants from '../common/StyleConstants';

const program = [
  {
    type: DOGIT_V3_PROGRAM_TYPE.SOCIALIZATION,
    name: '사회화 교육 1개월',
    beforePrice: '23,330',
    afterPrice: '16,000',
    rate: 31,
    selectedBg: 'linear-gradient(128deg, #ffbc35 57%, #fff600 93%)',
  },
  {
    type: DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY,
    name: '분리불안 뿌시기 챌린지',
    beforePrice: '',
    afterPrice: '29,000',
    rate: 67,
    selectedBg: 'linear-gradient(116deg, #4855d1 42%, #655ae3 94%)',
  },
  {
    type: DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING,
    name: '배변 교육 1개월',
    beforePrice: '20,000',
    afterPrice: '14,000',
    rate: 30,
    selectedBg: 'linear-gradient(297deg, #73dcc5 9%, #2ec9d1 55%)',
  },
  {
    type: DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING,
    name: '산책 매너 교육 2개월',
    beforePrice: '30,000',
    afterPrice: '21,000',
    rate: 30,
    selectedBg: 'linear-gradient(294deg, #f5c3a0 13%, #f47b96 47%)',
  },
  {
    type: DOGIT_V3_PROGRAM_TYPE.BARK,
    name: '짖음 문제 뿌시기 챌린지',
    beforePrice: '',
    afterPrice: '29,000',
    rate: 0,
    selectedBg: 'linear-gradient(to bottom, #a04292 34%, #410657 123%)',
  },
  {
    type: DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE,
    name: 'All in One',
    beforePrice: '85,000',
    afterPrice: '45,000',
    rate: 47,
    selectedBg: 'linear-gradient(to bottom, #48bcf6 34%, #3999f2)',
  },
  {
    type: DOGIT_V3_PROGRAM_TYPE.CUSTOMER_SERVICE_PROGRAM,
    name: '훈련사와 전화 상담',
    beforePrice: '30,000',
    afterPrice: '25,000',
    rate: 64,
    selectedBg: '',
  },
];

const deem = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
`;
const style = css`
  position: fixed;
  z-index: 60;
  bottom: 0;
  width: 100vw;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  border-radius: 30px 30px 0 0;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.08);
  background-color: ${Color.WHITE};
  padding-top: 30px;
  padding-bottom: 30px;
`;
const titleWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 21px;
  h1 {
    font-size: 22px;
    line-height: 1.36;
    letter-spacing: -0.82px;
    color: #42607f;
    margin-left: 20px;
    b {
      font-weight: bold;
    }
  }
`;
const optionStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 87.5%;
  height: 90px;
  border-radius: 10px;
  border: solid 1px #e7ebf4;
  left: 0;
  margin: 11px auto;
  img {
    width: 55px;
    height: 55px;
    margin-right: 18px;
  }
  h2 {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.52px;
    font-weight: bold;
  }
  s {
    p {
      font-size: 12px;
      letter-spacing: -0.45px;
      margin-right: 4px;
    }
  }
  h2 {
    margin: 0 4px 0 0;
  }
`;
const option = css`
  ${optionStyle};
  h2 {
    color: #42607f;
  }
  s {
    p {
      color: #42607f;
    }
  }
  text-decoration-color: #42607f;
`;
const rate = css`
  /* width: 30px; */
  /* margin-top: 1px; */
  padding: 3px 3px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.39px;
  border-radius: 5px;
`;
const descWrapper = css`
  h3 {
    color: #fff27b;
    font-size: 10px;
    letter-spacing: -0.37px;
  }
  margin-left: 19px;
`;
const priceWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  s {
    p::before {
      content: '정가 ';
    }
    p::after {
      content: '원';
    }
  }
`;
const closeWrapper = css`
  /* position: absolute; */
  /* right: 20px; */
  /* top: 36px; */
`;
const buttonWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
`;
const bottomBtn = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 90%;
  height: 50px;
  box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.82px;
  color: ${Color.WHITE};
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #f48c95;
  p {
    color: #4755d1;
  }
`;
const customerServiceBtn = css`
  ${bottomBtn}
  color: #e47982;
  background-color: ${Color.WHITE};
  border: solid 1px #e46872;
`;
const programListStyle = css`
  height: 250px;
  margin: 10px 0 20px;
  overflow-y: scroll;
`;
const refundImgWrp = css`
  width: 100px;
  height: 20px;
  img {
    width: 100%;
    height: 100%;
  }
`;
const chlngBadgeStyle = css`
  color: #f8e71c;
  font-size: 11px;
  line-height: 1.6;
  letter-spacing: -0.36px;
  border-radius: 5px;
  background-image: linear-gradient(96deg, #4855d1 12%, #6559e2 145%);
  text-align: center;
`;

export default function PurchaseModalView({
  type,
  selected,
  onClickChangeSelected,
  onClickSubmit,
  onClickCloseModal,
  listMode,
  onClickSubmitCSProgram,
}) {
  const isOptionProgram = (optionType) => {
    if (
      type === DOGIT_V3_PROGRAM_TYPE.CHALLENGE &&
      (optionType === DOGIT_V3_PROGRAM_TYPE.SEPARATION_ANXIETY || optionType === DOGIT_V3_PROGRAM_TYPE.BARK)
    )
      return true;
    // if (optionType === DOGIT_V3_PROGRAM_TYPE.CUSTOMER_SERVICE_PROGRAM) return true;
    return optionType === type;
  };

  return (
    <section>
      <div className={deem}>
        <article className={style}>
          <div className={titleWrapper}>
            <h1>
              도전! <b>14일 챌린지</b>🔥
            </h1>
            <div id='modal-close-btn' className={closeWrapper} onClick={onClickCloseModal}>
              <IconClose size={20} strokeColor={'#b1b4c1'} />
            </div>
          </div>
          {/* {!listMode && <FreeOptionView type={type} selected={selected} onClickChangeSelected={onClickChangeSelected} />} */}
          <div className={listMode ? programListStyle : ''}>
            {program.map((el, idx) => (
              <article
                id={`program-type-${el.type}`}
                className={option}
                key={idx}
                style={{
                  display: listMode
                    ? el.type === DOGIT_V3_PROGRAM_TYPE.TOILET_TRAINING ||
                      el.type === DOGIT_V3_PROGRAM_TYPE.WALKING_TRAINING ||
                      el.type === DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE
                      ? 'none'
                      : 'flex'
                    : isOptionProgram(el.type)
                    ? 'flex'
                    : 'none',
                  border: el.type === selected && 'solid 2px #4855d1',
                  marginTop: el.type === selected && '11px',
                  marginBottom: el.type === selected && '11px',
                  height: el.type === selected && '88px',
                }}
                onClick={() => onClickChangeSelected(el.type)}
              >
                <div className={descWrapper}>
                  {el.type === DOGIT_V3_PROGRAM_TYPE.ALL_IN_ONE && (
                    <h3 style={{ color: el.type === selected ? '#fff27b' : '#3ab89c' }}>#사회화 #분리불안예방 #짖음</h3>
                  )}
                  <div className={chlngBadgeStyle}>14일 매일 출석 시 1OO% 환급</div>
                  <h2>{el.name}</h2>
                  <div className={priceWrapper}>
                    {el.beforePrice && (
                      <s>
                        <p>{el.beforePrice}</p>
                      </s>
                    )}
                    <h2>{el.afterPrice}원</h2>
                  </div>
                </div>
                <img src={`//static.dogmate.co.kr/dogit/v4/detail/dogit-v4-detail-modal-${el.type}.png`} />
              </article>
            ))}
          </div>
          <div className={buttonWrapper}>
            <button
              onClick={_.debounce(
                selected === DOGIT_V3_PROGRAM_TYPE.CUSTOMER_SERVICE_PROGRAM ? onClickSubmitCSProgram : onClickSubmit,
                300
              )}
              className={bottomBtn}
              id='submit'
            >
              결제하고&nbsp;<p>전액 환급</p>&nbsp;도전!
            </button>
            {/* <button onClick={() => onClickGotoCS()} className={customerServiceBtn}>
              상담 먼저 하고 싶어요!
            </button> */}
          </div>
        </article>
      </div>
    </section>
  );
}
