import React from 'react';
import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import IconClose from '../../../icons/IconClose';

export default function NotionTopView({onClickClose}) {
  const style = css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: ${Color.LIGHT_WHITE};
    border-bottom: 1px solid ${Color.WHITE};
    width: 100%;
    height: 48px;
    button {
      position: absolute;
      top: 15px;
      right: 15px;
      background-color:none;
    }
  `

  return (
    <div className={style}>
      <button style={{backgroundColor: 'rgba(0, 0, 0, 0)'}} onClick={onClickClose}>
        <IconClose size='15' strokeColor={Color.LIGHT_GRAY} />
      </button>
    </div>
  )
}