import { css } from '@emotion/css/macro';
import StyleConstants from '../../../common/StyleConstants';
import IconClose from '../../../icons/IconClose';

const style = css`
  background-color: rgba(0, 0, 0, 0.7);
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 66535;
`;
const wrp = css`
  background: linear-gradient(#4755d0 40%, #4e5eed 40%, #4e5eed 70%, #4755d0 70%);
  width: 100vw;
  height: 100vw;
  border: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const border = css`
  width: 95vw;
  height: 95vw;
  border: solid 1px #808ae0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const certImgWrp = css`
  width: 62%;
  margin: 4% 0;
  img {
    width: 100%;
  }
`;
const title = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #adbcf0;
  h1 {
    color: #50e3c2;
    font-weight: bold;
    margin: 2.4% 0;
  }
`;
const profileWrp = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.75px;
    margin: 1.9% 0;
  }
`;
const imgWrp = css`
  width: 16.8vw;
  height: 16.8vw;
  background-size: cover;
  border-radius: 36px;
  border: solid 1px #2a37a9;
`;
const medalImgWrp = css`
  width: 18px;
  height: 26px;
  margin: 6.7% 0 -3.8%;
  img {
    width: 100%;
    height: 100%;
  }
  z-index: 32;
`;
const descStyle = css`
  font-size: 16px;
  text-align: center;
  line-height: 22.5px;
  letter-spacing: -0.46px;
  color: #bcc3ff;
  margin: 6% 0 0;
`;
const dogitImgWrp = css`
  width: 34px;
  height: 14px;
  position: relative;
  left: calc(50vw - 8.2%);
  bottom: -4.8%;
  img {
    width: 100%;
    height: 100%;
  }
`;
const closeBtn = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 2.8%;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
`;

export default function CertModalView({ programName, startYmd, endYmd, profileImg, userName, onClick }) {
  return (
    <article className={style}>
      <div className={wrp}>
        <div className={border}>
          <div className={certImgWrp}>
            <img src='//static.dogmate.co.kr/dogit/landing-v3/my/dogit-v4-cert-title.png' />
          </div>
          <div className={title}>
            <h1>{programName}</h1>
            <div>
              {startYmd} ~ {endYmd}
            </div>
            <div className={profileWrp}>
              <div className={medalImgWrp}>
                <img src='//static.dogmate.co.kr/dogit/landing-v3/my/dogit-v4-cert-medal.png' />
              </div>
              <div className={imgWrp} style={{ backgroundImage: `url(${profileImg})` }} />
              <p>{userName}</p>
            </div>
          </div>
          <div className={descStyle}>
            <p>
              강아지를 이해하고 아끼는 마음으로
              <br />
              문제행동 개선을 위해 노력하였으므로
              <br />이 증서를 수여합니다.
            </p>
          </div>
          <div className={dogitImgWrp}>
            <img src='//static.dogmate.co.kr/dogit/landing-v3/my/dogit-v4-cert-dogit.png' />
          </div>
        </div>
      </div>
      <button className={closeBtn} onClick={onClick}>
        <IconClose size={15} strokeColor='#fff' />
        &nbsp; 닫기
      </button>
    </article>
  );
}
