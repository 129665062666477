import React from 'react';
import { css } from '@emotion/css/macro';
import Color from '../../../common/Color';
import IconKakao from '../../../icons/IconKakao';
import IconPrev from '../../../icons/IconPrev';
import StyleConstants from '../../../common/StyleConstants';

export default function LoginView({ onClickLogin, onClickBack, kakaoLogin }) {
  const style = css`
    position: relative;
    text-align: center;
    max-width: ${StyleConstants.MAX_WIDTH}px;
    min-width: 320px;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    p {
      margin-top: 83px;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.39;
      letter-spacing: -0.59px;
      text-align: center;
      b {
        font-weight: 700;
      }
    }
  `;
  const stylePrev = css`
    position: absolute;
    top: 10px;
    left: 10px;
    button {
      background: none;
      width: 30px;
      height: 30px;
    }
  `;
  const styleKakaoWrapper = css`
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  const styleKakao = css`
    margin-top: 21px;
    width: 260px;
    height: 52px;
    border-radius: 26px;
    background-color: ${Color.KAKAO_YELLOW};
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      margin-left: 14px;
      color: ${Color.KAKAO_BROWN};
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.52px;
    }
  `;
  const styleBottomDog = css`
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 78%;
    min-width: 252px;
    img {
      width: 100%;
    }
  `;

  return (
    <div className={style}>
      <div className={stylePrev}>
        <button onClick={onClickBack}>
          <IconPrev width='12' height='20' strokeColor={Color.GRAY} />
        </button>
      </div>
      <p>
        로그인 하고
        <br />
        <b>챌린지</b>에 도전하세요!
      </p>
      <div className={styleKakaoWrapper}>
        <button className={styleKakao} onClick={kakaoLogin}>
          <IconKakao width={30} height={28} strokeColor={Color.KAKAO_YELLOW} backgroundColor={Color.KAKAO_BROWN} />
          <div>카카오 계정으로 로그인</div>
        </button>
      </div>
      <div className={styleBottomDog}>
        <img src='//static.dogmate.co.kr/dogit/illust/illust-blue-dog-upper-body-252x284.svg' />
      </div>
    </div>
  );
}
