import React from 'react';
import { css } from '@emotion/css/macro';
import StyleConstants from '../../../common/StyleConstants';
import * as _ from 'lodash';
import { DOGIT_V3_EVENT_TYPE } from '../../../common/Constants';
import moment from 'moment';
import ReviewViewV2 from './sub-view/ReviewViewV2';
import ImgView from './sub-view/ImgView';
import VideoView from './sub-view/VideoView';
import TotalReviewView from './sub-view/TotalReviewView';

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;
const style = css`
  ${flexColumn};
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin-bottom: 100px;
  margin-top: 60px;
`;
const imgStyle = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  height: 100%;
`;
const ctaBtnWrp = css`
  margin: -1px 0 -2px;
  width: 100%;
  img {
    width: 100%;
  }
`;
const btnWrp = css`
  max-width: ${StyleConstants.MAX_WIDTH}px;
  position: fixed;
  bottom: 22px;
  width: 100%;
  z-index: 65535;
  img {
    width: 90%;
    margin: 0 5%;
  }
`;
const reviewSlideStyle = css`
  margin-top: -2px;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: 100%;
  height: 100%;
`;
const slideStyle = css`
  position: absolute;
  display: block;
  margin-left: 24px;
  max-width: ${StyleConstants.MAX_WIDTH}px;
  width: calc(100% - 24px);
  margin-top: -280px;
  @media (min-width: 390px) {
    margin-top: -310px;
  }
  @media (min-width: 420px) {
    margin-top: -360px;
  }
  @media (min-width: 470px) {
    margin-top: -380px;
  }
  @media (min-width: 640px) {
    margin-top: -400px;
  }
`;

export default function ProgramDetailView({
  type,
  onClickOpenModal,
  isOpenModal,
  isCta,
  isOpenMenu,
  reviewList,
  totalParticipatedCnt,
  avgImprovementRate,
  reviewCnt,
  isStartReviewScoreAnimation,
}) {
  const [isOpenVideoView, setIsOpenVideoView] = React.useState(false);
  const [isOpenImgView, setIsOpenImgView] = React.useState(false);
  const [isOpenReviewView, setIsOpenReviewView] = React.useState(false);
  const [reviewInfo, setReviewInfo] = React.useState({});
  const [offsetY, setOffsetY] = React.useState(0);

  React.useEffect(() => {
    if (offsetY !== 0 && !isOpenImgView && !isOpenVideoView && !isOpenReviewView) {
      document.body.style.position = '';
      window.scrollTo(0, offsetY);
    }
  }, [isOpenImgView, isOpenVideoView, isOpenReviewView]);

  const getUserName = (userName) => {
    if (userName.length === 2) {
      return `${userName[0]}*`;
    } else if (userName.length === 3) {
      return `${userName[0]}*${userName[1]}`;
    } else {
      return `${userName[0]}**${userName[userName.length - 1]}`;
    }
  };

  const onClickOpenVideoView = (src, review) => {
    setOffsetY(window.scrollY);
    document.body.style.position = 'fixed';
    setReviewInfo({
      src,
      review: review.review,
      starRate: review.starRate,
      userName: getUserName(review.dgUserProgramInfo.dgUserInfo.userName),
      petKind: review.dgUserProgramInfo.dgPetInfo.petKind,
      age: `${moment().diff(review.dgUserProgramInfo.dgPetInfo.petBirthYm, 'years')}세`,
      badge: [
        review.dgUserProgramInfo.dgProgramInfo.programName.includes('짖음') ? '🙀 짖음 개선' : '😿 분리불안 개선',
        review.improvement === 1
          ? '🥰 개선 되었어요'
          : review.improvement === 2
          ? '😉 어느정도 개선'
          : '🙅🏻‍♀️ 개선되지 않았어요',
      ],
    });
    setIsOpenVideoView(true);
  };

  const onClickOpenImgView = (src, review) => {
    setOffsetY(window.scrollY);
    document.body.style.position = 'fixed';
    setReviewInfo({
      src,
      review: review.review,
      starRate: review.starRate,
      userName: getUserName(review.dgUserProgramInfo.dgUserInfo.userName),
      petKind: review.dgUserProgramInfo.dgPetInfo.petKind,
      age: `${moment().diff(review.dgUserProgramInfo.dgPetInfo.petBirthYm, 'years')}세`,
      badge: [
        review.dgUserProgramInfo.dgProgramInfo.programName.includes('짖음') ? '🙀 짖음 개선' : '😿 분리불안 개선',
        review.improvement === 1
          ? '🥰 개선 되었어요'
          : review.improvement === 2
          ? '😉 어느정도 개선'
          : '🙅🏻‍♀️ 개선되지 않았어요',
      ],
    });
    setIsOpenImgView(true);
  };

  const onClickOpenReviewView = () => {
    setOffsetY(window.scrollY);
    document.body.style.position = 'fixed';
    setIsOpenReviewView(true);
  };

  const onClickCloseVideoView = () => {
    setReviewInfo({});
    setIsOpenVideoView(false);
  };

  const onClickCloseImgView = () => {
    setReviewInfo({});
    setIsOpenImgView(false);
  };

  const onClickCloseReviewView = () => {
    setIsOpenReviewView(false);
  };

  return (
    <>
      {isOpenImgView && <ImgView reviewInfo={reviewInfo} onClickCloseImgView={onClickCloseImgView} />}
      {isOpenVideoView && <VideoView reviewInfo={reviewInfo} onClickCloseVideoView={onClickCloseVideoView} />}
      {isOpenReviewView && <TotalReviewView onClickCloseReviewView={onClickCloseReviewView} />}
      {
        <article className={style}>
          <img className={imgStyle} src={`//static.dogmate.co.kr/dogit/v4/detail/dogit-v4-detail-1-14.png`} />
          <img className={imgStyle} src={`//static.dogmate.co.kr/dogit/v4/detail/dogit-v4-detail-2-${type}.png`} />
          <div className={ctaBtnWrp}>
            <img
              id='cta-btn'
              onClick={() => onClickOpenModal(DOGIT_V3_EVENT_TYPE.CTA)}
              src='//static.dogmate.co.kr/dogit/v4/detail/dogit-v4-detail-cta-1.png'
            />
          </div>
          <img className={imgStyle} src={`//static.dogmate.co.kr/dogit/v4/detail/dogit-v4-detail-v4-3-${type}.png`} />
          <div className={imgStyle}>
            <img className={imgStyle} src='//static.dogmate.co.kr/dogit/v4/detail/dogit-v4-detail-v3-4-14.png' />
          </div>
          <ReviewViewV2
            onClickOpenImgView={onClickOpenImgView}
            onClickOpenReviewView={onClickOpenReviewView}
            onClickOpenVideoView={onClickOpenVideoView}
            reviewList={reviewList}
            totalParticipatedCnt={totalParticipatedCnt}
            avgImprovementRate={avgImprovementRate}
            reviewCnt={reviewCnt}
            isStartReviewScoreAnimation={isStartReviewScoreAnimation}
          />
          <img className={imgStyle} src={`//static.dogmate.co.kr/dogit/v4/detail/dogit-v4-detail-6-v2.png`} />
          <img className={imgStyle} src={`//static.dogmate.co.kr/dogit/v4/detail/dogit-v4-detail-7-14-v2.png`} />
        </article>
      }
      {!isOpenModal && isCta && !isOpenMenu && !isOpenImgView && !isOpenVideoView && !isOpenReviewView && (
        <div className={btnWrp}>
          <img
            id='floating-btn'
            onClick={() => onClickOpenModal(DOGIT_V3_EVENT_TYPE.CTA_FLOATING)}
            src={`//static.dogmate.co.kr/dogit/v4/detail/dogit-v4-detail-cta.png`}
          />
        </div>
      )}
    </>
  );
}
